import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import CardMain from "../../components/CardMain";

function Investors() {
  const [investors, setInvestors] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Investor" };

  useEffect(() => {
    let unmounted = false;

    const fetchInvestors = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/investor",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setInvestors(responseData.investors);
      } catch (err) {}
    };
    fetchInvestors();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const numberWithCommas = (number) => {
    return Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/investor/${cell.row.values.id}`);
      },
    };
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },

      {
        Header: "Data",
        accessor: "date",
        Cell: ({ row }) =>
          new Date(row.values.date).toLocaleDateString("en-GB"),
      },
      {
        Header: "Investor",
        accessor: "clientName",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Sum",
        accessor: "sum",
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {`${numberWithCommas(row.values.sum)} $`}
          </div>
        ),
      },
      // {
      //   Header: "TotalToday",
      //   accessor: "totalNow",
      //   Cell: ({ row }) => (
      //     <div
      //       style={{
      //         textAlign: "right",
      //       }}
      //     >
      //       {`${numberWithCommas(row.values.totalNow)} $`}
      //     </div>
      //   ),
      // },
      {
        Header: "Comments",
        accessor: "comments",
      },

      {
        Header: "userId",
        accessor: "userid",
        show: false,
      },
    ],
    []
  );

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}
      {!isLoading && investors && (
        <CardMain cardReport={true} cardName={cardOptions.cardName}>
          <ReactTable
            columns={columns}
            data={investors}
            getCellProps={onClickCellHandler}
          />
        </CardMain>
      )}
    </>
  );
}

export default Investors;
