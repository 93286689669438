import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Spinner, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import ClientField from "../../components/elements/form/ClientField";
import DateField from "../../components/elements/form/DateField";
import SumField from "../../components/elements/form/SumField";
import CommentField from "../../components/elements/form/CommentField";

// Schema for yup
const validationSchema = Yup.object().shape({
  clientName: Yup.string().required("*Investor is required"),
  description: Yup.string().required("*Description is required"),
  sum: Yup.number().required("*Sum is required"),
});

const NewInvestor = () => {
  const [investors, setInvestors] = useState();
  const [loadedClients, setLoadedClients] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Investor" };

  useEffect(() => {
    let unmounted = false;

    const fetchInvestors = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/investor",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setInvestors(responseData.investors);
      } catch (err) {}
    };
    fetchInvestors();

    const fetchClients = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/client",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.clients.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );

        setLoadedClients(responseData.clients);
      } catch (err) {}
    };
    fetchClients();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  //Calculations

  if (investors && loadedClients) {
    investors.forEach((e) => {
      e.totalNow = _.sumBy(investors, (i) => {
        if (e.clientName === i.clientName) {
          return i.sum;
        }
      });
    });
  }
  const totalNow = (client) => {
    let total = _.sumBy(investors, (i) => {
      if (client === i.clientName) {
        return i.sum;
      }
    });
    return total;
  };
  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}
      {!isLoading && loadedClients && investors && (
        <Formik
          initialValues={{
            date: new Date().toISOString().split("T")[0],
            clientName: "",
            description: "",
            sum: "",
            totalNow: "",
            comments: "",
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/investor`,
                "POST",
                JSON.stringify({
                  date: new Date(values.date),
                  clientName: values.clientName,
                  description: values.description,
                  sum: values.sum,
                  totalNow: totalNow(values.clientName)
                    ? totalNow(values.clientName) + values.sum
                    : values.sum,
                  comments: values.comments,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
            navigate("../investors");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <DateField name="date" label="Date:" />
                  <ClientField name="clientName" label="Client:" />
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formDescription">
                    <Form.Label>Description:</Form.Label>
                    <Form.Select
                      type="text"
                      name="description"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    >
                      <option>...choose description</option>
                      <option key={1} value={"Payment"}>
                        Payment
                      </option>
                      <option key={2} value={"Investment"}>
                        Investment
                      </option>
                      <option key={3} value={"Income"}>
                        Income
                      </option>
                    </Form.Select>
                    {touched.description && errors.description ? (
                      <div className="error-message">{errors.description}</div>
                    ) : null}
                  </Form.Group>
                  <SumField name="sum" label="Sum:"></SumField>
                </Row>
                <Row className="mb-3">
                  <CommentField name="comments" label="Comments:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewInvestor;
