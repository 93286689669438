import React from "react";
import { Form, Col } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";

const CustomInputComponent = (props) => (
  <Form.Control type="number" disabled={props.disabled} {...props} />
);

function SumField(props) {
  const { label, name } = props;
  return (
    <Form.Group as={Col} controlId="formSum">
      <Form.Label>{label} </Form.Label>
      <Field
        as={CustomInputComponent}
        name={name}
        disabled={props.disabled ? true : false}
      />

      <ErrorMessage name={name}>
        {(msg) => <div style={{ color: "red", fontSize: "13px" }}>{msg}</div>}
      </ErrorMessage>
    </Form.Group>
  );
}

export default SumField;
