import React, { useEffect, useState, useContext } from "react";
import { Spinner, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";

export default function PaymentsUser() {
  const [payments, setPayments] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  //Card options
  let cardOptions = { cardName: "Payment" };

  useEffect(() => {
    let unmounted = false;

    const fetchSubscriptions = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/subscription/payments",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setPayments(responseData.payments);
      } catch (err) {}
    };
    fetchSubscriptions();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  //Columns
  const columns = [
    {
      Header: "Id",
      accessor: "id",
      disableFilters: true,
      show: false,
    },

    {
      Header: "Date",
      accessor: "date",
      Cell: ({ row }) => new Date(row.values.date).toLocaleDateString("en-GB"),
    },
    {
      Header: "chainName",
      accessor: "chainName",
    },
    {
      Header: "blockNumber",
      accessor: "blockNumber",
    },
    {
      Header: "cryptoAddress",
      accessor: "cryptoAddress",
    },
    {
      Header: "tokenName",
      accessor: "tokenName",
    },
    {
      Header: "sum",
      accessor: "sum",
    },
    {
      Header: "Subscription Id",
      accessor: "subId",
      // show: false,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {isLoading && <Spinner animation="border" />}
      {!isLoading && payments && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className=""></div>
              <div className="fs-4 me-5">{cardOptions.cardName}s</div>
              <div className="">
                <Link to="../subscriptions">
                  <button className="btn btn-sm btn-secondary">Back</button>
                </Link>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={payments}
              // getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}
