import React, { useEffect, useState, useContext } from "react";
import { Spinner, Button, Table } from "react-bootstrap";
import { Formik, Form, Field, FieldArray } from "formik";
//import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import "./Update.css";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { NumberWithCommas } from "../../components/table/FormatedCell";

// Schema for yup
// const validationSchema = Yup.object().shape({
//   chainNumber: Yup.number().required("Please add the number."),
//   chainName: Yup.string()
//     .max(15, "Must be 15 characters or less")
//     .required("Please add the name."),
// });

const UpdateHarvLending = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [lends, setLends] = useState();
  const [tokens, setTokens] = useState();
  const [pools, setPools] = useState();
  const [lendDetails, setLendDetails] = useState();
  const [loadedManagers, setLoadedManagers] = useState();
  const [harv, setHarv] = useState();
  const [incomes, setIncomes] = useState();
  const [bonuses, setBonuses] = useState();

  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Harvesting in Lending" };

  useEffect(() => {
    let unmounted = false;

    const fetchIncomes = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/income",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setIncomes(responseData.incomes);
      } catch (err) {}
    };
    fetchIncomes();

    const fetchBonuses = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/bonus",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setBonuses(responseData.bonuses);
      } catch (err) {}
    };
    fetchBonuses();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.tokens.sort((a, b) =>
          a.tokenSymbol > b.tokenSymbol
            ? 1
            : b.tokenSymbol > a.tokenSymbol
            ? -1
            : 0
        );
        if (!unmounted) setTokens(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    const fetchPools = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pool",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.pools.sort((a, b) =>
          a.poolName > b.poolName ? 1 : b.poolName > a.poolName ? -1 : 0
        );
        if (!unmounted) setPools(responseData.pools);
      } catch (err) {}
    };
    fetchPools();

    const fetchBorrows = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/borrow",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.borrows.sort((a, b) =>
          a.posDate < b.posDate ? 1 : b.posDate < a.posDate ? -1 : 0
        );
        if (!unmounted) setLendDetails(responseData.borrows);
      } catch (err) {}
    };
    fetchBorrows();

    const fetchLends = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/lend",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLends(responseData.lends);
      } catch (err) {}
    };
    fetchLends();

    const fetchManagers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/manager",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.managers.sort((a, b) =>
          a.managerName > b.managerName
            ? 1
            : b.managerName > a.managerName
            ? -1
            : 0
        );
        if (!unmounted) setLoadedManagers(responseData.managers);
      } catch (err) {}
    };
    fetchManagers();

    const fetchHarvById = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/harv/${Id}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setHarv(responseData.harv);
      } catch (err) {}
    };
    fetchHarvById();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token, Id]);

  //Delete
  const deleteHandler = async () => {
    //delete lendDetails
    lendDetails.forEach(async (d) => {
      if (d.posDate.split("T")[0] === harv.harvDate.split("T")[0]) {
        try {
          await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/borrow/${d.id}`,
            "DELETE",
            null,
            { Authorization: "Bearer " + auth.token }
          );
        } catch (err) {}
      }
    });

    //delete Incomes
    incomes.forEach(async (d) => {
      if (
        d.date.split("T")[0] === harv.harvDate.split("T")[0] &&
        d.incomeType === harv.incomeType
      ) {
        try {
          await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/income/${d.id}`,
            "DELETE",
            null,
            { Authorization: "Bearer " + auth.token }
          );
        } catch (err) {}
      }
    });

    //delete Bonuses
    bonuses.forEach(async (d) => {
      if (
        d.date.split("T")[0] === harv.harvDate.split("T")[0] &&
        d.incomeType === harv.incomeType
      ) {
        try {
          await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/bonus/${d.id}`,
            "DELETE",
            null,
            { Authorization: "Bearer " + auth.token }
          );
        } catch (err) {}
      }
    });

    //delete Harv
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/harv/${Id}`,
        "DELETE",
        null,
        { Authorization: "Bearer " + auth.token }
      );
      navigate("../harvesting");
    } catch (err) {}
  };

  //token price
  const getPrice = (tokenSymbol) => {
    let tokenObj;

    tokenObj = tokens.find((e) => e.tokenSymbol === tokenSymbol);
    return tokenObj.tokenPrice;
  };

  const ifNoneToZero = (digit) => {
    if (digit) {
      return digit;
    } else {
      return 0;
    }
  };
  //link from pool
  const getLink = (poolName) => {
    let link;
    pools.forEach((e) => {
      if (e.poolName === poolName) {
        link = e.poolLink;
      }
    });
    return link;
  };

  //Dates
  let yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split("T")[0];

  //Calculations
  let positions = [];
  let newLends;
  if (lends && tokens && pools && lendDetails && harv) {
    //Main loop
    lends.forEach((f, i) => {
      //Token price for sum
      let tokenPrice;
      tokens.forEach((t) => {
        tokenPrice = getPrice(f.tokenName);
      });
      f.posSum = f.tokenQty * tokenPrice;
      //pool link
      f.link = getLink(f.providerName);

      //uniqe name of position
      f.uniqName = `${f.providerName}_${f.chainName}_${f.clientName}_${f.managerName}`;
      f.lendingName = `${f.providerName}_${f.chainName}`;

      //pool token
      pools.forEach((p) => {
        if (p.poolName === f.providerName) {
          f.poolToken = p.poolCoin;
        }
      });
    });

    //new array
    let LendingNames = lends.map((e) => e.uniqName);
    let shortLend = lends.map(
      ({
        uniqName,
        lendingName,
        providerName,
        chainName,
        managerName,
        clientName,
        poolToken,
        userId,
      }) => ({
        uniqName,
        lendingName,
        providerName,
        chainName,
        managerName,
        clientName,
        poolToken,
        userId,
      })
    );

    newLends = shortLend.filter(
      ({ uniqName }, index) => !LendingNames.includes(uniqName, index + 1)
    );

    newLends.forEach((f) => {
      lends.forEach((l) => {
        if (l.uniqName === f.uniqName) {
          f.link = l.link;
          f.tokenName = l.poolToken;
          f.poolTokenPrice = getPrice(f.tokenName);
        }
      });
      //tokens in position
      f.posSum = _.sumBy(lends, (w) => {
        if (w.uniqName === f.uniqName) {
          return w.posSum;
        }
      });
      //tokens in position
      f.tokensInLending = _.sumBy(lends, (w) => {
        if (w.uniqName === f.uniqName) {
          return `${w.tokenName} `;
        }
      });

      //tokenQminus1
      f.tokenQtyMinus1 = _.sumBy(lendDetails, (w) => {
        if (
          w.posDate.split("T")[0] === yesterdayDate &&
          f.providerName === w.providerName &&
          !f.isHarvested &&
          w.chainName === f.chainName
        ) {
          return ifNoneToZero(w.tokenQty);
        } else {
          return 0;
        }
      });

      lendDetails.forEach((d) => {
        if (
          d.posDate.split("T")[0] === harv.harvDate.split("T")[0] &&
          d.lendingName === f.lendingName
        ) {
          f.isHarvested = d.isHarvested;
          f.tokenQty = d.tokenQty;
          f.incomeDay = d.posIncomeDay;
          f.incomeTolal = d.posIncomeTotal;
          f.apy = d.posApy;
        }
      });

      //positions array
      positions.push({
        posDate: new Date().toLocaleDateString("en-GB"),
        lendingName: f.lendingName,
        providerName: f.providerName,
        tokensInLending: f.tokensInLending,
        chainName: f.chainName,
        token: f.tokenName,
        isHarvested: f.isHarvested,
        posSum: f.posSum,
        link: f.link,
        tokenQty: f.tokenQty,
        tokenQtyMinus1: f.tokenQtyMinus1,
        incomeDay: f.incomeDay,
        incomeTotal: f.incomeTolal,
        apy: f.apy,
        tokenPrice: f.poolTokenPrice,
        uniqName: f.uniqName,
        managerName: f.managerName,
        clientName: f.clientName,
        userId: f.userId,
      });
    });
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading &&
        lends &&
        tokens &&
        pools &&
        lendDetails &&
        loadedManagers &&
        harv && (
          <Formik
            initialValues={{ positions: positions }}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              //Calc Details
              newLends.forEach((e) => {
                values.positions.forEach((v) => {
                  if (v.uniqName === e.uniqName) {
                    e.isHarvested = v.isHarvested;
                    e.tokenQty = v.tokenQty;
                    e.incomeTotal = v.incomeTotal;
                    e.incomeDay = v.incomeDay;
                    e.apy = v.apy;
                  }
                });

                //Submit Details
                const submitHarvDetail = async () => {
                  try {
                    await sendRequest(
                      process.env.REACT_APP_BACKEND_URL + `/borrow`,
                      "POST",
                      JSON.stringify({
                        posDate: new Date(),
                        lendingName: e.lendingName,
                        providerName: e.providerName,
                        tokensPos: e.tokensInLending,
                        posSum: e.posSum,
                        tokenName: e.tokenName,
                        tokenPrice: e.poolTokenPrice,
                        isHarvested: e.isHarvested,
                        tokenQty: e.tokenQty,
                        posIncomeTotal: e.incomeTotal,
                        posIncomeDay: e.incomeDay,
                        posApy: e.apy,
                        managerName: e.managerName,
                        clientName: e.clientName,
                        userId: auth.userId,
                      }),
                      {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + auth.token,
                      }
                    );
                  } catch (err) {}
                };
                submitHarvDetail();
              });

              //Calc Incomes
              let uniqNames = lends.map((e) => e.uniqName);

              let shortLend = lends.map(
                ({
                  uniqName,
                  lendingName,
                  providerName,
                  managerName,
                  userId,
                }) => ({
                  uniqName,
                  lendingName,
                  providerName,
                  managerName,
                  userId,
                })
              );

              newLends = shortLend.filter(
                ({ uniqName }, index) =>
                  !uniqNames.includes(uniqName, index + 1)
              );

              //submit Incomes
              newLends.forEach((h) => {
                lends.forEach((l) => {
                  if (l.uniqName === h.uniqName) {
                    h.providerName = l.providerName;
                    h.managerName = l.managerName;
                    h.userId = l.userId;
                  }
                });
                h.posSum = _.sumBy(values.positions, (w) => {
                  if (w.uniqName === h.uniqName) {
                    return ifNoneToZero(w.posSum);
                  } else {
                    return 0;
                  }
                });

                h.incomeDay = _.sumBy(values.positions, (w) => {
                  if (w.uniqName === h.uniqName) {
                    return ifNoneToZero(w.incomeDay);
                  } else {
                    return 0;
                  }
                });

                h.incomePercent = (h.incomeDay * 36500) / h.posSum;
                // //get manager from harvesting
                // let managerNameArray = [];
                // loadedFarming.forEach((w) => {
                //   if (h.providerName === w.providerName) {
                //     managerNameArray.push(w.managerName);
                //   }
                // });
                // h.managerName = managerNameArray[0];

                //submit
                const submitIncome = async () => {
                  try {
                    await sendRequest(
                      process.env.REACT_APP_BACKEND_URL + `/income`,
                      "POST",
                      JSON.stringify({
                        date: new Date(),
                        providerName: h.providerName,
                        incomeType: "Lending",
                        incomeDay: h.incomeDay,
                        incomePercent: h.incomePercent,
                        managerName: h.managerName,
                        userId: auth.userId,
                      }),
                      {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + auth.token,
                      }
                    );
                  } catch (err) {}
                };
                submitIncome();
              });

              //submit Bonus
              //Calc
              let incomeManager = [
                ...new Set(lends.map((item) => item.managerName)),
              ];
              const setManager = (item, index) => {
                var object = { managerName: item };
                return object;
              };
              let incomesMan = incomeManager.map(setManager);

              //Main loop
              incomesMan.forEach((m) => {
                m.incomeDay = _.sumBy(values.positions, (w) => {
                  if (m.managerName === w.managerName) {
                    return ifNoneToZero(w.incomeDay);
                  } else {
                    return 0;
                  }
                });
                m.managerBonus = _.sumBy(loadedManagers, (n) => {
                  if (m.managerName === n.managerName) {
                    return n.managerBonus;
                  }
                });
                m.bonusDay = (m.incomeDay * m.managerBonus) / 100;

                //submit
                const submitIncomeMan = async () => {
                  try {
                    await sendRequest(
                      process.env.REACT_APP_BACKEND_URL + `/bonus`,
                      "POST",
                      JSON.stringify({
                        date: new Date(),
                        managerName: m.managerName,
                        incomeType: "Lending",
                        incomeDay: m.incomeDay,
                        bonusDay: m.bonusDay,
                        userId: auth.userId,
                      }),
                      {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + auth.token,
                      }
                    );
                  } catch (err) {}
                };
                submitIncomeMan();
              });

              //Calc Harvesting
              //Clients
              let harvClient = [
                ...new Set(lends.map((item) => item.clientName)),
              ];
              const setClient = (item, index) => {
                var object = { clientName: item };
                return object;
              };
              let harvByClient = harvClient.map(setClient);

              //Main loop
              harvByClient.forEach((h) => {
                h.incomeTotal = _.sumBy(values.positions, (f) => {
                  if (f.clientName === h.clientName) {
                    return f.incomeTotal;
                  }
                });
                h.incomeDay = _.sumBy(values.positions, (f) => {
                  if (f.clientName === h.clientName) {
                    return f.incomeDay;
                  }
                });
                h.posSum = _.sumBy(values.positions, (f) => {
                  if (f.clientName === h.clientName) {
                    return f.posSum;
                  }
                });
                h.apy = Math.round((h.incomeDay * 36500) / h.posSum);
                h.positions = _.sumBy(values.positions, (f) => {
                  if (f.clientName === h.clientName) {
                    return `${f.providerName} ${NumberWithCommas(
                      f.posSum
                    )}$ ${NumberWithCommas(f.incomeDay)}$ ${NumberWithCommas(
                      f.apy
                    )}%|`;
                  }
                });

                //submit Harvesting
                const submitHarv = async () => {
                  try {
                    await sendRequest(
                      process.env.REACT_APP_BACKEND_URL + `/harv`,
                      "POST",
                      JSON.stringify({
                        harvDate: new Date(),
                        positions: h.positions,
                        incomeType: "Lending",
                        incomeTotal: h.incomeTotal,
                        incomeDay: h.incomeDay,
                        apy: h.apy,
                        clientName: h.clientName,
                        userId: auth.userId,
                      }),
                      {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + auth.token,
                      }
                    );
                  } catch (err) {}
                  navigate("../harvesting/");
                };

                submitHarv();
              }); //end harvesting
            }} //end submit
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} className="w-100">
                <div className="card m-3">
                  <h5 className="card-header text-center">
                    {cardOptions.cardName}
                  </h5>
                  <div className="card-body border-bottom ">
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>lendingName</th>
                          <th>Provider</th>
                          <th>tokensInLending:</th>
                          <th>Sum</th>
                          <th>Token</th>
                          <th>isHar-d</th>
                          <th>tokenQty</th>
                          <th>incomeDay</th>
                          <th>incomeTotal</th>
                          <th>APY</th>
                          <th>Client</th>
                          <th style={{ display: "none" }}>HiddenField</th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="positions">
                          {() =>
                            positions.map((e, idx) => {
                              return (
                                <tr key={idx}>
                                  <td> {e.posDate}</td>
                                  <td> {e.lendingName}</td>
                                  <td>
                                    <a
                                      href={e.link}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {e.providerName}
                                    </a>
                                  </td>
                                  <td> {e.tokensInLending}</td>
                                  <td> {`$${NumberWithCommas(e.posSum)}`}</td>
                                  <td> {e.token}</td>
                                  <td>
                                    <Field
                                      name={`positions.${idx}.isHarvested`}
                                      className={"checkbox"}
                                      type="checkbox"
                                      disabled
                                      onChange={(e) => {
                                        let isHarv =
                                          !values.positions[idx].isHarvested;

                                        if (isHarv) {
                                          values.positions[idx].incomeDay =
                                            values.positions[idx].tokenQty *
                                            values.positions[idx].tokenPrice;
                                          values.positions[idx].incomeTotal =
                                            values.positions[idx].tokenQty *
                                            values.positions[idx].tokenPrice;
                                          values.positions[idx].apy =
                                            (values.positions[idx].incomeDay *
                                              36500) /
                                            values.positions[idx].posSum;
                                        } else {
                                          values.positions[idx].incomeDay =
                                            (values.positions[idx].tokenQty -
                                              values.positions[idx]
                                                .tokenQtyMinus1) *
                                            values.positions[idx].tokenPrice;
                                          values.positions[idx].incomeTotal =
                                            values.positions[idx].tokenQty *
                                            values.positions[idx].tokenPrice;
                                          values.positions[idx].apy =
                                            (values.positions[idx].incomeDay *
                                              36500) /
                                            values.positions[idx].posSum;
                                        }
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`positions.${idx}.tokenQty`}
                                      className={"form-control"}
                                      type="number"
                                      disabled
                                      style={{
                                        fontSize: "12px",
                                        width: "7rem",
                                        border: "1px solid #292929",
                                        borderRadius: "3px",
                                      }}
                                      onChange={handleChange}
                                      onBlur={(e) => {
                                        let isHarv =
                                          values.positions[idx].isHarvested;

                                        if (isHarv) {
                                          values.positions[idx].incomeDay =
                                            values.positions[idx].tokenQty *
                                            values.positions[idx].tokenPrice;
                                          values.positions[idx].incomeTotal =
                                            values.positions[idx].tokenQty *
                                            values.positions[idx].tokenPrice;
                                          values.positions[idx].apy =
                                            (values.positions[idx].incomeDay *
                                              36500) /
                                            values.positions[idx].posSum;
                                        } else {
                                          values.positions[idx].incomeDay =
                                            (values.positions[idx].tokenQty -
                                              values.positions[idx]
                                                .tokenQtyMinus1) *
                                            values.positions[idx].tokenPrice;
                                          values.positions[idx].incomeTotal =
                                            values.positions[idx].tokenQty *
                                            values.positions[idx].tokenPrice;
                                          values.positions[idx].apy =
                                            (values.positions[idx].incomeDay *
                                              36500) /
                                            values.positions[idx].posSum;
                                        }
                                        handleBlur(e);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`positions.${idx}.incomeDay`}
                                      className={"form-control-plaintext"}
                                      type="hidden"
                                    />
                                    {`$${NumberWithCommas(
                                      values.positions[idx].incomeDay
                                    )}`}
                                  </td>
                                  <td>
                                    <Field
                                      name={`positions.${idx}.incomeTotal`}
                                      type="hidden"
                                    />
                                    {`$${NumberWithCommas(
                                      values.positions[idx].incomeTotal
                                    )}`}
                                  </td>
                                  <td>
                                    <Field
                                      name={`positions.${idx}.apy`}
                                      type="hidden"
                                    />
                                    {`${NumberWithCommas(
                                      values.positions[idx].apy
                                    )}%`}
                                  </td>
                                  <td>{e.clientName}</td>
                                  <td style={{ display: "none" }}>
                                    <Field
                                      name={`positions.${idx}.uniqName`}
                                      type="hidden"
                                    />
                                    <Field
                                      name={`positions.${idx}.tokenQtyMinus1`}
                                      type="hidden"
                                    />

                                    <Field
                                      name={`positions.${idx}.tokenPrice`}
                                      type="hidden"
                                    />
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </FieldArray>
                      </tbody>
                    </Table>
                  </div>
                  <div className="card-footer text-center border-top-0">
                    <Button
                      className="me-1"
                      variant="danger"
                      type="button"
                      onClick={deleteHandler}
                    >
                      Delete
                    </Button>

                    <Link to="../harvesting">
                      <button
                        className=" btn btn-outline-secondary"
                        type="button"
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
    </>
  );
};

export default UpdateHarvLending;
