import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Spinner, Card, Button } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { EditableCell } from "../../components/table/EditableCell";
import { ReactTable } from "../../components/TableSearch";
import {
  NumberWithCommas,
  LinkCell,
} from "../../components/table/FormatedCell";
import { getPrice, customFetch } from "../../components/Functions";

function Wallet() {
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [wallets, setWallets] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [chains, setChains] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Wallet" };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      customFetch(sendRequest, auth, "/wallet", (data) =>
        setWallets(data.wallets)
      );
      customFetch(sendRequest, auth, "/token", (data) =>
        setLoadedTokens(data.tokens)
      );
      customFetch(sendRequest, auth, "/chain", (data) =>
        setChains(data.chains)
      );
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth]);

  let allLoaded = !isLoading && wallets && loadedTokens && chains;

  //Calculations

  if (allLoaded) {
    wallets.forEach((e) => {
      e.tokenPrice = getPrice(loadedTokens, e.tokenName);
      let tokenSum = e.tokenQty * e.tokenPrice;
      e.tokenSum = +(Math.round(tokenSum * 100) / 100).toFixed(0);

      let chainsEVM =
        e.chainName === "Ethereum" ||
        e.chainName === "BSC" ||
        e.chainName === "Optimism" ||
        e.chainName === "Polygon";
      //add link
      chains.forEach((x) => {
        if (x.chainName === e.chainName) {
          if (e.walletAddress && chainsEVM) {
            e.link = `${x.explorer}/address/${e.walletAddress}`;
          } else {
            e.link = x.explorer;
          }
        }
      });
    });
    for (const item of wallets) {
      if (item.isInvestment) {
        item.isInvestment = "Yes";
      } else {
        item.isInvestment = "";
      }
      if (item.inFarming) {
        item.inFarming = "Yes";
      } else {
        item.inFarming = "";
      }
    }
    wallets.sort((a, b) =>
      a.tokenSum < b.tokenSum ? 1 : b.tokenSum < a.tokenSum ? -1 : 0
    );
  }

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setWallets((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const submitData = async () => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/wallet/${row.id}`,
                "PATCH",
                JSON.stringify({
                  tokenName: row.tokenName,
                  tokenQty: value,
                  chainName: row.chainName,
                  isInvestment: row.isInvestment === "Yes" ? true : false,
                  inFarming: row.inFarming === "Yes" ? true : false,
                  comments: row.comments,
                  clientName: row.clientName,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
          };
          submitData();
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) setSkipPageReset(false);

    return () => {
      unmounted = true;
    };
  }, []);

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        switch (cell.column.id) {
          case "chainName":
            window.open(`${cell.row.original.link}`);
            break;
          case "tokenQty":
            break;

          default:
            navigate(`/wallet/${cell.row.values.id}`);
            break;
        }
      },
    };
  };

  let cardReport = auth.planName === "Base" ? false : true;

  //Window size cut colomn
  let showColomn;
  let windowWidth = window.innerWidth;
  if (windowWidth < 1200) {
    showColomn = false;
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      disableFilters: true,
      show: false,
    },
    {
      Header: "Token",
      accessor: "tokenName",
    },
    {
      Header: "Q-ty",
      accessor: "tokenQty",
      disableFilters: true,
      Cell: EditableCell,
    },
    {
      Header: "Price",
      accessor: "tokenPrice",
      disableFilters: true,
    },
    {
      Header: "Sum",
      accessor: "tokenSum",
      disableFilters: true,
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.tokenSum)} $`}</div>
      ),
    },
    {
      Header: "Chain",
      accessor: "chainName",
      Cell: LinkCell,
    },
    {
      Header: "isInvestment",
      accessor: "isInvestment",
      show: showColomn,
    },
    {
      Header: "inFarming",
      accessor: "inFarming",
      show: showColomn,
    },
    {
      Header: "Imported",
      accessor: "imported",
      show: showColomn,
    },
    {
      Header: "Comments",
      accessor: "comments",
      show: showColomn,
      disableFilters: true,
    },
    {
      Header: "Client",
      accessor: "clientName",
      show: showColomn,
      disableFilters: true,
    },
    {
      Header: "userId",
      accessor: "userid",
      disableFilters: true,
      show: false,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className="">
                <Button
                  as={Link}
                  to={`../wallet-imports`}
                  className="btn btn-sm btn-success me-2"
                >
                  Import <br />
                  wallet
                </Button>
                <Button
                  as={Link}
                  to={`../wallets/new`}
                  className="btn btn-sm btn-secondary "
                >
                  Add <br />
                  manually
                </Button>
              </div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              {cardReport && (
                <div className="">
                  <Button
                    as={Link}
                    to={`../wallets/report`}
                    className="btn btn-sm btn-secondary"
                  >
                    Report
                  </Button>
                </div>
              )}
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={wallets}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Wallet;
