import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import DateField from "../../components/elements/form/DateField";
import ClientField from "../../components/elements/form/ClientField";
import CommentField from "../../components/elements/form/CommentField";

// Schema for yup
const validationSchema = Yup.object().shape({
  clientName: Yup.string().required("*Client is required"),
});

const NewBalance = () => {
  const [balances, setBalances] = useState();
  const [wallets, setWallets] = useState();
  const [cexs, setCexs] = useState();
  const [loadedFarming, setLoadedFarming] = useState();
  const [loadedLends, setLoadedLends] = useState();
  const [harvs, setHarvs] = useState();
  const [offsets, setOffsets] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedClients, setLoadedClients] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Balance" };

  let balanceByClient;

  useEffect(() => {
    let unmounted = false;

    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };

    if (!unmounted) {
      fetch("/setting", (data) => setloadedSettings(data.settings));
      fetch("/offset", (data) => setOffsets(data.offsets));
      fetch("/harv", (data) => setHarvs(data.harvs));
      fetch("/cex", (data) => setCexs(data.cexs));
      fetch("/farm", (data) => setLoadedFarming(data.farms));
      fetch("/lend", (data) => setLoadedLends(data.lends));
      fetch("/balance", (data) => setBalances(data.balances));
      fetch("/wallet", (data) => setWallets(data.wallets));
      fetch("/token", (data) => setLoadedTokens(data.tokens));
      fetch("/client", (data) => setLoadedClients(data.clients));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const getPrice = (tokenSymbol) => {
    let tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    return tokenObj.tokenPrice;
  };
  //Calculations
  let defaultClient;
  if (
    !isLoading &&
    balances &&
    loadedClients &&
    loadedTokens &&
    loadedFarming &&
    loadedLends &&
    wallets &&
    harvs &&
    offsets &&
    cexs &&
    loadedSettings
  ) {
    //Default client
    loadedSettings.forEach((s) => {
      if (s.description === "Default client") {
        defaultClient = s.value;
      }
    });

    wallets.forEach((w) => {
      w.tokenPrice = getPrice(w.tokenName);
      w.sum = w.tokenQty * w.tokenPrice;
    });
    cexs.forEach((c) => {
      c.tokenPrice = getPrice(c.tokenName);
      c.actSum = c.tokenQty * c.tokenPrice;

      switch (c.orderType) {
        case "Short":
          c.pnl = c.tokenQty * c.priceOpen - c.actSum;

          break;
        case "Long":
          c.pnl = c.actSum - c.tokenQty * c.priceOpen;

          break;
        case "Collateral":
          c.pnl = 0;
          c.leverage = 1;

          break;
        default:
          c.pnl = 0;
          c.leverage = 1;
          break;
      }
      let sumLev = c.actSum / c.leverage;
      c.posActual = c.pnl + sumLev + c.collAdded;
    });
    //Farming
    loadedFarming.forEach((f) => {
      f.pairs = f.pair1 + "/" + f.pair2;
      f.pair1Price = getPrice(f.pair1);
      f.pair2Price = getPrice(f.pair2);

      //IL
      let ilSum = f.newPair1Qty * f.pair1Price + f.newPair2Qty * f.pair2Price;
      f.newPair1Qty = ilSum / 2 / f.pair1Price;
      f.newPair2Qty = ilSum / 2 / f.pair2Price;
      let ilK = f.newPair1Qty * f.newPair2Qty;
      let ilR = f.pair2Price / f.pair1Price;
      f.pair1Qty = Math.sqrt(ilK * ilR);
      f.pair2Qty = Math.sqrt(ilK / ilR);
      let pair1Sum = f.pair1Qty * f.pair1Price;
      let pair2Sum = f.pair2Qty * f.pair2Price;
      f.posSum = pair1Sum + pair2Sum;
    });

    //Lending
    loadedLends.forEach((l) => {
      l.tokenPrice = getPrice(l.tokenName);

      l.lendingName = l.providerName + "_" + l.chainName;
      l.lendingSum = l.tokenQty * l.tokenPrice;
    });

    //Clients
    let balanceClient = [
      ...new Set(loadedClients.map((item) => item.clientName)),
    ];
    const setClient = (item, index) => {
      var object = { clientName: item };
      return object;
    };
    balanceByClient = balanceClient.map(setClient);

    //Balances
    balanceByClient.forEach((b) => {
      b.wallet = _.sumBy(wallets, (i) => {
        if (b.clientName === i.clientName && i.isInvestment === false) {
          return i.sum;
        } else {
          return 0;
        }
      });
      b.walletInvest = _.sumBy(wallets, (i) => {
        if (b.clientName === i.clientName && i.isInvestment === true) {
          return i.sum;
        } else {
          return 0;
        }
      });
      b.exchange = _.sumBy(cexs, (i) => {
        if (i.clientName === b.clientName) {
          return i.posActual;
        } else {
          return 0;
        }
      });
      b.farming = _.sumBy(loadedFarming, (i) => {
        if (b.clientName === i.clientName) {
          return i.posSum;
        } else {
          return 0;
        }
      });
      b.lending = _.sumBy(loadedLends, (i) => {
        if (b.clientName === i.clientName) {
          return i.lendingSum;
        } else {
          return 0;
        }
      });
      b.harvesting = _.sumBy(harvs, (i) => {
        if (
          b.clientName === i.clientName &&
          new Date(i.harvDate).toLocaleDateString("en-GB") ===
            new Date().toLocaleDateString("en-GB")
        ) {
          return i.incomeTotal;
        } else {
          return 0;
        }
      });
      b.offsets =
        _.sumBy(offsets, (i) => {
          if (b.clientName === i.clientName1) {
            return i.sum;
          } else {
            return 0;
          }
        }) -
        _.sumBy(offsets, (i) => {
          if (b.clientName === i.clientName2) {
            return i.sum;
          } else {
            return 0;
          }
        });

      b.totalBalance =
        b.wallet +
        b.walletInvest +
        b.exchange +
        b.farming +
        b.lending +
        b.harvesting +
        b.offsets;
    });
  }
  const getWallet = (client) => {
    let total = _.sumBy(balanceByClient, (i) => {
      if (client === i.clientName) {
        return i.wallet;
      }
    });
    return total;
  };
  const getWalletInvest = (client) => {
    let total = _.sumBy(balanceByClient, (i) => {
      if (client === i.clientName) {
        return i.walletInvest;
      }
    });
    return total;
  };
  const getExchanges = (client) => {
    let total = _.sumBy(balanceByClient, (i) => {
      if (client === i.clientName) {
        return i.exchange;
      }
    });
    return total;
  };
  const getFarming = (client) => {
    let total = _.sumBy(balanceByClient, (i) => {
      if (client === i.clientName) {
        return i.farming;
      }
    });
    return total;
  };
  const getLending = (client) => {
    let total = _.sumBy(balanceByClient, (i) => {
      if (client === i.clientName) {
        return i.lending;
      }
    });
    return total;
  };
  const getHarvesting = (client) => {
    let total = _.sumBy(balanceByClient, (i) => {
      if (client === i.clientName) {
        return i.harvesting;
      }
    });
    return total;
  };
  const getOffsets = (client) => {
    let total = _.sumBy(balanceByClient, (i) => {
      if (client === i.clientName) {
        return i.offsets;
      }
    });
    return total;
  };
  const getBalance = (client) => {
    let total = _.sumBy(balanceByClient, (i) => {
      if (client === i.clientName) {
        return i.totalBalance;
      }
    });
    return total;
  };
  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}
      {!isLoading &&
        balances &&
        loadedClients &&
        loadedTokens &&
        loadedFarming &&
        loadedLends &&
        wallets &&
        harvs &&
        offsets &&
        cexs && (
          <Formik
            initialValues={{
              date: new Date().toISOString().split("T")[0],
              clientName: defaultClient ? defaultClient : "",
              comments: "",
              userId: auth.userId,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              try {
                await sendRequest(
                  process.env.REACT_APP_BACKEND_URL + `/balance`,
                  "POST",
                  JSON.stringify({
                    date: new Date(values.date),
                    clientName: values.clientName,
                    wallet: getWallet(values.clientName)
                      ? getWallet(values.clientName)
                      : 0,
                    walletInvest: getWalletInvest(values.clientName)
                      ? getWalletInvest(values.clientName)
                      : 0,
                    exchange: getExchanges(values.clientName)
                      ? getExchanges(values.clientName)
                      : 0,
                    farming: getFarming(values.clientName)
                      ? getFarming(values.clientName)
                      : 0,
                    lending: getLending(values.clientName)
                      ? getLending(values.clientName)
                      : 0,
                    harvesting: getHarvesting(values.clientName)
                      ? getHarvesting(values.clientName)
                      : 0,
                    offsets: getOffsets(values.clientName)
                      ? getOffsets(values.clientName)
                      : 0,
                    totalBalance: getBalance(values.clientName)
                      ? getBalance(values.clientName)
                      : 0,
                    comments: values.comments,
                    userId: auth.userId,
                  }),
                  {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + auth.token,
                  }
                );
              } catch (err) {}
              navigate("../balances");
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit} className="mx-auto w-75">
                {isLoading && <Spinner animation="border" />}
                <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                  <Row className="mb-3">
                    <DateField name="date" label="Date:" />
                    <ClientField name="clientName" label="Client:" />
                  </Row>
                  <Row className="mb-3">
                    <CommentField name="comments" label="Comments:" />
                  </Row>
                </CardNewUpdate>
              </Form>
            )}
          </Formik>
        )}
    </>
  );
};

export default NewBalance;
