import React, { useContext, useState, useEffect } from "react";
import { Form, Spinner, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import PoolField from "../../components/elements/form/PoolField";
import TokenField from "../../components/elements/form/TokenField";
import SumField from "../../components/elements/form/SumField";
import ManagerField from "../../components/elements/form/ManagerField";
import ChainField from "../../components/elements/form/ChainField";
import CommentField from "../../components/elements/form/CommentField";

// Schema for yup
const validationSchema = Yup.object().shape({
  providerName: Yup.string().required("*Provider is required"),
  poolName: Yup.string().required("*Pool is required"),
  pair1: Yup.string().required("*Pair is required"),
  pair2: Yup.string().required("*Pair is required"),
  apy: Yup.number().required("*APY is required"),
  chainName: Yup.string().required("*Chain is required"),
  managerName: Yup.string().required("*Manager is required"),
});

const UpdateProject = () => {
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedChains, setLoadedChains] = useState();
  const [loadedClients, setLoadedClients] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const [loadedManagers, setLoadedManagers] = useState();
  const [loadedProject, setLoadedProject] = useState();
  const [loadedFarming, setLoadedFarming] = useState();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Project" };

  useEffect(
    () => {
      let unmounted = false;

      const fetch = async (path, setLoad) => {
        try {
          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + path,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          );

          setLoad(responseData);
        } catch (err) {}
      };

      if (!unmounted) {
        fetch("/pool", (data) => setLoadedPools(data.pools));
        fetch("/token", (data) => setLoadedTokens(data.tokens));
        fetch(`/project/${Id}`, (data) => setLoadedProject(data.project));
        fetch("/chain", (data) => setLoadedChains(data.chains));
        fetch("/client", (data) => setLoadedClients(data.clients));
        fetch("/manager", (data) => setLoadedManagers(data.managers));
        fetch("/farm", (data) => setLoadedFarming(data.farms));
      }

      return () => {
        unmounted = true;
      };
    },
    [sendRequest, auth.token, Id],
    Id
  );

  let allLoaded =
    !isLoading &&
    loadedTokens &&
    loadedClients &&
    loadedChains &&
    loadedPools &&
    loadedManagers &&
    loadedProject &&
    loadedFarming;
  let inPosition = false;
  if (allLoaded) {
    //Check positions in farming
    loadedFarming.forEach((f) => {
      if (f.projectNumber === loadedProject.projectNumber) {
        inPosition = true;
      }
    });
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <Formik
          initialValues={{
            projectNumber: loadedProject.projectNumber,
            providerName: loadedProject.providerName,
            poolName: loadedProject.poolName,
            pair1: loadedProject.pair1,
            pair2: loadedProject.pair2,
            apy: loadedProject.apy,
            chainName: loadedProject.chainName,
            managerName: loadedProject.managerName,
            inFocus: loadedProject.inFocus ? loadedProject.inFocus : false,
            comments: loadedProject.comments ? loadedProject.comments : "",
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/project/${Id}`,
                "PATCH",
                JSON.stringify({
                  projectNumber: values.projectNumber,
                  providerName: values.providerName,
                  poolName: values.poolName,
                  pair1: values.pair1,
                  pair2: values.pair2,
                  apy: values.apy,
                  chainName: values.chainName,
                  managerName: values.managerName,
                  inFocus: values.inFocus,
                  comments: values.comments,
                  userId: values.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );

              navigate("../projects");
            } catch (err) {}
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate
                cardNew={false}
                cardName={cardOptions.cardName}
                isbuttondisabled={inPosition ? 1 : undefined}
              >
                <Row className="mb-3">
                  <SumField
                    name="projectNumber"
                    label="Project Number:"
                    disabled={true}
                  ></SumField>
                  <PoolField
                    name="providerName"
                    label="Provider Name:"
                    disabled={inPosition}
                  />
                  <PoolField
                    name="poolName"
                    label="Pool Name:"
                    disabled={inPosition}
                  />
                </Row>

                <Row className="mb-3">
                  <TokenField
                    name="pair1"
                    label="Pair 1:"
                    disabled={inPosition}
                  />
                  <TokenField
                    name="pair2"
                    label="Pair 2:"
                    disabled={inPosition}
                  />
                  <SumField name="apy" label="APY:"></SumField>
                </Row>
                <Row className="mb-3">
                  <ChainField
                    name="chainName"
                    label="Chain:"
                    disabled={inPosition}
                  />
                  <ManagerField
                    name="managerName"
                    label="Manager:"
                    disabled={inPosition}
                  />
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formStatus">
                    <Form.Label>In Focus:</Form.Label>
                    <Form.Check
                      type="checkbox"
                      name="inFocus"
                      checked={values.inFocus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.status}
                    />
                    {
                      <div className="message">
                        If checked, will be on top of the list.
                      </div>
                    }
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <CommentField name="comments" label="Comments:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UpdateProject;
