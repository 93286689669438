import React from "react";
import { Link } from "react-router-dom";

export default function MainInside() {
  return (
    <div className="px-4 pt-5 my-5 text-center mx-auto border-bottom">
      <h3 className="display-4 fw-bold">Welcome to cryptoBase</h3>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">Please, consider this is Beta version.</p>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
          <Link to="../subscriptions">
            <button type="button" className="btn btn-success me-1">
              Subscription
            </button>
          </Link>
          <Link to="../settings">
            <button type="button" className="btn btn-secondary">
              Setting
            </button>
          </Link>

          <a
            className="btn btn-warning"
            href="https://docs.cryptobase.bimdao.io/"
          >
            How to
          </a>
        </div>
      </div>
      <div className="overflow-hidden" style={{ maxHeight: "30vh" }}></div>
    </div>
  );
}
