import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components//TableSearch";
import CardMain from "../../components/CardMain";
import {
  NumberWithCommas,
  ColorCell,
} from "../../components/table/FormatedCell";

function Incomes() {
  const [incomes, setIncomes] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Income" };

  useEffect(() => {
    let unmounted = false;

    const fetchSettings = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/setting",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setloadedSettings(responseData.settings);
      } catch (err) {}
    };
    fetchSettings();

    const fetchIncomes = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/income",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setIncomes(responseData.incomes);
      } catch (err) {}
    };
    fetchIncomes();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/income/${cell.row.values.id}`);
      },
    };
  };

  //Calculations
  let yellowGreen, redYellow;
  if (!isLoading && incomes && loadedSettings) {
    yellowGreen = loadedSettings.find((d) => d.name === "colorGreenApi");
    redYellow = loadedSettings.find((d) => d.name === "colorRedApi");
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      show: false,
    },

    {
      Header: "Date",
      accessor: "date",
      disableFilters: true,
      Cell: ({ row }) => new Date(row.values.date).toLocaleDateString("en-GB"),
    },
    {
      Header: "Provider",
      accessor: "providerName",
    },
    {
      Header: "IncomeType",
      accessor: "incomeType",
    },
    {
      Header: "IncomeDay",
      accessor: "incomeDay",
      disableFilters: true,
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.incomeDay)} $`}</div>
      ),
    },

    {
      Header: "Percent",
      accessor: "incomePercent",
      disableFilters: true,
      Cell: (cell) => ColorCell(cell, yellowGreen.value, redYellow.value),
    },
    {
      Header: "Manager",
      accessor: "managerName",
    },
    {
      Header: "userId",
      accessor: "userid",
      show: false,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading && incomes && loadedSettings && (
        <CardMain cardReport={true} cardName={cardOptions.cardName}>
          <ReactTable
            columns={columns}
            data={incomes}
            getCellProps={onClickCellHandler}
          />
        </CardMain>
      )}
    </>
  );
}

export default Incomes;
