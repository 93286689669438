import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Spinner, Card, Button } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";

function CexImport() {
  const [cexs, setCexs] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: " Imported Exchages" };

  useEffect(() => {
    let unmounted = false;
    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };
    if (!unmounted) {
      fetch("/cex-import", (data) => setCexs(data.cexs));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  let allLoaded = !isLoading && cexs;

  //Calculations

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/cex-import/${cell.row.values.id}`);
      },
    };
  };

  //Window size cut colomn
  let showColomn;
  let windowWidth = window.innerWidth;
  if (windowWidth < 1200) {
    showColomn = false;
  }

  //Yes if true
  if (allLoaded) {
    for (const cex of cexs) {
      if (cex.spot) {
        cex.spotText = <div className="bg-success text-white">+</div>;
      }
      if (cex.futures) {
        cex.futuresText = <div className="bg-success text-white">+</div>;
      }
    }
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      disableFilters: true,
      show: false,
    },
    {
      Header: "Exchange",
      accessor: "exchangeName",
    },
    {
      Header: "Spot",
      accessor: "spotText",
      disableFilters: true,
    },
    {
      Header: "Futures",
      accessor: "futuresText",
      disableFilters: true,
    },

    {
      Header: "Comments",
      accessor: "comments",
      show: showColomn,
      disableFilters: true,
    },
    {
      Header: "Client",
      accessor: "clientName",
      show: showColomn,
      disableFilters: true,
    },
    {
      Header: "userId",
      accessor: "userid",
      disableFilters: true,
      show: false,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className="">
                <Button
                  as={Link}
                  to={`../cex-import/new`}
                  className="btn btn-sm btn-secondary "
                >
                  Add <br />
                  exchange
                </Button>
              </div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>

              <div className="">
                <Button
                  as={Link}
                  to={`../cexs`}
                  className="btn btn-sm btn-secondary"
                >
                  Back
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={cexs}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default CexImport;
