import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Spinner, Card } from "react-bootstrap";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import detectEthereumProvider from "@metamask/detect-provider";
//import Web3 from "web3";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CommentField from "../../components/elements/form/CommentField";

const Metamask = () => {
  const [setting, setSetting] = useState();
  //const [web3Api, setWeb3Api] = useState(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  //Card name
  let cardOptions = { cardName: "Metamask" };

  useEffect(() => {
    let unmounted = false;

    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };

    if (!unmounted) {
      fetch("/setting", (data) => setSetting(data.settings));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  let allLoaded = !isLoading && setting;

  //Calculations
  let initialValues, connectedWallet, Id;
  if (allLoaded) {
    setting.forEach((element) => {
      if (element.name === "connectedWallet") {
        connectedWallet = element.value;
        Id = element.id;
      }
    });
    initialValues = {
      connectedWallet: connectedWallet ? connectedWallet : "not connected",
    };
  }

  const onSubmit = async (values) => {
    if (connectedWallet) {
      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/setting/${Id}`,
          "DELETE",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        navigate("../settings");
        // setWeb3Api(null);
      } catch (err) {
        console.log(err);
      }
    } else {
      //connect web3
      const provider = await detectEthereumProvider();
      const account = await provider.request({
        method: "eth_requestAccounts",
      });

      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/setting`,
          "POST",
          JSON.stringify({
            name: "connectedWallet",
            description: "Connected Wallet",
            value: account[0],
            userId: auth.userId,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        navigate("../settings");
        // setWeb3Api(new Web3(provider));
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      {error && <ErrorAlert error={error} onClear={clearError} />}
      {isLoading ? <Spinner animation="border" /> : null}

      {allLoaded && (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <Card>
                <Card.Header className="text-center fs-3">
                  {cardOptions.cardName}
                </Card.Header>
                <Card.Body className="text-center">
                  <Row className="mb-4">
                    <CommentField
                      name="connectedWallet"
                      label="Connected Wallet:"
                      disabled="1"
                    />
                  </Row>
                </Card.Body>
                <Card.Footer className="text-center">
                  {connectedWallet ? (
                    <button type="submit" className="btn btn-secondary">
                      Disconnect
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-success">
                      Connect
                    </button>
                  )}
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Metamask;
