import React, { useContext } from "react";
import { useQuery } from "react-query";
import { Form, Col, Spinner } from "react-bootstrap";
import axios from "axios";

import { AuthContext } from "../../context/auth-context.js";
import SelectInput from "../../elements/SelectField";

export default function PoolField(props) {
  const auth = useContext(AuthContext);

  async function fetchArray() {
    return await axios({
      url: process.env.REACT_APP_BACKEND_URL + "/pool",
      method: "get",
      headers: { Authorization: "Bearer " + auth.token },
    });
  }
  const { isLoading, data } = useQuery("getPool", fetchArray);

  if (isLoading) {
    return <Spinner animation="border" />;
  }
  let options;
  if (!isLoading && data) {
    //Options in Array
    let arrayList = data.data.pools
      .map((item) => (item = item.poolName))
      .sort();
    options = arrayList.map((e) => ({
      label: e,
      value: e,
    }));
  }
  return (
    <>
      {!isLoading && data ? (
        <Form.Group as={Col}>
          <SelectInput
            type="text"
            label={props.label}
            name={props.name}
            options={options}
            disabled={props.disabled ? props.disabled : false}
            // defaultValue={defaultClient ? defaultClient : ""}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </SelectInput>
        </Form.Group>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </>
  );
}
