import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Nav, Dropdown } from "react-bootstrap";
import { AuthContext } from "../context/auth-context";
import { Icons } from "./Icons";
// import Logo from "../../img/logoColor.png";

export default function Header() {
  const Auth = useContext(AuthContext);
  const [toggler, setToggler] = useState(false);
  const togglerHandler = () => {
    setToggler(!toggler);
  };

  let show = toggler ? "show" : "";

  const navListBase = [
    { name: `  Wallet`, link: "./wallets", icon: Icons.wallet },
    { name: `  Exchanges`, link: "/cexs/report", icon: Icons.exchange },
  ];

  const navListDefi = [
    { name: `  Projects`, link: "/projects", icon: Icons.project },
    { name: `  Farming`, link: "/farms", icon: Icons.farming },
    { name: `  Lending`, link: "/Lends/report", icon: Icons.lending },
  ];

  const navListEnterprise = [
    { name: `  Comparing`, link: "/comps", icon: Icons.comparing },
    { name: `  Harvest`, link: "/harvesting", icon: Icons.harvesting },
    { name: `  Incomes`, link: "/incomes", icon: Icons.incomes },
    { name: `  Bonuses`, link: "/bonuses", icon: Icons.bonuses },
    { name: `  Offsets`, link: "/offsets", icon: Icons.offsets },
    { name: `  Balances`, link: "/balances", icon: Icons.balances },
    { name: `  Investors`, link: "/investors", icon: Icons.investors },
  ];
  const navListAdmin = [
    { name: `  Tokens`, link: "/tokens", icon: Icons.tokens },
    { name: `  Chains`, link: "/chains", icon: Icons.chains },
    { name: `  Exchanges`, link: "/exchanges", icon: Icons.exchanges },
    { name: `  Pools`, link: "/pools", icon: Icons.pools },
    { name: `  Users`, link: "/users", icon: Icons.users },
  ];

  const navListAdminUser = [
    { name: `  Users`, link: "/users", icon: Icons.users },
    {
      name: `  Subsriptions`,
      link: "/subscriptions/admin",
      icon: Icons.subAdmin,
    },
    { name: `  Payments`, link: "/Payments", icon: Icons.Payments },
    { name: `  Documents`, link: "/documents", icon: Icons.Documents },
  ];
  const navListSetting = [
    { name: `  Setting`, link: "/settings", icon: Icons.Setting },
    { name: `  Pricing`, link: "/pricing", icon: Icons.pricing },
    {
      name: `  Subsriptions`,
      link: "/subscriptions",
      icon: Icons.subs,
    },
    { name: `  Support`, link: "/support", icon: Icons.Support },
    { name: `  Metamask`, link: "/metamask", icon: Icons.metamask },
  ];
  const navCataloges = [
    {
      name: `  Managers`,
      link: "/managers",
      icon: Icons.Clients,
      type: "Enterprise",
    },
    { name: `  Clients`, link: "/clients", icon: Icons.clients, type: "Pro" },
    { name: `  Bridges`, link: "/bridges", icon: Icons.bridges, type: "Base" },
    { name: `  Chains`, link: "/chains", icon: Icons.chains, type: "Base" },
    {
      name: `  Exchanges`,
      link: "/exchanges",
      icon: Icons.exchanges,
      type: "Base",
    },
    { name: `  Pools`, link: "/pools", icon: Icons.pools, type: "Base" },
    { name: `  Tokens`, link: "/tokens", icon: Icons.tokens, type: "Base" },
  ];
  const baseMenu =
    Auth.planName === "Base" ||
    Auth.planName === "Pro" ||
    Auth.planName === "Enterprise" ||
    Auth.planName === "Trial";

  const premiumMenu =
    Auth.planName === "Enterprise" || Auth.planName === "Trial";
  //filter menu
  let filteredNavCataloges;
  if (Auth.planName === "Pro") {
    function Base(a) {
      if (a.type !== "Enterprise") {
        return a;
      }
    }
    filteredNavCataloges = navCataloges.filter(Base);
  } else if (Auth.planName === "Base") {
    function Base(a) {
      if (a.type === "Base") {
        return a;
      }
    }
    filteredNavCataloges = navCataloges.filter(Base);
  } else {
    filteredNavCataloges = navCataloges;
  }

  let userName;
  if (Auth.isLoggedIn && Auth.userEmail) {
    let userEmail = Auth.userEmail;
    userName = userEmail.split("@")[0];
  }

  return (
    <header className="navbar navbar-dark sticky-top bg-primary flex-md-nowrap p-0 shadow">
      <Link
        className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-3 text-warning"
        to="/"
      >
        {/* <img
          src={Logo}
          width={"40px"}
          height={"40px"}
          alt=""
          className="me-2"
        ></img> */}
        <span>cryptoBase</span>
      </Link>

      <div className="navbar-nav d-flex flex-row">
        <button
          className="navbar-toggler d-md-none collapsed me-3"
          type="button"
          onClick={togglerHandler}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {Auth.isLoggedIn && Auth.userType === "admin" && (
          <>
            <Dropdown>
              <Dropdown.Toggle
                className="me-2"
                variant="danger"
                id="dropdown-basic"
              >
                User
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {navListAdminUser.map((b) => {
                  return (
                    <Dropdown.Item as={Link} key={b.link} to={b.link}>
                      {b.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        {Auth.isLoggedIn && (
          <>
            <Dropdown>
              <Dropdown.Toggle
                className="me-2"
                variant="secondary"
                id="dropdown-basic"
              >
                Catalogs
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {filteredNavCataloges.map((b) => {
                  return (
                    <Dropdown.Item as={Link} key={b.link} to={b.link}>
                      {b.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
        {Auth.isLoggedIn && (
          <>
            <Dropdown>
              <Dropdown.Toggle
                className="me-2"
                variant="success"
                id="dropdown-setting"
              >
                User: {userName}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {navListSetting.map((b) => {
                  return (
                    <Dropdown.Item as={Link} key={b.link} to={b.link}>
                      {b.name}
                    </Dropdown.Item>
                  );
                })}
                <hr />
                <Dropdown.Item
                  className="me-3 py-2 text-dark text-decoration-none"
                  href="https://docs.cryptobase.bimdao.io/"
                  target="_blank"
                >
                  Docs
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <button className="btn btn-default me-2">
              <img src={iconSetting} width={"30px"} alt=""></img>
            </button> */}

            <div className="nav-item text-nowrap">
              <button
                className="btn btn-warning  px-3 me-2"
                onClick={Auth.logout}
              >
                Sign out
              </button>
            </div>
          </>
        )}
        {!Auth.isLoggedIn && (
          <>
            <div className="nav-item me-2">
              <Link className="nav-link link-light" to="/">
                <span
                  data-feather="file-text"
                  className="align-text-bottom"
                ></span>
                About
              </Link>
            </div>
            <div className="nav-item me-2">
              <Link className="nav-link link-light" to="./features">
                <span
                  data-feather="file-text"
                  className="align-text-bottom"
                ></span>
                Features
              </Link>
            </div>
            <div className="nav-item me-2">
              <Link className="nav-link link-light" to="./pricing">
                <span
                  data-feather="file-text"
                  className="align-text-bottom"
                ></span>
                Pricing
              </Link>
            </div>

            <div className="nav-item text-nowrap">
              <Link to="./login">
                <button className="btn btn-warning  px-3 me-2">Login</button>
              </Link>
            </div>
          </>
        )}
      </div>
      <div className={"collapse navbar-collapse " + show}>
        <nav
          className="col-md-4 col-lg-2 d-md-block 
        bg-light sidebar "
        >
          {Auth.isLoggedIn && baseMenu && (
            <div className=" nav flex-column ">
              {navListBase.map((b) => {
                return (
                  <Nav.Item key={b.link} className="mb-1 ">
                    <Nav.Link
                      as={Link}
                      to={b.link}
                      onClick={togglerHandler}
                      className=" w-100 btn  btn-toggle d-inline-flex align-items-center rounded border-0"
                    >
                      {b.icon} <span className="ms-2"> {b.name}</span>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}

              <Nav.Item className="border-top "></Nav.Item>
              <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
                <span>DeFi</span>
              </h6>
              {navListDefi.map((b) => {
                return (
                  <Nav.Item key={b.link} className="mb-1 ">
                    <Nav.Link
                      as={Link}
                      to={b.link}
                      onClick={togglerHandler}
                      className=" w-100  btn  btn-toggle d-inline-flex align-items-center rounded border-0"
                    >
                      {b.icon} <span className="ms-2"> {b.name}</span>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </div>
          )}
          {Auth.isLoggedIn && premiumMenu && (
            <div className=" nav flex-column position-sticky ">
              <Nav.Item className="border-top "></Nav.Item>
              <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
                <span>Enterprise</span>
              </h6>
              {navListEnterprise.map((b) => {
                return (
                  <Nav.Item key={b.link} className="mb-1 ">
                    <Nav.Link
                      as={Link}
                      to={b.link}
                      onClick={togglerHandler}
                      className=" w-100  btn  btn-toggle d-inline-flex align-items-center rounded border-0"
                    >
                      {b.icon} <span className="ms-2"> {b.name}</span>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </div>
          )}
          {Auth.isLoggedIn && Auth.userType === "admin" && (
            <Accordion>
              <Accordion.Item eventKey="1" className="a-item">
                <Accordion.Header className="a-header">Admin</Accordion.Header>
                <Accordion.Body>
                  {navListAdmin.map((b) => {
                    return (
                      <Nav.Item key={b.link} className="">
                        <Nav.Link
                          as={Link}
                          to={b.link}
                          onClick={togglerHandler}
                          className=" w-100  btn  btn-toggle d-inline-flex align-items-center rounded border-0"
                        >
                          {b.icon} <span className="ms-2"> {b.name}</span>
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          {Auth.isLoggedIn && (
            <Accordion className="pt-1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{Auth.userEmail}</Accordion.Header>
                <Accordion.Body>
                  <Nav.Link
                    as={Link}
                    to="./settings"
                    onClick={togglerHandler}
                    className=" w-100 nav-link  btn btn-toggle  d-inline-flex 
                  align-items-center rounded border-0"
                  >
                    {Icons.setting} <span className="ms-2"> Setting</span>
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to="./subscriptions"
                    onClick={togglerHandler}
                    className="w-100 btn  btn-toggle d-inline-flex align-items-center rounded border-0"
                  >
                    {Icons.subscription}
                    <span className="ms-2">Subscription</span>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="./support"
                    onClick={togglerHandler}
                    className="w-100 btn  btn-toggle d-inline-flex align-items-center rounded border-0"
                  >
                    {Icons.subscription}
                    <span className="ms-2">Support</span>
                  </Nav.Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </nav>
      </div>
    </header>
  );
}
