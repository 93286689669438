import React, { useEffect, useState, useContext } from "react";

import { AuthContext } from "../../components/context/auth-context";

export const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  cell,
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) setValue(initialValue);
    return () => {
      unmounted = true;
    };
  }, [initialValue]);

  let colomneCells =
    id === "tokenQty" || (id === "priceOpen" && value !== 0) || id === "value";
  // Check to make sure not all columns are editable
  if (colomneCells) {
    return (
      <input
        style={{
          fontSize: "12px",
          width: "7rem",
          border: "1px solid #292929",
          borderRadius: "3px",
          backgroundColor: "#E8F9FD",
        }}
        value={value}
        type={"number"}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
  return <div>{value}</div>;
};

// Create an editable cell renderer
export const EditableCellText = ({
  value: initialValue,
  row: { index },
  column: { id },
  cell,
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const auth = useContext(AuthContext);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) setValue(initialValue);
    return () => {
      unmounted = true;
    };
  }, [initialValue]);

  //disabled cell for all
  const cellAll = cell.row.values.name !== "connectedWallet";

  const ColumnCell =
    id === "value" &&
    auth.planName !== "Base" &&
    auth.planName !== "Pro" &&
    cellAll;

  const cellPro =
    cell.row.values.name === "defaultClient" ||
    cell.row.values.name === "colorGreenApi" ||
    cell.row.values.name === "colorRedApi";

  const ColumnCellPro = id === "value" && auth.planName === "Pro" && cellPro;

  // Check to make sure not all columns are editable
  if (ColumnCell || ColumnCellPro) {
    return (
      <input
        style={{
          fontSize: "13px",
          width: "100%",
          border: "1px solid #292929",
          backgroundColor: "#E8F9FD",
        }}
        value={value}
        type={"text"}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
  return <div>{value}</div>;
};
