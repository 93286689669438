import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Spinner, Card, Button } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSort";
import {
  ColorCell,
  ColorIl,
  NumberWithCommas,
} from "../../components/table/FormatedCell";

function FarmReport() {
  const [loadedFarming, setLoadedFarming] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const [loadedProjects, setLoadedProjects] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  //Card options
  let cardOptions = { cardName: "Farming report" };

  useEffect(() => {
    let unmounted = false;

    const fetchSettings = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/setting",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setloadedSettings(responseData.settings);
      } catch (err) {}
    };
    fetchSettings();

    const fetchFarming = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/farm",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedFarming(responseData.farms);
      } catch (err) {}
    };
    fetchFarming();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    const fetchPools = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pool",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedPools(responseData.pools);
      } catch (err) {}
    };
    fetchPools();

    const fetchProjects = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/project",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedProjects(responseData.projects);
      } catch (err) {}
    };
    fetchProjects();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const getLink = (poolName) => {
    let link;
    loadedPools.forEach((e) => {
      if (e.poolName === poolName) {
        link = e.poolLink;
      }
    });
    return link;
  };

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/farms`);
      },
    };
  };
  const getPrice = (tokenSymbol) => {
    let tokenObj;
    if (!isLoading && loadedTokens) {
      tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    }
    return tokenObj.tokenPrice;
  };

  //Calculations
  let yellowGreen, redYellow, colorIl;
  if (
    !isLoading &&
    loadedFarming &&
    loadedPools &&
    loadedProjects &&
    loadedTokens &&
    loadedSettings
  ) {
    loadedFarming.forEach((e) => {
      e.linkProvider = getLink(e.providerName);
      e.linkPool = getLink(e.poolName);

      e.pairs = e.pair1 + "/" + e.pair2;
      e.pair1Price = getPrice(e.pair1);
      e.pair2Price = getPrice(e.pair2);

      //IL
      let ilSum = e.newPair1Qty * e.pair1Price + e.newPair2Qty * e.pair2Price;
      let ilK = e.newPair1Qty * e.newPair2Qty;
      e.newPair1Qty = ilSum / 2 / e.pair1Price;
      e.newPair2Qty = ilSum / 2 / e.pair2Price;
      let ilR = e.pair2Price / e.pair1Price;
      e.pair1Qty = Math.sqrt(ilK * ilR);
      e.pair2Qty = Math.sqrt(ilK / ilR);
      let pair1Sum = e.pair1Qty * e.pair1Price;
      let pair2Sum = e.pair2Qty * e.pair2Price;
      e.posSum = pair1Sum + pair2Sum;
      e.il = e.posSum - ilSum;
      e.apy = _.sumBy(loadedProjects, (p) => {
        if (e.projectNumber === p.projectNumber) {
          return p.apy;
        } else {
          return 0;
        }
      });
      e.incomeMonth = (e.posSum * e.apy) / 1200;
      e.incomeDay = (e.posSum * e.apy) / 36500;
    });
    //Sorting
    loadedFarming.sort((a, b) => (a.apy > b.apy ? 1 : b.apy > a.apy ? -1 : 0));

    //addTotal
    const getTotals = (data, key) => {
      let total = 0;
      data.forEach((item) => {
        total += item[key];
      });
      return total;
    };
    let incomeMonth = getTotals(loadedFarming, "incomeMonth");
    let posSum = getTotals(loadedFarming, "posSum");
    let il = getTotals(loadedFarming, "il");
    let incomeDay = getTotals(loadedFarming, "incomeDay");
    loadedFarming.unshift({
      id: "",
      projectNumber: "",
      date: "Total",
      providerName: "",
      poolName: "",
      pairs: "",
      pair1Qty: "",
      pair2Qty: "",
      posSum: posSum,
      il: il,
      apy: (incomeMonth * 1200) / posSum,
      incomeMonth: incomeMonth,
      incomeDay: incomeDay,
      chainName: "",
      clientName: "",
      userId: "",
    });

    //Colors from Settings
    yellowGreen = loadedSettings.find((d) => d.name === "colorGreenApi");
    redYellow = loadedSettings.find((d) => d.name === "colorRedApi");
    colorIl = loadedSettings.find((d) => d.name === "botIl");
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      show: false,
    },
    {
      Header: "PosId",
      accessor: "projectNumber",
      show: false,
    },
    {
      Header: "Date",
      accessor: "date",
    },

    {
      Header: "Provider",
      accessor: "providerName",
    },
    {
      Header: "Pool",
      accessor: "poolName",
      show: false,
    },
    {
      Header: "Pairs",
      accessor: "pairs",
    },
    {
      Header: "Pair1",
      accessor: "pair1Qty",
      show: false,
    },
    {
      Header: "Pair2",
      accessor: "pair2Qty",
      show: false,
    },
    {
      Header: "PosSum",
      accessor: "posSum",
      Cell: ({ row }) => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          {`${NumberWithCommas(row.values.posSum)} $`}
        </div>
      ),
    },
    {
      Header: "IL",
      accessor: "il",
      Cell: (cell) => ColorIl(cell, colorIl.value),
    },
    {
      Header: "APY",
      accessor: "apy",
      Cell: (cell) => ColorCell(cell, yellowGreen.value, redYellow.value),
    },

    {
      Header: "IncomeMonth",
      accessor: "incomeMonth",
      Cell: ({ row }) => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          {`${NumberWithCommas(row.values.incomeMonth)} $`}
        </div>
      ),
    },
    {
      Header: "IncomeDay",
      accessor: "incomeDay",
      Cell: ({ row }) => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          {`${NumberWithCommas(row.values.incomeDay)} $`}
        </div>
      ),
    },
    {
      Header: "Chain",
      accessor: "chainName",
      show: false,
    },
    {
      Header: "Manager",
      accessor: "managerName",
      show: false,
    },

    {
      Header: "Client",
      accessor: "clientName",
      show: false,
    },
    {
      Header: "userId",
      accessor: "userId",
      show: false,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}
      {!isLoading &&
        loadedFarming &&
        loadedTokens &&
        loadedProjects &&
        loadedPools &&
        loadedSettings && (
          <Card className="text-center card-center w-100">
            <Card.Header>
              <div className="d-flex flex-row justify-content-between">
                <div className="">
                  <Button
                    as={Link}
                    to={`../farms`}
                    className="btn btn-sm btn-secondary"
                  >
                    Back
                  </Button>
                </div>
                <div className="fs-4 me-5">{cardOptions.cardName}</div>
                <div className="">
                  <Button
                    as={Link}
                    to={`../farms`}
                    className="btn btn-sm btn-secondary"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <ReactTable
                columns={columns}
                data={loadedFarming}
                getCellProps={onClickCellHandler}
              />
            </Card.Body>
          </Card>
        )}
    </>
  );
}

export default FarmReport;
