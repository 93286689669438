import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Spinner, Card, Button } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSort";

import {
  NumberWithCommas,
  ColorBufferLend,
  ColorCell,
  LinkCell,
} from "../../components/table/FormatedCell";
function LendReport() {
  const [loadedLends, setLoadedLends] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Lending report" };

  useEffect(() => {
    let unmounted = false;

    const fetchSettings = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/setting",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setloadedSettings(responseData.settings);
      } catch (err) {}
    };
    fetchSettings();

    const fetchLends = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/lend",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedLends(responseData.lends);
      } catch (err) {}
    };
    fetchLends();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    const fetchPools = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pool",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedPools(responseData.pools);
      } catch (err) {}
    };
    fetchPools();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const getPrice = (tokenSymbol) => {
    let tokenObj;
    if (!isLoading && loadedLends && loadedTokens) {
      tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    }
    return tokenObj.tokenPrice;
  };

  const getLink = (poolName) => {
    let link;
    loadedPools &&
      loadedPools.forEach((e) => {
        if (e.poolName === poolName) {
          link = e.poolLink;
        }
      });
    return link;
  };

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        switch (cell.column.id) {
          case "providerName":
            window.open(`${cell.row.original.linkProvider}`);
            break;

          default:
            navigate(`/lends/`);
            break;
        }
      },
    };
  };

  //Calculations
  let yellowGreen, redYellow, redYellowLend, uniqueLends;
  if (!isLoading && loadedLends && loadedTokens && loadedSettings) {
    loadedLends.forEach((e) => {
      e.tokenPrice = getPrice(e.tokenName);

      e.lendingName = e.providerName + "_" + e.chainName;
      e.lendingSum = e.tokenQty * e.tokenPrice;
      e.income = (e.lendingSum * e.percent) / 100 / 12;
    });

    //
    let shortLends = loadedLends.map(({ lendingName, clientName }) => ({
      lendingName,
      clientName,
    }));

    //Delete dublicates
    uniqueLends = _.uniqWith(shortLends, _.isEqual);

    uniqueLends.forEach((h) => {
      h.providerName = h.lendingName.split("_")[0];
      h.linkProvider = getLink(h.providerName);
      h.lendingSum = _.sumBy(loadedLends, (w) => {
        if (h.lendingName === w.lendingName && w.orderType === "Lend") {
          return w.lendingSum;
        }
      });
      h.collateral = _.sumBy(loadedLends, (w) => {
        if (h.lendingName === w.lendingName && w.orderType === "Lend") {
          let colFactor = w.colFactor / 100;
          return w.lendingSum * colFactor;
        }
      });
      h.borrow = _.sumBy(loadedLends, (w) => {
        if (h.lendingName === w.lendingName && w.orderType === "Borrow") {
          return w.lendingSum;
        } else {
          return 0;
        }
      });
      h.actualPos = h.collateral + h.borrow;
      h.buffer = h.borrow ? (h.actualPos * 100) / -h.borrow : 100;
      h.incomeMonth = _.sumBy(loadedLends, (w) => {
        if (h.lendingName === w.lendingName) {
          return w.income;
        }
      });
      h.apy = (h.incomeMonth * 1200) / h.lendingSum;

      //creating token pairs
      let token1 = _.sumBy(loadedLends, (w) => {
        if (h.lendingName === w.lendingName && w.orderType === "Lend") {
          return w.tokenName + "/";
        }
      });
      let token2 = _.sumBy(loadedLends, (w) => {
        if (h.lendingName === w.lendingName && w.orderType === "Borrow") {
          return "/" + w.tokenName;
        }
      });

      h.pair = token2 ? token1 + token2 : token1 + "-";
    });

    //Sorting
    loadedLends.sort((a, b) =>
      a.lendingName > b.lendingName ? 1 : b.lendingName > a.lendingName ? -1 : 0
    );

    //Colors from Settings

    yellowGreen = loadedSettings.find((d) => d.name === "colorGreenApi");
    redYellow = loadedSettings.find((d) => d.name === "colorRedApi");
    redYellowLend = loadedSettings.find((d) => d.name === "botLendingCover");
  }

  //Window size cut colomn
  let showColomn;
  let windowWidth = window.innerWidth;
  if (windowWidth < 1200) {
    showColomn = false;
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      show: false,
    },
    {
      Header: "Provider",
      accessor: "providerName",
      show: showColomn,
      Cell: LinkCell,
    },
    {
      Header: "Name",
      accessor: "lendingName",
    },
    {
      Header: "Tokens",
      accessor: "pair",
    },

    {
      Header: "Sum",
      accessor: "lendingSum",
      show: showColomn,
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.lendingSum)} $`}</div>
      ),
    },
    {
      Header: "Collateral",
      accessor: "collateral",
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.collateral)} $`}</div>
      ),
    },
    {
      Header: "Borrow",
      accessor: "borrow",
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.borrow)} $`}</div>
      ),
    },
    {
      Header: "Actual Position",
      accessor: "actualPos",
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.actualPos)} $`}</div>
      ),
    },
    {
      Header: "Buffer",
      accessor: "buffer",
      Cell: (cell) => ColorBufferLend(cell, redYellowLend.value),
    },
    {
      Header: "IncomeMonth",
      accessor: "incomeMonth",
      show: showColomn,
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.incomeMonth)} $`}</div>
      ),
    },
    {
      Header: "APY",
      accessor: "apy",
      Cell: (cell) => ColorCell(cell, yellowGreen.value, redYellow.value),
    },
    {
      Header: "Client",
      accessor: "clientName",
      show: showColomn,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}
      {!isLoading && loadedLends && loadedTokens && loadedSettings && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className=""></div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              <div className="">
                <Button
                  as={Link}
                  to={`../lends`}
                  className="btn btn-sm btn-secondary"
                >
                  Details
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={uniqueLends}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default LendReport;
