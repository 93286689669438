import React from "react";
import { CSSTransition } from "react-transition-group";
import { Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";

import "./ErrorAlert.css";

const ErrorAlert = (props) => {
  return (
    <CSSTransition
      in={!!props.error}
      mountOnEnter
      unmountOnExit
      timeout={200}
      classNames="modal"
    >
      <Alert variant="info" dismissible onClick={props.onClear}>
        <Alert.Heading>An Error occurred!</Alert.Heading>
        <p>{props.error}</p>
        <Button className="btn btn-secondary" onClick={props.onClear}>
          Close
        </Button>
      </Alert>
    </CSSTransition>
  );
};

export default ErrorAlert;
