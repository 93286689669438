import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Spinner, Card, Button, Row } from "react-bootstrap";
import { Formik, Form } from "formik";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import ClientField from "../../components/elements/form/ClientField";

function WalletReport() {
  const [wallets, setWallets] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedClients, setLoadedClients] = useState();
  const [report, setReport] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  //Card options
  let cardOptions = { cardName: "Wallet report" };

  useEffect(() => {
    let unmounted = false;
    const fetchWallets = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/wallet",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setWallets(responseData.wallets);
      } catch (err) {}
    };
    fetchWallets();
    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    const fetchClients = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/client",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedClients(responseData.clients);
      } catch (err) {}
    };
    fetchClients();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/wallets`);
      },
    };
  };

  const getPrice = (tokenSymbol) => {
    let tokenObj;
    if (!isLoading && loadedTokens) {
      tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    }
    return tokenObj.tokenPrice;
  };

  function numberWithCommas(number) {
    return Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Client",
        accessor: "clientName",
      },
      {
        Header: "Chain",
        accessor: "chainName",
      },
      {
        Header: "Total Sum",
        accessor: "tokenSum",
        disableFilters: true,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {`${numberWithCommas(row.values.tokenSum)} $`}
          </div>
        ),
      },

      {
        Header: "IsInvestment",
        accessor: "isInvestment",
        show: false,
      },
    ],
    []
  );

  //Formik submit

  const onSubmit = (values) => {
    if (!isLoading && wallets && loadedTokens) {
      wallets.forEach((e) => {
        e.tokenPrice = getPrice(e.tokenName);
        e.tokenSum = e.tokenQty * e.tokenPrice;
      });
    }
    function aggregate(collection, propertyNames, iteratee) {
      function category(obj) {
        return _.chain(obj).pick(propertyNames).values().join(" ");
      }
      function summarize(group) {
        return _.chain(group)
          .first()
          .pick(propertyNames)
          .extend(_.reduce(group, iteratee))
          .value();
      }
      return _.chain(collection).groupBy(category).map(summarize).value();
    }
    let clientsChainsSumDubled = wallets.map(
      ({ clientName, chainName, tokenSum }) => ({
        clientName,
        chainName,
        tokenSum,
      })
    );
    let clientsChainsSum = aggregate(
      clientsChainsSumDubled,
      ["clientName", "chainName"],
      (memo, value) => ({
        tokenSum: +memo.tokenSum + +value.tokenSum,
      })
    );

    clientsChainsSum.sort((a, b) =>
      a.tokenSum < b.tokenSum ? 1 : b.tokenSum < a.tokenSum ? -1 : 0
    );

    let clientArray, sumClient;
    if (values.client) {
      clientArray = clientsChainsSum.filter(
        (s) => s.clientName === values.client
      );
      sumClient = _.sumBy(clientArray, (i) => i.tokenSum);
    } else {
      sumClient = _.sumBy(clientsChainsSum, (i) => i.tokenSum);
      clientArray = clientsChainsSum;
    }

    let totalClient = {
      clientName: "",
      chainName: "Total",
      tokenSum: sumClient,
    };

    clientArray.unshift(totalClient);

    setReport(clientArray);
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {isLoading && <Spinner animation="border" />}

      {!isLoading && loadedClients && wallets && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <Formik
                initialValues={{
                  client: "",
                }}
                onSubmit={onSubmit}
              >
                {({ handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit} className="mx-auto w-25">
                    <div className="fs-4 mb-1">{cardOptions.cardName}</div>
                    <hr />
                    <Row className="mb-3">
                      <ClientField name="client" label="Client:" />
                    </Row>
                    <div>
                      <Button className="btn btn-sm btn-success" type="submit">
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="">
                <Button
                  as={Link}
                  to={`../wallets`}
                  className="btn btn-sm btn-secondary"
                >
                  Back
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={report}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default WalletReport;
