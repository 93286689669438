import React, { useEffect, useState, useContext } from "react";
import { Spinner } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import CardMain from "../../components/CardMain";
import { NumberWithCommas } from "../../components/table/FormatedCell";

function Clients() {
  const [loadedClients, setLoadedClients] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Client" };

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },

      {
        Header: "ClientName",
        accessor: "clientName",
      },
      {
        Header: "ClientPercentage",
        accessor: "clientPercent",
        disableFilters: true,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            {`${NumberWithCommas(row.values.clientPercent)} %`}
          </div>
        ),
      },
      {
        Header: "userId",
        accessor: "userId",
        show: false,
      },
    ],
    []
  );

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/client",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoadedClients(responseData.clients);
      } catch (err) {}
    };
    fetchClients();
  }, [sendRequest, auth.token]);

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading && loadedClients && (
        <CardMain cardReport={true} cardName={cardOptions.cardName}>
          <ReactTable
            columns={columns}
            data={loadedClients}
            getRowProps={(row) => ({
              style: { cursor: "pointer" },
              onClick: (event) => {
                navigate(`/client/${row.values.id}`);
              },
            })}
          />
        </CardMain>
      )}
    </>
  );
}

export default Clients;
