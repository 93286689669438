import React, { useContext } from "react";
import { Spinner, Alert, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import DateField from "../../components/elements/form/DateField";
import ClientField from "../../components/elements/form/ClientField";
import CommentField from "../../components/elements/form/CommentField";
import SumField from "../../components/elements/form/SumField";
import { useData, useGetDataId } from "../../components/hooks/dbase-hook";
import CardNewUpdate from "../../components/CardNewUpdate";

// Schema for yup
const validationSchema = Yup.object().shape({
  date: Yup.string().required("*Date is required"),
  clientName1: Yup.string().required("*Client1 is required"),
  clientName2: Yup.string().required("*Client2 is required"),
  sum: Yup.number().required("*Sum is required"),
});

function OffsetUpdate() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Add name
  let cardOptions = { cardName: "Offset" };
  let pathId = `/${cardOptions.cardName.toLowerCase()}/${Id}`;

  //functions for update Query
  const onSuccess = () => {
    navigate(`../${cardOptions.cardName.toLowerCase()}s`);
  };
  const onError = () => {
    return <Alert>{error.message}</Alert>;
  };
  const onSettled = () => {};

  //update Query
  const { mutate: queryRequest, error } = useData(
    onSuccess,
    onError,
    onSettled
  );

  //get Settings
  // const fetchSetting = () => {
  //   return axios({
  //     url: process.env.REACT_APP_BACKEND_URL + "/setting",
  //     method: "get",
  //     headers: { Authorization: "Bearer " + auth.token },
  //   });
  // };

  // const {
  //   isLoading: isLoadingSettings,
  //   data: loadedSettings,
  //   error: errorSetting,
  // } = useQuery("getSettings", fetchSetting);

  //get Offset
  //functions for update Query
  const onSuccessGet = () => {};
  const onErrorGet = () => {
    return <Alert>{errorOffset.message}</Alert>;
  };
  const onSettledGet = () => {};

  const getOptions = {
    path: pathId,
    token: auth.token,
  };

  const {
    isLoading: isLoadingOffset,
    data: loadedOffset,
    error: errorOffset,
  } = useGetDataId(onSuccessGet, onErrorGet, onSettledGet, getOptions);

  //Formik submit

  const onSubmit = (values) => {
    let options = {
      path: pathId,
      method: "patch",
      token: auth.token,
      data: JSON.stringify({
        date: new Date(values.date),
        clientName1: values.clientName1,
        clientName2: values.clientName2,
        sum: values.sum,
        comments: values.comments,
        userId: auth.userId,
      }),
    };
    queryRequest(options);
  };

  //Delete handler
  // const deleteHandler = () => {
  //   let requestOptions = { path: pathId, method: "delete", token: auth.token };
  //   queryRequest(requestOptions);
  // };

  return (
    <>
      {/* {errorSetting && <Alert variant="warning">{errorSetting.message}</Alert>} */}
      {errorOffset && <Alert variant="warning">{errorOffset.message}</Alert>}
      {!isLoadingOffset && !errorOffset && loadedOffset ? (
        <Formik
          initialValues={{
            date: new Date(loadedOffset.data.offset.date)
              .toISOString()
              .split("T")[0],
            clientName1: loadedOffset.data.offset.clientName1,
            clientName2: loadedOffset.data.offset.clientName2,
            sum: loadedOffset.data.offset.sum,
            comments: loadedOffset.data.offset.comments,
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({ handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={false} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <DateField name="date" label="Date:" />
                  <SumField name="sum" label="Sum:" />
                </Row>

                <Row className="mb-2">
                  <ClientField name="clientName1" label="Client1+:" />
                  <ClientField name="clientName2" label="Client2-:" />
                </Row>
                <Row className="mb-2">
                  <CommentField name="comments" label="Comments:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner animation="border" />
      )}
    </>
  );
}

export default OffsetUpdate;
