/* eslint-disable react/prop-types */
import React from "react";
import { useField } from "formik";
import Select from "react-select";

function SelectInput({ label, ...props }) {
  const [field, meta, { setValue, setTouched }] = useField(props);
  const options = props.children.map((option) => ({
    value: option.props.value,
    label: option.props.children,
  }));

  const onChange = ({ value }) => {
    setValue(value);
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    control: (provided) => ({
      ...provided,
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  return (
    <div className="mb-3">
      <label htmlFor={props.id || props.name} className="form-label">
        {label}
      </label>
      <Select
        error={props.isError ? true : false}
        styles={customStyles}
        defaultValue={options.find((option) => option.value === field.value)}
        options={options}
        isDisabled={props.disabled ? props.disabled : false}
        onChange={onChange}
        onBlur={setTouched}
        // className={
        //   meta.touched && meta.error ? "new border rounded border-danger" : null
        // }
      />
      {meta.touched && meta.error ? (
        <div className="form-text text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
}
export default SelectInput;
