import React, { useEffect, useState, useContext } from "react";
import { Spinner, Button, Table } from "react-bootstrap";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  useFormikContext,
  useField,
} from "formik";
//import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";

import "./New.css";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { NumberWithCommas } from "../../components/table/FormatedCell";

// Schema for yup
// const validationSchema = Yup.object().shape({
//   chainNumber: Yup.number().required("Please add the number."),
//   chainName: Yup.string()
//     .max(15, "Must be 15 characters or less")
//     .required("Please add the name."),
// });

const NewHarvesting = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [farm, setFarm] = useState();
  const [token, setToken] = useState();
  const [pool, setPool] = useState();
  const [details, setDetails] = useState();
  const [loadedManagers, setLoadedManagers] = useState();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Harvesting in Farming" };

  useEffect(() => {
    let unmounted = false;

    //Fetch function
    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };

    //get DB data
    if (!unmounted) {
      fetch("/farm", (data) => setFarm(data.farms));
      fetch("/token", (data) => setToken(data.tokens));
      fetch("/pool", (data) => setPool(data.pools));
      fetch("/detail", (data) => setDetails(data.details));
      fetch("/manager", (data) => setLoadedManagers(data.managers));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  let allLoaded =
    !isLoading &&
    !isSubmiting &&
    farm &&
    token &&
    pool &&
    details &&
    loadedManagers;

  //token price
  const getPrice = (tokenSymbol) => {
    let tokenObj;

    tokenObj = token.find((e) => e.tokenSymbol === tokenSymbol);
    return tokenObj.tokenPrice;
  };

  const ifNoneToZero = (digit) => {
    if (digit) {
      return digit;
    } else {
      return 0;
    }
  };
  //link from pool
  const getLink = (poolName) => {
    let link;
    pool.forEach((e) => {
      if (e.poolName === poolName) {
        link = e.poolLink;
      }
    });
    return link;
  };

  //Dates
  let yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split("T")[0];

  //Calculations
  let positions = [];
  if (allLoaded) {
    //Main loop
    farm.forEach((f, i) => {
      //Token price for sum
      let pair1Price;
      let pair2Price;
      token.forEach((t) => {
        pair1Price = getPrice(f.pair1);
        pair2Price = getPrice(f.pair2);
      });
      f.posSum = f.pair1Qty * pair1Price + f.pair2Qty * pair2Price;

      //pool link
      f.link = getLink(f.providerName);

      //price of harv token
      f.tokenPrice = getPrice(f.tokenName);
      f.lpQtyStart = f.lpQty;

      //uniqe name of position
      f.uniqName = `${f.providerName}_${f.clientName}_${f.managerName}`;

      //lpQminus1 and tokenQminus1

      let lpQtyMinus1 = _.sumBy(details, (w) => {
        if (
          w.posDate.split("T")[0] === yesterdayDate &&
          f.projectNumber === w.projectNumber
        ) {
          return w.lpQty;
        }
      });
      let lpQtyStartMinus1 = _.sumBy(details, (w) => {
        if (
          w.posDate.split("T")[0] === yesterdayDate &&
          f.projectNumber === w.projectNumber
        ) {
          return w.lpQtyStart;
        }
      });

      //lpQminus1
      if (lpQtyMinus1 && lpQtyStartMinus1 === f.lpQtyStart) {
        f.lpQtyMinus1 = lpQtyMinus1;
      } else {
        f.lpQtyMinus1 = f.lpQtyStart;
      }

      //tokenQminus1
      f.tokenQtyMinus1 = _.sumBy(details, (w) => {
        if (
          w.posDate.split("T")[0] === yesterdayDate &&
          f.projectNumber === w.projectNumber &&
          !f.isHarvested
        ) {
          return ifNoneToZero(w.tokenQty);
        } else {
          return 0;
        }
      });

      //positions array
      positions.push({
        posDate: new Date().toLocaleDateString("en-GB"),
        projectNumber: f.projectNumber,
        providerName: f.providerName,
        chainName: f.chainName,
        lpQtyStart: f.lpQty,
        token: f.tokenName,
        isHarvested: true,
        pairs: `${f.pair1}/${f.pair2}`,
        posSum: f.posSum,
        link: f.link,
        lpQty: f.lpQtyMinus1,
        lpQtyMinus1: f.lpQtyMinus1,
        tokenQty: 0,
        tokenQtyMinus1: f.tokenQtyMinus1,
        incomeDay: 0,
        incomeTotal: 0,
        apy: 0,
        tokenPrice: f.tokenPrice,
        uniqName: f.uniqName,
        managerName: f.managerName,
        clientName: f.clientName,
        userId: f.userId,
      });
      positions.sort((a, b) =>
        a.chainName > b.chainName ? 1 : b.chainName > a.chainName ? -1 : 0
      );
    });
  }
  //field with action on state
  const MyField = (props) => {
    const { values } = useFormikContext();
    const [field] = useField(props);

    //Calculations
    let isHarv = values.positions[props.idx].isHarvested;

    let lpDifDay =
      values.positions[props.idx].lpQty -
        values.positions[props.idx].lpQtyMinus1 || 0;
    let lpDiffTotal =
      values.positions[props.idx].lpQty -
        values.positions[props.idx].lpQtyStart || 0;
    let lpIncomeDay =
      (values.positions[props.idx].posSum /
        values.positions[props.idx].lpQtyMinus1) *
        lpDifDay || 0;
    let lpIncomeTotal =
      (values.positions[props.idx].posSum /
        values.positions[props.idx].lpQtyStart) *
        lpDiffTotal || 0;
    let incomeDay, incomeTotal, apy;
    if (isHarv) {
      incomeDay =
        values.positions[props.idx].tokenQty *
          values.positions[props.idx].tokenPrice +
        lpIncomeDay;
      incomeTotal =
        values.positions[props.idx].tokenQty *
          values.positions[props.idx].tokenPrice +
        lpIncomeTotal;
      apy = (incomeDay * 36500) / values.positions[props.idx].posSum;
    } else {
      incomeDay =
        (values.positions[props.idx].tokenQty -
          values.positions[props.idx].tokenQtyMinus1) *
          values.positions[props.idx].tokenPrice +
        lpIncomeDay;
      incomeTotal =
        values.positions[props.idx].tokenQty *
          values.positions[props.idx].tokenPrice +
        lpIncomeTotal;
      apy = (incomeDay * 36500) / values.positions[props.idx].posSum;
    }

    values.positions[props.idx].incomeDay = incomeDay;
    values.positions[props.idx].incomeTotal = incomeTotal;
    values.positions[props.idx].apy = apy;

    return (
      <>
        <input {...props} {...field} />
      </>
    );
  };

  //Submit Details ========>
  const submitDetail = async (values) => {
    //submit loop
    farm.forEach(async (e) => {
      values.positions.forEach((v) => {
        if (v.projectNumber === e.projectNumber) {
          e.isHarvested = v.isHarvested;
          e.pairs = v.pairs;
          e.lpQty = v.lpQty;
          e.lpQtyStart = v.lpQtyStart;
          e.tokenQty = v.tokenQty;
          e.incomeTotal = v.incomeTotal;
          e.incomeDay = v.incomeDay;
          e.apy = v.apy;
        }
      });

      //submit request
      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/detail`,
          "POST",
          JSON.stringify({
            posDate: new Date(),
            projectNumber: e.projectNumber,
            providerName: e.providerName,
            pairs: e.pairs,
            posSum: e.posSum,
            tokenName: e.tokenName,
            tokenPrice: e.tokenPrice,
            isHarvested: e.isHarvested,
            lpQty: e.lpQty,
            lpQtyStart: e.lpQtyStart,
            tokenQty: e.tokenQty,
            posIncomeTotal: e.incomeTotal,
            posIncomeDay: e.incomeDay,
            posApy: e.apy,
            chainName: e.chainName,
            managerName: e.managerName,
            clientName: e.clientName,
            userId: auth.userId,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {}
    });
  };

  //Submit Incomes =====>
  const submitIncome = async (values) => {
    //calc Incomes
    //new array
    let uniqNames = farm.map((e) => e.uniqName);

    let shortFarm = farm.map(({ uniqName, providerName, managerName }) => ({
      uniqName,
      providerName,
      managerName,
    }));
    let incomesPro = shortFarm.filter(
      ({ uniqName }, index) => !uniqNames.includes(uniqName, index + 1)
    );

    //submit loop
    incomesPro.forEach(async (h) => {
      h.posSum = _.sumBy(values.positions, (w) => {
        if (w.uniqName === h.uniqName) {
          return ifNoneToZero(w.posSum);
        } else {
          return 0;
        }
      });

      h.incomeDay = _.sumBy(values.positions, (w) => {
        if (w.uniqName === h.uniqName) {
          return ifNoneToZero(w.incomeDay);
        } else {
          return 0;
        }
      });

      h.incomePercent = (h.incomeDay * 36500) / h.posSum;

      //submit request
      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/income`,
          "POST",
          JSON.stringify({
            date: new Date(),
            providerName: h.providerName,
            incomeType: "Farming",
            incomeDay: h.incomeDay,
            incomePercent: h.incomePercent,
            managerName: h.managerName,
            userId: auth.userId,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {}
    });
  };

  //Submit Bonus =====>
  const submitIncomeMan = async (values) => {
    //calc
    let incomeManager = [...new Set(farm.map((item) => item.managerName))];
    const setManager = (item, index) => {
      var object = { managerName: item };
      return object;
    };
    let incomesMan = incomeManager.map(setManager);

    //submit loop
    incomesMan.forEach(async (m) => {
      m.incomeDay = _.sumBy(values.positions, (w) => {
        if (m.managerName === w.managerName) {
          return ifNoneToZero(w.incomeDay);
        } else {
          return 0;
        }
      });
      m.managerBonus = _.sumBy(loadedManagers, (n) => {
        if (m.managerName === n.managerName) {
          return n.managerBonus;
        }
      });
      m.bonusDay = (m.incomeDay * m.managerBonus) / 100;

      //submit request
      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/bonus`,
          "POST",
          JSON.stringify({
            date: new Date(),
            managerName: m.managerName,
            incomeType: "Farming",
            incomeDay: m.incomeDay,
            bonusDay: m.bonusDay,
            userId: auth.userId,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {}
    });
  };

  //Submit Harvesting =======>
  const submitHarv = async (values) => {
    //calc
    //Clients
    let harvClient = [...new Set(farm.map((item) => item.clientName))];
    const setClient = (item, index) => {
      var object = { clientName: item };
      return object;
    };
    let harvByClient = harvClient.map(setClient);

    //submit loop
    harvByClient.forEach(async (h) => {
      h.incomeTotal = _.sumBy(values.positions, (f) => {
        if (f.clientName === h.clientName) {
          return f.incomeTotal;
        }
      });
      h.incomeDay = _.sumBy(values.positions, (f) => {
        if (f.clientName === h.clientName) {
          return f.incomeDay;
        }
      });
      h.posSum = _.sumBy(values.positions, (f) => {
        if (f.clientName === h.clientName) {
          return f.posSum;
        }
      });
      h.apy = Math.round((h.incomeDay * 36500) / h.posSum);
      h.positions = _.sumBy(values.positions, (f) => {
        if (f.clientName === h.clientName) {
          return `${f.providerName} ${NumberWithCommas(
            f.posSum
          )}$ ${NumberWithCommas(f.incomeDay)}$ ${NumberWithCommas(f.apy)}%|`;
        }
      });

      //submit request
      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/harv`,
          "POST",
          JSON.stringify({
            harvDate: new Date(),
            positions: h.positions,
            incomeType: "Farming",
            incomeTotal: h.incomeTotal,
            incomeDay: h.incomeDay,
            apy: h.apy,
            clientName: h.clientName,
            userId: auth.userId,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        navigate("../harvesting/");
      } catch (err) {}
    }); //end harvesting
  };

  //Submit Formik
  const onSubmit = async (values) => {
    setIsSubmiting(true);
    await submitDetail(values);
    await submitIncome(values);
    await submitIncomeMan(values);
    await submitHarv(values);
    setIsSubmiting(false);
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <Formik
          initialValues={{ positions: positions }}
          // validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="w-100">
              <div className="card ">
                <h5 className="card-header text-center">
                  {cardOptions.cardName}
                </h5>
                <div className="card-body border-bottom ">
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>ProjectN</th>
                        <th>Provider</th>
                        <th>Chain</th>
                        <th>Pairs</th>
                        <th>Sum</th>
                        <th>Token</th>
                        <th>isHar-d</th>
                        <th>lpQty</th>
                        <th>tokenQty</th>
                        <th>incomeDay</th>
                        <th>incomeTotal</th>
                        <th>APY</th>
                        <th style={{ display: "none" }}>HiddenField</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name="positions">
                        {() =>
                          positions.map((e, idx) => {
                            return (
                              <tr key={idx}>
                                <td> {e.posDate}</td>
                                <td> {e.projectNumber}</td>
                                <td>
                                  <a
                                    href={e.link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {e.providerName}
                                  </a>
                                </td>
                                <td> {e.chainName}</td>
                                <td> {e.pairs}</td>
                                <td> {`$${NumberWithCommas(e.posSum)}`}</td>
                                <td> {e.token}</td>
                                <td>
                                  <Field
                                    name={`positions.${idx}.isHarvested`}
                                    className={"checkbox"}
                                    type="checkbox"
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`positions.${idx}.lpQty`}
                                    className={"form-control-sm"}
                                    type="number"
                                    style={{
                                      fontSize: "12px",
                                      width: "7rem",
                                      border: "1px solid #292929",
                                      borderRadius: "3px",
                                      backgroundColor: "#E8F9FD",
                                    }}
                                  />
                                </td>

                                <td>
                                  <Field
                                    name={`positions.${idx}.tokenQty`}
                                    className={"form-control-sm"}
                                    style={{
                                      fontSize: "12px",
                                      width: "7rem",
                                      border: "1px solid #292929",
                                      borderRadius: "3px",
                                      backgroundColor: "#E8F9FD",
                                    }}
                                    type="number"
                                  />
                                </td>

                                <td>
                                  <MyField
                                    name={`positions.${idx}.incomeDay`}
                                    idx={idx}
                                    type="hidden"
                                  />
                                  {`$${NumberWithCommas(
                                    values.positions[idx].incomeDay
                                  )}`}
                                </td>

                                <td>
                                  <MyField
                                    name={`positions.${idx}.incomeTotal`}
                                    idx={idx}
                                    type="hidden"
                                  />
                                  {`$${NumberWithCommas(
                                    values.positions[idx].incomeTotal
                                  )}`}
                                </td>

                                <td>
                                  <MyField
                                    name={`positions.${idx}.apy`}
                                    idx={idx}
                                    type="hidden"
                                  />
                                  {`${NumberWithCommas(
                                    values.positions[idx].apy
                                  )}%`}
                                </td>
                                <td style={{ display: "none" }}>
                                  <Field
                                    name={`positions.${idx}.uniqName`}
                                    type="hidden"
                                  />
                                  <Field
                                    name={`positions.${idx}.lpQtyStart`}
                                    type="hidden"
                                  />

                                  <Field
                                    name={`positions.${idx}.lpQtyMinus1`}
                                    type="hidden"
                                  />

                                  <Field
                                    name={`positions.${idx}.tokenQtyMinus1`}
                                    type="hidden"
                                  />

                                  <Field
                                    name={`positions.${idx}.tokenPrice`}
                                    type="hidden"
                                  />
                                  <Field
                                    name={`positions.${idx}.lpIncomeDay`}
                                    type="hidden"
                                  />
                                  <Field
                                    name={`positions.${idx}.lpIncomeTotal`}
                                    type="hidden"
                                  />
                                </td>
                              </tr>
                            );
                          })
                        }
                      </FieldArray>
                    </tbody>
                  </Table>
                </div>
                <div className="card-footer text-center border-top-0">
                  <Button
                    className="me-1"
                    variant="success"
                    type="submit"
                    disabled={isLoading}
                  >
                    Save
                  </Button>

                  <Link to="../harvesting">
                    <button
                      className=" btn btn-outline-secondary"
                      type="button"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewHarvesting;
