import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import "./App.css";

import { useAuth } from "./components/hooks/auth-hook";

import { AuthContext } from "./components/context/auth-context";
import Tokens from "./pages/admin/tokens";
import Wallet from "./pages/user/wallet";
import Projects from "./pages/user/projects";
import Farming from "./pages/user/farming";
import Comparing from "./pages/user/comparing";
import Nopage from "./pages/404";
import Balance from "./pages/user/balance";
import Lending from "./pages/user/lending";
import Harvesting from "./pages/user/harvesting";
import Incomes from "./pages/user/incomes";
import Bonuses from "./pages/user/bonuses";
import Investors from "./pages/user/investors";
import Chains from "./pages/admin/chains";
import Managers from "./pages/user/managers";
import Clients from "./pages/user/clients";
import Exchange from "./pages/admin/exchange";
import Pools from "./pages/admin/pools";
import UpdateChain from "./pages/update/UpdateChain";
import Signin from "./user/signin";
import Signup from "./user/signup";
import NewChain from "./pages/new/NewChain";
import NewBalance from "./pages/new/NewBalance";
import NewClient from "./pages/new/NewClient";
import Main from "./pages/main";
import Users from "./user/users";
import UpdateUser from "./user/UpdateUser";
import UpdateClient from "./pages/update/UpdateClient";
import NewToken from "./pages/new/NewToken";
import UpdateToken from "./pages/update/UpdateToken";
import NewExchange from "./pages/new/NewExchange";
import UpdateExchange from "./pages/update/UpdateExchange";
import NewPool from "./pages/new/NewPool";
import UpdatePool from "./pages/update/UpdatePool";
import NewManager from "./pages/new/NewManager";
import UpdateManager from "./pages/update/UpdateManager";
import Cexs from "./pages/user/cexs";
import NewCex from "./pages/new/NewCex";
import UpdateCex from "./pages/update/UpdateCex";
import CexReport from "./pages/report/CexReport";
import NewProject from "./pages/new/NewProject";
import UpdateProject from "./pages/update/UpdateProject";
import NewFarming from "./pages/new/NewFarming";
import UpdateFarming from "./pages/update/UpdateFarming";
import NewLend from "./pages/new/NewLend";
import UpdateLend from "./pages/update/UpdateLending";
import NewCompare from "./pages/new/NewComparing";
import UpdateCompare from "./pages/update/UpdateCompare";
import Details from "./pages/user/detailes";
import NewIncome from "./pages/new/NewIncome";
import UpdateIncome from "./pages/update/UpdateIncome";
import NewBonus from "./pages/new/NewBonus";
import UpdateBonus from "./pages/update/UpdateBonus";
import Offsets from "./pages/user/offsets";
import Setting from "./pages/service/setting";
import NewInvestor from "./pages/new/NewInvestor";
import UpdateInvestor from "./pages/update/UpdateInvestor";
import UpdateBalance from "./pages/update/UpdateBalance";
import FarmReport from "./pages/report/FarmReport";
import LendReport from "./pages/report/LendReport";
import Borrows from "./pages/user/borrows";
import IncomeReport from "./pages/report/IncomeReport";
import BonusReport from "./pages/report/BonusReport";
import ClientReport from "./pages/report/ClientReport";
import WalletReport from "./pages/report/WalletReport";
import NewHarvesting from "./pages/new/NewHarvesting";
import UpdateHarvesting from "./pages/update/UpdateHarvesting";
import NewHarvLending from "./pages/new/NewHarvLending";
import UpdateHarvLending from "./pages/update/UpdateHarvLending";
import OffsetNew from "./pages/new/OffsetNew";
import OffsetUpdate from "./pages/update/OffsetUpdate";
import Header from "./components/navbar/Header";
import SideBar from "./components/navbar/SideBar";
import WalletNew from "./pages/new/WalletNew";
import WalletUpdate from "./pages/update/WalletUpdate";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import MainInside from "./pages/MainInside";
import Subscribe from "./pages/Subscribe";
import Subscription from "./pages/service/Subscription";
import NewSubscriptionIn from "./pages/new/NewSubscribe";
import Support from "./pages/service/Support";
import SubsAdmin from "./pages/admin/SubscriptionsAdmin";
import UpdateSubs from "./pages/update/UpdateSubs";
import InvestorReport from "./pages/report/InvestorReport";
import Payments from "./pages/admin/Payments";
import NewPayment from "./pages/new/NewPayment";
import UpdatePayment from "./pages/update/UpdatePayment";
import PaymentsUser from "./pages/service/PaymentsUser";
import Forgot from "./user/Forgot";
import NewPassword from "./user/NewPassword";
import Documents from "./pages/admin/Documents";
import PricingInside from "./pages/PricingInside";
import WalletImport from "./pages/user/Wallet-import";
import IWalletNew from "./pages/new/NewIwallet";
import IWalletUpdate from "./pages/update/UpdateIwallet";
import CexImport from "./pages/user/Cex-import";
import NewICex from "./pages/new/NewIcex";
import UpdateICex from "./pages/update/UpdateIcex";
import Bridge from "./pages/catalogs/Bridges";
import NewBridge from "./pages/new/NewBridge";
import UpdateBridge from "./pages/update/UpdateBridge";
import Metamask from "./pages/service/Metamask";

const App = () => {
  const {
    userId,
    userEmail,
    userType,
    planName,
    subType,
    verified,
    token,
    login,
    logout,
  } = useAuth();
  const queryClient = new QueryClient();

  let routes;

  if (token) {
    routes = (
      <Routes>
        {/* admins and users */}
        <Route exact path="/" element={<MainInside />} />

        <Route exact path="/bridges" element={<Bridge />} />
        <Route exact path="/bridge/new" element={<NewBridge />} />
        <Route exact path="/bridge/:id" element={<UpdateBridge />} />

        <Route exact path="/tokens" element={<Tokens />} />
        <Route exact path="/token/new" element={<NewToken />} />
        <Route path="/token/:id" element={<UpdateToken />} />

        {/* users */}
        <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/projects/new" element={<NewProject />} />
        <Route path="/project/:id" element={<UpdateProject />} />

        <Route exact path="/wallets" element={<Wallet />} />
        <Route exact path="/wallets/new" element={<WalletNew />} />
        <Route exact path="/wallets/report" element={<WalletReport />} />
        <Route path="/wallet/:id" element={<WalletUpdate />} />

        <Route exact path="/wallet-imports" element={<WalletImport />} />
        <Route exact path="/wallet-import/new" element={<IWalletNew />} />
        <Route exact path="/wallet-import/:id" element={<IWalletUpdate />} />

        <Route exact path="/cexs" element={<Cexs />} />
        <Route exact path="/cexs/new" element={<NewCex />} />
        <Route path="/cex/:id" element={<UpdateCex />} />
        <Route exact path="/cexs/report" element={<CexReport />} />

        <Route exact path="/cex-imports" element={<CexImport />} />
        <Route exact path="/cex-import/new" element={<NewICex />} />
        <Route exact path="/cex-import/:id" element={<UpdateICex />} />

        <Route exact path="/farms" element={<Farming />} />
        <Route exact path="/farms/new" element={<NewFarming />} />
        <Route path="/farm/:id" element={<UpdateFarming />} />
        <Route exact path="/farms/report" element={<FarmReport />} />

        <Route exact path="/lends" element={<Lending />} />
        <Route exact path="/lends/new" element={<NewLend />} />
        <Route path="/lend/:id" element={<UpdateLend />} />
        <Route exact path="/lends/report" element={<LendReport />} />

        <Route exact path="/comps" element={<Comparing />} />
        <Route exact path="/comps/new" element={<NewCompare />} />
        <Route path="/comp/:id" element={<UpdateCompare />} />

        <Route exact path="/harvesting" element={<Harvesting />} />
        <Route exact path="/harvesting/detailsFarming" element={<Details />} />
        <Route exact path="/harvesting/borrows" element={<Borrows />} />
        <Route
          exact
          path="/harvesting/farming/new"
          element={<NewHarvesting />}
        />
        <Route
          exact
          path="/harvesting/lending/new"
          element={<NewHarvLending />}
        />
        <Route
          exact
          path="/harvesting/farming/:id"
          element={<UpdateHarvesting />}
        />
        <Route
          exact
          path="/harvesting/lending/:id"
          element={<UpdateHarvLending />}
        />

        <Route exact path="/offsets" element={<Offsets />} />
        <Route exact path="/offsets/new" element={<OffsetNew />} />
        <Route path="/offset/:id" element={<OffsetUpdate />} />

        <Route exact path="/balances" element={<Balance />} />
        <Route exact path="/balances/new" element={<NewBalance />} />
        <Route path="/balances/:id" element={<UpdateBalance />} />

        <Route exact path="/incomes" element={<Incomes />} />
        <Route exact path="/incomes/new" element={<NewIncome />} />
        <Route exact path="/incomes/report" element={<IncomeReport />} />
        <Route path="/income/:id" element={<UpdateIncome />} />

        <Route exact path="/bonuses" element={<Bonuses />} />
        <Route exact path="/bonuses/new" element={<NewBonus />} />
        <Route exact path="/bonuses/report" element={<BonusReport />} />
        <Route path="/bonus/:id" element={<UpdateBonus />} />

        <Route exact path="/investors" element={<Investors />} />
        <Route exact path="/investors/new" element={<NewInvestor />} />
        <Route exact path="/investors/report" element={<InvestorReport />} />
        <Route path="/investor/:id" element={<UpdateInvestor />} />

        <Route exact path="/managers" element={<Managers />} />
        <Route exact path="/managers/new" element={<NewManager />} />
        <Route path="/manager/:id" element={<UpdateManager />} />

        <Route exact path="/clients" element={<Clients />} />
        <Route exact path="/clients/new" element={<NewClient />} />
        <Route exact path="/clients/report" element={<ClientReport />} />
        <Route path="/client/:id" element={<UpdateClient />} />

        {/* admins */}

        <Route exact path="/documents" element={<Documents />} />

        <Route exact path="/chains" element={<Chains />} />
        <Route exact path="/chains/new" element={<NewChain />} />
        <Route path="/chain/:id" element={<UpdateChain />} />

        <Route exact path="/exchanges" element={<Exchange />} />
        <Route exact path="/exchanges/new" element={<NewExchange />} />
        <Route path="/exchange/:id" element={<UpdateExchange />} />

        <Route exact path="/pools" element={<Pools />} />
        <Route exact path="/pools/new" element={<NewPool />} />
        <Route path="/pool/:id" element={<UpdatePool />} />

        <Route exact path="/users" element={<Users />} />
        <Route exact path="/user/:id" element={<UpdateUser />} />

        <Route exact path="/settings" element={<Setting />} />

        <Route exact path="/payments" element={<Payments />} />
        <Route exact path="/payments/new" element={<NewPayment />} />
        <Route exact path="/payment/:id" element={<UpdatePayment />} />

        {/* setting */}
        <Route exact path="/subscriptions" element={<Subscription />} />
        <Route
          exact
          path="/subscriptions/new"
          element={<NewSubscriptionIn />}
        />
        <Route exact path="/subscriptions/admin" element={<SubsAdmin />} />
        <Route
          exact
          path="/subscriptions/payments"
          element={<PaymentsUser />}
        />
        <Route exact path="/subscription/:id" element={<UpdateSubs />} />

        <Route exact path="/support" element={<Support />} />
        <Route exact path="/pricing" element={<PricingInside />} />
        <Route exact path="/metamask" element={<Metamask />} />

        <Route exact path="/login" element={<Navigate to="/" />} />

        <Route path="*" element={<Nopage />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route exact path="/login" element={<Signin />} />
        <Route exact path="/forgot" element={<Forgot />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/" element={<Main />} />
        <Route exact path="/features" element={<Features />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/plan/:id" element={<Subscribe />} />
        <Route exact path="/recovery/:id" element={<NewPassword />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        userId: userId,
        userEmail: userEmail,
        userType: userType,
        planName: planName,
        subType: subType,
        verified: verified,
        token: token,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <QueryClientProvider client={queryClient}>
          <Header />
          <div className="container-fluid">
            <div className="row">
              <SideBar />
              <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="d-flex flex-wrap flex-md-nowrap  pt-3 pb-2 mb-3 ">
                  {routes}
                </div>
              </main>
            </div>
          </div>
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
        </QueryClientProvider>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
