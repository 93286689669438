import React from "react";
import { Card } from "react-bootstrap";

import "./CardMain.css";
import ButtonsTop from "./elements/ButtonsTop";

export default function CardMain(props) {
  const { cardReport, cardName, ...rest } = props;

  return (
    <Card
      className="text-center card-center w-100 scroll"
      // bg="dark"
      // text="white"
      {...rest}
    >
      <Card.Header>
        {cardReport ? (
          <ButtonsTop report={true} cardName={cardName} />
        ) : (
          <ButtonsTop report={false} cardName={cardName} />
        )}
      </Card.Header>
      <Card.Body>{props.children}</Card.Body>
      <Card.Footer>{}</Card.Footer>
    </Card>
  );
}
