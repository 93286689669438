import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Spinner, Card, Button } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSort";
import {
  ColorBuffer,
  NumberWithCommas,
  LinkCell,
} from "../../components/table/FormatedCell";
import { getPrice, customFetch } from "../../components/Functions";

function CexReport() {
  const [loadedCexs, setLoadedCexs] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedExchanges, setLoadedExchanges] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Exchange Report" };

  let loadedAll =
    !isLoading &&
    loadedCexs &&
    loadedTokens &&
    loadedExchanges &&
    loadedSettings;

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      customFetch(sendRequest, auth, "/setting", (data) =>
        setloadedSettings(data.settings)
      );
      customFetch(sendRequest, auth, "/token", (data) =>
        setLoadedTokens(data.tokens)
      );
      customFetch(sendRequest, auth, "/cex", (data) =>
        setLoadedCexs(data.cexs)
      );
      customFetch(sendRequest, auth, "/exchange", (data) =>
        setLoadedExchanges(data.exchanges)
      );
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth]);

  //Calculations

  let loadedReport,
    loadedReportNew,
    yellowGreen = 0,
    redYellow = -10;

  if (loadedAll) {
    loadedCexs.forEach((e) => {
      e.tokenPrice = getPrice(loadedTokens, e.tokenName);
      e.actSum = (Math.round(e.tokenQty * e.tokenPrice * 100) / 100).toFixed(0);

      switch (e.orderType) {
        case "Short":
          e.pnl =
            (Math.round(e.tokenQty * e.priceOpen * 100) / 100).toFixed(0) -
            e.actSum;

          break;
        case "Long":
          (e.pnl =
            e.actSum -
            Math.round(e.tokenQty * e.priceOpen * 100) / 100).toFixed(0);

          break;
        case "Collateral":
          e.pnl = 0;
          e.leverage = 1;

          break;
        default:
          e.pnl = 0;
          e.leverage = 1;
          break;
      }
      let sumLev = e.actSum / e.leverage;
      e.posActual = parseInt(
        (Math.round((e.pnl + sumLev + e.collAdded) * 100) / 100).toFixed(0)
      );
      e.posMarket = parseInt((Math.round(sumLev * 100) / 100).toFixed(0));

      switch (e.orderType) {
        case "Collateral":
          e.diff = 0;
          e.buffer = 0;
          e.posMarket = 0;
          break;
        default:
          e.diff = e.posActual - e.posMarket;
          if (e.posMarket !== 0) {
            e.buffer = (
              Math.round((e.diff / e.posMarket) * 10000) / 100
            ).toFixed(0);
          } else {
            e.buffer = 0;
          }

          break;
      }
    });
    loadedReport = loadedCexs.map(
      ({
        exchangeName,
        accountType,
        tokenName,
        clientName,
        posMarket,
        posActual,
      }) => ({
        exchangeName,
        accountType,
        tokenName,
        clientName,
        posMarket,
        posActual,
      })
    );
    loadedReport.forEach((l) => {
      if (l.accountType === "Cross") {
        l.tokenName = "Cross";
      } else if (l.accountType === "Spot") {
        l.tokenName = "Spot";
      }
    });

    function aggregate(collection, propertyNames, iteratee) {
      function category(obj) {
        return _.chain(obj).pick(propertyNames).values().join(" ");
      }
      function summarize(group) {
        return _.chain(group)
          .first()
          .pick(propertyNames)
          .extend(_.reduce(group, iteratee))
          .value();
      }
      return _.chain(collection).groupBy(category).map(summarize).value();
    }

    loadedReportNew = aggregate(
      loadedReport,
      ["exchangeName", "accountType", "clientName", "tokenName"],
      (memo, value) => ({
        posActual: +memo.posActual + +value.posActual,
        posMarket: +memo.posMarket + +value.posMarket,
      })
    );

    //add diif and buffer ather then spot
    loadedReportNew.forEach((e) => {
      switch (e.accountType) {
        case "Spot":
          e.diff = 0;
          e.buffer = 0;
          break;

        default:
          e.diff = e.posActual - e.posMarket;
          e.buffer = parseInt(
            (Math.round((e.diff / e.posMarket) * 100 * 100) / 100).toFixed(0)
          );
          break;
      }
      //add link
      loadedExchanges.forEach((x) => {
        if (x.exchangeName === e.exchangeName) {
          e.link = x.link;
        }
      });
    });

    //setting for red cex buffer
    redYellow = loadedSettings.find((d) => d.name === "botExchangeCover");
  }

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        if (cell.column.id === "exchangeName") {
          window.open(`${cell.row.original.link}`);
        } else {
          navigate(`/cexs`);
        }
      },
    };
  };

  const columns = [
    {
      Header: "Exchange",
      accessor: "exchangeName",
      Cell: LinkCell,
    },
    {
      Header: "Account",
      accessor: "accountType",
    },
    {
      Header: "Token",
      accessor: "tokenName",
    },
    {
      Header: "MarketPos",
      accessor: "posMarket",
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.posMarket)} $`}</div>
      ),
    },

    {
      Header: "ActualPos",
      accessor: "posActual",
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.posActual)} $`}</div>
      ),
    },
    {
      Header: "Buffer, %",
      accessor: "buffer",
      Cell: (cell) => ColorBuffer(cell, yellowGreen, redYellow),
    },
    {
      Header: "Diff, $",
      accessor: "diff",
      Cell: ({ row }) => <div>{`${NumberWithCommas(row.values.diff)} $`}</div>,
    },
    {
      Header: "Client",
      accessor: "clientName",
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner />}

      {loadedAll && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className="p-1"></div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              <div className="">
                <Button
                  as={Link}
                  to={`../cexs`}
                  className="btn btn-sm btn-secondary"
                >
                  Details
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={loadedReportNew}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default CexReport;
