import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import _ from "lodash";

import "../new/New.css";
import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { EditableCell } from "../../components/table/EditableCell";
import { ReactTable } from "../../components/TableSearch";

const Details = () => {
  //const [value1, setValue1] = useState();
  const [data, setData] = useState();
  const [harvs, setHarvs] = useState();
  const [incomes, setIncomes] = useState();
  const [bonuses, setBonuses] = useState();
  const [loadedManagers, setLoadedManagers] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    let unmounted = false;

    const fetchDetails = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/detail",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.details.sort((a, b) =>
          a.posDate < b.posDate ? 1 : b.posDate < a.posDate ? -1 : 0
        );

        if (!unmounted) setData(responseData.details);
      } catch (err) {}
    };
    fetchDetails();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    const fetchPools = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pool",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedPools(responseData.pools);
      } catch (err) {}
    };
    fetchPools();

    const fetchHarvs = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/harv",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setHarvs(responseData.harvs);
      } catch (err) {}
    };
    fetchHarvs();

    const fetchIncomes = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/income",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setIncomes(responseData.incomes);
      } catch (err) {}
    };
    fetchIncomes();

    const fetchBonuses = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/bonus",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setBonuses(responseData.bonuses);
      } catch (err) {}
    };
    fetchBonuses();

    const fetchManagers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/manager",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedManagers(responseData.managers);
      } catch (err) {}
    };
    fetchManagers();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  //Date
  let yesterdayDate = new Date(
    new Date().setDate(new Date().getDate() - 1)
  ).toLocaleDateString("en-GB");

  const getPrice = (tokenSymbol) => {
    let tokenObj;

    tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    return tokenObj.tokenPrice;
  };

  //
  const numberWithCommas = (number) => {
    return Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const ifNoneToZero = (digit) => {
    if (digit) {
      return digit;
    } else {
      return 0;
    }
  };

  let isLoadingData;

  //Calculations Data
  if (loadedTokens && data) {
    isLoadingData = true;
    data.forEach((d) => {
      if (
        new Date(d.posDate).toLocaleDateString("en-GB") ===
        new Date().toLocaleDateString("en-GB")
      ) {
        d.tokenPrice = getPrice(d.tokenName);

        let lpQtyMinus1 = _.sumBy(data, (w) => {
          if (
            new Date(w.posDate).toLocaleDateString("en-GB") === yesterdayDate &&
            d.projectNumber === w.projectNumber
          ) {
            return w.lpQty;
          }
        });
        let lpQtyStartMinus1 = _.sumBy(data, (w) => {
          if (
            new Date(w.posDate).toLocaleDateString("en-GB") === yesterdayDate &&
            d.projectNumber === w.projectNumber
          ) {
            return w.lpQtyStart;
          }
        });
        if (lpQtyMinus1 && lpQtyStartMinus1 === d.lpQtyStart) {
          d.lpQtyMinus1 = lpQtyMinus1;
        } else {
          d.lpQtyMinus1 = d.lpQtyStart;
        }

        d.tokenQtyMinus1 = _.sumBy(data, (w) => {
          if (
            new Date(w.posDate).toLocaleDateString("en-GB") === yesterdayDate &&
            d.projectNumber === w.projectNumber &&
            !d.isHarvested
          ) {
            return ifNoneToZero(w.tokenQty);
          } else {
            return 0;
          }
        });

        d.lpDiffDay = d.lpQty - d.lpQtyMinus1;
        d.lpDiffTotal = d.lpQty - d.lpQtyStart;

        if (d.posSum && d.lpQtyMinus1 && d.lpDiffDay) {
          d.lpIncomeDay = (d.posSum / d.lpQtyMinus1) * d.lpDiffDay;
        } else {
          d.lpIncomeDay = 0;
        }

        if (d.posSum && d.lpQtyStart) {
          d.lpIncomeTotal = (d.posSum / d.lpQtyStart) * d.lpDiffTotal;
        } else {
          d.lpIncomeTotal = 0;
        }

        if (d.tokenPrice) {
          d.posIncomeDay =
            (d.tokenQty - ifNoneToZero(d.tokenQtyMinus1)) * d.tokenPrice +
            d.lpIncomeDay;
        } else {
          d.posIncomeDay = 0;
        }
        // console.log(`d.token_${d.lpIncomeTotal}`);
        if (d.tokenPrice) {
          d.posIncomeTotal = d.tokenQty * d.tokenPrice + d.lpIncomeTotal;
        } else {
          d.posIncomeTotal = 0;
        }
        if (d.posIncomeDay && d.posSum) {
          d.posApy = (d.posIncomeDay * 36000) / d.posSum;
        } else {
          d.posApy = 0;
        }
      }
    });
    isLoadingData = false;
  }

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) setSkipPageReset(false);

    return () => {
      unmounted = true;
    };
  }, [data]);

  //Submit Details
  const submitDetail = () => {
    data.forEach(async (e) => {
      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/detail/${e.id}`,
          "PATCH",
          JSON.stringify({
            //posDate: new Date(e.posDate).toLocaleDateString("en-gb"),
            projectNumber: e.projectNumber,
            providerName: e.providerName,
            pairs: e.pairs,
            posSum: e.posSum,
            tokenName: e.tokenName,
            tokenPrice: e.tokenPrice,
            isHarvested: e.isHarvested,
            lpQty: e.lpQty,
            lpQtyStart: e.lpQtyStart,
            tokenQty: e.tokenQty,
            posIncomeTotal: e.posIncomeTotal,
            posIncomeDay: e.posIncomeDay,
            posApy: e.posApy,
            chainName: e.chainName,
            managerName: e.managerName,
            clientName: e.clientName,
            userId: auth.userId,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {}
    });
  };

  //Calculation for Harv
  if (harvs && data) {
    isLoadingData = true;
    harvs.forEach((h) => {
      if (
        new Date(h.harvDate).toLocaleDateString("en-GB") ===
        new Date().toLocaleDateString("en-GB")
      ) {
        h.incomeTotal = _.sumBy(data, (f) => {
          if (
            h.clientName === f.clientName &&
            new Date(f.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB")
          ) {
            return f.posIncomeTotal;
          }
        });
        h.incomeDay = _.sumBy(data, (f) => {
          if (
            h.clientName === f.clientName &&
            new Date(f.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB")
          ) {
            return Math.round(f.posIncomeDay);
          }
        });
        h.posSum = _.sumBy(data, (f) => {
          if (
            h.clientName === f.clientName &&
            new Date(f.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB")
          ) {
            return f.posSum;
          }
        });
        h.apy = Math.round((h.incomeDay * 36500) / h.posSum);
        h.positions = _.sumBy(data, (w) => {
          if (
            h.clientName === w.clientName &&
            new Date(w.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB")
          ) {
            return `${w.providerName} ${numberWithCommas(
              w.posSum
            )}$ ${numberWithCommas(w.posIncomeDay)}$ ${numberWithCommas(
              w.posApy
            )}%|`;
          }
        });
      }
    });
    isLoadingData = false;
  }

  //Submit Harv
  const submitHarv = () => {
    harvs.forEach(async (e) => {
      if (
        new Date(e.harvDate).toLocaleDateString("en-GB") ===
          new Date().toLocaleDateString("en-GB") &&
        e.incomeType === "Farming"
      ) {
        try {
          await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/harv/${e.id}`,
            "PATCH",
            JSON.stringify({
              harvDate: e.harvDate,
              positions: e.positions,
              incomeType: e.incomeType,
              incomeTotal: e.incomeTotal,
              incomeDay: e.incomeDay,
              apy: e.apy,
              clientName: e.clientName,
              userId: auth.userId,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {}
      }
    });
  };

  //Calc Incomes
  if (incomes && data) {
    console.log(incomes);

    isLoadingData = true;
    incomes.forEach((h) => {
      h.incomeDay = _.sumBy(data, (w) => {
        if (
          new Date(w.posDate).toLocaleDateString("en-GB") ===
            new Date().toLocaleDateString("en-GB") &&
          h.providerName === w.providerName
        ) {
          return ifNoneToZero(w.posIncomeDay);
        } else {
          return 0;
        }
      });
      h.posSum = _.sumBy(data, (w) => {
        if (
          new Date(w.posDate).toLocaleDateString("en-GB") ===
            new Date().toLocaleDateString("en-GB") &&
          h.providerName === w.providerName
        ) {
          return ifNoneToZero(w.posSum);
        } else {
          return 0;
        }
      });

      h.incomePercent = (h.incomeDay * 36500) / h.posSum;

      h.managerName = _.sumBy(data, (w) => {
        if (
          new Date(w.posDate).toLocaleDateString("en-GB") ===
            new Date().toLocaleDateString("en-GB") &&
          h.providerName === w.providerName
        ) {
          return w.managerName;
        }
      });
    });

    isLoadingData = false;
  }

  //Submit Incomes
  const submitIncome = () => {
    incomes.forEach(async (h) => {
      if (
        new Date(h.date).toLocaleDateString("en-GB") ===
          new Date().toLocaleDateString("en-GB") &&
        h.incomeType === "Farming"
      ) {
        try {
          await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/income/${h.id}`,
            "PATCH",
            JSON.stringify({
              date: new Date(),
              providerName: h.providerName,
              incomeType: h.incomeType,
              incomeDay: h.incomeDay,
              incomePercent: h.incomePercent,
              managerName: h.managerName,
              userId: auth.userId,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {}
      }
    });
  };

  //Calc Bonuses
  if (bonuses && loadedManagers && data) {
    isLoadingData = true;
    bonuses.forEach((m) => {
      m.incomeDay = _.sumBy(data, (w) => {
        if (
          new Date(w.posDate).toLocaleDateString("en-GB") ===
            new Date().toLocaleDateString("en-GB") &&
          m.managerName === w.managerName
        ) {
          return ifNoneToZero(w.posIncomeDay);
        } else {
          return 0;
        }
      });
      m.managerBonus = _.sumBy(loadedManagers, (n) => {
        if (m.managerName === n.managerName) {
          return n.managerBonus;
        }
      });
      m.bonusDay = (m.incomeDay * m.managerBonus) / 100;
    });
    isLoadingData = false;
  }

  //Submit Bonuses
  const submitIncomeMan = () => {
    bonuses.forEach(async (m) => {
      if (
        new Date(m.date).toLocaleDateString("en-GB") ===
          new Date().toLocaleDateString("en-GB") &&
        m.incomeType === "Farming"
      ) {
        try {
          await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/bonus/${m.id}`,
            "PATCH",
            JSON.stringify({
              date: new Date(),
              managerName: m.managerName,
              incomeType: m.incomeType,
              incomeDay: m.incomeDay,
              bonusDay: m.bonusDay,
              userId: auth.userId,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {}
      }
    });
  };

  const SubmitSave = () => {
    //
    submitHarv();
    submitIncome();
    submitIncomeMan();
    submitDetail();
  };

  //Delete
  const deleteHandler = async (Id) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/detail/${Id}`,
        "DELETE",
        null,
        { Authorization: "Bearer " + auth.token }
      );
    } catch (err) {}
    navigate("../harvesting/");
  };
  //
  const getLink = (poolName) => {
    let link;
    loadedPools.forEach((e) => {
      if (e.poolName === poolName) {
        link = e.poolLink;
      }
    });
    return link;
  };

  if (data && loadedPools) {
    data.forEach((e) => {
      e.linkProvider = getLink(e.providerName);
    });
  }

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        switch (cell.column.id) {
          case "delete":
            if (
              new Date(cell.row.values.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB")
            ) {
              deleteHandler(cell.row.original.id);
            } else {
              alert("Deleting of previous days are not allowed");
            }
            break;
          case "providerName":
            if (
              new Date(cell.row.values.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB")
            ) {
              window.open(`${cell.row.original.linkProvider}`);
            } else {
              alert("Deleting of previous days are not allowed");
            }
            break;

          default:
            break;
        }
      },
    };
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },
      {
        Header: "Date",
        accessor: "posDate",
        Cell: ({ row }) =>
          new Date(row.values.posDate).toLocaleDateString("en-GB"),
      },
      {
        Header: "Proj N",
        accessor: "projectNumber",
      },
      {
        Header: "Provider",
        accessor: "providerName",
        Cell: ({ row }) => {
          return (
            <div
              style={{
                textDecoration: "underline",
                color: "green",
              }}
            >
              {row.values.providerName}
            </div>
          );
        },
      },
      {
        Header: "Chain",
        accessor: "chainName",
      },
      {
        Header: "Pairs",
        accessor: "pairs",
      },
      {
        Header: "Sum",
        accessor: "posSum",
        Cell: ({ row }) => (
          <div>{`${numberWithCommas(row.values.posSum)} $`}</div>
        ),
      },
      {
        Header: "Token",
        accessor: "tokenName",
      },
      {
        Header: "isHarv-d",
        accessor: "isHarvested",
        Cell: ({ cell: { value, column }, row: { original } }) => {
          return (
            <div>
              <Form.Check
                type="checkbox"
                //name="isHarvested"
                checked={original.isHarvested}
                value={original.isHarvested}
                onChange={() => {
                  setIsChecked(original.isHarvested);
                  setIsChecked(!isChecked);
                  original.isHarvested = !original.isHarvested;
                  updateMyData(parseInt(original.id), column.id, isChecked);
                }}
                //onBlur={(event) => {}}
              />
            </div>
          );
        },
      },
      {
        Header: "LP Q-ty Start",
        accessor: "lpQtyStart",
        show: false,
      },
      {
        Header: "LP Q-ty -1",
        accessor: "lpQtyMinus1",
        show: false,
      },
      {
        Header: "LP Q-ty",
        accessor: "lpQty",
        Cell: EditableCell,
      },
      {
        Header: "LP Income Day",
        accessor: "lpIncomeDay",
        show: false,
      },

      {
        Header: "Token Q-ty -1",
        accessor: "tokenQtyMinus1",
        show: false,
      },
      {
        Header: "Token Q-ty",
        accessor: "tokenQty",
        Cell: EditableCell,
      },
      {
        Header: "Token Price",
        accessor: "tokenPrice",
        show: false,
      },
      {
        Header: "Total income",
        accessor: "posIncomeTotal",
        Cell: ({ row }) => (
          <div>{`${numberWithCommas(row.values.posIncomeTotal)} $`}</div>
        ),
      },

      {
        Header: "Income Day",
        accessor: "posIncomeDay",
        Cell: ({ row }) => (
          <div>{`${numberWithCommas(row.values.posIncomeDay)} $`}</div>
        ),
      },
      {
        Header: "APY",
        accessor: "posApy",
        Cell: ({ row }) => {
          let color;
          if (row.values.posApy <= 80 && row.values.posApy > 0) {
            color = (
              <div
                style={{
                  color: "#FFF8F3",
                  textAlign: "center",
                  padding: "0.5rem",
                  margin: -7,
                  fontSize: "15px",
                  backgroundColor: "#FFAD60",
                }}
              >
                {`${Math.round(row.values.posApy)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`}
              </div>
            );
          } else if (row.values.posApy > 80) {
            color = (
              <div
                style={{
                  textAlign: "center",
                  padding: "0.5rem",
                  margin: -7,
                  fontSize: "15px",
                  backgroundColor: "#A3DA8D",
                }}
              >
                {`${Math.round(row.values.posApy)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`}
              </div>
            );
          } else {
            color = (
              <div
                style={{
                  textAlign: "center",
                  padding: "0.5rem",
                  margin: -7,
                  fontSize: "15px",
                  backgroundColor: "#FF6363",
                }}
              >
                {`${Math.round(row.values.posApy)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`}
              </div>
            );
          }
          return color;
        },
      },
      {
        Header: "Del",
        accessor: "delete",
        Cell: ({ row }) => {
          if (
            new Date(row.values.posDate).toLocaleDateString("en-GB") ===
            new Date().toLocaleDateString("en-GB")
          ) {
            return <button type="button">x</button>;
          } else {
            return <div></div>;
          }
        },
      },
      {
        Header: "Manager",
        accessor: "managerName",
        show: false,
      },
      {
        Header: "Client",
        accessor: "clientName",
        show: false,
      },
      {
        Header: "userId",
        accessor: "userid",
        show: false,
      },
    ],
    [isChecked]
  );

  const sortBy = [{ id: "posDate" }];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {isLoading && <Spinner animation="border" />}

      {(!isLoading || !isLoadingData) &&
        loadedTokens &&
        loadedPools &&
        data &&
        bonuses &&
        incomes &&
        harvs && (
          <div>
            <Link to="../harvesting/">
              <button
                style={{ marginLeft: "1px", marginBottom: "2px" }}
                type="button"
              >
                Back
              </button>
            </Link>

            <button
              onClick={SubmitSave}
              disabled={isLoading}
              className="button-report"
              style={{ marginLeft: "1px", marginBottom: "2px", align: "" }}
              type="submit"
            >
              Save
            </button>

            <ReactTable
              columns={columns}
              data={data}
              sortBy={sortBy}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              getCellProps={onClickCellHandler}
            />
          </div>
        )}
    </>
  );
};

export default Details;
