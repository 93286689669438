import { Row, Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const NewSub = () => {
  //const [newClient, setNewClient] = useState();

  const subName = useParams().id.toUpperCase();

  //Card name
  let cardOptions = { cardName: "Subscription" };
  let subSumMonth, subSumYear;

  if (subName === "BASE") {
    subSumMonth = "9$";
    subSumYear = "99$";
  } else if (subName === "PRO") {
    subSumMonth = "29$";
    subSumYear = "299$";
  } else {
    subSumMonth = "99$";
    subSumYear = "999$";
  }

  return (
    <div className="mx-auto">
      <Card className="text-center">
        <Card.Header className="text-center fs-3 bg-primary text-white ">
          {cardOptions.cardName}
        </Card.Header>
        <Card.Body className="text-center">
          <Row className="mb-2">
            <div className="text-center">
              <h1>Thank you for choosing Plan</h1>
              <h2 className="mb-5">{subName}</h2>
              <h5 className="text-secondary  mb-5">
                Month subscribtion - {subSumMonth} in USDT, USDC or BUSD
              </h5>
              <h5 className="text-secondary  mb-5">
                Year subscribtion - {subSumYear} in USDT, USDC or BUSD
              </h5>
            </div>
          </Row>
          <Row className="text-secondary mb-5">
            <h5 className="text-secondary mb-5">
              Please, register in cryptoBase.
            </h5>
            <h5>During free trial period (3 days) pay your subscription!</h5>
          </Row>
        </Card.Body>
        <Card.Footer className="text-center">
          <Link to="../signup">
            <button type="button" className="btn btn-success me-1">
              Register
            </button>
          </Link>
          <Link to="../pricing">
            <button type="button" className="btn btn-secondary">
              Cancel
            </button>
          </Link>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default NewSub;
