import React, { useContext, useState } from "react";
import { Spinner, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useData, useGetDataId } from "../../components/hooks/dbase-hook";

import { AuthContext } from "../../components/context/auth-context";
import CardNewUpdate from "../../components/CardNewUpdate";
import SumField from "../../components/elements/form/SumField";
import TokenField from "../../components/elements/form/TokenField";
import ChainField from "../../components/elements/form/ChainField";
import TextField from "../../components/elements/form/TextField";
import ErrorAlert from "../../components/elements/ErrorAlert";
import DateField from "../../components/elements/form/DateField";

// Schema for yup
const validationSchema = Yup.object().shape({
  date: Yup.date().required("*Date is required"),
  chainName: Yup.string().required("*Chain is required"),
  blockNumber: Yup.string().required("*BlockNumber is required"),
  cryptoAddress: Yup.string().required("*CryptoAddress is required"),
  tokenName: Yup.string().required("*TokenName is required"),
  sum: Yup.number().required("*Sum is required"),
});

export default function UpdatePayment() {
  const [error, setError] = useState();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Payment" };
  let pathId = `/${cardOptions.cardName.toLowerCase()}/${Id}`;

  //functions for update Query
  const onSuccess = () => {
    navigate(`../${cardOptions.cardName.toLowerCase()}s`);
  };
  const onError = () => {
    setError(mutateError);
    return <ErrorAlert error={error} onClear={clearError} />;
  };
  const onSettled = () => {};

  //update Query
  const { mutate: queryRequest, error: mutateError } = useData(
    onSuccess,
    onError,
    onSettled
  );

  //get Wallet
  //functions for update Query
  const onSuccessGet = () => {};
  const onErrorGet = () => {
    setError(requestError);
    return <ErrorAlert error={error} onClear={clearError} />;
  };
  const onSettledGet = () => {};

  const getOptions = {
    path: pathId,
    token: auth.token,
  };

  const {
    isLoading,
    data,
    error: requestError,
  } = useGetDataId(onSuccessGet, onErrorGet, onSettledGet, getOptions);

  //Formik initialValues
  let initialValues;
  if (!isLoading && data) {
    initialValues = {
      date: data.data.payment.date.split("T")[0],
      chainName: data.data.payment.chainName,
      blockNumber: data.data.payment.blockNumber,
      cryptoAddress: data.data.payment.cryptoAddress,
      tokenName: data.data.payment.tokenName,
      sum: data.data.payment.sum,
      subId: data.data.payment.subId ? data.data.payment.subId : "",
    };
  }

  //Formik submit
  const onSubmit = (values) => {
    let options = {
      path: pathId,
      method: "patch",
      token: auth.token,
      data: JSON.stringify({
        date: new Date(values.date),
        chainName: values.chainName,
        blockNumber: values.blockNumber,
        cryptoAddress: values.cryptoAddress,
        tokenName: values.tokenName,
        sum: values.sum,
        subId: values.subId,
      }),
    };
    queryRequest(options);
  };
  function clearError() {
    setError(null);
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {!error && !isLoading && data ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={false} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <DateField name="date" label="Date:" />
                  <ChainField name="chainName" label="Chain:" />
                </Row>

                <Row className="mb-3">
                  <SumField name="blockNumber" label="Block Number:" />
                  <TextField name="cryptoAddress" label="CryptoAddress:" />
                </Row>
                <Row className="mb-3">
                  <TokenField name="tokenName" label="Token:" />
                  <SumField name="sum" label="Sum:" />
                </Row>
                <Row className="mb-3">
                  <TextField name="subId" label="Subs Id:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner animation="border" />
      )}
    </>
  );
}
