import React, { useEffect, useState, useContext } from "react";
import { Form, Row, Spinner, Col } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CardNewUpdate from "../../components/CardNewUpdate";
import TextField from "../../components/elements/form/TextField";
import SumField from "../../components/elements/form/SumField";

// Schema for yup
const validationSchema = Yup.object().shape({
  chainNumber: Yup.number().required("Please add the number."),
  chainName: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Please add the name."),
  explorer: Yup.string().required("*Explorer is required"),
});

const UpdateChain = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedChain, setLoadedChain] = useState();
  const [wallets, setWallets] = useState();
  const [cexs, setCexs] = useState();
  const [projects, setPorjects] = useState();
  const [loadedFarming, setLoadedFarming] = useState();
  const [loadedLends, setLoadedLends] = useState();
  const [details, setDetails] = useState();
  const auth = useContext(AuthContext);
  const Id = useParams().id;
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Chain" };

  useEffect(() => {
    let unmounted = false;
    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };
    if (!unmounted) {
      fetch(`/chain/${Id}`, (data) => setLoadedChain(data.chain));
      fetch("/wallet", (data) => setWallets(data.wallets));
      fetch("/cex", (data) => setCexs(data.cexs));
      fetch("/project", (data) => setPorjects(data.projects));
      fetch("/farm", (data) => setLoadedFarming(data.farms));
      fetch("/lend", (data) => setLoadedLends(data.lends));
      fetch("/detail", (data) => setDetails(data.details));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, Id, auth.token]);

  let allLoaded =
    !isLoading &&
    loadedChain &&
    loadedFarming &&
    loadedLends &&
    projects &&
    wallets &&
    cexs &&
    details;

  //disable delete button if chain in use
  let disableDelete, initialValues;
  if (allLoaded) {
    projects.forEach((project) => {
      if (project.chainName === loadedChain.chainName) {
        disableDelete = 1;
      }
    });
    loadedFarming.forEach((f) => {
      if (f.chainName === loadedChain.chainName) {
        disableDelete = 1;
      }
    });
    loadedLends.forEach((l) => {
      if (l.chainName === loadedChain.chainName) {
        disableDelete = 1;
      }
    });
    wallets.forEach((w) => {
      if (w.chainName === loadedChain.chainName) {
        disableDelete = 1;
      }
    });
    cexs.forEach((c) => {
      if (c.chainName === loadedChain.chainName) {
        disableDelete = 1;
      }
    });
    details.forEach((d) => {
      if (d.chainName === loadedChain.chainName) {
        disableDelete = 1;
      }
    });

    //Formik initialValues
    initialValues = {
      chainName: loadedChain.chainName,
      chainNumber: loadedChain.chainNumber,
      explorer: loadedChain.explorer ? loadedChain.explorer : "",
      activated: loadedChain.activated,
    };
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/chain/${Id}`,
                "PATCH",
                JSON.stringify({
                  chainNumber: values.chainNumber,
                  chainName: values.chainName,
                  explorer: values.explorer,
                  activated: values.activated,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
              navigate("../chains");
            } catch (err) {}
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <CardNewUpdate
                cardNew={false}
                cardName={cardOptions.cardName}
                isbuttondisabled={disableDelete}
              >
                <Row className="mb-2">
                  <SumField name="chainNumber" label="Chain Number:" />
                  <TextField name="chainName" label="Chain Name:" />
                </Row>
                <Row className="mb-2">
                  <TextField name="explorer" label="Chain Explorer:" />
                </Row>
                {auth.userType === "admin" ? (
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Activated:</Form.Label>
                      <Field as={Form.Select} name="activated">
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </Field>
                    </Form.Group>
                  </Row>
                ) : null}
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UpdateChain;
