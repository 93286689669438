import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useQueryClient } from "react-query";
import { Spinner } from "react-bootstrap";

import "../pages.css";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import CardMain from "../../components/CardMain";
import { NumberWithCommas } from "../../components/table/FormatedCell";

function Offsets() {
  const [offsets, setOffsets] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  //Card options
  let cardOptions = { cardName: "Offset" };

  useEffect(() => {
    let unmounted = false;

    const fetchOffsets = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/offset",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.offsets.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        if (!unmounted) setOffsets(responseData.offsets);
      } catch (err) {}
    };
    fetchOffsets();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  //Calculations
  if (!isLoading && offsets) {
    offsets.forEach((e) => {
      e.totalOffsetPlus = _.sumBy(offsets, (o) => {
        if (e.clientName1 === o.clientName1) {
          return o.sum;
        } else {
          return 0;
        }
      });
      e.totalOffsetMinus = _.sumBy(offsets, (o) => {
        if (e.clientName1 === o.clientName2) {
          return o.sum * -1;
        } else {
          return 0;
        }
      });
      // console.log(`test${e}`);
      if (
        new Date(e.date).toLocaleDateString("en-GB") ===
        new Date().toLocaleDateString("en-GB")
      ) {
        e.totalOffset = e.totalOffsetPlus + e.totalOffsetMinus;
      }
    });
  }

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        queryClient.invalidateQueries("getData");
        navigate(`/offset/${cell.row.values.id}`);
      },
    };
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },

      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        Cell: ({ row }) =>
          new Date(row.values.date).toLocaleDateString("en-GB"),
      },
      {
        Header: "Client1 +",
        accessor: "clientName1",
      },

      {
        Header: "Client2 -",
        accessor: "clientName2",
      },
      {
        Header: "Sum",
        accessor: "sum",
        disableFilters: true,
        Cell: ({ row }) => <div>{`${NumberWithCommas(row.values.sum)} $`}</div>,
      },
      // {
      //   Header: "Client1 Offset",
      //   accessor: "totalOffset",
      //   Cell: ({ row }) => (
      //     <div
      //       style={{
      //         textAlign: "right",
      //       }}
      //     >
      //       {`${numberWithCommas(row.values.totalOffset)} $`}
      //     </div>
      //   ),
      // },
      {
        Header: "Comments",
        accessor: "comments",
      },
      {
        Header: "userId",
        accessor: "userid",
        show: false,
      },
    ],
    []
  );

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading && offsets && (
        <CardMain cardReport={false} cardName={cardOptions.cardName}>
          <ReactTable
            columns={columns}
            data={offsets}
            getCellProps={onClickCellHandler}
          />
        </CardMain>
      )}
    </>
  );
}

export default Offsets;
