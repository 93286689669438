import React, { useContext } from "react";
import { Form, Row, Spinner, Col } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CardNewUpdate from "../../components/CardNewUpdate";
import TextField from "../../components/elements/form/TextField";

// Schema for yup
const validationSchema = Yup.object().shape({
  exchangeName: Yup.string().required("*Name is required"),
  link: Yup.string().required("*Link is required"),
});

const NewExchange = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Exchange" };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      <Formik
        initialValues={{ exchangeName: "", link: "", activated: false }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          try {
            await sendRequest(
              process.env.REACT_APP_BACKEND_URL + "/exchange",
              "POST",
              JSON.stringify({
                exchangeName: values.exchangeName,
                link: values.link,
                activated: values.activated,
              }),
              {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
              }
            );
            navigate("../exchanges");
          } catch (err) {}
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="mx-auto w-50">
            <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
              <Row className="mb-3">
                <TextField name="exchangeName" label="Name of Exchainge:" />
                <TextField name="link" label="Link:" />
              </Row>
              {auth.userType === "admin" ? (
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Activated:</Form.Label>
                    <Field as={Form.Select} name="activated">
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </Field>
                  </Form.Group>
                </Row>
              ) : null}
            </CardNewUpdate>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewExchange;
