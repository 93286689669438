import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import SumField from "../../components/elements/form/SumField";
import TextField from "../../components/elements/form/TextField";

// Schema for yup
const validationSchema = Yup.object().shape({
  clientName: Yup.string().required("*Name is required"),
  clientPercent: Yup.number().required("*Percent is required"),
  userId: Yup.string().required("*Id is required"),
});

const UpdateClient = () => {
  const [loadedClient, setLoadedClient] = useState();
  const [wallets, setWallets] = useState();
  const [cexs, setCexs] = useState();
  const [loadedFarming, setLoadedFarming] = useState();
  const [loadedLends, setLoadedLends] = useState();
  const [harvs, setHarvs] = useState();
  const [details, setDetails] = useState();
  const [loadedComps, setLoadedComps] = useState();
  const [offsets, setOffsets] = useState();
  const [balances, setBalances] = useState();
  const [investors, setInvestors] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Client" };

  //getById
  useEffect(() => {
    let unmounted = false;
    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };
    if (!unmounted) {
      fetch(`/client/${Id}`, (data) => setLoadedClient(data.client));
      fetch("/wallet", (data) => setWallets(data.wallets));
      fetch("/cex", (data) => setCexs(data.cexs));
      fetch("/comp", (data) => setLoadedComps(data.compares));
      fetch("/farm", (data) => setLoadedFarming(data.farms));
      fetch("/lend", (data) => setLoadedLends(data.lends));
      fetch("/harv", (data) => setHarvs(data.harvs));
      fetch("/detail", (data) => setDetails(data.details));
      fetch("/offset", (data) => setOffsets(data.offsets));
      fetch("/balance", (data) => setBalances(data.balances));
      fetch("/investor", (data) => setInvestors(data.investors));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, Id, auth.token]);

  let allLoaded =
    !isLoading &&
    loadedClient &&
    wallets &&
    cexs &&
    loadedFarming &&
    loadedLends &&
    loadedComps &&
    harvs &&
    details &&
    balances &&
    investors &&
    offsets;

  //disable delete button if token in use
  let disableDelete;
  if (allLoaded) {
    wallets.forEach((w) => {
      if (w.clientName === loadedClient.clientName) {
        disableDelete = 1;
      }
    });
    cexs.forEach((c) => {
      if (c.tokenName === loadedClient.clientName) {
        disableDelete = 1;
      }
    });
    loadedFarming.forEach((f) => {
      if (f.clientName === loadedClient.clientName) {
        disableDelete = 1;
      }
    });
    loadedLends.forEach((l) => {
      if (l.clientName === loadedClient.clientName) {
        disableDelete = 1;
      }
    });
    loadedComps.forEach((c) => {
      if (c.clientName === loadedClient.clientName) {
        disableDelete = 1;
      }
    });
    harvs.forEach((h) => {
      if (h.clientName === loadedClient.clientName) {
        disableDelete = 1;
      }
    });
    details.forEach((d) => {
      if (d.clientName === loadedClient.clientName) {
        disableDelete = 1;
      }
    });
    offsets.forEach((o) => {
      if (
        o.clientName1 === loadedClient.clientName ||
        o.clientName2 === loadedClient.clientName
      ) {
        disableDelete = 1;
      }
    });
    balances.forEach((b) => {
      if (b.clientName === loadedClient.clientName) {
        disableDelete = 1;
      }
    });
    investors.forEach((i) => {
      if (i.clientName === loadedClient.clientName) {
        disableDelete = 1;
      }
    });
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading ? <Spinner animation="border" /> : null}
      {allLoaded && (
        <Formik
          initialValues={{
            clientName: loadedClient.clientName,
            clientPercent: loadedClient.clientPercent,
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/client/${Id}`,
                "PATCH",
                JSON.stringify({
                  clientName: values.clientName,
                  clientPercent: values.clientPercent,
                  userId: values.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}

            navigate("../clients");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto m-50">
              <CardNewUpdate
                cardNew={false}
                cardName={cardOptions.cardName}
                isbuttondisabled={disableDelete}
              >
                <Row className="mb-3">
                  <TextField name="clientName" label="Client name:" />
                  <SumField name="clientPercent" label="Client %:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UpdateClient;
