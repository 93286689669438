import { useMutation, useQuery } from "react-query";
import axios from "axios";

//Function Get==========>
const getDataId = async (options) => {
  return await axios({
    url: process.env.REACT_APP_BACKEND_URL + options.path,
    method: "get",
    headers: { Authorization: "Bearer " + options.token },
  });
};

//get hook
export const useGetDataId = (onSuccess, onError, onSettled, options) => {
  return useQuery("getDataId", () => getDataId(options), {
    onSuccess,
    onError,
    onSettled,
    cacheTime: 0,
  });
};

//Function Get==========>
const getData = async (options) => {
  return await axios({
    url: process.env.REACT_APP_BACKEND_URL + options.path,
    method: "get",
    headers: { Authorization: "Bearer " + options.token },
  });
};

//get hook
export const useGetData = (onSuccess, onError, onSettled, options) => {
  return useQuery("getData", () => getData(options), {
    onSuccess,
    onError,
    onSettled,
    cacheTime: 0,
  });
};

//Function post, update, delete =======>
const query = async (options) => {
  let headersType = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + options.token,
  };
  return await axios({
    url: process.env.REACT_APP_BACKEND_URL + options.path,
    method: options.method,
    data: options.data ? options.data : null,
    headers: options.data
      ? headersType
      : { Authorization: "Bearer " + options.token },
  });
};
//post, update, delete hook
export const useData = (onSuccess, onError, onSettled) => {
  return useMutation(query, {
    onSuccess,
    onError,
    onSettled,
  });
};
