import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Formik, Form } from "formik";
import { Spinner, Card, Button, Row } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import DateField from "../../components/elements/form/DateField";

function BonusReport() {
  const [bonuses, setBonuses] = useState();
  const [bonusesR, setBonusesR] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  //Card options
  let cardOptions = { cardName: "Bonus report" };

  useEffect(() => {
    let unmounted = false;

    const fetchBonuses = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/bonus",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.bonuses.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        if (!unmounted) setBonuses(responseData.bonuses);
      } catch (err) {}
    };
    fetchBonuses();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/bonuses`);
      },
    };
  };

  //remove element from array
  function removeElement(arrayName, arrayElement) {
    for (var i = 0; i < arrayName.length; i++) {
      if (arrayName[i] === arrayElement) arrayName.splice(i, 1);
    }
  }
  const getReport = (values) => {
    //get Managers

    let incomeManager = [
      ...new Set(
        bonuses.map((item) => {
          if (
            values.dateStart <= item.date.split("T")[0] &&
            values.dateEnd >= item.date.split("T")[0]
          ) {
            return item.managerName;
          } else {
            return 0;
          }
        })
      ),
    ];
    removeElement(incomeManager, 0);

    const setManager = (item, index) => {
      var object = { managerName: item };
      return object;
    };
    let incomesMan = incomeManager.map(setManager);

    incomesMan.forEach((r) => {
      bonuses.forEach((b) => {
        if (b.incomeType === "Farming") {
          r.incomeTotal = _.sumBy(bonuses, (i) => {
            if (
              r.managerName === i.managerName &&
              i.incomeType === "Farming" &&
              values.dateStart <= i.date.split("T")[0] &&
              values.dateEnd >= i.date.split("T")[0]
            ) {
              return i.incomeDay;
            } else {
              return 0;
            }
          });
          r.bonusTotal = _.sumBy(bonuses, (i) => {
            if (
              r.managerName === i.managerName &&
              i.incomeType === "Farming" &&
              values.dateStart <= i.date.split("T")[0] &&
              values.dateEnd >= i.date.split("T")[0]
            ) {
              return i.bonusDay;
            } else {
              return 0;
            }
          });
          r.incomeType = "Farming";
        } else {
          r.incomeTotal = _.sumBy(bonuses, (i) => {
            if (
              r.managerName === i.managerName &&
              i.incomeType === "Lending" &&
              values.dateStart <= i.date.split("T")[0] &&
              values.dateEnd >= i.date.split("T")[0]
            ) {
              return i.incomeDay;
            } else {
              return 0;
            }
          });
          r.bonusTotal = _.sumBy(bonuses, (i) => {
            if (
              r.managerName === i.managerName &&
              i.incomeType === "Lending" &&
              values.dateStart <= i.date.split("T")[0] &&
              values.dateEnd >= i.date.split("T")[0]
            ) {
              return i.bonusDay;
            } else {
              return 0;
            }
          });
          r.incomeType = "Lending";
        }
      });
    });
    // incomesMan.sort((a, b) => (a.apy > b.apy ? 1 : b.apy > a.apy ? -1 : 0));
    setBonusesR(incomesMan);
  };

  function numberWithCommas(number) {
    return Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },
      {
        Header: "Manager",
        accessor: "managerName",
      },
      {
        Header: "IncomeType",
        accessor: "incomeType",
      },
      {
        Header: "IncomeTotal",
        accessor: "incomeTotal",
        disableFilters: true,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {`${numberWithCommas(row.values.incomeTotal)} $`}
          </div>
        ),
      },
      {
        Header: "BonusTotal",
        accessor: "bonusTotal",
        disableFilters: true,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {`${numberWithCommas(row.values.bonusTotal)} $`}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {isLoading && <Spinner animation="border" />}
      {!isLoading && bonuses && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <Formik
                initialValues={{
                  dateStart: new Date().toISOString().split("T")[0],
                  dateEnd: new Date().toISOString().split("T")[0],
                }}
                onSubmit={getReport}
              >
                {({ handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit} className="mx-auto w-50">
                    <div className="fs-4 mb-1">{cardOptions.cardName}</div>
                    <hr />
                    <Row className="mb-3">
                      <DateField name="dateStart" label="From:" />
                      <DateField name="dateEnd" label="To:" />
                    </Row>
                    <Row className="mb-3">
                      <div>
                        <Button
                          className="btn btn-sm btn-success"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </Row>
                  </Form>
                )}
              </Formik>

              <div className="">
                <Button
                  as={Link}
                  to={`../bonuses`}
                  className="btn btn-sm btn-secondary"
                >
                  Back
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={bonusesR}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default BonusReport;
