import React from "react";
import { Form, Button, Card, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import ErrorAlert from "../components/elements/ErrorAlert";
import { useHttpClient } from "../components/hooks/http-hook";

// Schema for yup
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("*Password is reqired.")
    .min(5, "*Please enter valid password, at least 5 characters."),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const NewPassword = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const Id = useParams().id;
  const navigate = useNavigate();

  return (
    <>
      {error && <ErrorAlert error={error} onClear={clearError} />}
      {isLoading && <Spinner animation="border" />}

      <Formik
        initialValues={{
          password: "",
          passwordConfirmation: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled

          try {
            await sendRequest(
              process.env.REACT_APP_BACKEND_URL + `/user/reset/${Id}`,
              "POST",
              JSON.stringify({
                password: values.password,
              }),
              {
                "Content-Type": "application/json",
              }
            );
            navigate("../login");
          } catch (err) {
            // setError(err.message || "Something went wrong, please try again.");
          }
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="mx-auto w-25 "
            style={{ minWidth: "450px" }}
          >
            <Card className="text-center card-center shadow">
              <Card.Header>RESET PASSWORD FORM</Card.Header>
              <Card.Body>
                <Form.Group controlId="formPassword">
                  <Form.Label>Password :</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    className={
                      touched.password && errors.password ? "error" : null
                    }
                  />
                  {touched.password && errors.password ? (
                    <div className="text-danger">{errors.password}</div>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="formPasswordConfirmation">
                  <Form.Label>Confirm password :</Form.Label>
                  <Form.Control
                    type="password"
                    name="passwordConfirmation"
                    placeholder="repeite password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirmation}
                    className={
                      touched.passwordConfirmation &&
                      errors.passwordConfirmation
                        ? "error"
                        : null
                    }
                  />
                  {touched.passwordConfirmation &&
                  errors.passwordConfirmation ? (
                    <div className="text-danger">
                      {errors.passwordConfirmation}
                    </div>
                  ) : null}
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  SEND
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewPassword;
