import React, { useEffect, useState, useContext } from "react";

import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import _ from "lodash";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CardMain from "../../components/CardMain";
import {
  ColorCell,
  NumberWithCommas,
  LinkCell,
  ColorIl,
  ColorHedgPair1,
  ColorHedgPair2,
} from "../../components/table/FormatedCell";
import { ReactTable } from "../../components/TableSort";
import { getPrice, customFetch, showColumn } from "../../components/Functions";

function Farming() {
  const [compares, setCompares] = useState();
  const [loadedFarming, setLoadedFarming] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const [loadedProjects, setLoadedProjects] = useState();
  const [chains, setChains] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Farm" };

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      customFetch(sendRequest, auth, "/comp", (data) =>
        setCompares(data.compares)
      );
      customFetch(sendRequest, auth, "/setting", (data) =>
        setloadedSettings(data.settings)
      );
      customFetch(sendRequest, auth, "/project", (data) =>
        setLoadedProjects(data.projects)
      );
      customFetch(sendRequest, auth, "/chain", (data) =>
        setChains(data.chains)
      );
      customFetch(sendRequest, auth, "/pool", (data) =>
        setLoadedPools(data.pools)
      );
      customFetch(sendRequest, auth, "/farm", (data) =>
        setLoadedFarming(data.farms)
      );
      customFetch(sendRequest, auth, "/token", (data) =>
        setLoadedTokens(data.tokens)
      );
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth]);

  let allLoaded =
    !isLoading &&
    loadedProjects &&
    loadedPools &&
    loadedSettings &&
    loadedFarming &&
    loadedTokens &&
    compares &&
    chains;

  //Click
  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        switch (cell.column.id) {
          case "providerName":
            window.open(`${cell.row.original.linkProvider}`);
            break;

          case "poolName":
            window.open(`${cell.row.original.linkPool}`);
            break;
          case "chainName":
            window.open(`${cell.row.original.chainLink}`);
            break;

          default:
            navigate(`/farm/${cell.row.values.id}`);
            break;
        }
      },
    };
  };

  //Calculations
  let yellowGreen, redYellow, colorIl, compArray;
  if (allLoaded) {
    loadedFarming.forEach((e) => {
      loadedPools.forEach((p) => {
        if (p.poolName === e.providerName) {
          e.linkProvider = p.poolLink;
        }
        if (p.poolName === e.poolName) {
          e.linkPool = p.poolLink;
        }
      });
      chains.forEach((c) => {
        if (c.chainName === e.chainName) {
          e.chainLink = c.explorer;
        }
      });

      e.pairs = e.pair1 + "/" + e.pair2;
      e.pair1Price = getPrice(loadedTokens, e.pair1);
      e.pair2Price = getPrice(loadedTokens, e.pair2);

      //IL
      let ilSum = e.newPair1Qty * e.pair1Price + e.newPair2Qty * e.pair2Price;

      if (e.v3) {
        let sqrtPriceStart = Math.sqrt(e.pair1PriceV3 / e.pair2PriceV3);
        let sqrtPrice = Math.sqrt(e.pair1Price / e.pair2Price);
        let sqrtPriceTop = Math.sqrt(e.priceTop);
        let sqrtPriceBottom = Math.sqrt(e.priceBottom);
        let lx =
          e.newPair1Qty *
          ((sqrtPriceStart * sqrtPriceTop) / (sqrtPriceTop - sqrtPriceStart));
        let ly = e.newPair2Qty / (sqrtPriceStart - sqrtPriceBottom);
        // let lMin = Math.min(lx, ly);
        e.pair1Qty =
          lx * ((sqrtPriceTop - sqrtPrice) / (sqrtPrice * sqrtPriceTop));

        e.pair2Qty = ly * (sqrtPrice - sqrtPriceBottom);
      } else {
        e.newPair1Qty = ilSum / 2 / e.pair1Price;
        e.newPair2Qty = ilSum / 2 / e.pair2Price;
        let ilK = e.newPair1Qty * e.newPair2Qty;
        let ilR = e.pair2Price / e.pair1Price;
        e.pair1Qty = Math.sqrt(ilK * ilR);
        e.pair2Qty = Math.sqrt(ilK / ilR);
      }
      let pair1Sum = e.pair1Qty * e.pair1Price;
      let pair2Sum = e.pair2Qty * e.pair2Price;
      e.posSum = pair1Sum + pair2Sum;
      e.il = e.posSum - ilSum;
      e.apy = _.sumBy(loadedProjects, (p) => {
        if (e.projectNumber === p.projectNumber) {
          return p.apy;
        } else {
          return 0;
        }
      });
      e.incomeMonth = (e.posSum * e.apy) / 1200;
    });
    //Sorting
    loadedFarming.sort((a, b) => (a.apy > b.apy ? 1 : b.apy > a.apy ? -1 : 0));

    //Colors from Settings
    yellowGreen = loadedSettings.find((d) => d.name === "colorGreenApi");
    redYellow = loadedSettings.find((d) => d.name === "colorRedApi");
    colorIl = loadedSettings.find((d) => d.name === "botIl");

    compArray = compares.map((e) => (e = e.tokenName));
  }

  //Window size cut colomn
  let show = showColumn(window.innerWidth, 1300);

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      show: false,
    },
    {
      Header: "PosId",
      accessor: "projectNumber",
      show,
    },
    {
      Header: "Date",
      accessor: "date",
      show,
    },

    {
      Header: "Provider",
      accessor: "providerName",
      Cell: LinkCell,
    },
    {
      Header: "Pool",
      accessor: "poolName",
      show,
      Cell: LinkCell,
    },
    {
      Header: "Pairs",
      accessor: "pairs",
    },
    {
      Header: "Pair1",
      accessor: "pair1Qty",
      show,
      Cell: (cell) => ColorHedgPair1(cell, compArray),
    },
    {
      Header: "Pair2",
      accessor: "pair2Qty",
      show,
      Cell: (cell) => ColorHedgPair2(cell, compArray),
    },
    {
      Header: "PosSum",
      accessor: "posSum",
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.posSum, 0)} $`}</div>
      ),
    },
    {
      Header: "IL",
      accessor: "il",
      Cell: (cell) => ColorIl(cell, colorIl.value),
    },
    {
      Header: "APY",
      accessor: "apy",
      Cell: (cell) => ColorCell(cell, yellowGreen.value, redYellow.value),
    },

    {
      Header: "Chain",
      accessor: "chainName",
      show,
      Cell: LinkCell,
    },
    {
      Header: "Manager",
      accessor: "managerName",
      show,
    },

    {
      Header: "Client",
      accessor: "clientName",
      show,
    },
    {
      Header: "userId",
      accessor: "userId",
      show: false,
    },
  ];
  //Hide report button for Base plan
  let cardReport = auth.planName === "Base" ? false : true;

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <CardMain cardReport={cardReport} cardName={cardOptions.cardName}>
          <ReactTable
            columns={columns}
            data={loadedFarming}
            getCellProps={onClickCellHandler}
          />
        </CardMain>
      )}
    </>
  );
}

export default Farming;
