import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Spinner, Col } from "react-bootstrap";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import ClientField from "../../components/elements/form/ClientField";
import CommentField from "../../components/elements/form/CommentField";
import CardNewUpdate from "../../components/CardNewUpdate";
import ErrorAlert from "../../components/elements/ErrorAlert";

// Schema for yup
const validationSchema = Yup.object().shape({
  chainName: Yup.string().required("*Chain is required"),
  walletAddress: Yup.string().required("*Wallet is required"),
  clientName: Yup.string().required("*Client is required"),
});

export default function IWalletUpdate() {
  const [isSubmit, setSubmit] = useState(false);
  const [wallet, setWallet] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Wallet-import" };

  useEffect(() => {
    let unmounted = false;

    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };
    if (!unmounted) {
      fetch(`/wallet-import/${Id}`, (data) => setWallet(data.wallet));
    }

    return () => {
      unmounted = true;
    };
  }, [auth.token, sendRequest, Id]);

  let allloaded = !isLoading && !isSubmit && wallet;

  //submit wallet
  const submitWallet = async (values) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/wallet-import/${Id}`,
        "PATCH",
        JSON.stringify({
          chainName: values.chainName,
          walletAddress: values.walletAddress,
          comments: values.comments,
          clientName: values.clientName,
          userId: auth.userId,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (error) {
      console.log(error);
    }
    navigate(`../wallet-imports`);
  };

  //Formik submit
  const onSubmit = async (values) => {
    setSubmit(true);
    await submitWallet(values);
    setSubmit(false);
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allloaded && (
        <Formik
          initialValues={{
            chainName: wallet.chainName,
            walletAddress: wallet.walletAddress,
            comments: wallet.comments,
            clientName: wallet.clientName,
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={false} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <CommentField
                    name="walletAddress"
                    label="Wallet's Address:"
                  />
                  <Form.Group as={Col}>
                    <Form.Label>Chain:</Form.Label>
                    <Field as={Form.Select} name="chainName">
                      <option value="Arbitrum">Arbitrum</option>
                      <option value="Avalanche">Avalanche</option>
                      <option value="BSC">BSC</option>
                      <option value="Ethereum">Ethereum</option>
                      <option value="Fantom">Fantom</option>
                      <option value="Optimism">Optimism</option>
                      <option value="Polygon">Polygon</option>
                      <option value="Solana">Solana</option>
                    </Field>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <CommentField name="comments" label="Comments:" />
                  <ClientField name="clientName" label="Client:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
