import React from "react";

import "../pages/main.css";
import HeroeImage from "../img/Front1.png";
import { Link } from "react-router-dom";

const Main = () => {
  return (
    <div className="container col-xxl-8 px-4 py-5">
      <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div className="col-10 col-sm-8 col-lg-6">
          <img
            className="rounded-lg-3"
            src={HeroeImage}
            alt=""
            width="700"
            height="400"
          ></img>
        </div>
        <div className="col-lg-6">
          <h1 className="display-5  lh-1 mb-3">
            Track and maintain your crypto positions with confidence
          </h1>
          <p className="lead">
            All your wallets in one place, import with one click
          </p>
          <p className="lead">
            Control exchange positions to prevent liquidations
          </p>
          <p className="lead">Store your findings in farming as a projects</p>
          <p className="lead">Watch Impermanent Loss of your LP positions</p>
          <p className="lead">
            Get warnings about Lending and Borrow position at risk
          </p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-start">
            <Link to="../features">
              <button
                type="button"
                className="btn btn-primary btn-lg px-4 me-md-2 fw-bold"
              >
                Details
              </button>
            </Link>
            <Link to="../signup">
              <button
                type="button"
                className="btn btn-outline-secondary btn-lg px-4"
              >
                Signup
              </button>
            </Link>
          </div>
        </div>
      </div>
      <footer className="pt-4 my-md-5 pt-md-5 border-top">
        <div className="row">
          <div className="col-12 col-md">
            {/* logo */}
            <small className="d-block mb-3 text-muted">&copy; 2020–2022</small>
          </div>
          {/* <div className="col-6 col-md">
            <h5>Features</h5>
            <ul className="list-unstyled text-small">
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Cool stuff
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Random feature
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Team feature
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Stuff for developers
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Another one
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Last time
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Resources</h5>
            <ul className="list-unstyled text-small">
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Resource
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Resource name
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Another resource
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Final resource
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>About</h5>
            <ul className="list-unstyled text-small">
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Team
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Locations
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Privacy
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Terms
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </footer>
    </div>
  );
};

export default Main;
