import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, Card } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSort";
import {
  NumberWithCommas,
  ColorCell,
} from "../../components/table/FormatedCell";

function Harvesting() {
  const [harvs, setHarvs] = useState();
  const [details, setDetails] = useState();
  const [borrows, setBorrows] = useState();
  const [loadedFarming, setLoadedFarming] = useState();
  const [loadedLends, setLoadedLends] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const [disableButtonFarming, setDisableButtonFarming] = useState(false);
  const [disableButtonLending, setDisableButtonLending] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Harvesting" };

  useEffect(() => {
    let unmounted = false;

    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };

    if (!unmounted) {
      fetch("/setting", (data) => setloadedSettings(data.settings));
      // fetch("/harv", (data) => setHarvs(data.harvs));
      fetch("/lend", (data) => setLoadedLends(data.lends));
      fetch("/farm", (data) => setLoadedFarming(data.farms));
      fetch("/token", (data) => setLoadedTokens(data.tokens));
      fetch("/borrow", (data) => setBorrows(data.borrows));
      fetch("/pool", (data) => setLoadedPools(data.pools));
      fetch("/detail", (data) => setDetails(data.details));
    }

    const fetchHarvs = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/harv",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        // responseData.harvs.sort((a, b) =>
        //   a.harvDate < b.harvDate ? 1 : b.harvDate < a.harvDate ? -1 : 0
        // );

        if (!unmounted) setHarvs(responseData.harvs);
        if (!unmounted) {
          setDisableButtonFarming(
            responseData.harvs.some(
              (e) =>
                new Date(e.harvDate).toISOString().split("T")[0] ===
                  new Date().toISOString().split("T")[0] &&
                e.incomeType === "Farming"
            )
          );
        }
        if (!unmounted) {
          setDisableButtonLending(
            responseData.harvs.some(
              (e) =>
                new Date(e.harvDate).toLocaleDateString("en-GB") ===
                  new Date().toLocaleDateString("en-GB") &&
                e.incomeType === "Lending"
            )
          );
        }
      } catch (err) {}
    };
    fetchHarvs();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const getPrice = (tokenSymbol) => {
    let tokenObj;
    if (!isLoading && loadedTokens) {
      tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    }
    return tokenObj.tokenPrice;
  };

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        switch (cell.row.values.incomeType) {
          case "Farming":
            if (
              new Date(cell.row.values.harvDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB")
            ) {
              navigate(`/harvesting/farming/${cell.row.values.id}`);
            } else {
              alert("Editing of previous days are not allowed!");
            }

            break;
          case "Lending":
            if (
              new Date(cell.row.values.harvDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB")
            ) {
              navigate(`/harvesting/lending/${cell.row.values.id}`);
            } else {
              alert("Editing of previous days are not allowed!");
            }

            break;

          default:
            break;
        }
        // navigate(`/harvesting/${cell.row.values.id}`);
      },
    };
  };

  //Calculations details

  let yellowGreen, redYellow;
  if (!isLoading && loadedFarming && loadedTokens && loadedSettings && harvs) {
    loadedFarming.forEach((e) => {
      e.pairs = e.pair1 + "/" + e.pair2;
      e.pair1Price = getPrice(e.pair1);
      e.pair2Price = getPrice(e.pair2);
      let pair1Sum = e.pair1Qty * e.pair1Price;
      let pair2Sum = e.pair2Qty * e.pair2Price;
      e.posSum = pair1Sum + pair2Sum;
    });

    //Colors from Settings
    yellowGreen = loadedSettings.find((d) => d.name === "colorGreenApi");
    redYellow = loadedSettings.find((d) => d.name === "colorRedApi");
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      show: false,
    },

    {
      Header: "Date",
      accessor: "harvDate",
      Cell: ({ row }) =>
        new Date(row.values.harvDate).toLocaleDateString("En-GB"),
    },
    {
      Header: "Positions",
      accessor: "positions",
      Cell: ({ row }) => {
        return (
          <div className="addlinebreak">
            {row.values.positions.split("|").join("\n")}
          </div>
        );
      },
    },
    {
      Header: "Income Type",
      accessor: "incomeType",
    },
    {
      Header: "Income Total",
      accessor: "incomeTotal",
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.incomeTotal)} $`}</div>
      ),
    },
    {
      Header: "Income Day",
      accessor: "incomeDay",
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.incomeDay)} $`}</div>
      ),
    },
    {
      Header: "Apy Day",
      accessor: "apy",
      Cell: (cell) => ColorCell(cell, yellowGreen.value, redYellow.value),
    },
    {
      Header: "Client",
      accessor: "clientName",
    },
    {
      Header: "userId",
      accessor: "userid",
      show: false,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading &&
        loadedFarming &&
        loadedTokens &&
        loadedPools &&
        loadedLends &&
        borrows &&
        harvs &&
        details &&
        loadedSettings && (
          <Card className="text-center card-center w-100">
            <Card.Header>
              <div className="d-flex flex-row justify-content-between">
                <div className="">
                  <Link to={`../harvesting/farming/new`}>
                    <button
                      disabled={disableButtonFarming}
                      className="btn btn-sm btn-secondary me-1"
                    >
                      New in <br />
                      Farming
                    </button>
                  </Link>
                  <Link to={`../harvesting/lending/new`}>
                    <button
                      disabled={disableButtonLending}
                      className="btn btn-sm btn-secondary"
                    >
                      New in <br />
                      Lending
                    </button>
                  </Link>
                </div>
                <div className="fs-4 me-5">{cardOptions.cardName}</div>
                <div className="p-1"></div>
              </div>
            </Card.Header>
            <Card.Body>
              <ReactTable
                columns={columns}
                data={harvs}
                getCellProps={onClickCellHandler}
              />
            </Card.Body>
          </Card>
        )}
    </>
  );
}

export default Harvesting;
