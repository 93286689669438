import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Formik, Form } from "formik";
import { Spinner, Card, Button, Row } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import DateField from "../../components/elements/form/DateField";
import ClientField from "../../components/elements/form/ClientField";
import { NumberWithCommas } from "../../components/table/FormatedCell";

function ClientReport() {
  const [balances, setBalances] = useState();
  const [loadedClients, setLoadedClients] = useState();
  const [report, setReport] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  //Card options
  let cardOptions = { cardName: "Client report" };

  useEffect(() => {
    let unmounted = false;
    const fetchBalances = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/balance",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setBalances(responseData.balances);
      } catch (err) {}
    };
    fetchBalances();

    const fetchClients = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/client",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedClients(responseData.clients);
      } catch (err) {}
    };
    fetchClients();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/clients`);
      },
    };
  };

  //Formik submit
  const getReport = (values) => {
    let reportClient = [];

    let reportByClient = { clientName: values.client };
    reportClient.push(reportByClient);

    reportClient.forEach((r) => {
      r.balance = _.sumBy(balances, (i) => {
        if (
          r.clientName === i.clientName &&
          i.date.split("T")[0] === values.date
        ) {
          return i.totalBalance;
        } else {
          return 0;
        }
      });
      r.clientPercent = _.sumBy(loadedClients, (i) => {
        if (r.clientName === i.clientName) {
          return i.clientPercent;
        } else {
          return 0;
        }
      });

      r.incomeMonth = (r.balance * r.clientPercent) / 1200;
      // r.clientPart = (r.incomeMonth * r.clientPercent) / 100;
      r.clientApi = (r.incomeMonth * 1200) / r.balance;
    });
    setReport(reportClient);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Client",
        accessor: "clientName",
      },
      {
        Header: "Current Balance",
        accessor: "balance",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.balance)} $`}</div>
        ),
      },
      {
        Header: "Income Month",
        accessor: "incomeMonth",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.incomeMonth)} $`}</div>
        ),
      },
      // {
      //   Header: "Client Part",
      //   accessor: "clientPart",
      //   disableFilters: true,
      //   Cell: ({ row }) => (
      //     <div>{`${NumberWithCommas(row.values.clientPart)} $`}</div>
      //   ),
      // },
      {
        Header: "Client APY %",
        accessor: "clientApi",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.clientApi)} %`}</div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {isLoading && <Spinner animation="border" />}
      {!isLoading && loadedClients && balances && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <Formik
                initialValues={{
                  date: new Date().toISOString().split("T")[0],
                  client: "",
                }}
                onSubmit={getReport}
              >
                {({ handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit} className="mx-auto w-50">
                    <div className="fs-4 mb-1">{cardOptions.cardName}</div>
                    <hr />
                    <Row className="mb-3">
                      <DateField name="date" label="on Date:" />
                      <ClientField name="client" label="Client:" />
                    </Row>
                    <Row className="mb-3">
                      <div>
                        <Button
                          className="btn btn-sm btn-success"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </Row>
                  </Form>
                )}
              </Formik>

              <div className="">
                <Button
                  as={Link}
                  to={`../clients`}
                  className="btn btn-sm btn-secondary"
                >
                  Back
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={report}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default ClientReport;
