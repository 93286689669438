import React, { useContext, useState, useEffect } from "react";
import { Form, Spinner, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import DateField from "../../components/elements/form/DateField";
import PoolField from "../../components/elements/form/PoolField";
import ManagerField from "../../components/elements/form/ManagerField";
import SumField from "../../components/elements/form/SumField";

// Schema for yup
const validationSchema = Yup.object().shape({
  providerName: Yup.string().required("*Provider is required"),
  incomeDay: Yup.number().required("*Income is required"),
  incomePercent: Yup.number().required("*Percent required"),
  managerName: Yup.string().required("*Manager is required"),
});

const UpdateIncome = () => {
  const [income, setIncome] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const [loadedManagers, setLoadedManagers] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Income" };

  useEffect(() => {
    let unmounted = false;

    const fetchIncomeById = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/income/${Id}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setIncome(responseData.income);
      } catch (err) {}
    };
    fetchIncomeById();

    const fetchPools = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pool",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.pools.sort((a, b) =>
          a.poolName > b.poolName ? 1 : b.poolName > a.poolName ? -1 : 0
        );
        if (!unmounted) setLoadedPools(responseData.pools);
      } catch (err) {}
    };
    fetchPools();
    const fetchManagers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/manager",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.managers.sort((a, b) =>
          a.managerName > b.managerName
            ? 1
            : b.managerName > a.managerName
            ? -1
            : 0
        );

        if (!unmounted) setLoadedManagers(responseData.managers);
      } catch (err) {}
    };
    fetchManagers();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token, Id]);

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}
      {!isLoading && loadedPools && loadedManagers && income && (
        <Formik
          initialValues={{
            date: new Date(income.date).toISOString().split("T")[0],
            providerName: income.providerName,
            incomeDay: income.incomeDay,
            incomePercent: income.incomePercent,
            managerName: income.managerName,
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            //   console.log(values.providerName);
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/income/${Id}`,
                "PATCH",
                JSON.stringify({
                  date: new Date(values.date),
                  providerName: values.providerName,
                  incomeType: income.incomeType,
                  incomeDay: values.incomeDay,
                  incomePercent: values.incomePercent,
                  managerName: values.managerName,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
            navigate("../incomes");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={false} cardName={cardOptions.cardName}>
                <Row className="mb-2">
                  <DateField name="date" label="Date:"></DateField>
                  <PoolField
                    name="providerName"
                    label="Provider Name:"
                  ></PoolField>
                </Row>
                <Row className="mb-2">
                  <SumField name="incomeDay" label="Income Day:"></SumField>
                  <SumField name="incomePercent" label="Income %:"></SumField>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label>Income Type:</Form.Label>
                    <Form.Select
                      type="text"
                      name="incomeType"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.incomeType}
                    >
                      <option key={1} value={"Farming"}>
                        Farming
                      </option>
                      <option key={2} value={"Lending"}>
                        Lending
                      </option>
                    </Form.Select>
                    {touched.incomeType && errors.incomeType ? (
                      <div className="error-message">{errors.incomeType}</div>
                    ) : null}
                  </Form.Group>
                  <ManagerField name="managerName" label="Manager:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UpdateIncome;
