import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Spinner, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";

import CardNewUpdate from "../../components/CardNewUpdate";
import ClientField from "../../components/elements/form/ClientField";
import DateField from "../../components/elements/form/DateField";
import TokenField from "../../components/elements/form/TokenField";
import SumField from "../../components/elements/form/SumField";

// Schema for yup
const validationSchema = Yup.object().shape({
  projectNumber: Yup.number().required("*Project Number is required"),
  clientName: Yup.string().required("*Client is required"),
  pair1Qty: Yup.number().required("*Pair1Qty is required"),
  pair2Qty: Yup.number().required("*Pair2Qty is required"),
});

const NewFarming = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedLP, setIsCheckedLP] = useState(false);
  const [isCheckedV3, setIsCheckedV3] = useState(false);
  const [loadedProjects, setLoadedProjects] = useState();
  const [loadedProject, setLoadedProject] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [pools, setPools] = useState();
  const [token, setToken] = useState();
  const [projectN, setProjectN] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Farm" };

  useEffect(() => {
    let unmounted = false;

    const fetchProjects = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/project",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedProjects(responseData.projects);
      } catch (err) {}
    };
    fetchProjects();

    const fetchSettings = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/setting",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setloadedSettings(responseData.settings);
      } catch (err) {}
    };
    fetchSettings();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    const fetchPools = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pool",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.pools.sort((a, b) =>
          a.poolName > b.poolName ? 1 : b.poolName > a.poolName ? -1 : 0
        );
        if (!unmounted) setPools(responseData.pools);
      } catch (err) {}
    };
    fetchPools();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  //Functions
  const getProjectById = (proN) => {
    loadedProjects.forEach((e) => {
      if (e.projectNumber === proN) {
        setLoadedProject(e);
      }
    });
  };
  const getPrice = (tokenSymbol) => {
    let tokenObj;
    if (!isLoading && loadedTokens) {
      tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    }
    return tokenObj.tokenPrice;
  };
  //Pool token
  const getTokenName = (projectNumber) => {
    loadedProjects.forEach((e) => {
      if (e.projectNumber === projectNumber) {
        pools.forEach((p) => {
          if (e.providerName === p.poolName) {
            setToken(p.poolCoin);
          }
        });
      }
    });
  };

  //Calculations
  let defaultClient;
  if (!isLoading && loadedProjects && loadedSettings) {
    //default manager
    loadedSettings.forEach((s) => {
      if (s.description === "Default client") {
        defaultClient = s.value;
      }
    });
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}
      {!isLoading && loadedProjects && (
        <Formik
          initialValues={{
            date: new Date().toISOString().split("T")[0],
            projectNumber: "",
            providerName: "",
            poolName: "",
            pair1: "",
            pair2: "",
            pair1PriceV3: "",
            pair2PriceV3: "",
            apy: "",
            chainName: "",
            managerName: "",
            clientName: defaultClient ? defaultClient : "",
            pair1Qty: "",
            pair2Qty: "",
            v3: "",
            priceBottom: "",
            priceTop: "",
            changeToken: false,
            tokenName: "",
            lpToken: false,
            lpQty: 0,
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/farm`,
                "POST",
                JSON.stringify({
                  date: values.date,
                  projectNumber: loadedProject.projectNumber,
                  providerName: loadedProject.providerName,
                  poolName: loadedProject.poolName,
                  pair1: loadedProject.pair1,
                  pair2: loadedProject.pair2,
                  pair1PriceV3: getPrice(loadedProject.pair1),
                  pair2PriceV3: getPrice(loadedProject.pair2),
                  apy: loadedProject.apy,
                  chainName: loadedProject.chainName,
                  managerName: loadedProject.managerName,
                  clientName: values.clientName,
                  pair1Qty: values.pair1Qty,
                  pair2Qty: values.pair2Qty,
                  newPair1Qty: values.pair1Qty,
                  newPair2Qty: values.pair2Qty,
                  v3: values.v3 ? values.v3 : false,
                  priceBottom: values.priceBottom ? values.priceBottom : 0,
                  priceTop: values.priceTop ? values.priceTop : 0,
                  changeToken: values.changeToken,
                  tokenName: values.tokenName ? values.tokenName : token,
                  lpToken: values.lpToken,
                  lpQty: values.lpQty,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.log(err);
            }

            navigate("../farms");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-2">
                  <DateField name="date" label="Date:" disabled={false} />
                  <Form.Group as={Col} controlId="formProjectNumber">
                    <Form.Label>Project Number: </Form.Label>
                    <Form.Control
                      type="number"
                      name="projectNumber"
                      placeholder="...project number"
                      value={values.projectNumber}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        setProjectN(values.projectNumber);
                        getTokenName(values.projectNumber);
                        getProjectById(values.projectNumber);
                        handleBlur(e);
                      }}
                      className={
                        touched.projectNumber && errors.projectNumber
                          ? "error"
                          : null
                      }
                    />
                    {touched.projectNumber && errors.projectNumber ? (
                      <div className="error-message">
                        {errors.projectNumber}
                      </div>
                    ) : null}
                  </Form.Group>
                </Row>

                {projectN && (
                  <div>
                    <Row className="mb-2">
                      <Form.Group as={Col} controlId="formProviderName">
                        <Form.Label>Provider Name:</Form.Label>
                        <Form.Select
                          type="text"
                          name="providerName"
                          placeholder=""
                          disabled
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            loadedProject ? loadedProject.providerName : ""
                          }
                        >
                          <option>
                            {loadedProject ? loadedProject.providerName : ""}
                          </option>
                        </Form.Select>
                        {touched.providerName && errors.providerName ? (
                          <div className="error-message">
                            {errors.providerName}
                          </div>
                        ) : null}
                      </Form.Group>

                      <Form.Group as={Col} controlId="formPoolName">
                        <Form.Label>Pool Name:</Form.Label>
                        <Form.Select
                          disabled
                          type="text"
                          name="poolName"
                          placeholder=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.poolName}
                        >
                          <option>
                            {loadedProject ? loadedProject.poolName : ""}
                          </option>
                        </Form.Select>
                        {touched.poolName && errors.poolName ? (
                          <div className="error-message">{errors.poolName}</div>
                        ) : null}
                      </Form.Group>
                    </Row>

                    <Row className="mb-2">
                      <Form.Group as={Col} controlId="formApy">
                        <Form.Label>APY: </Form.Label>
                        <Form.Control
                          disabled
                          type="number"
                          name="apy"
                          placeholder="add APY"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={loadedProject ? loadedProject.apy : ""}
                          className={touched.apy && errors.apy ? "error" : null}
                        />
                        {touched.apy && errors.apy ? (
                          <div className="error-message">{errors.apy}</div>
                        ) : null}
                      </Form.Group>

                      <Form.Group as={Col} controlId="formChainName">
                        <Form.Label>Chain:</Form.Label>
                        <Form.Select
                          disabled
                          type="text"
                          name="chainName"
                          placeholder=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.chainName}
                        >
                          <option>
                            {loadedProject ? loadedProject.chainName : ""}
                          </option>
                        </Form.Select>
                        {touched.chainName && errors.chainName ? (
                          <div className="error-message">
                            {errors.chainName}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Row>
                    <Row className="mb-2">
                      <Form.Group as={Col} controlId="formManagerName">
                        <Form.Label>Manager:</Form.Label>
                        <Form.Select
                          disabled
                          type="text"
                          name="managerName"
                          placeholder=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.managerName}
                        >
                          <option>
                            {loadedProject ? loadedProject.managerName : ""}
                          </option>
                        </Form.Select>
                        {touched.managerName && errors.managerName ? (
                          <div className="error-message">
                            {errors.managerName}
                          </div>
                        ) : null}
                      </Form.Group>

                      <ClientField name="clientName" label="Client:" />
                    </Row>
                    <Row className="mb-2">
                      <Form.Group as={Col} controlId="formPair1">
                        <Form.Label>Pair 1: </Form.Label>
                        <Form.Select
                          disabled
                          type="text"
                          name="pair1"
                          placeholder=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.pair1}
                        >
                          <option>
                            {loadedProject ? loadedProject.pair1 : ""}
                          </option>
                        </Form.Select>
                        {touched.pair1 && errors.pair1 ? (
                          <div className="error-message">{errors.pair1}</div>
                        ) : null}
                      </Form.Group>

                      <Form.Group as={Col} controlId="formPair2">
                        <Form.Label>Pair 2: </Form.Label>
                        <Form.Select
                          disabled
                          type="text"
                          name="pair2"
                          placeholder=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.pair2}
                        >
                          <option>
                            {loadedProject ? loadedProject.pair2 : ""}
                          </option>
                        </Form.Select>
                        {touched.pair2 && errors.pair2 ? (
                          <div className="error-message">{errors.pair2}</div>
                        ) : null}
                      </Form.Group>
                    </Row>
                    <Row className="mb-2">
                      <SumField name="pair1Qty" label="Pair 1 q-ty:" />
                      <SumField name="pair2Qty" label="Pair 2 q-ty:" />
                      <Form.Group as={Col} controlId="formCheckBoxV3">
                        <Form.Label>V3:</Form.Label>
                        <Form.Check
                          type="checkbox"
                          name="v3"
                          onChange={(e) => {
                            handleChange(e);
                            setIsCheckedV3(!isCheckedV3);
                          }}
                          onBlur={handleBlur}
                          value={values.v3}
                        />
                        {<div className="message">For v3 pools.</div>}
                      </Form.Group>
                    </Row>
                    {isCheckedV3 && (
                      <Row className="mb-2">
                        <SumField name="priceBottom" label="Bottom price:" />
                        <SumField name="priceTop" label="Top price:" />
                      </Row>
                    )}
                    <Row className="mb-2">
                      <Form.Group as={Col} controlId="formCheckBox">
                        <Form.Label>Change Token:</Form.Label>
                        <Form.Check
                          type="checkbox"
                          name="changeToken"
                          onChange={(e) => {
                            handleChange(e);
                            setIsChecked(!isChecked);
                          }}
                          onBlur={handleBlur}
                          value={values.changeToken}
                        />
                        {
                          <div className="message">
                            If checked, can be change of reward token.
                          </div>
                        }
                      </Form.Group>
                      {isChecked && (
                        <TokenField name="tokenName" label="Token:" />
                      )}
                    </Row>
                    <Row className="mb-2">
                      <Form.Group as={Col} controlId="formCheckBoxLP">
                        <Form.Label>Add LP Token:</Form.Label>
                        <Form.Check
                          type="checkbox"
                          name="lpToken"
                          onChange={(e) => {
                            handleChange(e);
                            setIsCheckedLP(!isCheckedLP);
                          }}
                          onBlur={handleBlur}
                          value={values.lpToken}
                        />
                        {
                          <div className="message">
                            If checked, can provide LP token.
                          </div>
                        }
                      </Form.Group>
                      {isCheckedLP && (
                        <SumField name="lpQty" label="LP q-ty:" />
                      )}
                    </Row>
                  </div>
                )}
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewFarming;
