import React from "react";
import "../pages/Pricing.css";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const PricingInside = () => {
  return (
    <div className="container py-3 scroll">
      <header>
        <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
          <a
            href="/"
            className="d-flex align-items-center text-dark text-decoration-none"
          >
            {/* icon */}
            <span className="fs-4">Pricing of cryptoBase</span>
          </a>

          <Nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
            <Nav.Link
              className="me-3 py-2 text-dark text-decoration-none"
              href="https://docs.cryptobase.bimdao.io/"
              target="_blank"
            >
              Support
            </Nav.Link>
          </Nav>
        </div>

        <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
          <h1 className="display-4 fw-normal">Pricing</h1>
          <p className="fs-5 text-muted">
            All payments in Crypto. <br /> 7 days free trial with Enterprise.
          </p>
        </div>
      </header>

      <main>
        <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Base</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  $9<small className="text-muted fw-light">/mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>$99/annually </li>
                  <br />
                  <li>Wallets</li>
                  <li>Exchanges</li>
                  <li>Base DeFi block </li>
                  <li>Email support</li>
                </ul>
                <Link to="/subscriptions/new">
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-outline-primary"
                  >
                    Get Base
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Pro</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  $29<small className="text-muted fw-light">/mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>$299/annually </li>
                  <br />
                  <li>Base features</li>
                  <li>Pro DeFi block</li>
                  <li>Default Bot warnings</li>
                  <li>Priority email support</li>
                </ul>
                <Link to="/subscriptions/new">
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-primary"
                  >
                    Get Pro
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm border-primary">
              <div className="card-header py-3 text-white bg-primary border-primary">
                <h4 className="my-0 fw-normal">Enterprise</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  $99<small className="text-muted fw-light">/mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>$999/annually </li>
                  <br />
                  <li>All features</li>
                  <li>Full DeFi block</li>
                  <li>Adjusted Bot warnings</li>
                  <li>Chat and email support</li>
                </ul>
                <Link to="/subscriptions/new">
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-primary"
                  >
                    Get Enterprise
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <h2 className="display-6 text-center mb-4">Compare plans</h2>

        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th style={{ width: "34%" }}></th>
                <th style={{ width: "22%" }}>Base</th>
                <th style={{ width: "22%" }}>Pro</th>
                <th style={{ width: "22%" }}>Enterprise</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Wallets
                </th>
                <td>+</td>
                <td>+ Reports</td>
                <td>all</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Exchanges
                </th>
                <td>+</td>
                <td>+ Adjustable colors</td>
                <td>all</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  DeFi
                </th>
                <td>
                  <p>Projects, Positions, Lending</p>
                </td>
                <td>
                  + Adjustable colors
                  <br /> + Reports
                </td>
                <td>
                  + Harvesting <br /> + Comparing
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Bot warnings
                </th>
                <td>-</td>
                <td>Default warnings</td>
                <td>+ Adjustable</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Clients
                </th>
                <td>-</td>
                <td>+</td>
                <td>all</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Offsets
                </th>
                <td>-</td>
                <td>+</td>
                <td>all</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Investors, Managers
                </th>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Project incomes
                </th>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Manager bonus
                </th>
                <td>-</td>
                <td>-</td>
                <td>+</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>

      <footer className="pt-4 my-md-5 pt-md-5 border-top">
        <div className="row">
          <div className="col-12 col-md">
            {/* logo */}
            <small className="d-block mb-3 text-muted">&copy; 2020–2022</small>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PricingInside;
