import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Spinner, Card, Button } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import { NumberWithCommas } from "../../components/table/FormatedCell";

function Bonuses() {
  const [bonuses, setBonuses] = useState();
  const [incomes, setIncomes] = useState();
  const [loadedManagers, setLoadedManagers] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Bonus" };

  useEffect(() => {
    let unmounted = false;

    const fetchBonuses = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/bonus",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setBonuses(responseData.bonuses);
      } catch (err) {}
    };
    fetchBonuses();

    const fetchIncomes = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/income",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.incomes.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        if (!unmounted) setIncomes(responseData.incomes);
      } catch (err) {}
    };
    fetchIncomes();

    const fetchManagers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/manager",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.managers.sort((a, b) =>
          a.managerName > b.managerName
            ? 1
            : b.managerName > a.managerName
            ? -1
            : 0
        );

        if (!unmounted) setLoadedManagers(responseData.managers);
      } catch (err) {}
    };
    fetchManagers();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/bonus/${cell.row.values.id}`);
      },
    };
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },

      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        Cell: ({ row }) =>
          new Date(row.values.date).toLocaleDateString("en-GB"),
      },
      {
        Header: "Manager",
        accessor: "managerName",
      },
      {
        Header: "IncomeType",
        accessor: "incomeType",
      },
      {
        Header: "IncomeinPositions",
        accessor: "incomeDay",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.incomeDay)} $`}</div>
        ),
      },
      {
        Header: "BonusDay",
        accessor: "bonusDay",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.bonusDay)} $`}</div>
        ),
      },
      {
        Header: "userId",
        accessor: "userid",
        show: false,
      },
    ],
    []
  );

  const updateHandler = () => {
    if (!isLoading && incomes && bonuses) {
      bonuses.forEach((m) => {
        if (
          new Date(m.date).toLocaleDateString("en-GB") ===
          new Date().toLocaleDateString("en-GB")
        ) {
          m.incomeDay = _.sumBy(incomes, (w) => {
            if (
              new Date(w.date).toLocaleDateString("en-GB") ===
                new Date().toLocaleDateString("en-GB") &&
              w.managerName === m.managerName &&
              w.incomeType === m.incomeType
            ) {
              return w.incomeDay;
            } else {
              return 0;
            }
          });
          m.managerBonus = _.sumBy(loadedManagers, (n) => {
            if (m.managerName === n.managerName) {
              return n.managerBonus;
            }
          });
          m.bonusDay = (m.incomeDay * m.managerBonus) / 100;

          //submit

          const submitIncomeMan = async () => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/bonus/${m.id}`,
                "PATCH",
                JSON.stringify({
                  date: new Date(),
                  managerName: m.managerName,
                  incomeType: m.incomeType,
                  incomeDay: m.incomeDay,
                  bonusDay: m.bonusDay,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
          };
          submitIncomeMan();
        }
      });
    }
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading && bonuses && (
        <Card
          className="text-center card-center w-100"
          // bg="dark"
          // text="white"
        >
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className="">
                <Button
                  className="btn btn-sm btn-secondary"
                  onClick={updateHandler}
                >
                  Update
                </Button>
              </div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              <div className="">
                <Button
                  as={Link}
                  to={`../bonuses/report`}
                  className="btn btn-sm btn-secondary"
                >
                  Report
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={bonuses}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Bonuses;
