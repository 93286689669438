import React, { useContext, useEffect, useState } from "react";
import { Spinner, Form, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useData, useGetDataId } from "../../components/hooks/dbase-hook";

import { AuthContext } from "../../components/context/auth-context";
import ClientField from "../../components/elements/form/ClientField";
import CommentField from "../../components/elements/form/CommentField";
import SumField from "../../components/elements/form/SumField";
import CardNewUpdate from "../../components/CardNewUpdate";
import TokenField from "../../components/elements/form/TokenField";
import ChainField from "../../components/elements/form/ChainField";
import ErrorAlert from "../../components/elements/ErrorAlert";

// Schema for yup
const validationSchema = Yup.object().shape({
  tokenName: Yup.string().required("*Name is required"),
  tokenQty: Yup.number().required("*Q-ty is required"),
  chainName: Yup.string().required("*Chain is required"),
  clientName: Yup.string().required("*Client is required"),
  userId: Yup.string().required("*Id is required"),
});

export default function WalletUpdate() {
  const [isChecked, setIsChecked] = useState();
  const [error, setError] = useState();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  let cardOptions = { cardName: "Wallet" };
  let pathId = `/${cardOptions.cardName.toLowerCase()}/${Id}`;

  //functions for update Query
  const onSuccess = () => {
    navigate(`../${cardOptions.cardName.toLowerCase()}s`);
  };
  const onError = () => {
    setError(mutateError);
    return <ErrorAlert error={error} onClear={clearError} />;
  };
  const onSettled = () => {};

  //update Query
  const { mutate: queryRequest, error: mutateError } = useData(
    onSuccess,
    onError,
    onSettled
  );

  //get Wallet
  //functions for update Query
  const onSuccessGet = () => {};
  const onErrorGet = () => {
    setError(requestError);
    return <ErrorAlert error={error} onClear={clearError} />;
  };
  const onSettledGet = () => {};

  const getOptions = {
    path: pathId,
    token: auth.token,
  };

  const {
    isLoading,
    data,
    error: requestError,
  } = useGetDataId(onSuccessGet, onErrorGet, onSettledGet, getOptions);

  //Formik initialValues
  //   const initialValues = {
  //     tokenName: data.tokenName,
  //     tokenQty: data.tokenQty,
  //     chainName: data.chainName,
  //     isInvestment: data.isInvestment,
  //     inFarming: data.inFarming,
  //     comments: data.comments,
  //     clientName: data.clientName,
  //     userId: auth.userId,
  //   };

  //Formik submit
  const onSubmit = (values) => {
    let options = {
      path: pathId,
      method: "patch",
      token: auth.token,
      data: JSON.stringify({
        tokenName: values.tokenName,
        tokenQty: values.tokenQty,
        chainName: values.chainName,
        walletAddress: values.walletAddress,
        isInvestment: values.isInvestment,
        inFarming: values.inFarming,
        imported: values.imported,
        comments: values.comments,
        clientName: values.clientName,
        userId: values.userId,
      }),
    };
    queryRequest(options);
  };
  useEffect(() => {
    if (data) {
      setIsChecked(data.data.wallet.isInvestment);
    }

    return () => {};
  }, [data]);

  const clearError = () => {
    setError(null);
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {!isLoading && !requestError && data ? (
        <Formik
          initialValues={{
            tokenName: data.data.wallet.tokenName,
            tokenQty: data.data.wallet.tokenQty,
            chainName: data.data.wallet.chainName,
            walletAddress: data.data.wallet.walletAddress,
            isInvestment: data.data.wallet.isInvestment,
            inFarming: data.data.wallet.inFarming,
            imported: data.data.wallet.imported,
            comments: data.data.wallet.comments,
            clientName: data.data.wallet.clientName,
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={false} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <TokenField name="tokenName" label="Token:" />
                  <SumField name="tokenQty" label="Quantity:" />
                </Row>

                <Row className="mb-3">
                  <ChainField name="chainName" label="Chain:" />
                  <ClientField name="clientName" label="Client:" />
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Investment:</Form.Label>
                    <Form.Check
                      type="checkbox"
                      name="isInvestment"
                      checked={values.isInvestment}
                      onChange={(e) => {
                        handleChange(e);
                        setIsChecked(!isChecked);
                      }}
                      onBlur={handleBlur}
                      value={values.isInvestment}
                    />
                    {
                      <div style={{ color: "grey", fontSize: "12px" }}>
                        If checked, will not be added to Comparing.
                      </div>
                    }
                  </Form.Group>
                  {!!isChecked && (
                    <Form.Group as={Col}>
                      <Form.Label>In farming:</Form.Label>
                      <Form.Check
                        type="checkbox"
                        name="inFarming"
                        checked={values.inFarming}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.inFarming}
                      />
                      {
                        <div style={{ color: "grey", fontSize: "12px" }}>
                          If checked, will be excluded from Wallet in Balance.
                        </div>
                      }
                    </Form.Group>
                  )}
                </Row>
                <Row className="mb-3">
                  <CommentField name="comments" label="Comments:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner animation="border" />
      )}
    </>
  );
}
