import React, { useEffect, useState, useContext } from "react";
import { Spinner } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import { NumberWithCommas } from "../../components/table/FormatedCell";
import CardMain from "../../components/CardMain";

function Managers() {
  const [loadedManagers, setLoadedManagers] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Manager" };

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },

      {
        Header: "Name",
        accessor: "managerName",
      },
      {
        Header: "ManagerBonus",
        accessor: "managerBonus",
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.managerBonus)} %`}</div>
        ),
      },
      {
        Header: "userId",
        accessor: "userId",
        show: false,
      },
    ],
    []
  );

  useEffect(() => {
    let unmounted = false;
    const fetchManagers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/manager",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedManagers(responseData.managers);
      } catch (err) {}
    };
    fetchManagers();
    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {isLoading && <Spinner animation="border" />}
      {!isLoading && loadedManagers && (
        <CardMain cardReport={false} cardName={cardOptions.cardName}>
          <ReactTable
            columns={columns}
            data={loadedManagers}
            getRowProps={(row) => ({
              style: { cursor: "pointer" },
              onClick: () => {
                navigate(`/manager/${row.values.id}`);
              },
            })}
          />
        </CardMain>
      )}
    </>
  );
}

export default Managers;
