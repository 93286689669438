import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Spinner, Card, Button, Alert } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";

function Tokens() {
  const [loadedTokens, setLoadedTokens] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  let navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Token" };

  useEffect(() => {
    let unmounted = false;

    const fetch = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens);
      } catch (err) {}
    };
    fetch();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  let allLoaded = !isLoading && loadedTokens;

  //Calc
  if (allLoaded) {
    for (const item of loadedTokens) {
      if (!item.activated) {
        item.activated = "Check";
      }
    }
  }
  //Only admin columns
  let showAdmin = auth.userType === "admin" ? true : false;

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        disableFilters: true,
        show: false,
      },
      {
        Header: "Token",
        accessor: "tokenSymbol",
      },
      {
        Header: "activated",
        accessor: "activated",
        show: showAdmin,
      },
      {
        disableFilters: true,
        Header: "Price",
        accessor: "tokenPrice",
      },
      {
        Header: "CoinGecko",
        accessor: "coinGecko",
      },
    ],
    [showAdmin]
  );

  //Click
  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        if (auth.userType === "admin") {
          navigate(`/token/${cell.row.values.id}`);
        } else {
          <Alert variant="warning">Only admins can edit.</Alert>;
        }
      },
    };
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className="">
                <Button
                  as={Link}
                  to={`../token/new`}
                  className="btn btn-sm btn-secondary"
                >
                  {auth.userType === "admin" ? "New" : "Request new"}
                </Button>
              </div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              <div className=""></div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={loadedTokens}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Tokens;
