import React, { useContext } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import SumField from "../../components/elements/form/SumField";
import TextField from "../../components/elements/form/TextField";

// Schema for yup
const validationSchema = Yup.object().shape({
  clientName: Yup.string().required("*Name is required"),
  clientPercent: Yup.number().required("*Percent is required"),
  userId: Yup.string().required("*Id is required"),
});

const NewClient = () => {
  //const [newClient, setNewClient] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Client" };
  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading ? <Spinner animation="border" /> : null}
      {!isLoading && (
        <Formik
          initialValues={{
            clientName: "",
            clientPercent: "",
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/client`,
                "POST",
                JSON.stringify({
                  clientName: values.clientName,
                  clientPercent: values.clientPercent,
                  userId: values.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
            navigate("../clients");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <TextField name="clientName" label="Client name:" />
                  <SumField name="clientPercent" label="Client %:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewClient;
