import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, Card, Button } from "react-bootstrap";

import { EditableCell } from "../../components/table/EditableCell";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import {
  CellColorOrder,
  ColorAcc,
  NumberWithCommas,
} from "../../components/table/FormatedCell";
import { showColumn } from "../../components/Functions";

function Cexs() {
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [data, setData] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Cex" };

  useEffect(() => {
    let unmounted = false;
    const fetchCexs = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/cex",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setData(responseData.cexs);
      } catch (err) {}
    };
    fetchCexs();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const getPrice = (tokenSymbol) => {
    let tokenObj;
    if (!isLoading && loadedTokens) {
      tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    }
    return tokenObj.tokenPrice;
  };

  //Calculations
  if (!isLoading && data && loadedTokens) {
    data.forEach((e) => {
      e.tokenPrice = getPrice(e.tokenName);
      e.actSum = (Math.round(e.tokenQty * e.tokenPrice * 100) / 100).toFixed(0);

      switch (e.orderType) {
        case "Short":
          e.pnl =
            (Math.round(e.tokenQty * e.priceOpen * 100) / 100).toFixed(0) -
            e.actSum;

          break;
        case "Long":
          (e.pnl =
            e.actSum -
            Math.round(e.tokenQty * e.priceOpen * 100) / 100).toFixed(0);

          break;
        case "Collateral":
          e.pnl = 0;
          e.leverage = 1;

          break;
        default:
          e.pnl = 0;
          e.leverage = 1;
          break;
      }
      let sumLev = e.actSum / e.leverage;
      e.posActual = (
        Math.round((e.pnl + sumLev + e.collAdded) * 100) / 100
      ).toFixed(0);
      e.fundingAmount = (
        Math.round(((e.actSum * e.fundingPers) / 12) * 100) / 100
      ).toFixed(0);
    });
    data.sort((a, b) =>
      a.exchangeName > b.exchangeName
        ? 1
        : b.exchangeName > a.exchangeName
        ? -1
        : 0
    );
  }

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const submitData = async () => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/cex/${row.id}`,
                "PATCH",
                JSON.stringify({
                  exchangeName: row.exchangeName,
                  orderType: row.orderType,
                  accountType: row.accountType,
                  tokenName: row.tokenName,
                  tokenQty: columnId === "tokenQty" ? value : row.tokenQty,
                  leverage: row.leverage,
                  fundingPers: row.fundingPers,
                  priceOpen: columnId === "priceOpen" ? value : row.priceOpen,
                  collAdded: row.collAdded,
                  comments: row.comments,
                  clientName: row.clientName,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
          };
          submitData();
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) setSkipPageReset(false);

    return () => {
      unmounted = true;
    };
  }, [data]);

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        switch (cell.column.id) {
          case "tokenQty":
            break;
          case "priceOpen":
            break;

          default:
            navigate(`/cex/${cell.row.values.id}`);
            break;
        }
      },
    };
  };

  //Window size cut colomn
  let show = showColumn(window.innerWidth, 1300);

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      show: false,
    },

    {
      Header: "Exchange",
      accessor: "exchangeName",
    },
    {
      Header: "Account",
      accessor: "accountType",
      disableFilters: true,
      Cell: ColorAcc,
    },
    {
      Header: "Order",
      accessor: "orderType",
      disableFilters: true,
      Cell: CellColorOrder,
    },
    {
      Header: "TokenName",
      accessor: "tokenName",
    },
    {
      Header: "Q-ty",
      accessor: "tokenQty",
      disableFilters: true,
      Cell: EditableCell,
    },
    {
      Header: "OpenPrice",
      accessor: "priceOpen",
      disableFilters: true,
      Cell: EditableCell,
    },
    {
      Header: "Leverage",
      accessor: "leverage",
      show,
      disableFilters: true,
    },
    {
      Header: "PnL",
      accessor: "pnl",
      show,
      disableFilters: true,
      Cell: ({ row }) => <div>{`${NumberWithCommas(row.values.pnl)} $`}</div>,
    },
    {
      Header: "ActualPos",
      accessor: "posActual",
      show,
      disableFilters: true,
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.posActual)} $`}</div>
      ),
    },
    {
      Header: "fundingAmount",
      accessor: "fundingAmount",
      show,
      disableFilters: true,
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.fundingAmount)} $`}</div>
      ),
    },
    {
      Header: "Import",
      accessor: "imported",
      show,
      disableFilters: true,
    },
    {
      Header: "Comments",
      accessor: "comments",
      show,
      disableFilters: true,
    },
    {
      Header: "Client",
      accessor: "clientName",
      show,
      disableFilters: true,
    },
    {
      Header: "userId",
      accessor: "userid",
      show: false,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading && data && loadedTokens && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className="">
                <Button
                  as={Link}
                  to={`../cex-imports`}
                  className="btn btn-sm btn-success me-2"
                >
                  Import <br />
                  positions
                </Button>
                <Button
                  as={Link}
                  to={`../Cexs/new`}
                  className="btn btn-sm btn-secondary"
                >
                  Add <br />
                  manually
                </Button>
              </div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              <div className="">
                <Button
                  as={Link}
                  to={`../Cexs/report`}
                  className="btn btn-sm btn-secondary"
                >
                  Back
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={data}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Cexs;
