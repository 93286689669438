import React from "react";
import { Form, Col } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";

const CustomInputComponent = (props) => (
  <Form.Check
    type="switch"
    disabled={props.disabled}
    checked={props.checked}
    {...props}
  />
);
function CheckField(props) {
  return (
    <Form.Group as={Col}>
      <Form.Label>{props.label} </Form.Label>
      <Field
        as={CustomInputComponent}
        name={props.name}
        disabled={props.disabled ? true : false}
        checked={props.checked ? true : false}
      />

      <ErrorMessage name={props.name}>
        {(msg) => <div style={{ color: "red", fontSize: "13px" }}>{msg}</div>}
      </ErrorMessage>
      <div>{props.text ? props.text : null}</div>
    </Form.Group>
  );
}

export default CheckField;
