import React, { useContext, useState } from "react";
import { Spinner, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { AuthContext } from "../../components/context/auth-context";
import CardNewUpdate from "../../components/CardNewUpdate";
import SumField from "../../components/elements/form/SumField";
import TokenField from "../../components/elements/form/TokenField";
import ChainField from "../../components/elements/form/ChainField";
import TextField from "../../components/elements/form/TextField";
import ErrorAlert from "../../components/elements/ErrorAlert";
import DateField from "../../components/elements/form/DateField";

// Schema for yup
const validationSchema = Yup.object().shape({
  date: Yup.date().required("*Date is required"),
  chainName: Yup.string().required("*Chain is required"),
  blockNumber: Yup.string().required("*BlockNumber is required"),
  cryptoAddress: Yup.string().required("*CryptoAddress is required"),
  tokenName: Yup.string().required("*TokenName is required"),
  sum: Yup.number().required("*Sum is required"),
});

export default function NewPayment() {
  const [error, setError] = useState();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Payment" };

  //Formik initialValues
  const initialValues = {
    date: new Date().toISOString().split("T")[0],
    chainName: "",
    blockNumber: "",
    cryptoAddress: "",
    tokenName: "",
    sum: 0,
  };

  //Formik submit
  const onSubmit = (values) => {
    axios({
      url:
        process.env.REACT_APP_BACKEND_URL +
        `/${cardOptions.cardName.toLowerCase()}`,
      method: "post",
      data: JSON.stringify({
        date: new Date(values.date),
        chainName: values.chainName,
        blockNumber: values.blockNumber,
        cryptoAddress: values.cryptoAddress,
        tokenName: values.tokenName,
        sum: values.sum,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    })
      .then(() => navigate(`../${cardOptions.cardName.toLowerCase()}s`))
      .catch((err) => setError(err));
  };
  function clearError() {
    setError(null);
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {!error ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <DateField name="date" label="Date:" />
                  <ChainField name="chainName" label="Chain:" />
                </Row>

                <Row className="mb-3">
                  <SumField name="blockNumber" label="Block Number:" />
                  <TextField name="cryptoAddress" label="CryptoAddress:" />
                </Row>
                <Row className="mb-3">
                  <TokenField name="tokenName" label="Token:" />
                  <SumField name="sum" label="Sum:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner animation="border" />
      )}
    </>
  );
}
