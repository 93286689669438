import React, { useContext } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import SumField from "../../components/elements/form/SumField";
import TextField from "../../components/elements/form/TextField";

// Schema for yup
const validationSchema = Yup.object().shape({
  managerName: Yup.string().required("*Name is required"),
  managerBonus: Yup.number().required("*Percent is required"),
  userId: Yup.string().required("*Id is required"),
});

const NewManager = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Manager" };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading ? <Spinner animation="border" /> : null}
      {!isLoading && (
        <Formik
          initialValues={{
            managerName: "",
            managerBonus: "",
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/manager`,
                "POST",
                JSON.stringify({
                  managerName: values.managerName,
                  managerBonus: values.managerBonus,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
            navigate("../managers");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <TextField name="managerName" label="Manager name:" />
                  <SumField name="managerBonus" label="Manager %:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewManager;
