import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, Card, Button } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { ReactTable } from "../../components/TableSearch";
import { AuthContext } from "../../components/context/auth-context";
import {
  NumberWithCommas,
  ColorLendBorrow,
  LinkCell,
} from "../../components/table/FormatedCell";

function Lending() {
  const [loadedLends, setLoadedLends] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Lend" };

  useEffect(() => {
    let unmounted = false;
    const fetchLends = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/lend",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedLends(responseData.lends);
      } catch (err) {}
    };
    fetchLends();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    const fetchPools = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pool",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedPools(responseData.pools);
      } catch (err) {}
    };
    fetchPools();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const getLink = (poolName) => {
    let link;
    loadedPools.forEach((e) => {
      if (e.poolName === poolName) {
        link = e.poolLink;
      }
    });
    return link;
  };
  if (!isLoading && loadedLends && loadedPools) {
    loadedLends.forEach((e) => {
      e.linkProvider = getLink(e.providerName);
    });
  }
  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        switch (cell.column.id) {
          case "providerName":
            window.open(`${cell.row.original.linkProvider}`);
            break;

          default:
            navigate(`/lend/${cell.row.values.id}`);
            break;
        }
      },
    };
  };

  const getPrice = (tokenSymbol) => {
    let tokenObj;
    if (!isLoading && loadedTokens) {
      tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    }
    return tokenObj.tokenPrice;
  };

  //Calculations
  if (!isLoading && loadedLends && loadedTokens) {
    loadedLends.forEach((e) => {
      e.tokenPrice = getPrice(e.tokenName);

      e.lendingName = e.providerName + "_" + e.chainName;
      e.lendingSum = e.tokenQty * e.tokenPrice;
      e.income = (e.lendingSum * e.percent) / 100 / 12;
    });
    loadedLends.sort((a, b) =>
      a.lendingName > b.lendingName ? 1 : b.lendingName > a.lendingName ? -1 : 0
    );
  }

  //Window size cut colomn
  let showColomn;
  let windowWidth = window.innerWidth;
  if (windowWidth < 1300) {
    showColomn = false;
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      show: false,
    },

    {
      Header: "Provider",
      accessor: "providerName",
      show: showColomn,
      Cell: LinkCell,
    },
    {
      Header: "Name",
      accessor: "lendingName",
    },
    {
      Header: "Order",
      accessor: "orderType",
      Cell: ColorLendBorrow,
    },

    {
      Header: "Token",
      accessor: "tokenName",
    },
    {
      Header: "Q-ty",
      accessor: "tokenQty",
      disableFilters: true,
      Cell: ({ row }) => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          {NumberWithCommas(row.values.tokenQty)}
        </div>
      ),
    },
    {
      Header: "Sum",
      accessor: "lendingSum",
      disableFilters: true,
      Cell: ({ row }) => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          {`${NumberWithCommas(row.values.lendingSum)} $`}
        </div>
      ),
    },
    {
      Header: "Percent",
      accessor: "percent",
      disableFilters: true,
      Cell: ({ row }) => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          {`${NumberWithCommas(row.values.percent)} %`}
        </div>
      ),
    },
    {
      Header: "Col Factor",
      accessor: "colFactor",
      show: showColomn,
      disableFilters: true,
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.colFactor)} %`}</div>
      ),
    },
    {
      Header: "IncomeCalc",
      accessor: "income",
      show: showColomn,
      disableFilters: true,
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.income)} $`}</div>
      ),
    },
    {
      Header: "Chain",
      accessor: "chainName",
      show: showColomn,
    },
    {
      Header: "Manager",
      accessor: "managerName",
      disableFilters: true,
      show: showColomn,
    },
    {
      Header: "Client",
      accessor: "clientName",
      disableFilters: true,
      show: showColomn,
    },
    {
      Header: "userId",
      accessor: "userid",
      show: false,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {isLoading && <Spinner animation="border" />}
      {!isLoading && loadedLends && loadedTokens && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className="">
                <Button
                  as={Link}
                  to={`../Lends/new`}
                  className="btn btn-sm btn-secondary"
                >
                  New
                </Button>
              </div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              <div className="">
                <Button
                  as={Link}
                  to={`../Lends/report`}
                  className="btn btn-sm btn-secondary"
                >
                  Back
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={loadedLends}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Lending;
