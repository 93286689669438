import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Spinner, Col } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CardNewUpdate from "../../components/CardNewUpdate";
import TextField from "../../components/elements/form/TextField";
import TokenField from "../../components/elements/form/TokenField";

// Schema for yup
const validationSchema = Yup.object().shape({
  poolName: Yup.string().required("*Name is required"),
  poolLink: Yup.string().required("*Link is required"),
  poolCoin: Yup.string().required("*Coin is required"),
});

const UpdatePool = () => {
  const [loadedPool, setLoadedPool] = useState();
  const [projects, setPorjects] = useState();
  const [loadedFarming, setLoadedFarming] = useState();
  const [loadedLends, setLoadedLends] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const Id = useParams().id;
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Pool" };

  useEffect(() => {
    let unmounted = false;
    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };
    if (!unmounted) {
      fetch("/project", (data) => setPorjects(data.projects));
      fetch("/farm", (data) => setLoadedFarming(data.farms));
      fetch("/lend", (data) => setLoadedLends(data.lends));
    }

    const fetchPoolById = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/pool/${Id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setLoadedPool(responseData.pool);
      } catch (err) {}
    };
    fetchPoolById();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, Id, auth.token]);

  let allLoaded =
    !isLoading && loadedPool && loadedFarming && loadedLends && projects;

  //disable delete button if token in use
  let disableDelete;
  if (allLoaded) {
    projects.forEach((project) => {
      if (
        project.providerName === loadedPool.poolName ||
        project.poolName === loadedPool.poolName
      ) {
        disableDelete = 1;
      }
    });
    loadedFarming.forEach((f) => {
      if (f.providerName === loadedPool.poolName) {
        disableDelete = 1;
      }
    });
    loadedLends.forEach((l) => {
      if (l.providerName === loadedPool.poolName) {
        disableDelete = 1;
      }
    });
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading ? <Spinner animation="border" /> : null}

      {allLoaded && (
        <Formik
          initialValues={loadedPool}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/pool/${Id}`,
                "PATCH",
                JSON.stringify({
                  poolName: values.poolName,
                  poolLink: values.poolLink,
                  poolCoin: values.poolCoin,
                  activated: values.activated,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
              navigate("../pools");
            } catch (err) {}
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <CardNewUpdate
                cardNew={false}
                cardName={cardOptions.cardName}
                isbuttondisabled={disableDelete}
              >
                <Row className="mb-3">
                  <TextField name="poolName" label="Pool Name:" />

                  <TokenField name="poolCoin" label="Token:" />
                </Row>
                <Row className="mb-3">
                  <TextField name="poolLink" label="Link of Pool:" />
                </Row>
                {auth.userType === "admin" ? (
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Activated:</Form.Label>
                      <Field as={Form.Select} name="activated">
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </Field>
                    </Form.Group>
                  </Row>
                ) : null}
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UpdatePool;
