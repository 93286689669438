import React, { useEffect, useState, useContext, useCallback } from "react";
import { Form, Row, Col, Spinner, InputGroup, Button } from "react-bootstrap";
import { Formik, useFormikContext, useField, Field } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CardNewUpdate from "../../components/CardNewUpdate";
import CheckField from "../../components/elements/form/CheckField";

const PriceField = (props) => {
  const {
    values: { tokenSymbol, coinGecko },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField(props.name);

  const priceHandler = useCallback(async () => {
    //event.preventDefault();
    let price;
    // try {
    //   const responseData = await sendRequest(
    //     `https://api.coingecko.com/api/v3/simple/price?ids=${coinGecko}&vs_currencies=usd`
    //   );
    //   if (Object.keys(responseData).length === 0) {
    //     throw new Error();
    //   }
    price = props.price;
    if (props.ischecked === "true") {
      setFieldValue(props.name, price * 1000);
    } else {
      setFieldValue(props.name, price);
    }
    // } catch (err) {
    //   //throw err = new Error("CoinGecko name is not correct.");
    // }
  }, [props.name, setFieldValue, props.ischecked, props.price]);

  useEffect(() => {
    if (
      tokenSymbol.trim() !== "" &&
      coinGecko.trim() !== "" &&
      touched.tokenSymbol &&
      touched.is1000 &&
      touched.coinGecko
    ) {
      priceHandler();
    }
  }, [
    tokenSymbol,
    coinGecko,
    touched.tokenSymbol,
    touched.coinGecko,
    touched.is1000,
    priceHandler,
  ]);
  return (
    <>
      <Form.Control {...props} {...field} />
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};

// Schema for yup
const validationSchema = Yup.object().shape({
  tokenSymbol: Yup.string().required("*Symbol is required"),
  coinGecko: Yup.string().required("*CoinGecko name is required"),
  tokenPrice: Yup.number().required("*Price is required"),
});

const UpdateToken = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedToken, setLoadedToken] = useState();
  const [pools, setPools] = useState();
  const [wallets, setWallets] = useState();
  const [cexs, setCexs] = useState();
  const [projects, setPorjects] = useState();
  const [loadedFarming, setLoadedFarming] = useState();
  const [loadedLends, setLoadedLends] = useState();
  const [loadedComps, setLoadedComps] = useState();
  const [details, setDetails] = useState();
  const auth = useContext(AuthContext);
  const Id = useParams().id;
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Token" };

  useEffect(() => {
    let unmounted = false;
    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };

    if (!unmounted) {
      fetch(`/token/${Id}`, (data) => setLoadedToken(data.token));
      fetch("/pool", (data) => setPools(data.pools));
      fetch("/wallet", (data) => setWallets(data.wallets));
      fetch("/cex", (data) => setCexs(data.cexs));
      fetch("/project", (data) => setPorjects(data.projects));
      fetch("/farm", (data) => setLoadedFarming(data.farms));
      fetch("/lend", (data) => setLoadedLends(data.lends));
      fetch("/comp", (data) => setLoadedComps(data.compares));
      fetch("/detail", (data) => setDetails(data.details));
    }
  }, [sendRequest, Id, auth.token]);

  let allLoaded =
    !isLoading &&
    loadedToken &&
    wallets &&
    cexs &&
    loadedFarming &&
    loadedLends &&
    pools &&
    projects &&
    loadedComps &&
    details;

  const clickHandler = () => {
    window.open("https://www.coingecko.com/", "_blank");
  };

  //disable delete button if token in use
  let disableDelete;
  if (allLoaded) {
    wallets.forEach((w) => {
      if (w.tokenName === loadedToken.tokenSymbol) {
        disableDelete = 1;
      }
    });
    cexs.forEach((c) => {
      if (c.tokenName === loadedToken.tokenSymbol) {
        disableDelete = 1;
      }
    });
    projects.forEach((project) => {
      if (
        project.pair1 === loadedToken.tokenSymbol ||
        project.pair2 === loadedToken.tokenSymbol
      ) {
        disableDelete = 1;
      }
    });
    loadedFarming.forEach((f) => {
      if (
        f.pair1 === loadedToken.tokenSymbol ||
        f.pair2 === loadedToken.tokenSymbol
      ) {
        disableDelete = 1;
      }
    });
    pools.forEach((p) => {
      if (p.poolCoin === loadedToken.tokenSymbol) {
        disableDelete = 1;
      }
    });
    loadedComps.forEach((c) => {
      if (c.tokenName === loadedToken.tokenSymbol) {
        disableDelete = 1;
      }
    });
    details.forEach((d) => {
      if (d.tokenName === loadedToken.tokenSymbol) {
        disableDelete = 1;
      }
    });
  }

  //Formik submit
  const Submit = async (values) => {
    // When button submits form and form is in the process of submitting, submit button is disabled

    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/token/${Id}`,
        "PATCH",
        JSON.stringify({
          tokenSymbol: values.tokenSymbol,
          coinGecko: values.coinGecko,
          tokenPrice: values.tokenPrice,
          is1000: values.is1000,
          activated: values.activated,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      navigate("../tokens");
    } catch (err) {}
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <Formik
          initialValues={{
            tokenSymbol: loadedToken.tokenSymbol,
            coinGecko: loadedToken.coinGecko,
            tokenPrice: loadedToken.tokenPrice,
            is1000: loadedToken.is1000,
            activated: loadedToken.activated,
          }}
          validationSchema={validationSchema}
          onSubmit={Submit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <CardNewUpdate
                cardNew={false}
                cardName={cardOptions.cardName}
                isbuttondisabled={disableDelete}
              >
                <Row className="mb-3">
                  {/* <TextField name="tokenSymbol" label="Token:" /> */}
                  <InputGroup className="mb-3 col">
                    <Button variant="outline-secondary" id="button-addon1">
                      Token
                    </Button>
                    <Field name="tokenSymbol" className=" form-control" />
                  </InputGroup>
                  <InputGroup className="mb-3 col">
                    <Field
                      placeholder="...add coinGecko id"
                      name="coinGecko"
                      className=" form-control"
                    />
                    <Button
                      onClick={clickHandler}
                      variant="secondary"
                      type="button"
                      id="button-addon1"
                    >
                      get API id
                    </Button>
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <CheckField
                    name="is1000"
                    label="is x1000:"
                    checked={values.is1000}
                    text={"If checked, price will be x1000"}
                    disabled
                  />

                  <Form.Group as={Col} controlId="formTokenPrice">
                    <Form.Label>Price of token:</Form.Label>
                    <br />

                    <PriceField
                      className={
                        touched.tokenPrice && errors.tokenPrice ? "error" : null
                      }
                      name="tokenPrice"
                      placeholder="price"
                      ischecked={values.is1000 ? true : false}
                      price={values.tokenPrice}
                    />

                    {touched.tokenPrice && errors.tokenPrice ? (
                      <div className="error-message">{errors.tokenPrice}</div>
                    ) : null}
                  </Form.Group>
                </Row>
                {auth.userType === "admin" ? (
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Activated:</Form.Label>
                      <Field as={Form.Select} name="activated">
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </Field>
                    </Form.Group>
                  </Row>
                ) : null}
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UpdateToken;
