import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import _ from "lodash";
import CardMain from "../../components/CardMain";
import { ReactTable } from "../../components/TableSearch";
import {
  NumberWithCommas,
  ColorComp,
  LinkCell,
} from "../../components/table/FormatedCell";

function Comparing() {
  const [loadedComps, setLoadedComps] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedCexs, setLoadedCexs] = useState();
  const [loadedWallets, setLoadedWallets] = useState();
  const [loadedFarming, setLoadedFarming] = useState();
  const [loadedLends, setLoadedLends] = useState();
  const [loadedExchanges, setLoadedExchanges] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = {
    cardName: "Comp",
  };

  useEffect(() => {
    let unmounted = false;

    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };

    if (!unmounted) {
      fetch("/exchange", (data) => setLoadedExchanges(data.exchanges));
      fetch("/token", (data) => setLoadedTokens(data.tokens));
      fetch("/wallet", (data) => setLoadedWallets(data.wallets));
      fetch("/cex", (data) => setLoadedCexs(data.cexs));
      fetch("/farm", (data) => setLoadedFarming(data.farms));
      fetch("/lend", (data) => setLoadedLends(data.lends));
      fetch("/comp", (data) => setLoadedComps(data.compares));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);
  const getPrice = (tokenSymbol) => {
    let tokenObj;

    tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);

    return tokenObj.tokenPrice;
  };

  let allLoaded =
    !isLoading &&
    loadedCexs &&
    loadedComps &&
    loadedLends &&
    loadedTokens &&
    loadedFarming &&
    loadedExchanges &&
    loadedWallets;
  //Calculations
  if (allLoaded) {
    //Farming calculation
    loadedFarming.forEach((e) => {
      e.pairs = e.pair1 + "/" + e.pair2;
      e.pair1Price = getPrice(e.pair1);
      e.pair2Price = getPrice(e.pair2);

      //IL
      let ilSum = e.newPair1Qty * e.pair1Price + e.newPair2Qty * e.pair2Price;
      let ilK = e.newPair1Qty * e.newPair2Qty;
      e.newPair1Qty = ilSum / 2 / e.pair1Price;
      e.newPair2Qty = ilSum / 2 / e.pair2Price;
      let ilR = e.pair2Price / e.pair1Price;
      e.pair1Qty = Math.sqrt(ilK * ilR);
      e.pair2Qty = Math.sqrt(ilK / ilR);
    });
    //Comparisons
    loadedComps.forEach((c) => {
      if (c.isSecondToken && c.tokenName2) {
        c.tokensInWallet = _.sumBy(loadedWallets, (w) => {
          if (
            c.tokenName2 === w.tokenName &&
            c.clientName === w.clientName &&
            w.isInvestment !== true
          ) {
            return w.tokenQty;
          } else {
            return 0;
          }
        });
        c.tokensInFarmingPair1 = _.sumBy(loadedFarming, (f) => {
          if (c.tokenName2 === f.pair1 && c.clientName === f.clientName) {
            return f.pair1Qty;
          } else {
            return 0;
          }
        });

        c.tokensInFarmingPair2 = _.sumBy(loadedFarming, (f) => {
          if (c.tokenName2 === f.pair2 && c.clientName === f.clientName) {
            return f.pair2Qty;
          } else {
            return 0;
          }
        });

        c.tokensInLendingLend = _.sumBy(loadedLends, (l) => {
          if (
            c.tokenName2 === l.tokenName &&
            c.clientName === l.clientName &&
            l.orderType === "Lend"
          ) {
            return l.tokenQty;
          } else {
            return 0;
          }
        });

        c.tokensInLendingBorrow = _.sumBy(loadedLends, (l) => {
          if (
            c.tokenName2 === l.tokenName &&
            c.clientName === l.clientName &&
            l.orderType === "Borrow"
          ) {
            return l.tokenQty;
          } else {
            return 0;
          }
        });
      } else {
        c.tokensInWallet = _.sumBy(loadedWallets, (w) => {
          if (
            c.tokenName === w.tokenName &&
            c.clientName === w.clientName &&
            w.isInvestment !== true
          ) {
            return w.tokenQty;
          } else {
            return 0;
          }
        });

        c.tokensInFarmingPair1 = _.sumBy(loadedFarming, (f) => {
          if (c.tokenName === f.pair1 && c.clientName === f.clientName) {
            return f.pair1Qty;
          } else {
            return 0;
          }
        });

        c.tokensInFarmingPair2 = _.sumBy(loadedFarming, (f) => {
          if (c.tokenName === f.pair2 && c.clientName === f.clientName) {
            return f.pair2Qty;
          } else {
            return 0;
          }
        });

        c.tokensInLendingLend = _.sumBy(loadedLends, (l) => {
          if (
            c.tokenName === l.tokenName &&
            c.clientName === l.clientName &&
            l.orderType === "Lend"
          ) {
            return l.tokenQty;
          } else {
            return 0;
          }
        });
        c.tokensInLendingBorrow = _.sumBy(loadedLends, (l) => {
          if (
            c.tokenName === l.tokenName &&
            c.clientName === l.clientName &&
            l.orderType === "Borrow"
          ) {
            return l.tokenQty;
          } else {
            return 0;
          }
        });
      }
      //Token 1
      c.tokensInCexShort = _.sumBy(loadedCexs, (x) => {
        if (
          c.tokenName === x.tokenName &&
          c.clientName === x.clientName &&
          x.orderType === "Short"
        ) {
          return x.tokenQty;
        } else {
          return 0;
        }
      });
      c.tokensInCexLong = _.sumBy(loadedCexs, (x) => {
        if (
          c.tokenName === x.tokenName &&
          c.clientName === x.clientName &&
          x.orderType === "Long"
        ) {
          return x.tokenQty;
        } else {
          return 0;
        }
      });
      c.exchangeQty = c.tokensInCexShort - c.tokensInCexLong;
      c.farmingQty =
        c.tokensInWallet +
        c.tokensInFarmingPair1 +
        c.tokensInFarmingPair2 +
        c.tokensInLendingLend -
        c.tokensInLendingBorrow;

      c.diff = c.exchangeQty - c.farmingQty;
      if (c.diff && c.farmingQty) {
        c.diffPercent = parseInt(
          (Math.round((c.diff / c.farmingQty) * 10000) / 100).toFixed(0)
        );
      } else {
        c.diffPercent = 0;
      }
      loadedExchanges.forEach((x) => {
        if (x.exchangeName === c.exchangeName) {
          c.link = x.link;
        }
      });
    });

    loadedComps.sort((a, b) =>
      a.diffPercent > b.diffPercent ? 1 : b.diffPercent > a.diffPercent ? -1 : 0
    );
  }

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        if (cell.column.id === "exchangeName") {
          window.open(`${cell.row.original.link}`);
        } else {
          navigate(`/comp/${cell.row.values.id}`);
        }
      },
    };
  };

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      show: false,
    },

    {
      Header: "Token1",
      accessor: "tokenName",
    },
    {
      Header: "ExchangeQ-ty",
      accessor: "exchangeQty",
      disableFilters: true,
      Cell: ({ row }) => (
        <div>{NumberWithCommas(row.values.exchangeQty, 3)}</div>
      ),
    },
    {
      Header: "Token2",
      accessor: "tokenName2",
    },
    {
      Header: "FarmingQ-ty",
      accessor: "farmingQty",
      disableFilters: true,
      Cell: ({ row }) => (
        <div>{NumberWithCommas(row.values.farmingQty, 3)}</div>
      ),
    },
    {
      Header: "Diff",
      accessor: "diff",
      disableFilters: true,
      Cell: ({ row }) => <div>{NumberWithCommas(row.values.diff, 3)}</div>,
    },
    {
      Header: "Diff %",
      accessor: "diffPercent",
      disableFilters: true,
      Cell: ColorComp,
    },
    {
      Header: "Exchange",
      accessor: "exchangeName",
      Cell: LinkCell,
    },
    {
      Header: "Client",
      accessor: "clientName",
    },
    {
      Header: "userId",
      accessor: "userid",
      show: false,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <CardMain cardReport={false} cardName={cardOptions.cardName}>
          <ReactTable
            columns={columns}
            data={loadedComps}
            getCellProps={onClickCellHandler}
          />
        </CardMain>
      )}
    </>
  );
}

export default Comparing;
