import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CardMain from "../../components/CardMain";
import { ReactTable } from "../../components/TableSearch";
import { ColorCell, LinkCell } from "../../components/table/FormatedCell";

function Projects() {
  const [loadedProjects, setLoadedProjects] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const [loadedFarming, setLoadedFarming] = useState();
  const [chains, setChains] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Project" };

  useEffect(() => {
    let unmounted = false;

    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };
    if (!unmounted) {
      fetch("/setting", (data) => setloadedSettings(data.settings));
      fetch("/project", (data) => setLoadedProjects(data.projects));
      fetch("/chain", (data) => setChains(data.chains));
      fetch("/pool", (data) => setLoadedPools(data.pools));
      fetch("/farm", (data) => setLoadedFarming(data.farms));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  let allLoaded =
    !isLoading &&
    loadedProjects &&
    loadedPools &&
    loadedSettings &&
    loadedFarming &&
    chains;

  //Calculations
  let showColomn, yellowGreen, redYellow;
  if (allLoaded) {
    loadedProjects.forEach((e) => {
      loadedFarming.forEach((f) => {
        if (f.projectNumber === e.projectNumber) {
          e.status = "Yes";
        }
      });

      e.pairs = e.pair1 + "/" + e.pair2;

      loadedPools.forEach((p) => {
        if (p.poolName === e.providerName) {
          e.link = p.poolLink;
        }
      });
      chains.forEach((c) => {
        if (c.chainName === e.chainName) {
          e.chainLink = c.explorer;
        }
      });

      for (const project of loadedProjects) {
        if (project.inFocus) {
          project.inFocusText = "Yes";
        } else {
          project.inFocusText = "";
        }
      }
    });

    //sort
    loadedProjects.sort((a, b) =>
      a.inFocusText < b.inFocusText ? 1 : b.inFocusText < a.inFocusText ? -1 : 0
    );

    //Colors from Settings
    yellowGreen = loadedSettings.find((d) => d.name === "colorGreenApi");
    redYellow = loadedSettings.find((d) => d.name === "colorRedApi");
  }

  //Window size cut colomn
  let windowWidth = window.innerWidth;
  if (windowWidth < 1200) {
    showColomn = false;
  }

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        switch (cell.column.id) {
          case "chainName":
            window.open(`${cell.row.original.chainLink}`);
            break;
          case "providerName":
            window.open(`${cell.row.original.link}`);
            break;

          default:
            navigate(`/project/${cell.row.values.id}`);
            break;
        }
      },
    };
  };

  //Columns
  const columns = [
    {
      Header: "Id",
      accessor: "id",
      disableFilters: true,
      show: false,
    },
    {
      Header: "ProjectId",
      accessor: "projectNumber",
      disableFilters: true,
    },
    {
      Header: "Provider",
      accessor: "providerName",
      Cell: LinkCell,
    },
    {
      Header: "Pool",
      accessor: "poolName",
      show: showColomn,
      disableFilters: true,
    },
    {
      Header: "Pairs",
      accessor: "pairs",
    },
    {
      Header: "APY",
      accessor: "apy",
      Cell: (cell) => ColorCell(cell, yellowGreen.value, redYellow.value),
    },
    {
      Header: "Chain",
      accessor: "chainName",
      show: showColomn,
      Cell: LinkCell,
    },
    {
      Header: "Manager",
      accessor: "managerName",
      disableFilters: true,
      show: showColomn,
    },
    {
      Header: "InFocus",
      accessor: "inFocusText",
      disableFilters: true,
      show: showColomn,
    },
    {
      Header: "InFarming",
      accessor: "status",
      disableFilters: true,
    },
    {
      Header: "Comments",
      accessor: "comments",
      disableFilters: true,
      show: showColomn,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <CardMain cardReport={false} cardName={cardOptions.cardName}>
          <ReactTable
            columns={columns}
            data={loadedProjects}
            getCellProps={onClickCellHandler}
          />
        </CardMain>
      )}
    </>
  );
}

export default Projects;
