import React from "react";
import { Form, Col } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";

const CustomInputComponent = (props) => <Form.Control type="text" {...props} />;

export default function TextField(props) {
  const { label, name } = props;
  return (
    <Form.Group as={Col} controlId="formSum">
      <Form.Label>{label} </Form.Label>
      <Field as={CustomInputComponent} name={name} />

      <ErrorMessage name={name}>
        {(msg) => <div style={{ color: "red", fontSize: "13px" }}>{msg}</div>}
      </ErrorMessage>
    </Form.Group>
  );
}
