import React, { useContext, useState, useEffect } from "react";
import { Spinner, Form, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";

import { useWeb3 } from "../../components/hooks/web3-hook";
import { AuthContext } from "../../components/context/auth-context";
import ClientField from "../../components/elements/form/ClientField";
import CommentField from "../../components/elements/form/CommentField";
import SumField from "../../components/elements/form/SumField";
import CardNewUpdate from "../../components/CardNewUpdate";
import TokenField from "../../components/elements/form/TokenField";
import ChainField from "../../components/elements/form/ChainField";
import ErrorAlert from "../../components/elements/ErrorAlert";

// Schema for yup
const validationSchema = Yup.object().shape({
  tokenName: Yup.string().required("*Name is required"),
  tokenQty: Yup.number().required("*Q-ty is required"),
  chainName: Yup.string().required("*Chain is required"),
  clientName: Yup.string().required("*Client is required"),
  userId: Yup.string().required("*Id is required"),
});

export default function WalletNew() {
  const [error, setError] = useState();
  const [tokens, setTokens] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { isLoading: isLoading3, error: error3, tokensAddress } = useWeb3();

  //Card name
  let cardOptions = { cardName: "Wallet" };

  let address = "0x7ceb9f4A9e215C34979707eB73306Ba7E93E333A";
  useEffect(() => {
    const getTokens = async (address) => {
      try {
        const tokenList = await tokensAddress(address);
        setTokens(tokenList);
      } catch (error) {}
    };
    getTokens(address);
    return () => {};
  }, [tokensAddress, address]);

  if (!isLoading3 && !error3 && tokens) {
    // console.log(tokens);
  }

  //get Settings
  async function fetchSetting() {
    return await axios({
      url: process.env.REACT_APP_BACKEND_URL + "/setting",
      method: "get",
      headers: { Authorization: "Bearer " + auth.token },
    });
  }
  const {
    isLoading: isLoadingSettings,
    data: loadedSettings,
    error: errorSetting,
  } = useQuery("getSettings", fetchSetting);
  errorSetting && setError(errorSetting);

  let allLoaded = !isLoadingSettings && loadedSettings && !errorSetting;
  let defaultClient;
  if (allLoaded) {
    //Default client

    loadedSettings.data.settings.forEach((s) => {
      if (s.description === "Default client") {
        defaultClient = s.value;
      }
    });
  }

  //Formik initialValues
  const initialValues = {
    tokenName: "",
    tokenQty: "",
    chainName: "",
    isInvestment: false,
    inFarming: false,
    comments: "",
    clientName: defaultClient ? defaultClient : "",
    userId: auth.userId,
  };

  //Formik submit
  const onSubmit = (values) => {
    axios({
      url:
        process.env.REACT_APP_BACKEND_URL +
        `/${cardOptions.cardName.toLowerCase()}`,
      method: "post",
      data: JSON.stringify({
        tokenName: values.tokenName,
        tokenQty: values.tokenQty,
        chainName: values.chainName,
        isInvestment: values.isInvestment,
        inFarming: values.inFarming,
        imported: "",
        comments: values.comments,
        clientName: values.clientName,
        userId: values.userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    })
      .then(() => navigate(`../${cardOptions.cardName.toLowerCase()}s`))
      .catch((err) => console.log(err));
  };
  function clearError() {
    setError(null);
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoadingSettings && <Spinner animation="border" />}
      {allLoaded ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <TokenField name="tokenName" label="Token:" />
                  <SumField name="tokenQty" label="Quantity:" />
                </Row>

                <Row className="mb-3">
                  <ChainField name="chainName" label="Chain:" />
                  <ClientField name="clientName" label="Client:" />
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Investment:</Form.Label>
                    <Form.Check
                      type="checkbox"
                      name="isInvestment"
                      onChange={(e) => {
                        handleChange(e);
                        setIsChecked(!isChecked);
                      }}
                      onBlur={handleBlur}
                      value={values.isInvestment}
                    />
                    {
                      <div style={{ color: "grey", fontSize: "12px" }}>
                        If checked, will not be added to Comparing.
                      </div>
                    }
                  </Form.Group>
                  {isChecked && (
                    <Form.Group as={Col}>
                      <Form.Label>In farming:</Form.Label>
                      <Form.Check
                        type="checkbox"
                        name="inFarming"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.inFarming}
                      />
                      {
                        <div style={{ color: "grey", fontSize: "12px" }}>
                          If checked, will be excluded from Wallet in Balance.
                        </div>
                      }
                    </Form.Group>
                  )}
                </Row>
                <Row className="mb-3">
                  <CommentField name="comments" label="Comments:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner animation="border" />
      )}
    </>
  );
}
