import React from "react";
import { Form, Col } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";

const CustomInputComponent = (props) => (
  <Form.Control type="date" disabled={props.disabled} {...props} />
);
function DateField(props) {
  return (
    <Form.Group as={Col} controlId="formDate">
      <Form.Label>{props.label} </Form.Label>
      <Field
        as={CustomInputComponent}
        name={props.name}
        disabled={props.disabled ? true : false}
      />

      <ErrorMessage name={props.name}>
        {(msg) => <div style={{ color: "red", fontSize: "13px" }}>{msg}</div>}
      </ErrorMessage>
    </Form.Group>
  );
}

export default DateField;
