import React, { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import { Spinner, Card, Button, Row } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import ClientField from "../../components/elements/form/ClientField";
import { NumberWithCommas } from "../../components/table/FormatedCell";
import { useGetData } from "../../components/hooks/dbase-hook";

function Documents() {
  const [error, setError] = useState();
  const [report, setReport] = useState([]);
  const auth = useContext(AuthContext);

  //Card options
  let cardOptions = { cardName: "Document" };
  let path = `/${cardOptions.cardName.toLowerCase()}`;

  const clearError = () => {
    setError(null);
  };
  //getDocs
  //functions for update Query
  const onSuccessGet = () => {};
  const onErrorGet = () => {
    setError(requestError);
    return <ErrorAlert error={error} onClear={clearError} />;
  };
  const onSettledGet = () => {};

  const getOptions = {
    path: path,
    token: auth.token,
  };

  const {
    isLoading,
    data,
    error: requestError,
  } = useGetData(onSuccessGet, onErrorGet, onSettledGet, getOptions);

  let totalReport;
  if (data) {
    totalReport = data.data.documents.sort((a, b) =>
      a.total < b.total ? 1 : b.total < a.total ? -1 : 0
    );
  }

  useEffect(() => {
    if (totalReport) {
      setReport(totalReport);
    }

    return () => {};
  }, [totalReport]);
  //Calculations

  //Formik submit
  const getReport = (values) => {
    let reportUser = [];

    let reportByUser = { email: values.email };
    reportUser.push(reportByUser);

    reportUser.forEach((r) => {
      totalReport.forEach((t) => {
        if (r.email === t.email) {
          r.wallets = t.wallets;
        }
      });
    });

    setReport(reportUser);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "User",
        accessor: "email",
      },
      {
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        Cell: ({ row }) => (
          <div
            style={{
              fontWeight: "bold",
            }}
          >{`${NumberWithCommas(row.values.total)}`}</div>
        ),
      },
      {
        Header: "Wallet",
        accessor: "wallets",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.wallets)}`}</div>
        ),
      },
      {
        Header: "CEX",
        accessor: "cex",
        disableFilters: true,
        Cell: ({ row }) => <div>{`${NumberWithCommas(row.values.cex)}`}</div>,
      },
      {
        Header: "Project",
        accessor: "project",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.project)}`}</div>
        ),
      },
      {
        Header: "Farming",
        accessor: "farming",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.farming)}`}</div>
        ),
      },
      {
        Header: "Lending",
        accessor: "lending",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.lending)}`}</div>
        ),
      },
      {
        Header: "Comparing",
        accessor: "comparing",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.comparing)}`}</div>
        ),
      },
      {
        Header: "Harvest",
        accessor: "harvest",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.harvest)}`}</div>
        ),
      },
      {
        Header: "Incomes",
        accessor: "incomes",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.incomes)}`}</div>
        ),
      },
      {
        Header: "Bonuses",
        accessor: "bonuses",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.bonuses)}`}</div>
        ),
      },
      {
        Header: "Clients",
        accessor: "clients",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.clients)}`}</div>
        ),
      },
      {
        Header: "Managers",
        accessor: "managers",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>{`${NumberWithCommas(row.values.managers)}`}</div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading && report && data && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <Formik
                initialValues={{
                  date: new Date().toISOString().split("T")[0],
                  client: "",
                }}
                onSubmit={getReport}
              >
                {({ handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit} className="mx-auto w-25">
                    <div className="fs-4 mb-1">{cardOptions.cardName}</div>
                    <hr />
                    <Row className="mb-3">
                      <ClientField name="user" label="User:" />
                    </Row>
                    <Row className="mb-3">
                      <div>
                        <Button
                          className="btn btn-sm btn-success"
                          type="submit"
                        >
                          Report
                        </Button>
                      </div>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={report}
              //   getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Documents;
