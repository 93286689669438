import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Spinner, Col, Card } from "react-bootstrap";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CommentField from "../../components/elements/form/CommentField";
import DateField from "../../components/elements/form/DateField";

// Schema for yup
const validationSchema = Yup.object().shape({
  planName: Yup.string().required("*Plan is required"),
  subType: Yup.string().required("*Type of subscription is required"),
  cryptoAddress: Yup.string().required("*Address is required"),
  chainName: Yup.string().required("*Chain is required"),
});

const UpdateSubs = () => {
  const [subs, setSubs] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Subscription" };

  useEffect(() => {
    let unmounted = false;

    const fetchSubscriptions = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/subscription/admin",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setSubs(responseData.subscriptions);
      } catch (err) {}
    };
    fetchSubscriptions();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  //Calculations
  let initialValues, date;

  if (!isLoading && subs) {
    subs.forEach((s) => {
      if (s.id === Id) {
        date = s.date;
        initialValues = {
          planName: s.planName,
          subType: s.subType,
          cryptoAddress: s.cryptoAddress,
          chainName: s.chainName,
          validTill: s.validTill.split("T")[0],
          subStatus: s.subStatus,
          sum: s.sum,
          comments: s.comments,
          userId: s.userId,
        };
      }
    });
  }

  return (
    <>
      {error && <ErrorAlert error={error} onClear={clearError} />}
      {isLoading ? <Spinner animation="border" /> : null}
      {!isLoading && subs && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/subscription/admin/${Id}`,
                "PATCH",
                JSON.stringify({
                  date: date,
                  planName: values.planName,
                  subType: values.subType,
                  cryptoAddress: values.cryptoAddress,
                  chainName: values.chainName,
                  validTill: new Date(values.validTill),
                  subStatus: values.subStatus,
                  sum: values.sum,
                  comments: values.comments,
                  userId: values.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.log(err);
            }
            navigate("../subscriptions/admin");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <Card>
                <Card.Header className="text-center fs-3">
                  {cardOptions.cardName}
                </Card.Header>
                <Card.Body className="text-center">
                  <Row className="mb-4">
                    <Form.Group as={Col}>
                      <Form.Label>Plan:</Form.Label>
                      <Field as={Form.Select} name="planName">
                        <option value="Base">Base</option>
                        <option value="Pro">Pro</option>
                        <option value="Enterprise">Enterprise</option>
                        <option value="Trial">Trial</option>
                      </Field>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Type of subscription:</Form.Label>
                      <Field as={Form.Select} name="subType">
                        <option value="Yearly">Yearly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="3_Days">3_Days</option>
                      </Field>
                    </Form.Group>
                  </Row>
                  <Row className="mb-2">
                    <DateField name="validTill" label="Valid till:" />
                    <Form.Group as={Col}>
                      <Form.Label>Status:</Form.Label>
                      <Field as={Form.Select} name="subStatus">
                        <option value="Active">Active</option>
                        <option value="Expire soon">Expire Soon</option>
                        <option value="Expired">Expired</option>
                        <option value="Pending...">Pending...</option>
                      </Field>
                    </Form.Group>
                  </Row>
                  <Row className="mb-2">
                    <CommentField name="comments" label="Add your comments:" />
                  </Row>
                </Card.Body>
                <Card.Footer className="text-center">
                  <button type="submit" className="btn btn-success me-1 ">
                    Save
                  </button>

                  <Link to="../subscriptions/admin">
                    <button type="button" className="btn btn-secondary">
                      Cancel
                    </button>
                  </Link>
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UpdateSubs;
