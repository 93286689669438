import React, { useContext } from "react";
import { Card, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import ButtonsNew from "./elements/form/ButtonsNew";
import ButtonsUpdate from "./elements/form/ButtonsUpdate";
import { AuthContext } from "../components/context/auth-context";
import { useData } from "../components/hooks/dbase-hook";

function CardNewUpdate(props) {
  const Id = useParams().id;
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { cardNew, cardName, ...rest } = props;

  let path = `../${cardName.toLowerCase()}s`;
  let pathId = `/${cardName.toLowerCase()}/${Id}`;

  //functions for update Query
  const onSuccess = () => {
    navigate(`../${cardName.toLowerCase()}s`);
  };
  const onError = () => {
    return <Alert>{error.message}</Alert>;
  };
  const onSettled = () => {};

  //update Query
  const { mutate: queryRequest, error } = useData(
    onSuccess,
    onError,
    onSettled
  );

  //Delete handler
  const deleteHandler = () => {
    let requestOptions = { path: pathId, method: "delete", token: auth.token };
    queryRequest(requestOptions);
  };

  //Cancel handler

  const cancelHandler = () => {
    navigate(path);
  };
  return (
    <Card
      className="text-center card-center shadow scroll"
      // bg="dark"
      // text="white"
      {...rest}
    >
      <Card.Header>
        {cardNew ? `New ${cardName}` : `Update ${cardName}`}
      </Card.Header>
      <Card.Body>{props.children}</Card.Body>
      <Card.Footer>
        {cardNew ? (
          <ButtonsNew link={path} />
        ) : (
          <ButtonsUpdate
            link={path}
            onCancel={cancelHandler}
            onDelete={deleteHandler}
            buttonDisabled={props.isbuttondisabled}
          />
        )}
      </Card.Footer>
    </Card>
  );
}

export default CardNewUpdate;
