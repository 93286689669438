import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { useHttpClient } from "../../components/hooks/http-hook";

const ALERTDIV = styled.div`
  width: 40%;
  margin: 5em auto;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  font-family: "Roboto Mono", monospace;
  src: url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap");
`;

const CONTAINER = styled.div`
  background: #f7f9fa;
  height: auto;
  width: 95%;
  margin: 1em auto;
  color: snow;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  font-family: "Roboto Mono", monospace;
  src: url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap");

  @media (min-width: 786px) {
    width: 80%;
  }

  label {
    color: #292929;
    font-size: 1.2em;
    font-weight: 400;
    margin-top: 1rem;
  }

  h1 {
    color: #292929;
    padding-top: 0.5em;
  }

  .form-group {
    margin-bottom: 2.5em;
  }

  .error {
    border: 2px solid #ff6565;
  }

  .error-message {
    color: #ff6565;
    padding: 0.5em 0.2em;
    height: 1em;
    margin-bottom: 1em;
    font-size: 0.8em;
  }
  .message {
    color: grey;
    padding: 0.5em 0.2em;
    height: 1em;
    margin-bottom: 1em;
    font-size: 0.8em;
  }
`;

const MYFORM = styled(Form)`
  width: 95%;
  text-align: left;
  padding-top: 2em;
  padding-bottom: 2em;
  font-size: 15px;

  @media (min-width: 786px) {
    width: 80%;
  }
`;

const BUTTON = styled(Button)`
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: #cee5d0;
  padding: 0.3rem 0.5rem;
  font: inherit;
  font-size: 1.2em;
  font-weight: 400;
  margin-top: 1rem;

  &:hover {
    background: #f8df00;
    color: #292929;
  }
  &:disabled {
    background: grey;
    color: #292929;
  }
`;

// Schema for yup
const validationSchema = Yup.object().shape({
  managerName: Yup.string().required("*Manager is required"),
  bonusDay: Yup.number().required("*Bonus is required"),
  incomeDay: Yup.number().required("*Income is required"),
});

const NewBonus = () => {
  const [loadedManagers, setLoadedManagers] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    let unmounted = false;

    const fetchManagers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/manager",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.managers.sort((a, b) =>
          a.managerName > b.managerName
            ? 1
            : b.managerName > a.managerName
            ? -1
            : 0
        );

        if (!unmounted) setLoadedManagers(responseData.managers);
      } catch (err) {}
    };
    fetchManagers();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  //Calculations

  return (
    <React.Fragment>
      <ALERTDIV>
        <ErrorAlert error={error} onClear={clearError} />
      </ALERTDIV>
      {!isLoading && loadedManagers && (
        <CONTAINER>
          {/* Sets initial values for form inputs */}
          <Formik
            initialValues={{
              date: new Date().toLocaleDateString("en-GB"),
              managerName: "",
              bonusDay: "",
              incomeDay: "",
              userId: auth.userId,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              try {
                await sendRequest(
                  process.env.REACT_APP_BACKEND_URL + `/bonus`,
                  "POST",
                  JSON.stringify({
                    date: new Date(),
                    managerName: values.managerName,
                    bonusDay: values.bonusDay,
                    incomeDay: values.incomeDay,
                    userId: auth.userId,
                  }),
                  {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + auth.token,
                  }
                );
              } catch (err) {}
              navigate("../bonuses");
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <MYFORM onSubmit={handleSubmit} className="mx-auto">
                {isLoading && (
                  <div className="center">
                    <LoadingSpinner asOverlay />
                  </div>
                )}
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="formDate">
                    <Form.Label>Date: </Form.Label>
                    <Form.Control
                      type="text"
                      name="date"
                      disabled
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.date}
                      className={touched.date && errors.date ? "error" : null}
                    />
                    {touched.date && errors.date ? (
                      <div className="error-message">{errors.date}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} controlId="formManagerName">
                    <Form.Label>Manager:</Form.Label>
                    <Form.Select
                      type="text"
                      name="managerName"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.managerName}
                    >
                      <option>...choose manager</option>
                      {loadedManagers.map((name, index) => (
                        <option key={index} value={name.managerName}>
                          {name.managerName}
                        </option>
                      ))}
                    </Form.Select>
                    {touched.managerName && errors.managerName ? (
                      <div className="error-message">{errors.managerName}</div>
                    ) : null}
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <Form.Group as={Col} controlId="formIncomeDay">
                    <Form.Label>Income Day: </Form.Label>
                    <Form.Control
                      type="number"
                      name="incomeDay"
                      placeholder="... add incomeDay"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.incomeDay}
                      className={
                        touched.incomeDay && errors.incomeDay ? "error" : null
                      }
                    />
                    {touched.incomeDay && errors.incomeDay ? (
                      <div className="error-message">{errors.incomeDay}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} controlId="formBonusDay">
                    <Form.Label>Bonus Day: </Form.Label>
                    <Form.Control
                      type="number"
                      name="bonusDay"
                      placeholder="... add bonusDay"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bonusDay}
                      className={
                        touched.bonusDay && errors.bonusDay ? "error" : null
                      }
                    />
                    {touched.bonusDay && errors.bonusDay ? (
                      <div className="error-message">{errors.bonusDay}</div>
                    ) : null}
                  </Form.Group>
                </Row>

                <Form.Group as={Row} controlId="formUserId">
                  {/* <Form.Label>User Id :</Form.Label> */}
                  <Form.Control
                    type="hidden"
                    name="userId"
                    placeholder="userId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userId}
                  />
                </Form.Group>

                <BUTTON variant="primary" type="submit" disabled={isLoading}>
                  Save
                </BUTTON>
                <Link to="../bonuses">
                  <BUTTON className="button-second" type="button">
                    Cancel
                  </BUTTON>
                </Link>
              </MYFORM>
            )}
          </Formik>
        </CONTAINER>
      )}
    </React.Fragment>
  );
};

export default NewBonus;
