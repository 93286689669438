import React, { useContext, useState } from "react";
import { Spinner, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";

import { AuthContext } from "../../components/context/auth-context";
import DateField from "../../components/elements/form/DateField";
import ClientField from "../../components/elements/form/ClientField";
import CommentField from "../../components/elements/form/CommentField";
import SumField from "../../components/elements/form/SumField";
import CardNewUpdate from "../../components/CardNewUpdate";
import ErrorAlert from "../../components/elements/ErrorAlert";

// Schema for yup
const validationSchema = Yup.object().shape({
  date: Yup.string().required("*Date is required"),
  clientName1: Yup.string().required("*Client1 is required"),
  clientName2: Yup.string().required("*Client2 is required"),
  sum: Yup.number().required("*Sum is required"),
});

function OffsetNew() {
  const [error, setError] = useState();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  let cardOptions = { cardName: "Offset" };
  //get Settings
  function fetchSetting() {
    return axios({
      url: process.env.REACT_APP_BACKEND_URL + "/setting",
      method: "get",
      headers: { Authorization: "Bearer " + auth.token },
    });
  }
  const {
    isLoading: isLoadingSettings,
    data: loadedSettings,
    error: errorSetting,
  } = useQuery("getSettings", fetchSetting);
  errorSetting && setError(errorSetting);

  //Formik initialValues
  const initialValues = {
    date: new Date().toISOString().split("T")[0],
    clientName1: "",
    clientName2: "",
    sum: "",
    comments: "",
    userId: auth.userId,
  };

  //Formik submit
  const onSubmit = (values) => {
    axios({
      url:
        process.env.REACT_APP_BACKEND_URL +
        `/${cardOptions.cardName.toLowerCase()}`,
      method: "post",
      data: JSON.stringify({
        date: new Date(values.date),
        clientName1: values.clientName1,
        clientName2: values.clientName2,
        sum: values.sum,
        comments: values.comments,
        userId: auth.userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    })
      .then(() => navigate(`../${cardOptions.cardName.toLowerCase()}s`))
      .catch((err) => console.log(err));
  };
  function clearError() {
    setError(null);
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {!isLoadingSettings && !errorSetting && loadedSettings ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <DateField name="date" label="Date:" />
                  <SumField name="sum" label="Sum:" />
                </Row>

                <Row className="mb-2">
                  <ClientField name="clientName1" label="Client1+:" />
                  <ClientField name="clientName2" label="Client2-:" />
                </Row>
                <Row className="mb-2">
                  <CommentField name="comments" label="Comments:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner animation="border" />
      )}
    </>
  );
}

export default OffsetNew;
