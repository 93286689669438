import { useState, useEffect, useCallback } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userType, setUserType] = useState(null);
  const [planName, setPlanName] = useState(null);
  const [subType, setSubType] = useState(null);
  const [verified, setVerified] = useState(null);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback(
    (
      uid,
      userEmail,
      userType,
      planName,
      subType,
      verified,
      token,
      expirationDate
    ) => {
      setToken(token);
      setUserId(uid);
      setUserEmail(userEmail);
      setUserType(userType);
      setPlanName(planName);
      setSubType(subType);
      setVerified(verified);

      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 10);
      setTokenExpirationDate(tokenExpirationDate);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          token: token,
          userEmail: userEmail,
          userType: userType,
          planName: planName,
          subType: subType,
          verified: verified,
          expiration: tokenExpirationDate.toISOString(),
        })
      );
    },
    []
  );
  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setUserEmail(null);
    setUserType(null);
    setPlanName(null);
    setSubType(null);
    setVerified(null);
    setTokenExpirationDate(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.userEmail,
        storedData.userType,
        storedData.planName,
        storedData.subType,
        storedData.verified,
        storedData.token,
        new Date(storedData.expiration)
      );
    }
  }, [login]);
  return {
    userId,
    userEmail,
    userType,
    planName,
    subType,
    verified,
    token,
    login,
    logout,
  };
};
