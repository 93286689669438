import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Spinner, Col } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import ClientField from "../../components/elements/form/ClientField";
import CardNewUpdate from "../../components/CardNewUpdate";
import CommentField from "../../components/elements/form/CommentField";
import CheckField from "../../components/elements/form/CheckField";

// Schema for yup
const validationSchema = Yup.object().shape({
  publicAPI: Yup.string().required("*API is required"),
  keyAPI: Yup.string().required("*API is required"),
  exchangeName: Yup.string().required("*Exchange name is required"),
  clientName: Yup.string().required("*Client is required"),
});

const NewICex = () => {
  const [exchanges, setExchanges] = useState();
  const [settings, setSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Cex-import" };

  useEffect(() => {
    let unmounted = false;
    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };
    if (!unmounted) {
      fetch("/setting", (data) => setSettings(data.settings));
      fetch("/exchange", (data) => setExchanges(data.exchanges));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  let allloaded = !isLoading && settings && exchanges;

  let defaultClient;
  if (allloaded) {
    settings.forEach((s) => {
      if (s.description === "Default client") {
        defaultClient = s.value;
      }
    });
  }

  //Formik initialValues
  const initialValues = {
    publicAPI: "",
    keyAPI: "",
    password: "",
    exchangeName: "",
    spot: false,
    futures: false,
    comments: "",
    clientName: defaultClient ? defaultClient : "",
    userId: auth.userId,
  };

  //submit cex
  const onSubmit = async (values) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/cex-import`,
        "POST",
        JSON.stringify({
          publicAPI: values.publicAPI,
          keyAPI: values.keyAPI,
          password: values.password,
          exchangeName: values.exchangeName,
          spot: values.spot,
          futures: values.futures,
          comments: values.comments,
          clientName: values.clientName,
          userId: auth.userId,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      navigate("../cex-imports");
    } catch (err) {}
  };
  const Info = (values) => {
    if (values.exchangeName === "FTX") {
      return (
        <Row className="mb-3">
          <div className="text-danger mt-2 ">
            Import from FTX is possible only from Main wallet. <br />
            FTX don't have separated Spot and Perpetual wallets. <br />
            All tokens will be imported as collaterals to Perpetual wallet.
          </div>
        </Row>
      );
    } else if (values.exchangeName === "Crypto.com") {
      return (
        <Row className="mb-3">
          <div className="text-danger mt-2 ">
            Import in Crypto.com is possible only from Spot wallet. <br />
            Crypto.com don't have API for Perpetual wallet and positions.
          </div>
        </Row>
      );
    } else {
      return (
        <Row className="mb-3">
          <CheckField name="spot" label="Import Spot:" checked={values.spot} />
          <CheckField
            name="futures"
            label="Import Futures:"
            checked={values.futures}
            // disabled
          />
        </Row>
      );
    }
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allloaded && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Exchange:</Form.Label>
                    <Field as={Form.Select} name="exchangeName">
                      <option value="">...choose exchange</option>
                      <option value="Binance">Binance</option>
                      <option value="Bybit">Bybit</option>
                      <option value="Crypto.com">Crypto.com</option>
                      <option value="FTX">FTX</option>
                      <option value="OKX">OKX</option>
                    </Field>
                  </Form.Group>

                  <ClientField name="clientName" label="Client:" />
                </Row>

                <Row className="mb-3">
                  <CommentField name="publicAPI" label="API Key:" />
                  <CommentField name="keyAPI" label="Secret Key:" />
                </Row>
                <Row className="mb-3">
                  {values.exchangeName === "OKX" ? (
                    <CommentField name="password" label="Password:" />
                  ) : null}
                  <CommentField name="comments" label="Comments:" />
                </Row>
                {Info(values)}
                {/* {values.exchangeName === "FTX" ? (
                  <>
                    <div className="text-danger mt-2 ">
                      Import from FTX is possible only from Main wallet. <br />
                      FTX don't have separated Spot and Futures wallets. <br />
                      All token will be imported as collaterals in Futures
                      wallet.
                    </div>
                    <br />
                  </>
                ) : (
                  <Row className="mb-3">
                    <CheckField
                      name="spot"
                      label="Import Spot:"
                      checked={values.spot}
                    />
                    <CheckField
                      name="futures"
                      label="Import Futures:"
                      checked={values.futures}
                      // disabled
                    />
                  </Row>
                )} */}
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewICex;
