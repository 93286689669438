import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Spinner, Card, Button, Row } from "react-bootstrap";
import { Formik, Form } from "formik";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import ClientField from "../../components/elements/form/ClientField";
//import DateField from "../../components/elements/form/DateField";
import { ReactTable } from "../../components/TableSearch";

export default function InvestorReport() {
  const [investors, setInvestors] = useState();
  const [loadedClients, setLoadedClients] = useState();
  const [report, setReport] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  //Card options
  let cardOptions = { cardName: "Investor report" };

  useEffect(() => {
    let unmounted = false;

    const fetchInvestors = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/investor",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.investors.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        if (!unmounted) setInvestors(responseData.investors);
      } catch (err) {}
    };
    fetchInvestors();

    const fetchClients = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/client",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedClients(responseData.clients);
      } catch (err) {}
    };
    fetchClients();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/investors`);
      },
    };
  };

  function numberWithCommas(number) {
    return Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columns = React.useMemo(
    () => [
      //   {
      //     Header: "Start Sum",
      //     accessor: "startSum",
      //     disableFilters: true,
      //     Cell: ({ row }) => (
      //       <div
      //         style={{
      //           textAlign: "right",
      //         }}
      //       >
      //         {row.values.startSum
      //           ? `${numberWithCommas(row.values.startSum)} $`
      //           : null}
      //       </div>
      //     ),
      //   },
      {
        Header: "Data",
        accessor: "date",
        Cell: ({ row }) =>
          row.values.date
            ? new Date(row.values.date).toLocaleDateString("en-GB")
            : null,
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Flow",
        accessor: "sum",
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {row.values.sum ? `${numberWithCommas(row.values.sum)} $` : "Total"}
          </div>
        ),
      },
      {
        Header: "Inflow",
        accessor: "inflow",
        disableFilters: true,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {row.values.inflow
              ? `${numberWithCommas(row.values.inflow)} $`
              : null}
          </div>
        ),
      },
      {
        Header: "Outflow",
        accessor: "outflow",
        disableFilters: true,
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {row.values.outflow
              ? `${numberWithCommas(row.values.outflow)} $`
              : null}
          </div>
        ),
      },
      {
        Header: "End Sum",
        accessor: "endSum",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>
            {row.values.endSum
              ? `${numberWithCommas(row.values.endSum)} $`
              : null}
          </div>
        ),
      },
    ],
    []
  );

  //Formik submit

  const onSubmit = (values) => {
    // if (!isLoading && investors && loadedClients) {
    let filterInvestor = [];
    filterInvestor = investors.filter((e) => {
      return e.clientName === values.clientName;
    });

    let startSum = 0;
    let inflow =
      _.sumBy(filterInvestor, (w) => {
        if (w.sum > 0 && w.clientName === values.clientName) {
          return w.sum;
        }
      }) || 0;
    let outflow =
      _.sumBy(filterInvestor, (w) => {
        if (w.sum < 0 && w.clientName === values.clientName) {
          return w.sum;
        }
      }) || 0;
    let endSum = startSum + inflow + outflow;

    filterInvestor.unshift({
      startSum: startSum,
      inflow: inflow,
      outflow: outflow,
      endSum: endSum,
    });

    setReport(filterInvestor);
    //}
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {isLoading && <Spinner animation="border" />}

      {!isLoading && loadedClients && investors && report && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <Formik
                initialValues={{
                  client: "",
                  dateStart: new Date().toISOString().split("T")[0],
                  dateEnd: new Date().toISOString().split("T")[0],
                }}
                onSubmit={onSubmit}
              >
                {({ handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit} className="mx-auto w-25">
                    <div className="fs-4 mb-1">{cardOptions.cardName}</div>
                    <hr />
                    <Row className="mb-3">
                      {/* <DateField name="dateStart" label="From:" />
                      <DateField name="dateEnd" label="To:" /> */}
                    </Row>
                    <Row className="mb-3">
                      <ClientField name="clientName" label="Investor:" />
                    </Row>
                    <div>
                      <Button className="btn btn-sm btn-success" type="submit">
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="">
                <Button
                  as={Link}
                  to={`../investors`}
                  className="btn btn-sm btn-secondary"
                >
                  Back
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={report}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}
