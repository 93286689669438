import React, { useContext } from "react";
import { Form, Button, Card, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";

import { AuthContext } from "../components/context/auth-context";
import ErrorAlert from "../components/elements/ErrorAlert";
import { useHttpClient } from "../components/hooks/http-hook";

// Schema for yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  password: Yup.string()
    .required("*Password is reqired.")
    .min(5, "*Please enter valid password, at least 5 characters."),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  userType: Yup.string(),
});

const Signup = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  // const errorHandler = () => {
  //   setError(null);
  // };

  return (
    <React.Fragment>
      {error && <ErrorAlert error={error} onClear={clearError} />}
      {/* Sets initial values for form inputs */}
      {isLoading && <Spinner animation="border" />}
      <Formik
        initialValues={{
          email: "",
          password: "",
          passwordConfirmation: "",
          userType: "user",
          planName: "Trial",
          subType: "3_Days",
          verified: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled

          try {
            const responseData = await sendRequest(
              process.env.REACT_APP_BACKEND_URL + "/user/signup",
              "POST",
              JSON.stringify({
                email: values.email,
                password: values.password,
                userType: values.userType,
                planName: values.planName,
                subType: values.subType,
                verified: values.verified,
              }),
              {
                "Content-Type": "application/json",
              }
            );

            auth.login(
              responseData.userId,
              responseData.userEmail,
              responseData.userType,
              responseData.planName,
              responseData.subType,
              responseData.verified,
              responseData.token
            );
            navigate("../login");
          } catch (err) {
            // setError(err.message || "Something went wrong, please try again.");
          }
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="mx-auto w-25 "
            style={{ minWidth: "450px" }}
          >
            <Card className="text-center card-center shadow">
              <Card.Header>SIGNUP FORM</Card.Header>
              <Card.Body>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email :</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={touched.email && errors.email ? "error" : null}
                  />
                  {touched.email && errors.email ? (
                    <div className="text-danger">{errors.email}</div>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="formPassword">
                  <Form.Label>Password :</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    className={
                      touched.password && errors.password ? "error" : null
                    }
                  />
                  {touched.password && errors.password ? (
                    <div className="text-danger">{errors.password}</div>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="formPasswordConfirmation">
                  <Form.Label>Confirm password :</Form.Label>
                  <Form.Control
                    type="password"
                    name="passwordConfirmation"
                    placeholder="repeat password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirmation}
                    className={
                      touched.passwordConfirmation &&
                      errors.passwordConfirmation
                        ? "error"
                        : null
                    }
                  />
                  {touched.passwordConfirmation &&
                  errors.passwordConfirmation ? (
                    <div className="text-danger">
                      {errors.passwordConfirmation}
                    </div>
                  ) : null}
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Signup
                </Button>
                <hr />
                <p>
                  Back to <Link to="../login"> Login</Link>
                </p>
              </Card.Footer>
            </Card>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default Signup;
