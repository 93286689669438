import { useState, useCallback } from "react";
import { Alchemy, Network } from "alchemy-sdk";

const config = {
  apiKey: "EzxZMXHoP9CkmumKJqNfUozkezXneyWh",
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(config);

export const useWeb3 = () => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const tokensAddress = useCallback(async (address) => {
    try {
      // Get token balances
      const balances = await alchemy.core.getTokenBalances(address);

      // Remove tokens with zero balance
      const nonZeroBalances = balances.tokenBalances.filter((token) => {
        return (
          token.tokenBalance !==
          "0x0000000000000000000000000000000000000000000000000000000000000000"
        );
      });
      if (nonZeroBalances) {
        nonZeroBalances.forEach(async (token) => {
          // Get balance of token
          let balance = token.tokenBalance;

          // Get metadata of token
          const metadata = await alchemy.core.getTokenMetadata(
            token.contractAddress
          );

          // Compute token balance in human-readable format
          balance = balance / Math.pow(10, metadata.decimals);
          token.balance = +balance.toFixed(2);
          token.symbol = metadata.symbol;
          token.chainName = "Ethereum";
          delete token.error;
          delete token.contractAddress;
          delete token.tokenBalance;
          // Print name, balance, and symbol of token
          //   console.log(`${metadata.name} ${metadata.symbol}: ${balance} `);
        });
        let ethAmountRow = await alchemy.core.getBalance(address, "latest");

        let ethAmount = parseInt(ethAmountRow._hex, 16) / 1000000000000000000;
        let ethObject = {
          balance: ethAmount,
          chainName: "Ethereum",
          symbol: "ETH",
        };
        nonZeroBalances.unshift(ethObject);
        setIsLoading(false);
        return nonZeroBalances;
      }
    } catch (err) {
      setError(err.message || "Something went wrong, please try again.");
      setIsLoading(false);
    }
  }, []);

  return { isLoading, error, tokensAddress };
};
