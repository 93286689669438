import React, { useContext } from "react";
import { useQuery } from "react-query";
import { Form, Col, Spinner } from "react-bootstrap";
import axios from "axios";

import { AuthContext } from "../../context/auth-context.js";
import SelectInput from "../../elements/SelectField";

function ClientField(props) {
  const auth = useContext(AuthContext);

  //get clients
  function fetchClient() {
    return axios({
      url: process.env.REACT_APP_BACKEND_URL + "/client",
      method: "get",
      headers: { Authorization: "Bearer " + auth.token },
    });
  }

  const { isLoading: isLoadingClients, data: loadedClients } = useQuery(
    "getClients",
    fetchClient
  );
  //get settings
  function fetchSettings() {
    return axios({
      url: process.env.REACT_APP_BACKEND_URL + "/setting",
      method: "get",
      headers: { Authorization: "Bearer " + auth.token },
    });
  }

  const { isLoading: isLoadingSettings, data: loadedSettings } = useQuery(
    "getSettings",
    fetchSettings
  );

  if (isLoadingClients || isLoadingSettings) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  let clientList, clientOptions, defaultClient;
  if (
    !isLoadingClients &&
    loadedClients &&
    !isLoadingSettings &&
    loadedSettings
  ) {
    // Default client
    loadedSettings.data.settings.forEach((s) => {
      if (s.description === "Default client") {
        defaultClient = s.value;
      }
    });

    //Options in clients
    clientList = loadedClients.data.clients
      .map((clientName) => (clientName = clientName.clientName))
      .sort();
    if (clientList.length === 0) {
      clientList = [defaultClient];
    }
    clientOptions = clientList.map((e) => ({
      label: e,
      value: e,
    }));
  }

  return (
    <>
      {!isLoadingClients &&
      loadedClients &&
      !isLoadingSettings &&
      loadedSettings ? (
        <Form.Group as={Col}>
          <SelectInput
            type="text"
            label={props.label}
            name={props.name}
            options={clientOptions}
            defaultValue={defaultClient ? defaultClient : ""}
            disabled={props.disabled ? props.disabled : false}
          >
            {clientOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </SelectInput>
        </Form.Group>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default ClientField;
