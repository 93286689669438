import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Spinner, Col } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CardNewUpdate from "../../components/CardNewUpdate";
import TextField from "../../components/elements/form/TextField";
import { customFetch } from "../../components/Functions";

// Schema for yup
const validationSchema = Yup.object().shape({
  bridgeName: Yup.string().required("*Name is required"),
  link: Yup.string().required("*Link is required"),
});

const UpdateBridge = () => {
  const [bridge, setBridge] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Bridge" };

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      customFetch(sendRequest, auth, `/bridge/${Id}`, (data) =>
        setBridge(data.bridge)
      );
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth, Id]);

  let allloaded = !isLoading && bridge;

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading ? <Spinner animation="border" /> : null}

      {allloaded && (
        <Formik
          initialValues={{
            bridgeName: bridge.bridgeName,
            link: bridge.link,
            activated: bridge.activated,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/bridge/${Id}`,
                "PATCH",
                JSON.stringify({
                  bridgeName: values.bridgeName,
                  link: values.link,
                  activated: values.activated,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
              navigate("../bridges");
            } catch (err) {}
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <CardNewUpdate cardNew={false} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <TextField name="bridgeName" label="Bridge Name:" />
                </Row>
                <Row className="mb-3">
                  <TextField name="link" label="Link:" />
                </Row>
                {auth.userType === "admin" ? (
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Activated:</Form.Label>
                      <Field as={Form.Select} name="activated">
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </Field>
                    </Form.Group>
                  </Row>
                ) : null}
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UpdateBridge;
