import React, { useContext } from "react";
import { Form, Row, Spinner, Col, Card } from "react-bootstrap";
import { Field, Formik, useFormikContext, useField } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import TextField from "../../components/elements/form/TextField";
import CommentField from "../../components/elements/form/CommentField";

// Schema for yup
const validationSchema = Yup.object().shape({
  planName: Yup.string().required("*Plan is required"),
  subType: Yup.string().required("*Type of subscription is required"),
  cryptoAddress: Yup.string().required("*Address is required"),
  chainName: Yup.string().required("*Chain is required"),
});

const NewSubscriptionIn = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  //Card name
  let cardOptions = { cardName: "Subscription" };

  function myFunction() {
    /* Get the text field */
    let copyText = "0x10beFAc6E2D58B5786B75DF9c6ddD5b4FBB1E40E";

    /* Select the text field */
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText);

    // /* Alert the copied text */
    // alert("Copied the text: " + copyText);
  }

  const MyField = (props) => {
    const {
      values: { planName, subType },
      touched,
      setFieldValue,
    } = useFormikContext();
    const [field] = useField(props);

    let baseMonth = planName === "Base" && subType === "Monthly";
    let baseYear = planName === "Base" && subType === "Yearly";
    let proMonth = planName === "Pro" && subType === "Monthly";
    let proYear = planName === "Pro" && subType === "Yearly";
    let enterMonth = planName === "Enterprise" && subType === "Monthly";

    React.useEffect(() => {
      // set the value
      let touchedFields = touched.planName || touched.subType;

      if (baseMonth & touchedFields) {
        setFieldValue(props.name, 9);
      } else if (baseYear & touchedFields) {
        setFieldValue(props.name, 99);
      } else if (proMonth & touchedFields) {
        setFieldValue(props.name, 29);
      } else if (proYear & touchedFields) {
        setFieldValue(props.name, 299);
      } else if (enterMonth & touchedFields) {
        setFieldValue(props.name, 99);
      } else {
        setFieldValue(props.name, 999);
      }
    }, [
      baseMonth,
      baseYear,
      proMonth,
      proYear,
      enterMonth,
      touched,
      setFieldValue,
      props.name,
    ]);

    return (
      <>
        <input {...props} {...field} />
      </>
    );
  };

  return (
    <>
      {error && <ErrorAlert error={error} onClear={clearError} />}
      {isLoading ? <Spinner animation="border" /> : null}
      {!isLoading && (
        <Formik
          initialValues={{
            date: new Date(),
            planName: "",
            subType: "",
            cryptoAddress: "",
            chainName: "BSC",
            validTill: new Date(),
            subStatus: "Pending...",
            comments: "",
            sum: 0,
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/subscription`,
                "POST",
                JSON.stringify({
                  date: new Date(),
                  planName: values.planName,
                  subType: values.subType,
                  cryptoAddress: values.cryptoAddress,
                  chainName: values.chainName,
                  validTill: new Date(),
                  subStatus: values.subStatus,
                  sum: values.sum,
                  comments: values.comments,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.log(err);
            }
            navigate("../subscriptions");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <Card>
                <Card.Header className="text-center fs-3">
                  {cardOptions.cardName}
                </Card.Header>
                <Card.Body className="text-center">
                  <Row className="mb-4">
                    <Form.Group as={Col}>
                      <Form.Label>Plan:</Form.Label>
                      <Field as={Form.Select} name="planName">
                        <option>...choose plan</option>
                        <option value="Base">Base</option>
                        <option value="Pro">Pro</option>
                        <option value="Enterprise">Enterprise</option>
                      </Field>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Period of subscription:</Form.Label>
                      <Field as={Form.Select} name="subType">
                        <option>...choose period</option>
                        <option value="Yearly">Yearly</option>
                        <option value="Monthly">Monthly</option>
                      </Field>
                    </Form.Group>
                  </Row>
                  <Row className="mb-2">
                    <div className="text-center">
                      <h5 className="text-secondary mb-5">
                        Please, send{" "}
                        <MyField
                          style={{
                            fontSize: "18px",
                            color: "red",
                            width: "3rem",
                            textAlign: "center",
                          }}
                          name="sum"
                          disabled
                        />{" "}
                        USDT, USDC or BUSD to the address below:
                      </h5>
                    </div>
                  </Row>
                  <Row className="text-secondary mb-5">
                    <div>
                      Address: 0x10beFAc6E2D58B5786B75DF9c6ddD5b4FBB1E40E
                      <button
                        type="button"
                        onClick={myFunction}
                        className="btn  btn-sm btn-secondary ms-3"
                      >
                        Copy
                      </button>
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <Form.Group as={Col}>
                      <Form.Label>Payment using Chain:</Form.Label>
                      <Field as={Form.Select} name="chainName">
                        <option value="Ethereum">Ethereum</option>
                        <option value="BSC">BSC</option>
                        <option value="Polygon">Polygon</option>
                        <option value="Avalanche">Avalanche</option>
                      </Field>
                    </Form.Group>
                    <TextField
                      name="cryptoAddress"
                      label="Add your cryptoAddress:"
                    />
                  </Row>
                  <Row className="mb-2">
                    <CommentField name="comments" label="Add your comments:" />
                    <div className="text-danger">
                      Please note, if payment didn't come in 24 hours, this
                      subscription will be deleted.
                    </div>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-center">
                  <button type="submit" className="btn btn-success me-1 ">
                    Subscribe
                  </button>
                  <Link to="../subscriptions">
                    <button type="button" className="btn btn-secondary">
                      Cancel
                    </button>
                  </Link>
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewSubscriptionIn;
