import React from "react";
import { Button } from "react-bootstrap";
import { Formik } from "formik";

function ButtonsUpdate(props) {
  const { isSubmitting } = Formik;
  return (
    <>
      <Button
        variant="success"
        type="submit"
        disabled={isSubmitting}
        className="me-3"
      >
        Save
      </Button>
      {/* <Link to={props.link}>
        <Button variant="outline-danger" onClick={props.onClick}>
          Delete
        </Button>
      </Link>{" "} */}
      <Button
        variant="outline-danger"
        className="me-3"
        onClick={props.onDelete}
        disabled={props.buttonDisabled ? true : false}
      >
        Delete
      </Button>
      <Button variant="outline-secondary" onClick={props.onCancel}>
        Cancel
      </Button>
    </>
  );
}

export default ButtonsUpdate;
