import React from "react";
import "../pages/Features.css";
import { Link } from "react-router-dom";
import { Icons } from "../components/navbar/Icons";

const Features = () => {
  return (
    <div className="container px-4 py-5" id="icon-grid">
      <h2 className="pb-2 border-bottom">Main features:</h2>

      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
        <div className="col d-flex align-items-start">
          <svg
            className="bi text-muted flex-shrink-0 me-3"
            width="1.75em"
            height="1.75em"
          >
            {Icons.wallet}
          </svg>
          <div>
            <h4 className="fw-bold mb-0">Wallet</h4>
            <p>
              Track your wallets in any chains. CB upbates prices every minute.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <svg
            className="bi text-muted flex-shrink-0 me-3"
            width="1.75em"
            height="1.75em"
          >
            {Icons.exchange}
          </svg>
          <div>
            <h4 className="fw-bold mb-0">Exchanges</h4>
            <p>
              Track your Cex's positions. CB bot sends warning before
              liquidations.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <svg
            className="bi text-muted flex-shrink-0 me-3"
            width="1.75em"
            height="1.75em"
          >
            {Icons.balances}
          </svg>
          <div>
            <h4 className="fw-bold mb-0">Balance</h4>
            <p>Track your Total by date like Wallets, Cex's, DeFi</p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <svg
            className="bi text-muted flex-shrink-0 me-3"
            width="1.75em"
            height="1.75em"
          >
            {Icons.investors}
          </svg>
          <div>
            <h4 className="fw-bold mb-0">Investors, Clients, Managers</h4>
            <p>Track your settlement with investors, clients, managers.</p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <svg
            className="bi text-muted flex-shrink-0 me-3"
            width="1.75em"
            height="1.75em"
          >
            {Icons.project}
          </svg>
          <div>
            <h4 className="fw-bold mb-0">DeFi: Projects</h4>
            <p>Track your DeFi protocols. Use direct link to the protocol.</p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <svg
            className="bi text-muted flex-shrink-0 me-3"
            width="1.75em"
            height="1.75em"
          >
            {Icons.farming}
          </svg>
          <div>
            <h4 className="fw-bold mb-0">DeFi: Positions</h4>
            <p>
              Track your DeFi positions. SB shows Imparmanent Loss in current
              position. CB bot sends IL warning.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <svg
            className="bi text-muted flex-shrink-0 me-3"
            width="1.75em"
            height="1.75em"
          >
            {Icons.lending}
          </svg>
          <div>
            <h4 className="fw-bold mb-0">DeFi: Lending</h4>
            <p>
              Track your Lending positions. CB bot sends warning before
              liquidation.
            </p>
          </div>
        </div>
        <div className="col d-flex align-items-start">
          <svg
            className="bi text-muted flex-shrink-0 me-3"
            width="1.75em"
            height="1.75em"
          >
            {Icons.comparing}
          </svg>
          <div>
            <h4 className="fw-bold mb-0">DeFi: Comparing</h4>
            <p>
              If you are using hedging strategy, CB compares your tokens in
              position and exchange.
            </p>
          </div>
        </div>
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
        <Link to="../pricing">
          <button
            type="button"
            className="btn btn-primary btn-lg px-4 me-md-2 fw-bold"
          >
            Pricing
          </button>
        </Link>
        <Link to="../signup">
          <button
            type="button"
            className="btn btn-outline-secondary btn-lg px-4"
          >
            Signup
          </button>
        </Link>
      </div>
      <footer className="pt-4 my-md-5 pt-md-5 border-top">
        <div className="row">
          <div className="col-12 col-md">
            {/* logo */}
            <small className="d-block mb-3 text-muted">&copy; 2020–2022</small>
          </div>
          {/* <div className="col-6 col-md">
            <h5>Features</h5>
            <ul className="list-unstyled text-small">
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Cool stuff
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Random feature
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Team feature
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Stuff for developers
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Another one
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Last time
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Resources</h5>
            <ul className="list-unstyled text-small">
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Resource
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Resource name
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Another resource
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Final resource
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>About</h5>
            <ul className="list-unstyled text-small">
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Team
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Locations
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Privacy
                </a>
              </li>
              <li className="mb-1">
                <a className="link-secondary text-decoration-none" href="#">
                  Terms
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </footer>
    </div>
  );
};

export default Features;
