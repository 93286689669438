import React from "react";

//Setting
let greenBack = "#A3DA8D";
let yellowBack = "#FFF1BD";
let redBack = "#FF6363";
let redBackLight = "#FF8C8C";

//style function
const cellStyle = (colorBack) => {
  if (colorBack === redBack || colorBack === redBackLight) {
    return {
      color: "#FFF8F3",
      textAlign: "center",
      padding: "0.5rem",
      margin: -5,
      fontSize: "13px",
      backgroundColor: colorBack,
    };
  } else {
    return {
      textAlign: "center",
      padding: "0.5rem",
      margin: -5,
      fontSize: "13px",
      backgroundColor: colorBack,
    };
  }
};

//format numbers
export const NumberWithCommas = (number, dec) => {
  return (Math.round(number * 100) / 100)
    .toFixed(dec)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//Project, Farming: color cell
export const ColorCell = (cell, yellowGreen, redYellow) => {
  let color;
  if (cell.value < yellowGreen && cell.value >= redYellow) {
    color = (
      <div style={cellStyle(yellowBack)}>
        {`${NumberWithCommas(cell.value, 0)} %`}
      </div>
    );
  } else if (cell.value >= yellowGreen) {
    color = (
      <div style={cellStyle(greenBack)}>
        {`${NumberWithCommas(cell.value, 0)} %`}
      </div>
    );
  } else {
    color = (
      <div style={cellStyle(redBackLight)}>
        {`${NumberWithCommas(cell.value, 0)} %`}
      </div>
    );
  }
  return color;
};

//Color IL
export const ColorIl = (cell, colorIl) => {
  let color;
  if (cell.value / cell.row.original.posSum < -colorIl / 100) {
    color = (
      <div style={cellStyle(redBack)}>
        {`${NumberWithCommas(cell.value, 0)}`}
      </div>
    );
  } else if (cell.value / cell.row.original.posSum < -colorIl / 200) {
    color = (
      <div style={cellStyle(yellowBack)}>
        {`${NumberWithCommas(cell.value, 0)}`}
      </div>
    );
  } else {
    color = (
      <div
        style={{
          textAlign: "center",
        }}
      >
        {`${NumberWithCommas(cell.value, 0)}`}
      </div>
    );
  }
  return color;
};

export const LinkCell = (cell) => (
  <span
    style={{
      textDecoration: "underline",
      color: "green",
      cursor: "pointer",
    }}
  >
    {cell.value}
  </span>
);

//Exchanges
export const CellColorOrder = (cell) => {
  let color;
  switch (cell.value) {
    case "Short":
      color = <div style={cellStyle(redBack)}>{cell.value}</div>;
      break;
    case "Long":
      color = <div style={cellStyle(greenBack)}>{cell.value}</div>;
      break;

    default:
      color = <span>{cell.value}</span>;
      break;
  }
  return color;
};

//Exchange page
export const ColorAcc = ({ cell: { value, column }, row: { original } }) => {
  let color;
  if (value === "Cross" && original.orderType === "Collateral") {
    color = (
      <div
        style={{
          padding: "0.5rem",
          margin: -7,
          fontSize: "15px",
          backgroundColor: "#B1D0E0",
        }}
      >
        {value}
      </div>
    );
  } else {
    color = <div>{value}</div>;
  }
  return color;
};

//Exchange report
export const ColorBuffer = (cell, yellowGreen, redYellow) => {
  let color;
  if (
    cell.value <= redYellow.value &&
    cell.row.original.accountType !== "Spot"
  ) {
    color = (
      <div style={cellStyle(redBack)}>{`${NumberWithCommas(
        cell.value,
        0
      )} %`}</div>
    );
  } else if (
    cell.value >= yellowGreen &&
    cell.row.original.accountType !== "Spot"
  ) {
    color = (
      <div style={cellStyle(greenBack)}>
        {`${NumberWithCommas(cell.value, 0)} %`}
      </div>
    );
  } else if (cell.row.original.accountType !== "Spot") {
    let cellNumber = cell.value ? cell.value : 0;
    color = (
      <div style={cellStyle(yellowBack)}>
        {`${NumberWithCommas(cellNumber, 0)} %`}
      </div>
    );
  } else {
    color = <div>{`${NumberWithCommas(cell.value, 0)} %`}</div>;
  }
  return color;
};

//Lending report

export const ColorBufferLend = (cell, redYellowLend) => {
  let color;
  if (cell.value >= +redYellowLend + 10) {
    color = (
      <div style={cellStyle(greenBack)}>
        {`${NumberWithCommas(cell.value, 0)} %`}
      </div>
    );
  } else if (cell.value < +redYellowLend) {
    color = (
      <div style={cellStyle(redBack)}>
        {`${NumberWithCommas(cell.value, 0)} %`}
      </div>
    );
  } else {
    color = (
      <div style={cellStyle(yellowBack)}>
        {`${NumberWithCommas(cell.value, 0)} %`}
      </div>
    );
  }
  return color;
};

export const ColorLendBorrow = (cell) => {
  let color;
  switch (cell.value) {
    case "Borrow":
      color = <div style={cellStyle(redBack)}>{cell.value}</div>;
      break;
    case "Lend":
      color = <div style={cellStyle(greenBack)}>{cell.value}</div>;
      break;

    default:
      color = <span>{cell.value}</span>;
      break;
  }
  return color;
};

//Subscriptions users
export const ColorSubs = (cell) => {
  let color;
  if (cell.value === "Expired") {
    color = <div style={cellStyle(redBack)}>{cell.value}</div>;
  } else if (cell.value === "Active") {
    color = <div style={cellStyle(greenBack)}>{cell.value}</div>;
  } else {
    color = <div style={cellStyle(yellowBack)}>{cell.value}</div>;
  }
  return color;
};

//Comparing buffer
export const ColorComp = (cell) => {
  let color;
  if (cell.value < -5) {
    color = (
      <div style={cellStyle(redBack)}>{`${NumberWithCommas(
        cell.value,
        0
      )} %`}</div>
    );
  } else if (cell.value > 5) {
    color = (
      <div style={cellStyle(greenBack)}>
        {`${NumberWithCommas(cell.value, 0)} %`}
      </div>
    );
  } else {
    color = <div>{`${NumberWithCommas(cell.value, 0)} %`}</div>;
  }
  return color;
};

//Farming is hedging
export const ColorHedgPair1 = (cell, compArray) => {
  let color;

  if (compArray.includes(cell.row.original.pair1)) {
    color = (
      <div style={cellStyle(greenBack)}>{`${NumberWithCommas(
        cell.value,
        2
      )} `}</div>
    );
  } else {
    color = <div>{`${NumberWithCommas(cell.value, 2)} `}</div>;
  }

  return color;
};
export const ColorHedgPair2 = (cell, compArray) => {
  let color;

  if (compArray.includes(cell.row.original.pair2)) {
    color = (
      <div style={cellStyle(greenBack)}>{`${NumberWithCommas(
        cell.value,
        2
      )} `}</div>
    );
  } else {
    color = <div>{`${NumberWithCommas(cell.value, 2)} `}</div>;
  }

  return color;
};
