import React, { useEffect, useState, useContext } from "react";
import { Spinner, Button, Table } from "react-bootstrap";
import { Formik, Form, Field, FieldArray } from "formik";
//import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import "./Update.css";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { NumberWithCommas } from "../../components/table/FormatedCell";

// Schema for yup
// const validationSchema = Yup.object().shape({
//   chainNumber: Yup.number().required("Please add the number."),
//   chainName: Yup.string()
//     .max(15, "Must be 15 characters or less")
//     .required("Please add the name."),
// });

const UpdateHarvesting = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [farm, setFarm] = useState();
  const [token, setToken] = useState();
  const [pool, setPool] = useState();
  const [details, setDetails] = useState();
  const [harv, setHarv] = useState();
  const [incomes, setIncomes] = useState();
  const [bonuses, setBonuses] = useState();
  // const [isChecked, setIsChecked] = useState();

  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Harvesting in Farming" };

  useEffect(() => {
    let unmounted = false;
    const fetchFarming = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/farm",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.farms.sort((a, b) =>
          a.chainName > b.chainName ? 1 : b.chainName > a.chainName ? -1 : 0
        );

        if (!unmounted) setFarm(responseData.farms);
      } catch (err) {}
    };
    fetchFarming();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.tokens.sort((a, b) =>
          a.tokenSymbol > b.tokenSymbol
            ? 1
            : b.tokenSymbol > a.tokenSymbol
            ? -1
            : 0
        );
        if (!unmounted) setToken(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    const fetchPools = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pool",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.pools.sort((a, b) =>
          a.poolName > b.poolName ? 1 : b.poolName > a.poolName ? -1 : 0
        );
        if (!unmounted) setPool(responseData.pools);
      } catch (err) {}
    };
    fetchPools();

    const fetchDetails = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/detail",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.details.sort((a, b) =>
          a.posDate < b.posDate ? 1 : b.posDate < a.posDate ? -1 : 0
        );

        if (!unmounted) setDetails(responseData.details);
      } catch (err) {}
    };
    fetchDetails();

    const fetchHarvById = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/harv/${Id}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setHarv(responseData.harv);
      } catch (err) {}
    };
    fetchHarvById();

    const fetchIncomes = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/income",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setIncomes(responseData.incomes);
      } catch (err) {}
    };
    fetchIncomes();

    const fetchBonuses = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/bonus",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setBonuses(responseData.bonuses);
      } catch (err) {}
    };
    fetchBonuses();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token, Id]);

  //Delete
  const deleteHandler = async () => {
    //delete Details
    details.forEach(async (d) => {
      if (d.posDate.split("T")[0] === harv.harvDate.split("T")[0]) {
        try {
          await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/detail/${d.id}`,
            "DELETE",
            null,
            { Authorization: "Bearer " + auth.token }
          );
        } catch (err) {}
      }
    });

    //delete Incomes
    incomes.forEach(async (d) => {
      if (
        d.date.split("T")[0] === harv.harvDate.split("T")[0] &&
        d.incomeType === harv.incomeType
      ) {
        try {
          await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/income/${d.id}`,
            "DELETE",
            null,
            { Authorization: "Bearer " + auth.token }
          );
        } catch (err) {}
      }
    });
    //delete Bonuses
    bonuses.forEach(async (d) => {
      if (
        d.date.split("T")[0] === harv.harvDate.split("T")[0] &&
        d.incomeType === harv.incomeType
      ) {
        try {
          await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/bonus/${d.id}`,
            "DELETE",
            null,
            { Authorization: "Bearer " + auth.token }
          );
        } catch (err) {}
      }
    });

    //delete Harv
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/harv/${Id}`,
        "DELETE",
        null,
        { Authorization: "Bearer " + auth.token }
      );
      navigate("../harvesting");
    } catch (err) {}
  };

  //token price
  const getPrice = (tokenSymbol) => {
    let tokenObj;

    tokenObj = token.find((e) => e.tokenSymbol === tokenSymbol);
    return tokenObj.tokenPrice;
  };

  const ifNoneToZero = (digit) => {
    if (digit) {
      return digit;
    } else {
      return 0;
    }
  };
  //link from pool
  const getLink = (poolName) => {
    let link;
    pool.forEach((e) => {
      if (e.poolName === poolName) {
        link = e.poolLink;
      }
    });
    return link;
  };

  //Dates
  let yesterdayDate = new Date(
    new Date().setDate(new Date().getDate() - 1)
  ).toLocaleDateString("en-GB");

  //Calculations
  let positions = [];

  if (farm && token && pool && harv && details) {
    //console.log(harv);

    //Main loop

    farm.forEach((f, i) => {
      //Token price for sum
      let pair1Price;
      let pair2Price;
      token.forEach((t) => {
        pair1Price = getPrice(f.pair1);
        pair2Price = getPrice(f.pair2);
      });
      f.posSum = f.pair1Qty * pair1Price + f.pair2Qty * pair2Price;

      //pool link
      f.link = getLink(f.providerName);

      //price of harv token
      f.tokenPrice = getPrice(f.tokenName);

      f.lpQtyStart = f.lpQty;

      //lpQminus1 and tokenQminus1

      let lpQtyMinus1 = _.sumBy(details, (w) => {
        if (
          w.posDate === yesterdayDate &&
          f.projectNumber === w.projectNumber
        ) {
          return w.lpQty;
        }
      });
      let lpQtyStartMinus1 = _.sumBy(details, (w) => {
        if (
          w.posDate === yesterdayDate &&
          f.projectNumber === w.projectNumber
        ) {
          return w.lpQtyStart;
        }
      });

      //lpQminus1
      if (lpQtyMinus1 && lpQtyStartMinus1 === f.lpQtyStart) {
        f.lpQtyMinus1 = lpQtyMinus1;
      } else {
        f.lpQtyMinus1 = f.lpQtyStart;
      }

      //tokenQminus1
      f.tokenQtyMinus1 = _.sumBy(details, (w) => {
        if (
          w.posDate === yesterdayDate &&
          f.projectNumber === w.projectNumber &&
          !f.isHarvested
        ) {
          return ifNoneToZero(w.tokenQty);
        } else {
          return 0;
        }
      });

      details.forEach((d) => {
        if (
          d.posDate.split("T")[0] === harv.harvDate.split("T")[0] &&
          d.projectNumber === f.projectNumber
        ) {
          f.isHarvested = d.isHarvested;
          f.pairs = d.pairs;
          f.lpQty = d.lpQty;
          f.tokenQty = d.tokenQty;
          f.incomeDay = d.posIncomeDay;
          f.incomeTolal = d.posIncomeTotal;
          f.apy = d.posApy;
        }
      });
      //positions array
      positions.push({
        posDate: new Date().toLocaleDateString("en-GB"),
        projectNumber: f.projectNumber,
        providerName: f.providerName,
        chainName: f.chainName,
        lpQtyStart: f.lpQty,
        token: f.tokenName,
        isHarvested: f.isHarvested,
        pairs: f.pairs,
        posSum: f.posSum,
        link: f.link,
        lpQty: f.lpQty,
        lpQtyMinus1: f.lpQtyMinus1,
        tokenQty: f.tokenQty,
        tokenQtyMinus1: f.tokenQtyMinus1,
        incomeDay: f.incomeDay,
        incomeTotal: f.incomeTolal,
        apy: f.apy,
        tokenPrice: f.tokenPrice,
        managerName: f.managerName,
        clientName: f.clientName,
        userId: f.userId,
      });
    });
    // isPosition =
  }
  //console.log(positions);

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading &&
        farm &&
        token &&
        pool &&
        harv &&
        details &&
        incomes &&
        bonuses && (
          <Formik
            initialValues={{ positions: positions }}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              //clients
              let harvClient = [
                ...new Set(farm.map((item) => item.clientName)),
              ];
              const setClient = (item, index) => {
                var object = { clientName: item };
                return object;
              };
              let harvByClient = harvClient.map(setClient);
              //main loop
              harvByClient.forEach((h) => {
                h.incomeTotal = _.sumBy(values.positions, (f) => {
                  if (f.clientName === h.clientName) {
                    return f.incomeTotal;
                  }
                });
                h.incomeDay = _.sumBy(values.positions, (f) => {
                  if (f.clientName === h.clientName) {
                    return f.incomeDay;
                  }
                });
                h.posSum = _.sumBy(values.positions, (f) => {
                  if (f.clientName === h.clientName) {
                    return f.posSum;
                  }
                });
                h.apy = Math.round((h.incomeDay * 36500) / h.posSum);
                h.positions = _.sumBy(values.positions, (f) => {
                  if (f.clientName === h.clientName) {
                    return `${f.providerName} ${NumberWithCommas(
                      f.posSum
                    )}$ ${NumberWithCommas(f.incomeDay)}$ ${NumberWithCommas(
                      f.apy
                    )}%|`;
                  }
                });
                //update harv
                const submitHarvById = async () => {
                  try {
                    await sendRequest(
                      process.env.REACT_APP_BACKEND_URL + `/harv/${Id}`,
                      "PATCH",
                      JSON.stringify({
                        // harvDate: h.harvDate,
                        positions: h.positions,
                        incomeType: "Farming",
                        incomeTotal: h.incomeTotal,
                        incomeDay: h.incomeDay,
                        apy: h.apy,
                        clientName: h.clientName,
                        userId: auth.userId,
                      }),
                      {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + auth.token,
                      }
                    );
                  } catch (err) {}
                };
                submitHarvById();
              });
              navigate("../harvesting/");
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} className="w-100">
                <div className="card m-3">
                  <h5 className="card-header text-center">
                    {cardOptions.cardName}
                  </h5>
                  <div className="card-body border-bottom ">
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>ProjectN</th>
                          <th>Provider</th>
                          <th>Chain</th>
                          <th>Pairs</th>
                          <th>Sum</th>
                          <th>Token</th>
                          <th>isHar-d</th>
                          <th>lpQty</th>
                          <th>tokenQty</th>
                          <th>incomeDay</th>
                          <th>incomeTotal</th>
                          <th>APY</th>
                          <th style={{ display: "none" }}>HiddenField</th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray name="positions">
                          {() =>
                            positions.map((e, idx) => {
                              return (
                                <tr key={idx}>
                                  <td> {e.posDate}</td>
                                  <td> {e.projectNumber}</td>
                                  <td>
                                    <a
                                      href={e.link}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {e.providerName}
                                    </a>
                                  </td>
                                  <td> {e.chainName}</td>
                                  <td> {e.pairs}</td>
                                  <td> {`$${NumberWithCommas(e.posSum)}`}</td>
                                  <td> {e.token}</td>
                                  <td>
                                    <Field
                                      name={`positions.${idx}.isHarvested`}
                                      className={"checkbox"}
                                      type="checkbox"
                                      disabled
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={(e) => {
                                        let isHarv =
                                          values.positions[idx].isHarvested;

                                        let lpDifDay =
                                          values.positions[idx].lpQty -
                                            values.positions[idx].lpQtyMinus1 ||
                                          0;
                                        let lpDiffTotal =
                                          values.positions[idx].lpQty -
                                            values.positions[idx].lpQtyStart ||
                                          0;
                                        let lpIncomeDay =
                                          (values.positions[idx].posSum /
                                            values.positions[idx].lpQtyMinus1) *
                                            lpDifDay || 0;
                                        let lpIncomeTotal =
                                          (values.positions[idx].posSum /
                                            values.positions[idx].lpQtyStart) *
                                            lpDiffTotal || 0;
                                        if (isHarv) {
                                          values.positions[idx].incomeDay =
                                            values.positions[idx].tokenQty *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeDay;
                                          values.positions[idx].incomeTotal =
                                            values.positions[idx].tokenQty *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeTotal;
                                          values.positions[idx].apy =
                                            (values.positions[idx].incomeDay *
                                              36500) /
                                            values.positions[idx].posSum;
                                        } else {
                                          values.positions[idx].incomeDay =
                                            (values.positions[idx].tokenQty -
                                              values.positions[idx]
                                                .tokenQtyMinus1) *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeDay;
                                          values.positions[idx].incomeTotal =
                                            values.positions[idx].tokenQty *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeTotal;
                                          values.positions[idx].apy =
                                            (values.positions[idx].incomeDay *
                                              36500) /
                                            values.positions[idx].posSum;
                                        }
                                        handleBlur(e);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      name={`positions.${idx}.lpQty`}
                                      className={"form-control-sm"}
                                      type="number"
                                      disabled
                                      style={{
                                        fontSize: "12px",
                                        width: "7rem",
                                        border: "1px solid #292929",
                                        borderRadius: "3px",
                                      }}
                                      onChange={handleChange}
                                      onBlur={(e) => {
                                        let isHarv =
                                          values.positions[idx].isHarvested;

                                        let lpDifDay =
                                          values.positions[idx].lpQty -
                                            values.positions[idx].lpQtyMinus1 ||
                                          0;
                                        let lpDiffTotal =
                                          values.positions[idx].lpQty -
                                            values.positions[idx].lpQtyStart ||
                                          0;
                                        let lpIncomeDay =
                                          (values.positions[idx].posSum /
                                            values.positions[idx].lpQtyMinus1) *
                                            lpDifDay || 0;
                                        let lpIncomeTotal =
                                          (values.positions[idx].posSum /
                                            values.positions[idx].lpQtyStart) *
                                            lpDiffTotal || 0;
                                        if (isHarv) {
                                          values.positions[idx].incomeDay =
                                            values.positions[idx].tokenQty *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeDay;
                                          values.positions[idx].incomeTotal =
                                            values.positions[idx].tokenQty *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeTotal;
                                          values.positions[idx].apy =
                                            (values.positions[idx].incomeDay *
                                              36500) /
                                            values.positions[idx].posSum;
                                        } else {
                                          values.positions[idx].incomeDay =
                                            (values.positions[idx].tokenQty -
                                              values.positions[idx]
                                                .tokenQtyMinus1) *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeDay;
                                          values.positions[idx].incomeTotal =
                                            values.positions[idx].tokenQty *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeTotal;
                                          values.positions[idx].apy =
                                            (values.positions[idx].incomeDay *
                                              36500) /
                                            values.positions[idx].posSum;
                                        }
                                        handleBlur(e);
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <Field
                                      name={`positions.${idx}.tokenQty`}
                                      className={"form-control-sm"}
                                      disabled
                                      style={{
                                        fontSize: "12px",
                                        width: "7rem",
                                        border: "1px solid #292929",
                                        borderRadius: "3px",
                                      }}
                                      type="number"
                                      onChange={handleChange}
                                      onBlur={(e) => {
                                        let isHarv =
                                          values.positions[idx].isHarvested;

                                        let lpDifDay =
                                          values.positions[idx].lpQty -
                                            values.positions[idx].lpQtyMinus1 ||
                                          0;
                                        let lpDiffTotal =
                                          values.positions[idx].lpQty -
                                            values.positions[idx].lpQtyStart ||
                                          0;
                                        let lpIncomeDay =
                                          (values.positions[idx].posSum /
                                            values.positions[idx].lpQtyMinus1) *
                                            lpDifDay || 0;
                                        let lpIncomeTotal =
                                          (values.positions[idx].posSum /
                                            values.positions[idx].lpQtyStart) *
                                            lpDiffTotal || 0;
                                        if (isHarv) {
                                          values.positions[idx].incomeDay =
                                            values.positions[idx].tokenQty *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeDay;
                                          values.positions[idx].incomeTotal =
                                            values.positions[idx].tokenQty *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeTotal;
                                          values.positions[idx].apy =
                                            (values.positions[idx].incomeDay *
                                              36500) /
                                            values.positions[idx].posSum;
                                        } else {
                                          values.positions[idx].incomeDay =
                                            (values.positions[idx].tokenQty -
                                              values.positions[idx]
                                                .tokenQtyMinus1) *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeDay;
                                          values.positions[idx].incomeTotal =
                                            values.positions[idx].tokenQty *
                                              values.positions[idx].tokenPrice +
                                            lpIncomeTotal;
                                          values.positions[idx].apy =
                                            (values.positions[idx].incomeDay *
                                              36500) /
                                            values.positions[idx].posSum;
                                        }
                                        handleBlur(e);
                                      }}
                                    />
                                  </td>

                                  <td>
                                    <Field
                                      name={`positions.${idx}.incomeDay`}
                                      className={"form-control-plaintext"}
                                      type="hidden"
                                    />
                                    {`$${NumberWithCommas(
                                      values.positions[idx].incomeDay
                                    )}`}
                                  </td>

                                  <td>
                                    <Field
                                      name={`positions.${idx}.incomeTotal`}
                                      type="hidden"
                                    />
                                    {`$${NumberWithCommas(
                                      values.positions[idx].incomeTotal
                                    )}`}
                                  </td>

                                  <td>
                                    <Field
                                      name={`positions.${idx}.apy`}
                                      type="hidden"
                                    />
                                    {`${NumberWithCommas(
                                      values.positions[idx].apy
                                    )}%`}
                                  </td>
                                  <td style={{ display: "none" }}>
                                    <Field
                                      name={`positions.${idx}.uniqName`}
                                      type="hidden"
                                    />
                                    <Field
                                      name={`positions.${idx}.lpQtyStart`}
                                      type="hidden"
                                    />

                                    <Field
                                      name={`positions.${idx}.lpQtyMinus1`}
                                      type="hidden"
                                    />

                                    <Field
                                      name={`positions.${idx}.tokenQtyMinus1`}
                                      type="hidden"
                                    />

                                    <Field
                                      name={`positions.${idx}.tokenPrice`}
                                      type="hidden"
                                    />
                                    <Field
                                      name={`positions.${idx}.lpIncomeDay`}
                                      type="hidden"
                                    />
                                    <Field
                                      name={`positions.${idx}.lpIncomeTotal`}
                                      type="hidden"
                                    />
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </FieldArray>
                      </tbody>
                    </Table>
                  </div>
                  <div className="card-footer text-center border-top-0">
                    <Button
                      className="me-1"
                      variant="danger"
                      type="button"
                      onClick={deleteHandler}
                    >
                      Delete
                    </Button>

                    <Link to="../harvesting">
                      <button
                        className=" btn btn-outline-secondary"
                        type="button"
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
    </>
  );
};

export default UpdateHarvesting;
