import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import ClientField from "../../components/elements/form/ClientField";
import SumField from "../../components/elements/form/SumField";
import CardNewUpdate from "../../components/CardNewUpdate";
import TokenField from "../../components/elements/form/TokenField";
import ExchangeField from "../../components/elements/form/ExchangeField";
import CommentField from "../../components/elements/form/CommentField";

// Schema for yup
const validationSchema = Yup.object().shape({
  exchangeName: Yup.string().required("*Exchange name is required"),
  orderType: Yup.string().required("*Order is required"),
  accountType: Yup.string().required("*Account is required"),
  tokenName: Yup.string().required("*Token name is required"),
  tokenQty: Yup.number().required("*Q-ty is required"),
  leverage: Yup.number().required("*Leverage is required"),
  fundingPers: Yup.number("*Funding is a percentage"),
  priceOpen: Yup.number().required("*Price is required"),
  collAdded: Yup.number("*Price is a number"),
  clientName: Yup.string().required("*Client is required"),
  userId: Yup.string().required("*Id is required"),
});

const NewCex = () => {
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedExchanges, setLoadedExchanges] = useState();
  const [loadedClients, setLoadedClients] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Cex" };

  useEffect(() => {
    let unmounted = false;
    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens.sort());
      } catch (err) {}
    };
    fetchTokens();

    const fetchExchanges = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/exchange",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.exchanges.sort((a, b) =>
          a.exchangeName > b.exchangeName
            ? 1
            : b.exchangeName > a.exchangeName
            ? -1
            : 0
        );

        if (!unmounted) setLoadedExchanges(responseData.exchanges);
      } catch (err) {}
    };
    fetchExchanges();

    const fetchClients = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/client",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.clients.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );

        if (!unmounted) setLoadedClients(responseData.clients);
      } catch (err) {}
    };
    fetchClients();

    const fetchSettings = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/setting",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setloadedSettings(responseData.settings);
      } catch (err) {}
    };
    fetchSettings();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  let defaultClient;
  if (!isLoading && loadedExchanges && loadedSettings) {
    loadedSettings.forEach((s) => {
      if (s.description === "Default client") {
        defaultClient = s.value;
      }
    });
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {!isLoading && loadedTokens && loadedClients && loadedExchanges && (
        <Formik
          initialValues={{
            exchangeName: "",
            orderType: "",
            accountType: "",
            tokenName: "",
            tokenQty: "",
            leverage: 4,
            fundingPers: 0,
            priceOpen: 0,
            collAdded: 0,
            comments: "",
            clientName: defaultClient ? defaultClient : "",
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/cex`,
                "POST",
                JSON.stringify({
                  exchangeName: values.exchangeName,
                  orderType: values.orderType,
                  accountType: values.accountType,
                  tokenName: values.tokenName,
                  tokenQty: values.tokenQty,
                  leverage: values.leverage,
                  fundingPers: values.fundingPers,
                  priceOpen: values.priceOpen,
                  collAdded: values.collAdded,
                  comments: values.comments,
                  clientName: values.clientName,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );

              navigate("../cexs");
            } catch (err) {}
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <ExchangeField name="exchangeName" label="Exchange:" />
                  <Form.Group as={Col}>
                    <Form.Label>Order:</Form.Label>
                    <Form.Select
                      type="text"
                      name="orderType"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.orderType}
                    >
                      <option>Choose order</option>
                      <option key={1} value={"Short"}>
                        Short
                      </option>
                      <option key={2} value={"Long"}>
                        Long
                      </option>
                      <option key={3} value={"Collateral"}>
                        Collateral
                      </option>
                      <option key={4} value={"StopShort"}>
                        Stop-Short
                      </option>
                    </Form.Select>
                    {touched.orderType && errors.orderType ? (
                      <div
                        className="text-danger "
                        style={{ fontSize: "12px" }}
                      >
                        {errors.orderType}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Account:</Form.Label>
                    <Form.Select
                      type="text"
                      name="accountType"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.accountType}
                    >
                      <option>Choose account</option>
                      <option key={1} value={"Spot"}>
                        Spot
                      </option>
                      <option key={2} value={"Cross"}>
                        Cross
                      </option>
                      <option key={3} value={"Isolated"}>
                        Isolated
                      </option>
                    </Form.Select>
                    {touched.accountType && errors.accountType ? (
                      <div
                        className="text-danger "
                        style={{ fontSize: "12px" }}
                      >
                        {errors.accountType}
                      </div>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <TokenField name="tokenName" label="Token:" />
                  <SumField name="tokenQty" label="Quantity:" />
                  {values.orderType !== "Collateral" && (
                    <Form.Group as={Col} controlId="formLeverage">
                      <Form.Label>Leverage: </Form.Label>
                      <Form.Control
                        type="number"
                        name="leverage"
                        placeholder="leverage"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values.orderType === "Collateral"
                            ? 1
                            : values.leverage
                        }
                        className={
                          touched.leverage && errors.leverage ? "error" : null
                        }
                      />
                      {touched.leverage && errors.leverage ? (
                        <div className="error-message">{errors.leverage}</div>
                      ) : null}
                    </Form.Group>
                    // <SumField name="leverage" label="Leverage:" />
                  )}
                </Row>
                {values.orderType !== "Collateral" && (
                  <Row className="mb-3">
                    <SumField name="priceOpen" label="Open Price:" />
                    <SumField name="collAdded" label="Added Collateralls:" />
                    <SumField name="fundingPers" label="Funding:" />
                  </Row>
                )}

                <Row className="mb-3">
                  <ClientField name="clientName" label="Client:" />
                  <CommentField name="comments" label="Comments:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewCex;
