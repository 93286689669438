import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import SumField from "../../components/elements/form/SumField";
import TextField from "../../components/elements/form/TextField";

// Schema for yup
const validationSchema = Yup.object().shape({
  managerName: Yup.string().required("*Name is required"),
  userId: Yup.string().required("*Id is required"),
});

const UpdateManager = () => {
  const [loadedManager, setLoadedManager] = useState();
  const [projects, setProjects] = useState();
  const [loadedFarming, setLoadedFarming] = useState();
  const [loadedLends, setLoadedLends] = useState();
  const [harvs, setHarvs] = useState();
  const [details, setDetails] = useState();
  const [incomes, setIncomes] = useState();
  const [bonuses, setBonuses] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Manager" };

  //getById
  useEffect(() => {
    let unmounted = false;

    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };
    if (!unmounted) {
      fetch(`/manager/${Id}`, (data) => setLoadedManager(data.manager));
      fetch("/project", (data) => setProjects(data.projects));
      fetch("/income", (data) => setIncomes(data.incomes));
      fetch("/farm", (data) => setLoadedFarming(data.farms));
      fetch("/lend", (data) => setLoadedLends(data.lends));
      fetch("/harv", (data) => setHarvs(data.harvs));
      fetch("/detail", (data) => setDetails(data.details));
      fetch("/bonus", (data) => setBonuses(data.bonuses));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, Id, auth.token]);

  let allLoaded =
    !isLoading &&
    loadedManager &&
    projects &&
    loadedFarming &&
    loadedLends &&
    incomes &&
    harvs &&
    details &&
    bonuses;

  //disable delete button if token in use
  let disableDelete;
  if (allLoaded) {
    projects.forEach((c) => {
      if (c.managerName === loadedManager.managerName) {
        disableDelete = 1;
      }
    });
    loadedFarming.forEach((f) => {
      if (f.managerName === loadedManager.managerName) {
        disableDelete = 1;
      }
    });
    loadedLends.forEach((l) => {
      if (l.managerName === loadedManager.managerName) {
        disableDelete = 1;
      }
    });

    harvs.forEach((h) => {
      if (h.managerName === loadedManager.managerName) {
        disableDelete = 1;
      }
    });
    details.forEach((d) => {
      if (d.clientName === loadedManager.managerName) {
        disableDelete = 1;
      }
    });
    incomes.forEach((c) => {
      if (c.managerName === loadedManager.managerName) {
        disableDelete = 1;
      }
    });
    bonuses.forEach((o) => {
      if (o.clientName1 === loadedManager.managerName) {
        disableDelete = 1;
      }
    });
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading ? <Spinner animation="border" /> : null}

      {allLoaded && (
        <Formik
          initialValues={{
            managerName: loadedManager.managerName,
            managerBonus: loadedManager.managerBonus,
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/manager/${Id}`,
                "PATCH",
                JSON.stringify({
                  managerName: values.managerName,
                  managerBonus: values.managerBonus,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
            navigate("../managers");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <CardNewUpdate
                cardNew={false}
                cardName={cardOptions.cardName}
                isbuttondisabled={disableDelete}
              >
                <Row className="mb-3">
                  <TextField name="managerName" label="Name:" />
                  <SumField name="managerBonus" label="Manager's %:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UpdateManager;
