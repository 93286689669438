import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Spinner, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import ClientField from "../../components/elements/form/ClientField";
import ManagerField from "../../components/elements/form/ManagerField";
import ChainField from "../../components/elements/form/ChainField";
import PoolField from "../../components/elements/form/PoolField";
import TokenField from "../../components/elements/form/TokenField";
import SumField from "../../components/elements/form/SumField";

// Schema for yup
const validationSchema = Yup.object().shape({
  providerName: Yup.string().required("*Provider name is required"),
  orderType: Yup.string().required("*Order is required"),
  tokenName: Yup.string().required("*Token name is required"),
  tokenQty: Yup.number().required("*Q-ty is required"),
  percent: Yup.number().required("*Percentage is required"),
  chainName: Yup.string().required("*Chain is required"),
  managerName: Yup.string().required("*Manager is required"),
  clientName: Yup.string().required("*Client is required"),
  userId: Yup.string().required("*Id is required"),
});

const NewLend = () => {
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedChains, setLoadedChains] = useState();
  const [loadedClients, setLoadedClients] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const [loadedManagers, setLoadedManagers] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Lend" };

  useEffect(() => {
    let unmounted = false;

    const fetchSettings = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/setting",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setloadedSettings(responseData.settings);
      } catch (err) {}
    };
    fetchSettings();

    const fetchManagers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/manager",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.managers.sort((a, b) =>
          a.managerName > b.managerName
            ? 1
            : b.managerName > a.managerName
            ? -1
            : 0
        );

        if (!unmounted) setLoadedManagers(responseData.managers);
      } catch (err) {}
    };
    fetchManagers();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.tokens.sort((a, b) =>
          a.tokenSymbol > b.tokenSymbol
            ? 1
            : b.tokenSymbol > a.tokenSymbol
            ? -1
            : 0
        );

        if (!unmounted) setLoadedTokens(responseData.tokens.sort());
      } catch (err) {}
    };
    fetchTokens();

    const fetchChains = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/chain",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.chains.sort((a, b) =>
          a.chainName > b.chainName ? 1 : b.chainName > a.chainName ? -1 : 0
        );

        if (!unmounted) setLoadedChains(responseData.chains);
      } catch (err) {}
    };
    fetchChains();

    const fetchClients = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/client",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.clients.sort((a, b) =>
          a.clientName > b.clientName ? 1 : b.clientName > a.clientName ? -1 : 0
        );

        if (!unmounted) setLoadedClients(responseData.clients);
      } catch (err) {}
    };
    fetchClients();

    const fetchPools = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pool",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.pools.sort((a, b) =>
          a.poolName > b.poolName ? 1 : b.poolName > a.poolName ? -1 : 0
        );
        if (!unmounted) setLoadedPools(responseData.pools);
      } catch (err) {}
    };
    fetchPools();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  let defaultClient, defaultManager;

  if (!isLoading && loadedChains && loadedPools && loadedSettings) {
    //Default client, manager
    loadedSettings.forEach((s) => {
      if (s.description === "Default client") {
        defaultClient = s.value;
      }
      if (s.description === "Default manager") {
        defaultManager = s.value;
      }
    });
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading &&
        loadedTokens &&
        loadedClients &&
        loadedChains &&
        loadedManagers &&
        loadedPools && (
          <Formik
            initialValues={{
              providerName: "",
              orderType: "",
              tokenName: "",
              tokenQty: "",
              colFactor: "60",
              percent: 0,
              chainName: "",
              managerName: defaultManager ? defaultManager : "",
              clientName: defaultClient ? defaultClient : "",
              userId: auth.userId,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              try {
                await sendRequest(
                  process.env.REACT_APP_BACKEND_URL + `/lend`,
                  "POST",
                  JSON.stringify({
                    providerName: values.providerName,
                    orderType: values.orderType,
                    tokenName: values.tokenName,
                    tokenQty: values.tokenQty,
                    colFactor: values.colFactor,
                    percent: values.percent,
                    chainName: values.chainName,
                    managerName: values.managerName,
                    clientName: values.clientName,
                    userId: auth.userId,
                  }),
                  {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + auth.token,
                  }
                );
              } catch (err) {}
              navigate("../lends");
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} className="mx-auto w-75">
                <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                  <Row className="mb-2">
                    <PoolField
                      name="providerName"
                      label="Provider Name:"
                    ></PoolField>
                    <Form.Group as={Col} controlId="formOrderType">
                      <Form.Label>Order:</Form.Label>
                      <Form.Select
                        type="text"
                        name="orderType"
                        placeholder=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.orderType}
                      >
                        <option>... choose order</option>
                        <option key={1} value={"Lend"}>
                          Lend
                        </option>
                        <option key={2} value={"Borrow"}>
                          Borrow
                        </option>
                      </Form.Select>
                      {touched.orderType && errors.orderType ? (
                        <div className="error-message">{errors.orderType}</div>
                      ) : null}
                    </Form.Group>
                  </Row>
                  <Row className="mb-2">
                    <TokenField name="tokenName" label="Token:" />
                    <SumField name="tokenQty" label="Quantity:" />
                  </Row>
                  {values.orderType === "Lend" ? (
                    <Row className="mb-2">
                      <SumField name="colFactor" label="Collateral factor %:" />
                    </Row>
                  ) : null}

                  <Row className="mb-2">
                    <SumField name="percent" label="APY:" />

                    <ChainField name="chainName" label="Chain:" />
                  </Row>

                  <Row className="mb-2">
                    <ClientField name="clientName" label="Client:" />
                    <ManagerField name="managerName" label="Manager:" />
                  </Row>
                </CardNewUpdate>
              </Form>
            )}
          </Formik>
        )}
    </>
  );
};

export default NewLend;
