import React, { useContext } from "react";
import { Accordion, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/auth-context";
import { Icons } from "./Icons";
import "./SideBar.css";

export default function SideBar() {
  const Auth = useContext(AuthContext);
  const navListBase = [
    { name: `  Wallet`, link: "./wallets", icon: Icons.wallet },
    { name: `  Exchanges`, link: "/cexs/report", icon: Icons.exchange },
  ];

  const navListDefi = [
    { name: `  Projects`, link: "/projects", icon: Icons.project },
    { name: `  Farming`, link: "/farms", icon: Icons.farming },
    { name: `  Lending`, link: "/Lends/report", icon: Icons.lending },
  ];

  const navListEnterprise = [
    { name: `  Comparing`, link: "/comps", icon: Icons.comparing },
    { name: `  Harvest`, link: "/harvesting", icon: Icons.harvesting },
    { name: `  Incomes`, link: "/incomes", icon: Icons.incomes },
    { name: `  Bonuses`, link: "/bonuses", icon: Icons.bonuses },
    { name: `  Offsets`, link: "/offsets", icon: Icons.offsets },
    { name: `  Balances`, link: "/balances", icon: Icons.balances },
    { name: `  Investors`, link: "/investors", icon: Icons.investors },
  ];
  const navListAdmin = [
    { name: `  Tokens`, link: "/tokens", icon: Icons.tokens },
    { name: `  Chains`, link: "/chains", icon: Icons.chains },
    { name: `  Exchanges`, link: "/exchanges", icon: Icons.exchanges },
    { name: `  Pools`, link: "/pools", icon: Icons.pools },
    { name: `  Users`, link: "/users", icon: Icons.users },
    { name: `  Users sub`, link: "/subscriptions/admin", icon: Icons.subAdmin },
    { name: `  Payments`, link: "/Payments", icon: Icons.Payments },
  ];

  let baseMenu =
    Auth.planName === "Base" ||
    Auth.planName === "Pro" ||
    Auth.planName === "Enterprise" ||
    Auth.planName === "Trial";

  let premiumMenu = Auth.planName === "Enterprise" || Auth.planName === "Trial";

  return (
    <>
      <nav
        className="col-md-3 col-lg-2 d-md-block 
        bg-light sidebar collapse navbar-collapse"
      >
        {Auth.isLoggedIn && baseMenu && (
          <div className=" nav flex-column position-sticky pt-4">
            {navListBase.map((b) => {
              return (
                <Nav.Item key={b.link} className="mb-1 ">
                  <Nav.Link
                    as={Link}
                    to={b.link}
                    className=" w-100 btn  btn-toggle d-inline-flex align-items-center rounded border-0"
                  >
                    {b.icon} <span className="ms-2 navlink"> {b.name}</span>
                  </Nav.Link>
                </Nav.Item>
              );
            })}

            <Nav.Item className="border-top "></Nav.Item>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
              <span>DeFi</span>
            </h6>
            {navListDefi.map((b) => {
              return (
                <Nav.Item key={b.link} className="mb-1 ">
                  <Nav.Link
                    as={Link}
                    to={b.link}
                    className=" w-100  btn  btn-toggle d-inline-flex align-items-center rounded border-0"
                  >
                    {b.icon} <span className="ms-2 navlink"> {b.name}</span>
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </div>
        )}
        {Auth.isLoggedIn && premiumMenu && (
          <div className=" nav flex-column position-sticky ">
            <Nav.Item className="border-top "></Nav.Item>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
              <span>Enterprise</span>
            </h6>
            {navListEnterprise.map((b) => {
              return (
                <Nav.Item key={b.link} className="mb-1 ">
                  <Nav.Link
                    as={Link}
                    to={b.link}
                    className=" w-100  btn  btn-toggle d-inline-flex align-items-center rounded border-0"
                  >
                    {b.icon} <span className="ms-2 navlink"> {b.name}</span>
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </div>
        )}
        {Auth.isLoggedIn && Auth.userType === "admin" && (
          <Accordion>
            <Accordion.Item eventKey="1" className="a-item">
              <Accordion.Header className="a-header navlink">
                Admin
              </Accordion.Header>
              <Accordion.Body>
                {navListAdmin.map((b) => {
                  return (
                    <Nav.Item key={b.link} className="">
                      <Nav.Link
                        as={Link}
                        to={b.link}
                        className=" w-100  btn  btn-toggle d-inline-flex align-items-center rounded border-0"
                      >
                        {b.icon} <span className="ms-2 navlink"> {b.name}</span>
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </nav>
    </>
  );
}
