import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import ErrorAlert from "../components/elements/ErrorAlert";

// Schema for yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
});

const Forgot = () => {
  const [error, setError] = useState();
  const navigate = useNavigate();

  //clear error
  const clearError = () => {
    setError(null);
  };
  return (
    <div className=" flex-column w-100">
      {error && <ErrorAlert error={error} onClear={clearError} />}

      {/* Sets initial values for form inputs */}
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: values.email,
            }),
          };
          let response;
          try {
            response = await fetch(
              process.env.REACT_APP_BACKEND_URL + "/user/forgot",
              requestOptions
            );

            const responseData = await response.json();
            if (!response.ok) {
              throw new Error(responseData.message);
            }

            setSubmitting(false);

            navigate("../login");
          } catch (err) {
            setSubmitting(false);
            setError(err.message || "Something went wrong, please try again.");
          }
          //resetForm();
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="mx-auto w-25"
            style={{ minWidth: "450px" }}
          >
            <Card className="text-center card-center shadow">
              <Card.Header>RECOVERY FORM</Card.Header>
              <Card.Body>
                <Form.Group>
                  <Form.Label>Email :</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={touched.email && errors.email ? "error" : null}
                  />
                  {touched.email && errors.email ? (
                    <div className="text-danger">{errors.email}</div>
                  ) : null}
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Button variant="warning" type="submit" disabled={isSubmitting}>
                  Reset Password
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Forgot;
