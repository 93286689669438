const getPrice = (tokens, tokenSymbol) => {
  const tokenObj = tokens.find((e) => e.tokenSymbol === tokenSymbol);
  let price = tokenObj ? tokenObj.tokenPrice : 0;
  return price;
};

const customFetch = async (sendRequest, auth, path, setLoad) => {
  try {
    const responseData = await sendRequest(
      process.env.REACT_APP_BACKEND_URL + path,
      "GET",
      null,
      { Authorization: "Bearer " + auth.token }
    );

    setLoad(responseData);
  } catch (err) {}
};

const showColumn = (window, size) => {
  if (window < size) {
    return false;
  }
};
export { getPrice, customFetch, showColumn };
