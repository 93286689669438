import React from "react";

export default function Support() {
  return (
    <div className="px-4 pt-5 my-5 text-center mx-auto border-bottom">
      <h3 className="display-4 fw-bold">Support</h3>
      <hr></hr>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">
          <a href="https://docs.cryptobase.bimdao.io/">Wiki of cryptoBase</a>
        </p>

        <p className="lead mb-4">
          In case of any issues, please contact support:
        </p>
        <p className="lead mb-4">admin@bimdao.io</p>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5"></div>
      </div>
    </div>
  );
}
