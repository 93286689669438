import React, { useEffect, useState, useContext } from "react";
import { Spinner, Card } from "react-bootstrap";

import { EditableCellText } from "../../components/table/EditableCell";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";

const Setting = () => {
  const [settings, setSettings] = useState();
  const [skipPageReset, setSkipPageReset] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  // const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Setting" };

  useEffect(() => {
    let unmounted = false;

    const fetchSettings = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/setting",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setSettings(responseData.settings);
      } catch (err) {}
    };
    fetchSettings();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },
      {
        Header: "Name",
        accessor: "name",
        show: false,
      },

      {
        Header: "Description",
        accessor: "description",
      },
      // {
      //   Header: "OnOff",
      //   accessor: "toggle",
      // },
      {
        Header: "Value",
        accessor: "value",
        disableFilters: true,
        Cell: EditableCellText,
      },

      // {
      //   Header: "Save/Delete",
      //   accessor: "buttons",
      // },
      {
        Header: "userId",
        accessor: "userid",
        show: false,
      },
    ],
    []
  );

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setSettings((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const submitData = async () => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/setting/${row.id}`,
                "PATCH",
                JSON.stringify({
                  name: row.name,
                  description: row.description,
                  value: value,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
          };
          submitData();
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset

  useEffect(() => {
    setSkipPageReset(false);
  }, []);

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner />}

      {!isLoading && settings && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className="p-1"></div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              <div className="p-1"></div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={settings}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              //getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default Setting;
