import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner, Card } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import {
  ColorSubs,
  NumberWithCommas,
} from "../../components/table/FormatedCell";

export default function SubsAdmin() {
  const [subs, setSubs] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Subscription" };

  useEffect(() => {
    let unmounted = false;

    const fetchSubscriptions = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/subscription/admin",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.subscriptions.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );
        if (!unmounted) setSubs(responseData.subscriptions);
      } catch (err) {}
    };
    fetchSubscriptions();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  //Calculations
  if (!isLoading && subs) {
    // console.log(subs);
  }

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/subscription/${cell.row.values.id}`);
      },
    };
  };

  //Columns
  const columns = [
    {
      Header: "Id",
      accessor: "id",
      // show: false,
    },

    {
      Header: "Date",
      accessor: "date",
      Cell: ({ row }) => new Date(row.values.date).toLocaleDateString("en-GB"),
    },
    {
      Header: "Plan",
      accessor: "planName",
    },
    {
      Header: "Period",
      accessor: "subType",
    },
    {
      Header: "Sum",
      accessor: "sum",
      Cell: ({ row }) => <div>{`${NumberWithCommas(row.values.sum)} $`}</div>,
    },
    {
      Header: "cryptoAddress",
      accessor: "cryptoAddress",
    },

    {
      Header: "validTill",
      accessor: "validTill",
      Cell: ({ row }) =>
        new Date(row.values.validTill).toLocaleDateString("en-GB"),
    },
    {
      Header: "Status",
      accessor: "subStatus",
      Cell: ColorSubs,
    },
    {
      Header: "Comments",
      accessor: "comments",
    },
    {
      Header: "userId",
      accessor: "userId",
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {isLoading && <Spinner animation="border" />}
      {!isLoading && subs && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className=""></div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              <div className=""></div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={subs}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}
