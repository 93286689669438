import React, { useContext } from "react";
import { useQuery } from "react-query";
import { Form, Col, Spinner } from "react-bootstrap";
import axios from "axios";

import { AuthContext } from "../../context/auth-context.js";
import SelectInput from "../../elements/SelectField";

export default function ManagerField(props) {
  const auth = useContext(AuthContext);

  async function fetchArray() {
    return await axios({
      url: process.env.REACT_APP_BACKEND_URL + "/manager",
      method: "get",
      headers: { Authorization: "Bearer " + auth.token },
    });
  }
  const { isLoading: isLoadingManagers, data: loadedManagers } = useQuery(
    "getManagers",
    fetchArray
  );

  function fetchSettings() {
    return axios({
      url: process.env.REACT_APP_BACKEND_URL + "/setting",
      method: "get",
      headers: { Authorization: "Bearer " + auth.token },
    });
  }
  const { isLoading: isLoadingSettings, data: loadedSettings } = useQuery(
    "getSettings",
    fetchSettings
  );

  if (isLoadingManagers || isLoadingSettings) {
    return <Spinner animation="border" />;
  }
  let options, defaultManager;
  if (
    !isLoadingManagers &&
    !isLoadingSettings &&
    loadedManagers &&
    loadedSettings
  ) {
    // Default client
    loadedSettings.data.settings.forEach((s) => {
      if (s.description === "Default manager") {
        defaultManager = s.value;
      }
    });

    //Options in Array
    let arrayList = loadedManagers.data.managers
      .map((item) => (item = item.managerName))
      .sort();
    if (arrayList.length === 0) {
      arrayList = [defaultManager];
    }
    options = arrayList.map((e) => ({
      label: e,
      value: e,
    }));
  }
  return (
    <>
      {!isLoadingManagers &&
      !isLoadingSettings &&
      loadedManagers &&
      loadedSettings ? (
        <Form.Group as={Col}>
          <SelectInput
            type="text"
            label={props.label}
            name={props.name}
            options={options}
            defaultValue={defaultManager ? defaultManager : ""}
            disabled={props.disabled ? props.disabled : false}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </SelectInput>
        </Form.Group>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}
