import React, { useContext, useState, useEffect } from "react";
import { Form, Spinner, Row, Col } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../components/context/auth-context";
import ErrorAlert from "../components/elements/ErrorAlert";
import { useHttpClient } from "../components/hooks/http-hook";
import CardNewUpdate from "../components/CardNewUpdate";
import TextField from "../components/elements/form/TextField";

// Schema for yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  userType: Yup.string().required("*Type is reqired."),
});

const UpdateUser = () => {
  const [loadedUser, setLoadedUser] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "User" };

  useEffect(() => {
    let unmounted = false;
    const fetch = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/user/${Id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        if (!unmounted) setLoadedUser(responseData.user);
      } catch (err) {}
    };
    fetch();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, Id, auth.token]);

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}
      {!isLoading && loadedUser && (
        <Formik
          // mapPropsToValues={() => ({ userType: "" })}
          initialValues={{
            email: loadedUser.email,
            userType: loadedUser.userType,
            planName: loadedUser.planName,
            subType: loadedUser.subType,
            verified: loadedUser.verified,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/user/${Id}`,
                "PATCH",
                JSON.stringify({
                  email: values.email,
                  userType: values.userType,
                  planName: values.planName,
                  subType: values.subType,
                  verified: values.verified,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
            // console.log({
            //   email: values.email,
            //   password: values.password,
            //   userType: values.userType,
            //   planName: values.planName,
            //   subType: values.subType,
            //   verified: values.verified,
            // });
            navigate("../users");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto">
              <CardNewUpdate cardNew={false} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <TextField name="email" label="Email:" />
                  <Form.Group>
                    <Form.Label>User Type :</Form.Label>
                    <Form.Select
                      type="text"
                      name="userType"
                      value={values.userType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        touched.userType && errors.userType ? "error" : null
                      }
                    >
                      <option value="user" label="user" />
                      <option value="admin" label="admin" />
                      <option value="newUser" label="newUser" />
                    </Form.Select>
                    {touched.userType && errors.userType ? (
                      <div className="error-message">{errors.userType}</div>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Plan:</Form.Label>
                    <Field as={Form.Select} name="planName">
                      <option value="Trial">Trial</option>
                      <option value="Base">Base</option>
                      <option value="Pro">Pro</option>
                      <option value="Enterprise">Enterprise</option>
                    </Field>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>subType:</Form.Label>
                    <Field as={Form.Select} name="subType">
                      <option value="Yearly">Yearly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="7_Days">7_Days</option>
                    </Field>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Verified:</Form.Label>
                    <Field as={Form.Select} name="verified">
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Field>
                  </Form.Group>
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
      ;
    </>
  );
};

export default UpdateUser;
