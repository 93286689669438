import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ButtonsTop(props) {
  const { cardName, report } = props;

  let linkNew = `../${cardName.toLowerCase()}s/new`;
  let linkReport = `../${cardName.toLowerCase()}s/report`;

  return (
    <div className="d-flex flex-row w-100 justify-content-between">
      <div>
        <Button as={Link} to={linkNew} className="btn btn-secondary btn-sm">
          New
        </Button>
      </div>

      <div className="fs-4">{cardName}</div>
      {report ? (
        <div>
          <Button
            as={Link}
            to={linkReport}
            className=" btn btn-secondary btn-sm "
          >
            Report
          </Button>
        </div>
      ) : (
        <div className="p-1"></div>
      )}
    </div>
  );
}
