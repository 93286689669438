import React from "react";
import { Link } from "react-router-dom";

export default function Nopage() {
  return (
    <div className="px-4 pt-5 my-5 text-center mx-auto border-bottom">
      <h1 className="display-4 fw-bold">Error 404</h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">Page not found.</p>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
          <Link to="../">
            <button type="button" className="btn btn-success me-1">
              Home
            </button>
          </Link>
          <Link to="../settings">
            <button type="button" className="btn btn-secondary">
              Setting
            </button>
          </Link>
        </div>
      </div>
      <div className="overflow-hidden" style={{ maxHeight: "30vh" }}></div>
    </div>
  );
}
