import React, { useContext } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CardNewUpdate from "../../components/CardNewUpdate";
import TextField from "../../components/elements/form/TextField";
import SumField from "../../components/elements/form/SumField";

// Schema for yup
const validationSchema = Yup.object().shape({
  chainNumber: Yup.number().required("*Nunber is required"),
  chainName: Yup.string().required("*Name is required"),
  explorer: Yup.string().required("*Explorer is required"),
});

const NewChain = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Chain" };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      <Formik
        initialValues={{ chainNumber: "", chainName: "", explorer: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          try {
            await sendRequest(
              process.env.REACT_APP_BACKEND_URL + "/chain",
              "POST",
              JSON.stringify({
                chainNumber: values.chainNumber,
                chainName: values.chainName,
                explorer: values.explorer,
              }),
              {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
              }
            );
            navigate("../chains");
          } catch (err) {}
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="mx-auto w-50">
            <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
              <Row className="mb-2">
                <SumField name="chainNumber" label="Chain Number:" />
                <TextField name="chainName" label="Chain Name:" />
              </Row>
              <Row className="mb-2">
                <TextField name="explorer" label="Chain Explorer:" />
              </Row>
            </CardNewUpdate>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewChain;
