import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Spinner, Card, Button, Alert } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import { LinkCell } from "../../components/table/FormatedCell";

function Exchange() {
  const [loadedExchanges, setLoadedExchanges] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  let navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Exchange" };

  useEffect(() => {
    const fetch = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/exchange",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoadedExchanges(responseData.exchanges);
      } catch (err) {}
    };
    fetch();
  }, [sendRequest, auth.token]);

  let allLoaded = !isLoading && loadedExchanges;

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        if (cell.column.id === "link") {
          window.open(`${cell.row.original.link}`);
        } else if (auth.userType === "admin") {
          navigate(`/exchange/${cell.row.values.id}`);
        } else {
          <Alert variant="warning">Only admins can edit.</Alert>;
        }
      },
    };
  };

  //Only admin columns
  let showAdmin = auth.userType === "admin" ? true : false;

  //Calc
  if (allLoaded) {
    for (const item of loadedExchanges) {
      if (!item.activated) {
        item.activated = "Check";
      }
    }
  }
  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },

      {
        Header: "ExchangeName",
        accessor: "exchangeName",
      },
      {
        Header: "activated",
        accessor: "activated",
        show: showAdmin,
      },
      {
        Header: "Link",
        accessor: "link",
        disableFilters: true,
        Cell: LinkCell,
      },
    ],
    [showAdmin]
  );

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className="">
                <Button
                  as={Link}
                  to={`../exchanges/new`}
                  className="btn btn-sm btn-secondary"
                >
                  {auth.userType === "admin" ? "New" : "Request new"}
                </Button>
              </div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              <div className=""></div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={loadedExchanges}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Exchange;
