import React, { useContext } from "react";
import { Form, Button, Card, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import metaMask from "../img/metamask.png";
import detectEthereumProvider from "@metamask/detect-provider";

import { AuthContext } from "../components/context/auth-context";
import ErrorAlert from "../components/elements/ErrorAlert";
import { useHttpClient } from "../components/hooks/http-hook";

// Schema for yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  password: Yup.string()
    .required("*Password is reqired.")
    .min(5, "*Please enter valid password, at least 5 characters."),
});

const Signin = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  // get login Metamask
  const loginMetamask = async () => {
    //connect web3
    const provider = await detectEthereumProvider();
    const account = await provider.request({
      method: "eth_requestAccounts",
    });

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/metamask/login",
        "POST",
        JSON.stringify({
          connectedWallet: account[0],
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      // const responseData = await response.json();

      // if (!response.ok) {
      //   throw new Error(responseData.message);
      // }

      auth.login(
        responseData.userId,
        responseData.userEmail,
        responseData.userType,
        responseData.planName,
        responseData.subType,
        responseData.verified,
        responseData.token
      );
      navigate("../");
    } catch (err) {}
  };

  return (
    <>
      {isLoading ? <Spinner animation="border" /> : null}

      <div className=" flex-column w-100">
        {error && <ErrorAlert error={error} onClear={clearError} />}
        {/* Sets initial values for form inputs */}
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: values.email,
                password: values.password,
              }),
            };
            let response;
            try {
              response = await fetch(
                process.env.REACT_APP_BACKEND_URL + "/user/login",
                requestOptions
              );

              const responseData = await response.json();
              if (!response.ok) {
                throw new Error(responseData.message);
              }

              setSubmitting(false);
              auth.login(
                responseData.userId,
                responseData.userEmail,
                responseData.userType,
                responseData.planName,
                responseData.subType,
                responseData.verified,
                responseData.token
              );
              navigate("../");
            } catch (err) {
              setSubmitting(false);
            }
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form
              onSubmit={handleSubmit}
              className="mx-auto w-25"
              style={{ minWidth: "450px" }}
            >
              <Card className="text-center card-center shadow">
                <Card.Header>LOGIN FORM</Card.Header>
                <Card.Body>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email :</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className={touched.email && errors.email ? "error" : null}
                    />
                    {touched.email && errors.email ? (
                      <div className="text-danger">{errors.email}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="formPassword">
                    <Form.Label>Password :</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className={
                        touched.password && errors.password ? "error" : null
                      }
                    />
                    {touched.password && errors.password ? (
                      <div className="text-danger">{errors.password}</div>
                    ) : null}
                  </Form.Group>
                </Card.Body>
                <Card.Footer>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Login
                  </Button>
                  <hr />

                  <Button variant="secondary" onClick={loginMetamask}>
                    {<img src={metaMask} alt="" width={"20px"} />} Login
                  </Button>

                  <hr />
                  <p>
                    Not registered? <Link to="../signup"> Signup</Link>
                  </p>
                  <p>
                    <Link to="../forgot">Forgot password?</Link>
                  </p>
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Signin;
