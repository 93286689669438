import React, { useContext, useState, useEffect } from "react";
import { Form, Spinner, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import ClientField from "../../components/elements/form/ClientField";
import DateField from "../../components/elements/form/DateField";
import SumField from "../../components/elements/form/SumField";
import TokenField from "../../components/elements/form/TokenField";

// Schema for yup
const validationSchema = Yup.object().shape({
  projectNumber: Yup.number().required("*Project Number is required"),
  clientName: Yup.string().required("*Client is required"),
  pair1Qty: Yup.number().required("*Pair1Qty is required"),
  pair2Qty: Yup.number().required("*Pair2Qty is required"),
});

const UpdateFarming = () => {
  const [loadedFarming, setLoadedFarming] = useState();
  const [isChecked, setIsChecked] = useState();
  const [isCheckedLP, setIsCheckedLP] = useState(false);
  const [isCheckedV3, setIsCheckedV3] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const Id = useParams().id;

  //Card name
  let cardOptions = { cardName: "Farm" };

  useEffect(() => {
    let unmounted = false;
    const fetchFarmingById = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/farm/${Id}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedFarming(responseData.farm);
        if (!unmounted) setIsChecked(responseData.farm.changeToken);
        if (!unmounted) setIsCheckedLP(responseData.farm.lpToken);
        if (!unmounted) setIsCheckedV3(responseData.farm.v3);
      } catch (err) {}
    };
    fetchFarmingById();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token, Id]);

  const getPrice = (tokenSymbol) => {
    let tokenObj;
    if (!isLoading && loadedTokens) {
      tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    }
    return tokenObj.tokenPrice;
  };

  //Calculations
  if (!isLoading && loadedFarming && loadedTokens) {
    loadedFarming.pair1Price = getPrice(loadedFarming.pair1);
    loadedFarming.pair2Price = getPrice(loadedFarming.pair2);

    //IL
    let ilSum =
      loadedFarming.newPair1Qty * loadedFarming.pair1Price +
      loadedFarming.newPair2Qty * loadedFarming.pair2Price;
    loadedFarming.newPair1Qty = ilSum / 2 / loadedFarming.pair1Price;
    loadedFarming.newPair2Qty = ilSum / 2 / loadedFarming.pair2Price;
    let ilK = loadedFarming.newPair1Qty * loadedFarming.newPair2Qty;
    let ilR = loadedFarming.pair2Price / loadedFarming.pair1Price;
    loadedFarming.pair1Qty = Math.sqrt(ilK * ilR);
    loadedFarming.pair2Qty = Math.sqrt(ilK / ilR);
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}
      {!isLoading && loadedFarming && loadedTokens && (
        <Formik
          initialValues={{
            date: loadedFarming.date,
            projectNumber: loadedFarming.projectNumber,
            providerName: loadedFarming.providerName,
            poolName: loadedFarming.poolName,
            pair1: loadedFarming.pair1,
            pair2: loadedFarming.pair2,
            apy: loadedFarming.apy,
            chainName: loadedFarming.chainName,
            managerName: loadedFarming.managerName,
            clientName: loadedFarming.clientName,
            pair1Qty: loadedFarming.pair1Qty,
            pair2Qty: loadedFarming.pair2Qty,
            v3: loadedFarming.v3 ? loadedFarming.v3 : false,
            priceBottom: loadedFarming.priceBottom
              ? loadedFarming.priceBottom
              : 0,
            priceTop: loadedFarming.priceTop ? loadedFarming.priceTop : 0,
            changeToken: loadedFarming.changeToken,
            tokenName: loadedFarming.tokenName,
            lpToken: loadedFarming.lpToken,
            lpQty: loadedFarming.lpQty,
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/farm/${Id}`,
                "PATCH",
                JSON.stringify({
                  date: values.date,
                  projectNumber: loadedFarming.projectNumber,
                  providerName: loadedFarming.providerName,
                  poolName: loadedFarming.poolName,
                  pair1: loadedFarming.pair1,
                  pair2: loadedFarming.pair2,
                  apy: loadedFarming.apy,
                  chainName: loadedFarming.chainName,
                  managerName: loadedFarming.managerName,
                  clientName: values.clientName,
                  pair1Qty: values.pair1Qty,
                  pair2Qty: values.pair2Qty,
                  newPair1Qty: values.pair1Qty,
                  newPair2Qty: values.pair2Qty,
                  v3: values.v3,
                  priceBottom: loadedFarming.priceBottom,
                  priceTop: loadedFarming.priceTop,
                  changeToken: values.changeToken,
                  tokenName: values.tokenName,
                  lpToken: values.lpToken,
                  lpQty: values.lpQty,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}

            navigate("../farms");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-50">
              <CardNewUpdate cardNew={false} cardName={cardOptions.cardName}>
                <Row className="mb-2">
                  <DateField name="date" label="Date:" disabled={true} />
                  <Form.Group as={Col} controlId="formProjectNumber">
                    <Form.Label>Project Number: </Form.Label>
                    <Form.Control
                      disabled
                      type="number"
                      name="projectNumber"
                      value={values.projectNumber}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      className={
                        touched.projectNumber && errors.projectNumber
                          ? "error"
                          : null
                      }
                    />
                    {touched.projectNumber && errors.projectNumber ? (
                      <div className="error-message">
                        {errors.projectNumber}
                      </div>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="formProviderName">
                    <Form.Label>Provider Name:</Form.Label>
                    <Form.Select
                      type="text"
                      name="providerName"
                      placeholder=""
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={loadedFarming ? loadedFarming.providerName : ""}
                    >
                      <option>
                        {loadedFarming ? loadedFarming.providerName : ""}
                      </option>
                    </Form.Select>
                    {touched.providerName && errors.providerName ? (
                      <div className="error-message">{errors.providerName}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} controlId="formPoolName">
                    <Form.Label>Pool Name:</Form.Label>
                    <Form.Select
                      disabled
                      type="text"
                      name="poolName"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.poolName}
                    >
                      <option>
                        {loadedFarming ? loadedFarming.poolName : ""}
                      </option>
                    </Form.Select>
                    {touched.poolName && errors.poolName ? (
                      <div className="error-message">{errors.poolName}</div>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="formApy">
                    <Form.Label>APY: </Form.Label>
                    <Form.Control
                      disabled
                      type="number"
                      name="apy"
                      placeholder="add APY"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={loadedFarming ? loadedFarming.apy : ""}
                      className={touched.apy && errors.apy ? "error" : null}
                    />
                    {touched.apy && errors.apy ? (
                      <div className="error-message">{errors.apy}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} controlId="formChainName">
                    <Form.Label>Chain:</Form.Label>
                    <Form.Select
                      disabled
                      type="text"
                      name="chainName"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.chainName}
                    >
                      <option>
                        {loadedFarming ? loadedFarming.chainName : ""}
                      </option>
                    </Form.Select>
                    {touched.chainName && errors.chainName ? (
                      <div className="error-message">{errors.chainName}</div>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="formManagerName">
                    <Form.Label>Manager:</Form.Label>
                    <Form.Select
                      disabled
                      type="text"
                      name="managerName"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.managerName}
                    >
                      <option>
                        {loadedFarming ? loadedFarming.managerName : ""}
                      </option>
                    </Form.Select>
                    {touched.managerName && errors.managerName ? (
                      <div className="error-message">{errors.managerName}</div>
                    ) : null}
                  </Form.Group>
                  <ClientField name="clientName" label="Client:" />
                </Row>

                <Row className="mb-2">
                  <Form.Group as={Col} controlId="formPair1">
                    <Form.Label>Pair 1: </Form.Label>
                    <Form.Select
                      disabled
                      type="text"
                      name="pair1"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pair1}
                    >
                      <option>
                        {loadedFarming ? loadedFarming.pair1 : ""}
                      </option>
                    </Form.Select>
                    {touched.pair1 && errors.pair1 ? (
                      <div className="error-message">{errors.pair1}</div>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col} controlId="formPair2">
                    <Form.Label>Pair 2: </Form.Label>
                    <Form.Select
                      disabled
                      type="text"
                      name="pair2"
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pair2}
                    >
                      <option>
                        {loadedFarming ? loadedFarming.pair2 : ""}
                      </option>
                    </Form.Select>
                    {touched.pair2 && errors.pair2 ? (
                      <div className="error-message">{errors.pair2}</div>
                    ) : null}
                  </Form.Group>
                </Row>

                <Row className="mb-2">
                  <SumField name="pair1Qty" label="Pair 1 q-ty:" />

                  <SumField name="pair2Qty" label="Pair 2 q-ty:" />

                  <Form.Group as={Col} controlId="formCheckBoxV3">
                    <Form.Label>V3:</Form.Label>
                    <Form.Check
                      disabled
                      type="checkbox"
                      name="v3"
                      checked={values.v3}
                      onChange={(e) => {
                        handleChange(e);
                        setIsCheckedV3(!isCheckedV3);
                      }}
                      onBlur={handleBlur}
                      value={values.v3}
                    />
                    {<div className="message">For v3 pools.</div>}
                  </Form.Group>
                </Row>
                {isCheckedV3 && (
                  <Row className="mb-2">
                    <SumField
                      name="priceBottom"
                      label="Bottom price:"
                      disabled
                    />
                    <SumField name="priceTop" label="Top price:" disabled />
                  </Row>
                )}
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="formCheckBox">
                    <Form.Label>Change Token:</Form.Label>
                    <Form.Check
                      disabled
                      type="checkbox"
                      name="changeToken"
                      checked={values.changeToken}
                      onChange={(e) => {
                        handleChange(e);
                        setIsChecked(!isChecked);
                      }}
                      onBlur={handleBlur}
                      value={values.changeToken}
                    />
                    {
                      <div className="message">
                        If checked, can be change of reward token.
                      </div>
                    }
                  </Form.Group>
                  {isChecked && <TokenField name="tokenName" label="Token:" />}
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} controlId="formCheckBoxLP">
                    <Form.Label>Add LP Token:</Form.Label>
                    <Form.Check
                      type="checkbox"
                      name="lpToken"
                      checked={values.lpToken}
                      onChange={(e) => {
                        handleChange(e);
                        setIsCheckedLP(!isChecked);
                      }}
                      onBlur={handleBlur}
                      value={values.lpToken}
                    />
                    {
                      <div className="message">
                        If checked, can provide LP token.
                      </div>
                    }
                  </Form.Group>
                  {isCheckedLP && (
                    <Form.Group as={Col} controlId="formLPQty">
                      <Form.Label>LP q-ty: </Form.Label>
                      <Form.Control
                        type="number"
                        name="lpQty"
                        placeholder="...add q-ty"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lpQty}
                      ></Form.Control>
                      {touched.lpQty && errors.lpQty ? (
                        <div className="error-message">{errors.lpQty}</div>
                      ) : null}
                    </Form.Group>
                  )}
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UpdateFarming;
