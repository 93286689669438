import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Spinner, Card } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSort";
import {
  ColorSubs,
  NumberWithCommas,
} from "../../components/table/FormatedCell";

function Subscription() {
  const [subscriptions, setSubscriptions] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  //Card options
  let cardOptions = { cardName: "Subscriptions" };

  useEffect(() => {
    let unmounted = false;

    const fetchSubscriptions = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/subscription",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.subscriptions.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        if (!unmounted) setSubscriptions(responseData.subscriptions);
      } catch (err) {}
    };
    fetchSubscriptions();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        // show: false,
      },

      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row }) =>
          new Date(row.values.date).toLocaleDateString("en-GB"),
      },
      {
        Header: "Plan",
        accessor: "planName",
      },
      {
        Header: "Period",
        accessor: "subType",
      },
      {
        Header: "Sum",
        accessor: "sum",
        Cell: ({ row }) => <div>{`${NumberWithCommas(row.values.sum)} $`}</div>,
      },
      {
        Header: "cryptoAddress",
        accessor: "cryptoAddress",
      },

      {
        Header: "validTill",
        accessor: "validTill",
        Cell: ({ row }) =>
          new Date(row.values.validTill).toLocaleDateString("en-GB"),
      },
      {
        Header: "Status",
        accessor: "subStatus",
        Cell: ColorSubs,
      },
    ],
    []
  );

  return (
    <>
      {error && <ErrorAlert error={error} onClear={clearError} />}

      {isLoading && <Spinner animation="border" />}
      {!isLoading && subscriptions && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <div className="">
                <Link to="./new">
                  <button className="btn btn-sm btn-secondary">
                    Subscribe
                  </button>
                </Link>
              </div>
              <div className="fs-4 me-5">{cardOptions.cardName}</div>
              <div className="">
                <Link to="./payments">
                  <button className="btn btn-sm btn-secondary">Payments</button>
                </Link>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={subscriptions}
              // getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Subscription;
