import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Formik, Form } from "formik";
import { Spinner, Card, Button, Row } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import { ReactTable } from "../../components/TableSearch";
import DateField from "../../components/elements/form/DateField";
import {
  ColorCell,
  NumberWithCommas,
} from "../../components/table/FormatedCell";

function IncomeReport() {
  const [incomes, setIncomes] = useState();
  const [incomesR, setIncomesR] = useState([]);
  const [loadedSettings, setloadedSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  //Card options
  let cardOptions = { cardName: "Income report" };

  useEffect(() => {
    let unmounted = false;

    const fetchSettings = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/setting",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setloadedSettings(responseData.settings);
      } catch (err) {}
    };
    fetchSettings();

    const fetchIncomes = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/income",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.incomes.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        if (!unmounted) setIncomes(responseData.incomes);
      } catch (err) {}
    };
    fetchIncomes();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/incomes`);
      },
    };
  };

  //addTotal
  const getTotals = (data, key) => {
    let total = 0;
    data.forEach((item) => {
      total += item[key];
    });
    return total;
  };

  //remove 0 from array
  function removeElement(arrayName, arrayElement) {
    for (var i = 0; i < arrayName.length; i++) {
      if (arrayName[i] === arrayElement) arrayName.splice(i, 1);
    }
  }

  //Report button
  const getReport = (values) => {
    //get Providers
    let incomeProvider = [
      ...new Set(
        incomes.map((item) => {
          if (
            values.dateFrom <= item.date.split("T")[0] &&
            values.dateTo >= item.date.split("T")[0]
          ) {
            return item.providerName;
          } else {
            return 0;
          }
        })
      ),
    ];
    removeElement(incomeProvider, 0);
    const setProvider = (item, index) => {
      var object = { providerName: item };
      return object;
    };
    let incomesPro = incomeProvider.map(setProvider);

    incomesPro.forEach((r) => {
      incomes.forEach((s) => {
        if (s.incomeType === "Farming" && s.providerName === r.providerName) {
          r.incomeType = "Farming";
          r.incomeTotal = _.sumBy(incomes, (i) => {
            if (
              r.providerName === i.providerName &&
              i.incomeType === "Farming" &&
              values.dateFrom <= i.date.split("T")[0] &&
              values.dateTo >= i.date.split("T")[0]
            ) {
              return i.incomeDay;
            } else {
              return 0;
            }
          });
          r.incomePercentDay = _.sumBy(incomes, (i) => {
            if (
              r.providerName === i.providerName &&
              i.incomeType === "Farming" &&
              values.dateFrom <= i.date.split("T")[0] &&
              values.dateTo >= i.date.split("T")[0]
            ) {
              return i.incomeDay * i.incomePercent;
            } else {
              return 0;
            }
          });
          let apy = r.incomePercentDay / r.incomeTotal;
          r.apy = apy ? apy : 0;
        } else if (
          s.incomeType === "Lending" &&
          s.providerName === r.providerName
        ) {
          r.incomeType = "Lending";
          r.incomeTotal = _.sumBy(incomes, (i) => {
            if (
              r.providerName === i.providerName &&
              i.incomeType === "Lending" &&
              values.dateFrom <= i.date.split("T")[0] &&
              values.dateTo >= i.date.split("T")[0]
            ) {
              return i.incomeDay;
            } else {
              return 0;
            }
          });
          r.incomePercentDay = _.sumBy(incomes, (i) => {
            if (
              r.providerName === i.providerName &&
              i.incomeType === "Lending" &&
              values.dateFrom <= i.date.split("T")[0] &&
              values.dateTo >= i.date.split("T")[0]
            ) {
              return i.incomeDay * i.incomePercent;
            } else {
              return 0;
            }
          });
          let apy = r.incomePercentDay / r.incomeTotal;
          r.apy = apy ? apy : 0;
        }
      });
    });

    incomesPro.sort((a, b) =>
      a.incomeTotal < b.incomeTotal ? 1 : b.incomeTotal < a.incomeTotal ? -1 : 0
    );
    let incomeTotal = getTotals(incomesPro, "incomeTotal");
    let avrApy =
      _.sumBy(incomesPro, (i) => i.incomeTotal * i.apy) / incomeTotal;

    incomesPro.unshift({
      id: "",
      total: "Total",
      providerName: "",
      incomeType: "",
      incomeTotal: incomeTotal,
      apy: avrApy,
    });

    setIncomesR(incomesPro);
  };

  //Calculations
  let yellowGreen, redYellow;
  if (!isLoading && incomes && loadedSettings) {
    yellowGreen = loadedSettings.find((d) => d.name === "colorGreenApi");
    redYellow = loadedSettings.find((d) => d.name === "colorRedApi");
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      show: false,
    },
    {
      Header: "Total",
      accessor: "total",
      disableFilters: true,
    },
    {
      Header: "Provider",
      accessor: "providerName",
    },
    {
      Header: "IncomeType",
      accessor: "incomeType",
    },
    {
      Header: "IncomeTotal",
      accessor: "incomeTotal",
      disableFilters: true,
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.incomeTotal)} $`}</div>
      ),
    },
    {
      Header: "EvgAPY",
      accessor: "apy",
      disableFilters: true,
      Cell: (cell) => ColorCell(cell, yellowGreen.value, redYellow.value),
    },
  ];

  return (
    <React.Fragment>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {!isLoading && incomes && loadedSettings && (
        <Card className="text-center card-center w-100">
          <Card.Header>
            <div className="d-flex flex-row justify-content-between">
              <Formik
                initialValues={{
                  dateFrom: new Date().toISOString().split("T")[0],
                  dateTo: new Date().toISOString().split("T")[0],
                }}
                onSubmit={getReport}
              >
                {({ handleChange, handleBlur, handleSubmit }) => (
                  <Form onSubmit={handleSubmit} className="mx-auto w-50">
                    <div className="fs-4 mb-1">{cardOptions.cardName}</div>
                    <hr />
                    <Row className="mb-3">
                      <DateField name="dateFrom" label="From:" />
                      <DateField name="dateTo" label="To:" />
                    </Row>
                    <Row className="mb-3">
                      <div>
                        <Button
                          className="btn btn-sm btn-success"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </Row>
                  </Form>
                )}
              </Formik>

              <div className="">
                <Button
                  as={Link}
                  to={`../incomes`}
                  className="btn btn-sm btn-secondary"
                >
                  Back
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <ReactTable
              columns={columns}
              data={incomesR}
              getCellProps={onClickCellHandler}
            />
          </Card.Body>
        </Card>
      )}
    </React.Fragment>
  );
}

export default IncomeReport;
