import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CardMain from "../../components/CardMain";
import { ReactTable } from "../../components/TableSearch";
import { NumberWithCommas } from "../../components/table/FormatedCell";

export default function Payments() {
  const [payments, setPayments] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "Payment" };

  useEffect(() => {
    let unmounted = false;

    const fetchSubscriptions = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/payment",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.payments.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );
        if (!unmounted) setPayments(responseData.payments);
      } catch (err) {}
    };
    fetchSubscriptions();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  //Calculations
  // if (!isLoading && payments) {
  //   console.log("test");
  // }

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        navigate(`/payment/${cell.row.values.id}`);
      },
    };
  };

  //Columns
  const columns = [
    {
      Header: "Id",
      accessor: "id",
      disableFilters: true,
      show: false,
    },

    {
      Header: "Date",
      accessor: "date",
      Cell: ({ row }) => new Date(row.values.date).toLocaleDateString("en-GB"),
    },
    {
      Header: "chainName",
      accessor: "chainName",
    },
    {
      Header: "blockNumber",
      accessor: "blockNumber",
    },
    {
      Header: "cryptoAddress",
      accessor: "cryptoAddress",
    },
    {
      Header: "tokenName",
      accessor: "tokenName",
    },
    {
      Header: "sum",
      accessor: "sum",
      Cell: ({ row }) => (
        <div>{`${NumberWithCommas(row.values.sum, 2)} $`}</div>
      ),
    },
    {
      Header: "SubsId",
      accessor: "subId",
      // show: false,
    },
  ];

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />

      {isLoading && <Spinner animation="border" />}
      {!isLoading && payments && (
        <CardMain cardReport={true} cardName={cardOptions.cardName}>
          <ReactTable
            columns={columns}
            data={payments}
            getCellProps={onClickCellHandler}
          />
        </CardMain>
      )}
    </>
  );
}
