import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import ExchangeField from "../../components/elements/form/ExchangeField";
import ClientField from "../../components/elements/form/ClientField";
import TokenField from "../../components/elements/form/TokenField";
import CheckField from "../../components/elements/form/CheckField";

// Schema for yup
const validationSchema = Yup.object().shape({
  tokenName: Yup.string().required("*Token name is required"),
  exchangeName: Yup.string().required("*Exchange name is required"),
  clientName: Yup.string().required("*Client is required"),
});

const NewCompare = () => {
  const [tokens, setTokens] = useState();
  const [exchanges, setExchanges] = useState();
  const [clients, setClients] = useState();
  const [settings, setSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Comp" };

  useEffect(() => {
    let unmounted = false;

    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };
    if (!unmounted) {
      fetch("/setting", (data) => setSettings(data.settings));
      fetch("/token", (data) => setTokens(data.tokens));
      fetch("/exchange", (data) => setExchanges(data.exchanges));
      fetch("/client", (data) => setClients(data.clients));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  let allloaded = !isLoading && tokens && clients && settings && exchanges;

  let defaultClient;
  if (allloaded) {
    //Default client
    settings.forEach((s) => {
      if (s.description === "Default client") {
        defaultClient = s.value;
      }
    });
  }

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allloaded && (
        <Formik
          initialValues={{
            exchangeName: "",
            tokenName: "",
            isSecondToken: false,
            tokenName2: "",
            clientName: defaultClient ? defaultClient : "",
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/comp`,
                "POST",
                JSON.stringify({
                  exchangeName: values.exchangeName,
                  tokenName: values.tokenName,
                  isSecondToken: values.isSecondToken,
                  tokenName2: values.tokenName2,
                  clientName: values.clientName,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
            navigate("../comps");
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto w-75">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <TokenField name="tokenName" label="Token:" />
                  <ExchangeField name="exchangeName" label="Exchange:" />
                </Row>
                <Row className="mb-3">
                  <CheckField
                    name="isSecondToken"
                    label="Another token:"
                    checked={values.isSecondToken}
                  />
                  {values.isSecondToken ? (
                    <TokenField name="tokenName2" label="Token 2:" />
                  ) : null}
                </Row>
                <Row className="mb-3">
                  <ClientField name="clientName" label="Client:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewCompare;
