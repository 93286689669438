import React, { useEffect, useState, useContext } from "react";
import { useTable, useSortBy } from "react-table";
import { Link, useNavigate } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import styled from "styled-components";
import _ from "lodash";

import "../new/New.css";
import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";

const ALERTDIV = styled.div`
  width: 40%;
  margin: 5em auto;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  font-family: "Roboto Mono", monospace;
  src: url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap");
`;

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  cell,
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) setValue(initialValue);
    return () => {
      unmounted = true;
    };
  }, [initialValue]);

  // Check to make sure not all columns are editable
  if (
    new Date(cell.row.values.posDate).toLocaleDateString("en-GB") ===
    new Date().toLocaleDateString("en-GB")
  ) {
    return (
      <input
        style={{
          fontSize: "12px",
          width: "7rem",
          border: "1px solid #292929",
          backgroundColor: "#F5EEDC",
        }}
        type={"number"}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
  return <div>{value}</div>;
};

// // Set our editable cell renderer as the default Cell renderer
// const defaultColumn = {
//   Cell: EditableCell,
// };

// Create a default prop getter
const defaultPropGetter = () => ({});

// Be sure to pass our updateMyData and the skipPageReset option
function Table({
  columns,
  data,
  updateMyData,
  skipPageReset,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  sortBy,
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: columns
            .filter((col) => col.show === false)
            .map((col) => col.accessor),
        },
        sortBy,
        // use the skipPageReset option to disable page resetting temporarily
        autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        updateMyData,
      },
      useSortBy
    );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 200);
  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),
                    getHeaderProps(column),
                    column.getSortByToggleProps(),
                  ])}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <div>Showing the first 200 results of {rows.length} rows</div>
    </>
  );
}

const Borrows = () => {
  const [data, setData] = useState();
  const [harvs, setHarvs] = useState();
  const [incomes, setIncomes] = useState();
  const [bonuses, setBonuses] = useState();
  const [loadedManagers, setLoadedManagers] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [loadedLends, setLoadedLends] = useState();
  const [loadedTokens, setLoadedTokens] = useState();
  const [loadedProjects, setLoadedProjects] = useState();
  const [loadedPools, setLoadedPools] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    let unmounted = false;

    const fetchBorrows = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/borrow",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        responseData.borrows.sort((a, b) =>
          a.posDate < b.posDate ? 1 : b.posDate < a.posDate ? -1 : 0
        );
        if (!unmounted) setData(responseData.borrows);
      } catch (err) {}
    };
    fetchBorrows();

    const fetchLends = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/lend",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedLends(responseData.lends);
      } catch (err) {}
    };
    fetchLends();

    const fetchTokens = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/token",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedTokens(responseData.tokens);
      } catch (err) {}
    };
    fetchTokens();

    const fetchPools = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/pool",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedPools(responseData.pools);
      } catch (err) {}
    };
    fetchPools();

    const fetchProjects = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/project",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedProjects(responseData.projects);
      } catch (err) {}
    };
    fetchProjects();

    const fetchHarvs = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/harv",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setHarvs(responseData.harvs);
      } catch (err) {}
    };
    fetchHarvs();

    const fetchIncomes = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/income",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.incomes.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        if (!unmounted) setIncomes(responseData.incomes);
      } catch (err) {}
    };
    fetchIncomes();
    const fetchBonuses = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/bonus",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        responseData.bonuses.sort((a, b) =>
          a.date < b.date ? 1 : b.date < a.date ? -1 : 0
        );

        if (!unmounted) setBonuses(responseData.bonuses);
      } catch (err) {}
    };
    fetchBonuses();

    const fetchManagers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/manager",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (!unmounted) setLoadedManagers(responseData.managers);
      } catch (err) {}
    };
    fetchManagers();

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  //Date
  let yesterdayDate = new Date(
    new Date().setDate(new Date().getDate() - 1)
  ).toLocaleDateString("en-GB");
  // let todayDate = new Date(
  //   new Date().setDate(new Date().getDate())
  // ).toLocaleDateString("en-GB");

  const getPrice = (tokenSymbol) => {
    let tokenObj;
    // if (
    //   !isLoading &&
    //   data &&
    //   harvs &&
    //   incomes &&
    //   bonuses &&
    //   loadedLends &&
    //   loadedTokens &&
    //   loadedManagers &&
    //   loadedProjects &&
    //   loadedPools
    // ) {
    tokenObj = loadedTokens.find((e) => e.tokenSymbol === tokenSymbol);
    // }
    return tokenObj.tokenPrice;
  };

  //Calculations

  const ifNoneToZero = (digit) => {
    if (digit) {
      return digit;
    } else {
      return 0;
    }
  };

  if (
    !isLoading &&
    data &&
    harvs &&
    incomes &&
    bonuses &&
    loadedLends &&
    loadedTokens &&
    loadedManagers &&
    loadedProjects &&
    loadedPools
  ) {
    // loadedLends.forEach((e) => {
    //   e.pairs = e.pair1 + "/" + e.pair2;
    //   e.pair1Price = getPrice(e.pair1);
    //   e.pair2Price = getPrice(e.pair2);
    //   let pair1Sum = e.pair1Qty * e.pair1Price;
    //   let pair2Sum = e.pair2Qty * e.pair2Price;
    //   e.posSum = parseInt(
    //     (Math.round((pair1Sum + pair2Sum) * 100) / 100).toFixed(0)
    //   );
    // });

    data.forEach((d) => {
      if (
        new Date(d.posDate).toLocaleDateString("en-GB") ===
        new Date().toLocaleDateString("en-GB")
      ) {
        d.tokenPrice = getPrice(d.tokenName);

        let lpQtyMinus1 = _.sumBy(data, (w) => {
          if (
            new Date(w.posDate).toLocaleDateString("en-GB") === yesterdayDate &&
            d.projectNumber === w.projectNumber
          ) {
            return w.lpQty;
          }
        });

        if (!lpQtyMinus1) {
          d.lpQtyStart = d.lpQty;
        } else {
          d.lpQtyStart = _.sumBy(data, (w) => {
            if (
              new Date(w.posDate).toLocaleDateString("en-GB") ===
                yesterdayDate &&
              d.projectNumber === w.projectNumber
            ) {
              return ifNoneToZero(w.lpQtyStart);
            }
          });
        }

        d.lpQtyMinus1 = ifNoneToZero(lpQtyMinus1);

        d.tokenQtyMinus1 = _.sumBy(data, (w) => {
          if (
            new Date(w.posDate).toLocaleDateString("en-GB") === yesterdayDate &&
            d.projectNumber === w.projectNumber &&
            !d.isHarvested
          ) {
            return ifNoneToZero(w.tokenQty);
          } else {
            return 0;
          }
        });

        d.lpDiffDay = d.lpQty - d.lpQtyMinus1;
        d.lpDiffTotal = d.lpQty - d.lpQtyStart;

        if (d.posSum && d.lpQtyMinus1 && d.lpDiffDay) {
          d.lpIncomeDay = (d.posSum / d.lpQtyMinus1) * d.lpDiffDay;
        } else {
          d.lpIncomeDay = 0;
        }

        if (d.posSum && d.lpQtyStart) {
          d.lpIncomeTotal = (d.posSum / d.lpQtyStart) * d.lpDiffTotal;
        } else {
          d.lpIncomeTotal = 0;
        }

        if (d.tokenPrice) {
          d.posIncomeDay =
            (d.tokenQty - ifNoneToZero(d.tokenQtyMinus1)) * d.tokenPrice +
            d.lpIncomeDay;
        } else {
          d.posIncomeDay = 0;
        }
        // console.log(`d.token_${d.lpIncomeTotal}`);
        if (d.tokenPrice) {
          d.posIncomeTotal = d.tokenQty * d.tokenPrice + d.lpIncomeTotal;
        } else {
          d.posIncomeTotal = 0;
        }
        if (d.posIncomeDay && d.posSum) {
          d.posApy = (d.posIncomeDay * 36000) / d.posSum;
        } else {
          d.posApy = 0;
        }
      }
    });
  }

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) setSkipPageReset(false);

    return () => {
      unmounted = true;
    };
  }, [data]);

  const SubmitSave = () => {
    if (
      !isLoading &&
      data &&
      harvs &&
      incomes &&
      bonuses &&
      loadedLends &&
      loadedTokens &&
      loadedManagers &&
      loadedProjects &&
      loadedPools
    ) {
      data.forEach((e) => {
        const submitDetail = async () => {
          try {
            await sendRequest(
              process.env.REACT_APP_BACKEND_URL + `/borrow/${e.id}`,
              "PATCH",
              JSON.stringify({
                posDate: e.posDate,
                lendingName: e.lendingName,
                providerName: e.providerName,
                tokensPos: e.tokensPos,
                posSum: e.posSum,
                tokenName: e.tokenName,
                tokenPrice: e.tokenPrice,
                isHarvested: e.isHarvested,
                tokenQty: e.tokenQty,
                posIncomeTotal: e.posIncomeTotal,
                posIncomeDay: e.posIncomeDay,
                posApy: e.posApy,
                managerName: e.managerName,
                clientName: e.clientName,
                userId: auth.userId,
              }),
              {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {}
        };
        submitDetail();
      });

      //Calculation for Harv
      harvs.forEach((h) => {
        if (
          new Date(h.harvDate).toLocaleDateString("en-GB") ===
          new Date().toLocaleDateString("en-GB")
        ) {
          h.incomeTotal = _.sumBy(data, (f) => {
            if (
              h.clientName === f.clientName &&
              new Date(f.posDate).toLocaleDateString("en-GB") ===
                new Date().toLocaleDateString("en-GB")
            ) {
              return f.posIncomeTotal;
            }
          });
          h.incomeDay = _.sumBy(data, (f) => {
            if (
              h.clientName === f.clientName &&
              new Date(f.posDate).toLocaleDateString("en-GB") ===
                new Date().toLocaleDateString("en-GB")
            ) {
              return Math.round(f.posIncomeDay);
            }
          });
          h.posSum = _.sumBy(data, (f) => {
            if (
              h.clientName === f.clientName &&
              new Date(f.posDate).toLocaleDateString("en-GB") ===
                new Date().toLocaleDateString("en-GB")
            ) {
              return f.posSum;
            }
          });
          h.apy = Math.round((h.incomeDay * 36500) / h.posSum);
          h.positions = _.sumBy(data, (w) => {
            if (
              h.clientName === w.clientName &&
              new Date(w.posDate).toLocaleDateString("en-GB") ===
                new Date().toLocaleDateString("en-GB")
            ) {
              return `${w.providerName} ${numberWithCommas(
                w.posSum
              )}$ ${numberWithCommas(w.posIncomeDay)}$ ${numberWithCommas(
                w.posApy
              )}%|`;
            }
          });
          // console.log(new Date(h.harvDate).toLocaleDateString("en-GB"));
        }
      });

      //submit Harvs
      harvs.forEach((e) => {
        // console.log(`harv_${new Date().toLocaleDateString("en-GB")}`);
        //add harv
        if (
          new Date(e.harvDate).toLocaleDateString("en-GB") ===
            new Date().toLocaleDateString("en-GB") &&
          e.incomeType === "Lending"
        ) {
          const submitHarv = async () => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/harv/${e.id}`,
                "PATCH",
                JSON.stringify({
                  harvDate: e.harvDate,
                  positions: e.positions,
                  incomeType: e.incomeType,
                  incomeTotal: e.incomeTotal,
                  incomeDay: e.incomeDay,
                  apy: e.apy,
                  clientName: e.clientName,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
            navigate("../harvesting/");
          };
          submitHarv();
        }
      });
      setData(data);

      //submit Incomes

      // let incomeProvider = [...new Set(data.map((item) => item.providerName))];
      // const setProvider = (item, index) => {
      //   var object = { providerName: item };
      //   return object;
      // };
      // incomesPro = incomeProvider.map(setProvider);

      incomes.forEach((h) => {
        h.incomeDay = _.sumBy(data, (w) => {
          if (
            new Date(w.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB") &&
            h.providerName === w.providerName
          ) {
            return ifNoneToZero(w.posIncomeDay);
          } else {
            return 0;
          }
        });
        h.posSum = _.sumBy(data, (w) => {
          if (
            new Date(w.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB") &&
            h.providerName === w.providerName
          ) {
            return ifNoneToZero(w.posSum);
          } else {
            return 0;
          }
        });

        h.incomePercent = (h.incomeDay * 36500) / h.posSum;

        h.managerName = _.sumBy(data, (w) => {
          if (
            new Date(w.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB") &&
            h.providerName === w.providerName
          ) {
            return w.managerName;
          }
        });
        //submit
        if (
          new Date(h.date).toLocaleDateString("en-GB") ===
            new Date().toLocaleDateString("en-GB") &&
          h.incomeType === "Lending"
        ) {
          const submitIncome = async () => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/income/${h.id}`,
                "PATCH",
                JSON.stringify({
                  date: new Date(),
                  providerName: h.providerName,
                  incomeType: h.incomeType,
                  incomeDay: h.incomeDay,
                  incomePercent: h.incomePercent,
                  managerName: h.managerName,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
          };
          submitIncome();
        }
      });
      //submit Bonuses
      // let incomeManager = [...new Set(data.map((item) => item.managerName))];
      // const setManager = (item, index) => {
      //   var object = { managerName: item };
      //   return object;
      // };
      // incomesMan = incomeManager.map(setManager);

      bonuses.forEach((m) => {
        m.incomeDay = _.sumBy(data, (w) => {
          if (
            new Date(w.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB") &&
            m.managerName === w.managerName
          ) {
            return ifNoneToZero(w.posIncomeDay);
          } else {
            return 0;
          }
        });
        m.managerBonus = _.sumBy(loadedManagers, (n) => {
          if (m.managerName === n.managerName) {
            return n.managerBonus;
          }
        });
        m.bonusDay = (m.incomeDay * m.managerBonus) / 100;

        //submit
        if (
          new Date(m.date).toLocaleDateString("en-GB") ===
            new Date().toLocaleDateString("en-GB") &&
          m.incomeType === "Lending"
        ) {
          const submitIncomeMan = async () => {
            try {
              await sendRequest(
                process.env.REACT_APP_BACKEND_URL + `/bonus/${m.id}`,
                "PATCH",
                JSON.stringify({
                  date: new Date(),
                  managerName: m.managerName,
                  incomeType: m.incomeType,
                  incomeDay: m.incomeDay,
                  bonusDay: m.bonusDay,
                  userId: auth.userId,
                }),
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {}
          };
          submitIncomeMan();
        }
      });
    }
  };
  const numberWithCommas = (number) => {
    return Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  //Delete
  const deleteHandler = async (Id) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/detail/${Id}`,
        "DELETE",
        null,
        { Authorization: "Bearer " + auth.token }
      );
    } catch (err) {}
    navigate("../harvesting/");
  };
  const getLink = (poolName) => {
    let link;
    loadedPools.forEach((e) => {
      if (e.poolName === poolName) {
        link = e.poolLink;
      }
    });
    return link;
  };
  if (!isLoading && data && loadedPools) {
    data.forEach((e) => {
      e.linkProvider = getLink(e.providerName);
    });
  }

  const onClickCellHandler = (cell) => {
    return {
      onClick: () => {
        switch (cell.column.id) {
          case "delete":
            if (
              new Date(cell.row.values.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB")
            ) {
              deleteHandler(cell.row.original.id);
            } else {
              alert("Deleting of previous days are not allowed");
            }
            break;
          case "providerName":
            if (
              new Date(cell.row.values.posDate).toLocaleDateString("en-GB") ===
              new Date().toLocaleDateString("en-GB")
            ) {
              window.open(`${cell.row.original.linkProvider}`);
            } else {
              alert("Deleting of previous days are not allowed");
            }
            break;

          default:
            break;
        }
      },
    };
  };

  // const deleteHandler = ({ row }) => {
  //   return {
  //     onClick: () => {
  //       console.log("delete");
  //       //  navigate(`/comparing/${cell.row.values.id}`);
  //     },
  //   };
  // };

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },
      {
        Header: "Date",
        accessor: "posDate",
        Cell: ({ row }) =>
          new Date(row.values.posDate).toLocaleDateString("en-GB"),
      },
      {
        Header: "LendingName",
        accessor: "lendingName",
      },
      {
        Header: "Provider",
        accessor: "providerName",
        Cell: ({ row }) => {
          return (
            <div
              style={{
                textDecoration: "underline",
                color: "green",
              }}
            >
              {row.values.providerName}
            </div>
          );
        },
      },
      {
        Header: "TokensInLending",
        accessor: "tokensPos",
      },
      {
        Header: "Sum",
        accessor: "posSum",
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {`${numberWithCommas(row.values.posSum)} $`}
          </div>
        ),
      },
      {
        Header: "RewardToken",
        accessor: "tokenName",
      },
      {
        Header: "isHarv-d",
        accessor: "isHarvested",
        Cell: ({ cell: { value, column }, row: { original } }) => {
          return (
            <div>
              <Form.Check
                type="checkbox"
                //name="isHarvested"
                checked={original.isHarvested}
                value={original.isHarvested}
                onChange={() => {
                  setIsChecked(original.isHarvested);
                  setIsChecked(!isChecked);
                  original.isHarvested = !original.isHarvested;
                  updateMyData(parseInt(original.id), column.id, isChecked);
                }}
                onBlur={(event) => {}}
              />
            </div>
          );
        },
      },
      {
        Header: "Token Q-ty -1",
        accessor: "tokenQtyMinus1",
        //show: false,
      },
      {
        Header: "Token Q-ty",
        accessor: "tokenQty",
        Cell: EditableCell,
      },
      {
        Header: "Token Price",
        accessor: "tokenPrice",
        show: false,
      },
      {
        Header: "Total income",
        accessor: "posIncomeTotal",
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {`${numberWithCommas(row.values.posIncomeTotal)} $`}
          </div>
        ),
      },

      {
        Header: "Income Day",
        accessor: "posIncomeDay",
        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            {`${numberWithCommas(row.values.posIncomeDay)} $`}
          </div>
        ),
      },
      {
        Header: "APY",
        accessor: "posApy",
        Cell: ({ row }) => {
          let color;
          if (row.values.posApy <= 80) {
            color = (
              <div
                style={{
                  color: "#FFF8F3",
                  textAlign: "center",
                  padding: "0.5rem",
                  margin: -7,
                  fontSize: "15px",
                  backgroundColor: "#FFAD60",
                }}
              >
                {`${Math.round(row.values.posApy)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`}
              </div>
            );
          } else if (row.values.posApy > 80) {
            color = (
              <div
                style={{
                  textAlign: "center",
                  padding: "0.5rem",
                  margin: -7,
                  fontSize: "15px",
                  backgroundColor: "#A3DA8D",
                }}
              >
                {`${Math.round(row.values.posApy)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`}
              </div>
            );
          } else {
            color = (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                {`${Math.round(row.values.posApy)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`}
              </div>
            );
          }
          return color;
        },
      },
      {
        Header: "Del",
        accessor: "delete",
        Cell: ({ row }) => {
          if (
            new Date(row.values.posDate).toLocaleDateString("en-GB") ===
            new Date().toLocaleDateString("en-GB")
          ) {
            return <button type="button">x</button>;
          } else {
            return <div></div>;
          }
        },
      },
      {
        Header: "Manager",
        accessor: "managerName",
        show: false,
      },
      {
        Header: "Client",
        accessor: "clientName",
        show: false,
      },
      {
        Header: "userId",
        accessor: "userid",
        show: false,
      },
    ],
    [isChecked]
  );

  const sortBy = [{ id: "posDate" }, { id: "chainName" }];

  return (
    <React.Fragment>
      <ALERTDIV>
        <ErrorAlert error={error} onClear={clearError} />
      </ALERTDIV>
      {isLoading && <Spinner animation="border" />}
      {!isLoading &&
        data &&
        harvs &&
        incomes &&
        bonuses &&
        loadedLends &&
        loadedTokens &&
        loadedManagers &&
        loadedProjects &&
        loadedPools && (
          <div>
            <Link to="../harvesting/">
              <button
                style={{ marginLeft: "1px", marginBottom: "2px" }}
                type="button"
              >
                Back
              </button>
            </Link>

            <button
              onClick={SubmitSave}
              className="button-report"
              style={{ marginLeft: "1px", marginBottom: "2px", align: "" }}
              type="submit"
            >
              Save
            </button>

            <Table
              columns={columns}
              data={data}
              sortBy={sortBy}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
              getCellProps={onClickCellHandler}
            />
          </div>
        )}
    </React.Fragment>
  );
};

export default Borrows;
