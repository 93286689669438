import React, { useContext, useState, useEffect } from "react";
import { Form, Spinner, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { AuthContext } from "../../components/context/auth-context";
import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import CardNewUpdate from "../../components/CardNewUpdate";
import PoolField from "../../components/elements/form/PoolField";
import TokenField from "../../components/elements/form/TokenField";
import SumField from "../../components/elements/form/SumField";
import ManagerField from "../../components/elements/form/ManagerField";
import ChainField from "../../components/elements/form/ChainField";
import CommentField from "../../components/elements/form/CommentField";

// Schema for yup
const validationSchema = Yup.object().shape({
  providerName: Yup.string().required("*Provider is required"),
  poolName: Yup.string().required("*Pool is required"),
  pair1: Yup.string().required("*Pair is required"),
  pair2: Yup.string().required("*Pair is required"),
  apy: Yup.number().required("*APY is required"),
  chainName: Yup.string().required("*Chain is required"),
  managerName: Yup.string().required("*Manager is required"),
});

const NewProject = () => {
  const [loadedChains, setLoadedChains] = useState();
  const [loadedClients, setLoadedClients] = useState();
  const [pools, setPools] = useState();
  const [tokens, setTokens] = useState();
  const [loadedManagers, setLoadedManagers] = useState();
  const [loadedProjects, setLoadedProjects] = useState();
  const [loadedSettings, setloadedSettings] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Project" };

  useEffect(() => {
    let unmounted = false;
    const fetch = async (path, setLoad) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + path,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoad(responseData);
      } catch (err) {}
    };

    if (!unmounted) {
      fetch("/setting", (data) => setloadedSettings(data.settings));
      fetch("/pool", (data) => setPools(data.pools));
      fetch("/token", (data) => setTokens(data.tokens));
      fetch("/project", (data) => setLoadedProjects(data.projects));
      fetch("/chain", (data) => setLoadedChains(data.chains));
      fetch("/client", (data) => setLoadedClients(data.clients));
      fetch("/manager", (data) => setLoadedManagers(data.managers));
    }

    return () => {
      unmounted = true;
    };
  }, [sendRequest, auth.token]);

  let allLoaded =
    !isLoading &&
    tokens &&
    loadedClients &&
    loadedChains &&
    pools &&
    loadedManagers &&
    loadedProjects &&
    loadedSettings;

  //Calculations
  let projectNumber, defaultManager;
  if (allLoaded) {
    //project number
    let numbersArray = loadedProjects.map((p) => p.projectNumber);
    if (numbersArray !== null) {
      projectNumber = _.max(numbersArray) + 1;
    }

    //default manager
    loadedSettings.forEach((s) => {
      if (s.description === "Default manager") {
        defaultManager = s.value;
      }
    });
  }
  const onSubmit = async (values) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/project`,
        "POST",
        JSON.stringify({
          projectNumber: values.projectNumber,
          providerName: values.providerName,
          poolName: values.poolName,
          pair1: values.pair1,
          pair2: values.pair2,
          apy: values.apy,
          chainName: values.chainName,
          managerName: values.managerName,
          inFocus: values.inFocus,
          comments: values.comments,
          userId: values.userId,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {}
    navigate("../projects");
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      {allLoaded && (
        <Formik
          initialValues={{
            projectNumber: projectNumber || 1,
            providerName: "",
            poolName: "",
            pair1: "",
            pair2: "",
            apy: "",
            chainName: "",
            managerName: defaultManager ? defaultManager : "",
            inFocus: false,
            comments: "",
            userId: auth.userId,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="mx-auto  w-75">
              <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formProjectNumber">
                    <Form.Label>Project Number: </Form.Label>
                    <Form.Control
                      type="number"
                      name="projectNumber"
                      placeholder=""
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.projectNumber}
                      className={
                        touched.projectNumber && errors.projectNumber
                          ? "error"
                          : null
                      }
                    />
                    {touched.projectNumber && errors.projectNumber ? (
                      <div className="error-message">
                        {errors.projectNumber}
                      </div>
                    ) : null}
                  </Form.Group>
                  <PoolField name="providerName" label="Provider Name:" />
                  <PoolField name="poolName" label="Pool Name:" />
                </Row>

                <Row className="mb-3">
                  <TokenField name="pair1" label="Pair 1:" />
                  <TokenField name="pair2" label="Pair 2:" />
                  <SumField name="apy" label="APY:"></SumField>
                </Row>
                <Row className="mb-3">
                  <ChainField name="chainName" label="Chain:" />
                  <ManagerField name="managerName" label="Manager:" />
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>In Focus:</Form.Label>
                    <Form.Check
                      type="checkbox"
                      name="inFocus"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.status}
                    />
                    {
                      <div className="message">
                        If checked, will be on top of the list.
                      </div>
                    }
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <CommentField name="comments" label="Comments:" />
                </Row>
              </CardNewUpdate>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewProject;
