import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner, Alert } from "react-bootstrap";

import ErrorAlert from "../components/elements/ErrorAlert";
import { useHttpClient } from "../components/hooks/http-hook";
import { AuthContext } from "../components/context/auth-context";
import CardMain from "../components/CardMain";
import { ReactTable } from "../components/TableSearch";

function Users() {
  const [loadedUsers, setLoadedUsers] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card options
  let cardOptions = { cardName: "User" };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/user",
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoadedUsers(responseData.users);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest, auth.token]);

  const boolHandler = (bool) => {
    let yesNo;
    if (!!bool === true) {
      yesNo = "Yes";
    } else {
      yesNo = "-";
    }
    return yesNo;
  };

  if (!isLoading && loadedUsers) {
    loadedUsers.forEach((e) => {
      e.verifiedText = boolHandler(e.verified);
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        show: false,
      },

      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "UserType",
        accessor: "userType",
      },
      {
        Header: "planName",
        accessor: "planName",
      },
      {
        Header: "subType",
        accessor: "subType",
      },
      {
        Header: "verified",
        accessor: "verifiedText",
      },
      {
        Header: "userId",
        accessor: "_id",
      },
    ],
    []
  );

  return (
    <>
      {/* {errorSetting && <Alert variant="warning">{errorSetting.message}</Alert>} */}
      {error && (
        <Alert variant="warning">
          <ErrorAlert error={error} onClear={clearError} />
        </Alert>
      )}
      {isLoading && (
        <div className="center">
          <Spinner />
        </div>
      )}
      {!isLoading && loadedUsers && (
        <CardMain cardReport={false} cardName={cardOptions.cardName}>
          <ReactTable
            columns={columns}
            data={loadedUsers}
            getRowProps={(row) => ({
              style: { cursor: "pointer" },
              onClick: () => {
                navigate(`/user/${row.values.id}`);
              },
            })}
          />
        </CardMain>
      )}
    </>
  );
}

export default Users;
