import React, { useContext, useEffect, useCallback } from "react";
import { Form, Row, Col, Spinner, InputGroup, Button } from "react-bootstrap";
import { Formik, useFormikContext, useField, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import ErrorAlert from "../../components/elements/ErrorAlert";
import { useHttpClient } from "../../components/hooks/http-hook";
import { AuthContext } from "../../components/context/auth-context";
import CardNewUpdate from "../../components/CardNewUpdate";
import CheckField from "../../components/elements/form/CheckField";

// Schema for yup
const validationSchema = Yup.object().shape({
  tokenSymbol: Yup.string().required("*Symbol is required"),
  coinGecko: Yup.string().required("*CoinGecko name is required"),
  tokenPrice: Yup.number().required("*Price is required"),
});

const PriceField = (props) => {
  const { sendRequest } = useHttpClient();

  const {
    values: { tokenSymbol, coinGecko },
    touched,
    setFieldValue,
  } = useFormikContext();
  const [field] = useField(props.name);

  const priceHandler = useCallback(async () => {
    //event.preventDefault();
    let price;
    try {
      const responseData = await sendRequest(
        `https://api.coingecko.com/api/v3/simple/price?ids=${coinGecko}&vs_currencies=usd`
      );
      if (Object.keys(responseData).length === 0) {
        throw new Error();
      }
      price = _.get(responseData, [coinGecko, "usd"]);
      if (props.ischecked) {
        setFieldValue(props.name, price * 1000);
      } else {
        setFieldValue(props.name, price);
      }
    } catch (err) {
      //throw err = new Error("CoinGecko name is not correct.");
    }
  }, [coinGecko, props.name, sendRequest, setFieldValue, props.ischecked]);

  useEffect(() => {
    if (
      tokenSymbol.trim() !== "" &&
      coinGecko.trim() !== "" &&
      touched.tokenSymbol &&
      touched.coinGecko
    ) {
      priceHandler();
    }
  }, [
    tokenSymbol,
    coinGecko,
    touched.tokenSymbol,
    touched.coinGecko,
    priceHandler,
  ]);
  return (
    <>
      <Form.Control
        {...props}
        {...field}
        disabled={props.disabled ? false : true}
      />
      {/* {!!meta.touched && !!meta.error && <div>{meta.error}</div>} */}
    </>
  );
};

const NewToken = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //Card name
  let cardOptions = { cardName: "Token" };

  const clickHandler = () => {
    window.open("https://www.coingecko.com/", "_blank");
  };

  return (
    <>
      <ErrorAlert error={error} onClear={clearError} />
      {isLoading && <Spinner animation="border" />}

      <Formik
        initialValues={{
          tokenSymbol: "",
          coinGecko: "",
          tokenPrice: "",
          is1000: false,
          activated: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          try {
            await sendRequest(
              process.env.REACT_APP_BACKEND_URL + "/token",
              "POST",
              JSON.stringify({
                tokenSymbol: values.is1000
                  ? `${values.tokenSymbol} x1000`
                  : values.tokenSymbol,
                coinGecko: values.coinGecko,
                tokenPrice: values.tokenPrice,
                is1000: values.is1000,
                activated: values.activated,
              }),
              {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {}
          navigate("../tokens");
        }}
      >
        {/* Callback function containing Formik state and helpers that handle common form actions */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="mx-auto w-50">
            <CardNewUpdate cardNew={true} cardName={cardOptions.cardName}>
              <Row className="mb-3">
                {/* <TextField name="tokenSymbol" label="Token:" /> */}
                <InputGroup className="mb-1 col">
                  <Button variant="outline-secondary" id="button-addon1">
                    Token
                  </Button>
                  <Field name="tokenSymbol" className=" form-control" />
                </InputGroup>
                <InputGroup className="mb-1 col">
                  <Field
                    placeholder="...add coinGecko id"
                    name="coinGecko"
                    className=" form-control"
                  />
                  <Button
                    onClick={clickHandler}
                    variant="secondary"
                    type="button"
                    id="button-addon1"
                  >
                    get id
                  </Button>
                </InputGroup>
              </Row>
              <Row className="mb-3">
                {touched.tokenSymbol && errors.tokenSymbol ? (
                  <div className="text-danger col">{errors.tokenSymbol}</div>
                ) : (
                  <div className="col"></div>
                )}
                {touched.coinGecko && errors.coinGecko ? (
                  <div className="text-danger col">{errors.coinGecko}</div>
                ) : (
                  <div className="col"></div>
                )}
              </Row>
              <Row className="mb-3">
                <CheckField
                  name="is1000"
                  label="is x1000:"
                  checked={values.is1000}
                  text={"If checked, price will be x1000"}
                />

                <Form.Group as={Col} controlId="formTokenPrice">
                  <Form.Label>Price of token:</Form.Label>
                  <br />

                  <PriceField
                    className={
                      touched.tokenPrice && errors.tokenPrice ? "error" : null
                    }
                    name="tokenPrice"
                    placeholder="price"
                    ischecked={values.is1000}
                    disabled={auth.userType === "admin" ? 1 : null}
                  />

                  {touched.tokenPrice && errors.tokenPrice ? (
                    <div className="text-danger">{errors.tokenPrice}</div>
                  ) : null}
                </Form.Group>
              </Row>
              {auth.userType === "admin" ? (
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Activated:</Form.Label>
                    <Field as={Form.Select} name="activated">
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </Field>
                  </Form.Group>
                </Row>
              ) : null}
            </CardNewUpdate>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NewToken;
