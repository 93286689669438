import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";

function ButtonsNew(props) {
  const { isSubmitting } = Formik;
  return (
    <>
      <Button
        variant="success"
        type="submit"
        disabled={isSubmitting}
        className="me-3"
      >
        Save
      </Button>
      {/* <Button variant="outline-danger">Delete</Button> */}
      <Link to={props.link}>
        <Button variant="outline-secondary">Cancel</Button>
      </Link>
    </>
  );
}

export default ButtonsNew;
